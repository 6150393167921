//hooks
import React, { useEffect, useState } from "react";
//components
import { Container } from '../../../components/container/container'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { LabelInput } from '../../../components/label/label.input'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { Footer } from '../../../components/footer/Footer'
import { Button } from '../../../components/buttons/button.default'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { getAssociations, getPaymentMethods } from "../../../services/api/callAPIsFunctions/defaultCalls.api";
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { api } from "../../../services/api/api";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import Multiselect from "../../../components/selects/mult.select";
import { paymentMethods } from "../../../utils/payment/paymentMethods";
import { useScreenSizeContext } from "../../../contexts/screenSizeContext";

export function PaymentMethods() {

    const navigate = useNavigate()
    const { screenX } = useScreenSizeContext()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [associationList, setAssociationsList] = useState([])

    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])
    const [clientModules, setClientModules] = useState([])
    const [selectedAssociation, setSelectedAssociation] = useState('')
    const [creditCardModules, setCreditCardModules] = useState([])
    const [pixModules, setPixModules] = useState([])
    const [moneyModules, setMoneyModules] = useState([])
    const [payRollModules, setPayRollModules] = useState([])

    useEffect(() => {

        async function getData() {

            const associationsGet = await getAssociations(userData[0]?.token, 1)
            if (associationsGet.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(associationsGet.data)
                return
            }

            const paymentsDataList = await getPaymentMethods(userData[0].token)
            if (paymentsDataList.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(paymentsDataList.data)
                return
            }
            setAssociationsList(associationsGet.data)
            if (paymentsDataList.data.length > 0) {
                paymentsDataList.data.map((paymentMethod) => {
                    if (paymentMethod.methodKey === 'C' && paymentMethod.forClient) {
                        selectedPaymentMethods.push('C')
                        let modulesThisMethod = []
                        userData[0]?.customersData.modules?.filter(module => {
                            if (paymentMethod.modules.includes(module?.id)) {
                                modulesThisMethod.push(module)
                            }
                        })
                        setCreditCardModules(modulesThisMethod)
                    }

                    if (paymentMethod.methodKey === 'P' && paymentMethod.forClient) {
                        selectedPaymentMethods.push('P')
                        let modulesThisMethod = []
                        userData[0]?.customersData.modules?.filter(module => {
                            if (paymentMethod.modules.includes(module?.id)) {
                                modulesThisMethod.push(module)
                            }
                        })
                        setPixModules(modulesThisMethod)
                    }
                    if (paymentMethod.methodKey === 'D' && paymentMethod.forClient) {
                        selectedPaymentMethods.push('D')
                        let modulesThisMethod = []
                        userData[0]?.customersData.modules?.filter(module => {
                            if (paymentMethod.modules.includes(module?.id)) {
                                modulesThisMethod.push(module)
                            }
                        })
                        setMoneyModules(modulesThisMethod)
                    }
                    if (paymentMethod.methodKey === 'I' && paymentMethod.forClient) {
                        selectedPaymentMethods.push('I')
                    }
                    if (paymentMethod.methodKey === 'F' && paymentMethod.forClient) {
                        const assoc = associationsGet.data.filter((association) => {
                            if (paymentMethod.associations_id === association.id) {
                                return association
                            }
                        })
                        setSelectedAssociation(assoc[0])
                        selectedPaymentMethods.push('F')
                        let modulesThisMethod = []
                        userData[0]?.customersData.modules?.filter(module => {
                            if (paymentMethod.modules.includes(module?.id)) {
                                modulesThisMethod.push(module)
                            }
                        })
                        setPayRollModules(modulesThisMethod)
                    }
                })
            }
            setSelectedPaymentMethods(selectedPaymentMethods)
            setClientModules(userData[0]?.customersData?.modules)
            setLoading(false)
        }
        getData()
    }, [])

    async function handleSubmit() {
        if (selectedPaymentMethods?.length === 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText("Selecione ao menos uma opção")
            return setShowNotificationModal(true)
        }
        if (selectedPaymentMethods.includes('F') && selectedAssociation === '') {
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Para desconto em folha é necessário selecionar qual será a associação')
            return setShowNotificationModal(true)
        }

        let formattedPayment = []

        selectedPaymentMethods.includes('C') ? formattedPayment.push({
            "methodKey": "C",
            "forClient": true,
            "associations_id": 0,
            "modules": creditCardModules?.map(module => { return module?.id })
        })
            :
            formattedPayment.push({
                "methodKey": "C",
                "forClient": false,
                "associations_id": 0,
                "modules": []
            })

        selectedPaymentMethods.includes('P') ? formattedPayment.push({
            "methodKey": "P",
            "forClient": true,
            "associations_id": 0,
            "modules": pixModules?.map(module => { return module?.id })
        })
            :
            formattedPayment.push({
                "methodKey": "P",
                "forClient": false,
                "associations_id": 0,
                "modules": []
            })

        selectedPaymentMethods.includes('D') ? formattedPayment.push({
            "methodKey": "D",
            "forClient": true,
            "associations_id": 0,
            "modules": moneyModules?.map(module => { return module?.id })
        })
            :
            formattedPayment.push({
                "methodKey": "D",
                "forClient": false,
                "associations_id": 0,
                "modules": []
            })

        selectedPaymentMethods.includes('I') ? formattedPayment.push({
            "methodKey": "I",
            "forClient": true,
            "associations_id": 0,
            "modules": []
        })
            :
            formattedPayment.push({
                "methodKey": "I",
                "forClient": false,
                "associations_id": 0,
                "modules": []
            })

        selectedPaymentMethods.includes('F') && !userData[0]?.customersData?.useBalance ? formattedPayment.push({
            "methodKey": "F",
            "forClient": true,
            "associations_id": selectedAssociation.id,
            "modules": payRollModules?.map(module => { return module?.id })
        })
            :
            ''

        setLoading(true)

        try {
            const createNewPaymentMethod = await api.post(`/api/v1/paymentMethods`,
                {
                    paymentMethods: formattedPayment
                }
                , {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })

            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModal(true)
            history.back()
            setLoading(false)

        } catch (error) {
            setLoading(true)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function handleSelectedPaymentMethods(paymentMethod) {
        if (!selectedPaymentMethods.includes(paymentMethod)) {
            if (paymentMethod === "C") {
                setCreditCardModules(clientModules)
            }
            if (paymentMethod === "P") {
                setPixModules(clientModules)
            }
            if (paymentMethod === "D") {
                setMoneyModules(clientModules)
            }
            if (paymentMethod === "F") {
                setPayRollModules(clientModules)
            }
            setSelectedPaymentMethods((prev) => ([...prev, paymentMethod]))
        } else {
            if (paymentMethod === 'F') { setSelectedAssociation('') }
            setSelectedPaymentMethods((prev) => ([...prev.filter(method => method !== paymentMethod)]))
        }
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Formas de pagamento'}></Title>
            </TitlePage>
            <>
                <Body hasFooter={true}>
                    {
                        loading ?

                            <div className="bg-bgPrimaryLight dark:bg-primaryDefaultDark flex flex-col w-full h-full sm:h-96 items-center justify-center">
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <div className="w-full flex flex-col items-start justify-start mb-8">
                                    <p className="text-titleBlackTextLight text-sm dark:text-titleGrayTextDark">Selecione abaixo as <strong>opções de pagamento</strong> que devem aparecer para seus clientes</p>
                                </div>
                                <form className="w-full items-start justify-start flex flex-col flex-wrap gap-4">
                                    <div className="border-b border-gray-200 w-full pb-4 flex flex-col items-start justify-start">
                                        <LabelInput text={'Cartão de crédito'}>
                                            <InputCheckBox value={selectedPaymentMethods.includes('C') ? true : false} onClick={() => { handleSelectedPaymentMethods('C') }}></InputCheckBox>
                                        </LabelInput>
                                        {
                                            selectedPaymentMethods.includes('C') &&
                                            <LabelInput text={'Módulos'}>
                                                <Multiselect
                                                    width={screenX < 640 ? '72' : ''}
                                                    items={clientModules}
                                                    mult
                                                    value={creditCardModules}
                                                    onChange={(e) => {
                                                        setCreditCardModules(e)
                                                    }}
                                                    selectedLabel={'name'}
                                                    id={`selectedTermsServices`} />
                                            </LabelInput>
                                        }
                                    </div>
                                    <div className="border-b border-gray-200 w-full pb-4 flex flex-col items-start justify-start">
                                        <LabelInput text={'Pix'}>
                                            <InputCheckBox value={selectedPaymentMethods.includes('P') ? true : false} onClick={() => { handleSelectedPaymentMethods('P') }}></InputCheckBox>
                                        </LabelInput>
                                        {
                                            selectedPaymentMethods.includes('P') &&
                                            <LabelInput text={'Módulos'}>
                                                <Multiselect
                                                    width={screenX < 640 ? '72' : ''}
                                                    items={clientModules}
                                                    mult
                                                    value={pixModules}
                                                    onChange={(e) => {
                                                        setPixModules(e)
                                                    }}
                                                    selectedLabel={'name'}
                                                    id={`selectedPixModules`} />
                                            </LabelInput>
                                        }
                                    </div>
                                    <div className="border-b border-gray-200 w-full pb-4 flex flex-col items-start justify-start">
                                        <LabelInput text={'Presencial/Dinheiro'}>
                                            <InputCheckBox value={selectedPaymentMethods.includes('D') ? true : false} onClick={() => handleSelectedPaymentMethods('D')}></InputCheckBox>
                                        </LabelInput>
                                        {
                                            selectedPaymentMethods.includes('D') &&
                                            <LabelInput text={'Módulos'}>
                                                <Multiselect
                                                    width={screenX < 640 ? '72' : ''}
                                                    items={clientModules}
                                                    mult
                                                    value={moneyModules}
                                                    onChange={(e) => {
                                                        setMoneyModules(e)
                                                    }}
                                                    selectedLabel={'name'}
                                                    id={`selectedMoneyModules`} />
                                            </LabelInput>
                                        }
                                    </div>
                                    {
                                        !userData[0]?.customersData.useBalance &&
                                        <div className="border-b border-gray-200 w-full pb-4 flex flex-col items-start justify-start">
                                            <Tippy
                                                arrow={true}
                                                animation='perspective'
                                                placement='right'
                                                delay={100}
                                                content={'A associação escolhida neste item, não sofrerá cobranças para efetuar a reserva'}
                                            >
                                                <div>
                                                    <LabelInput text={'Desconto em folha'}>
                                                        <InputCheckBox value={selectedPaymentMethods.includes('F') ? true : false} onClick={() => handleSelectedPaymentMethods('F')}></InputCheckBox>
                                                    </LabelInput>
                                                </div>
                                            </Tippy>
                                            {
                                                selectedPaymentMethods.includes('F') &&
                                                <>
                                                    <LabelInput text={'Selecione a associação'}>
                                                        <InputAutoComplete
                                                            data={associationList}
                                                            optionList={['name']}
                                                            selectedLabel={['name']}
                                                            preSelectedValue={selectedAssociation.name?.length > 0 ? selectedAssociation.name : ''}
                                                            onChange={(association) => setSelectedAssociation(association)}
                                                        >
                                                        </InputAutoComplete>
                                                    </LabelInput>
                                                    {
                                                        selectedPaymentMethods.includes('F') &&
                                                        <LabelInput text={'Módulos'}>
                                                            <Multiselect
                                                                width={screenX < 640 ? '72' : ''}
                                                                items={clientModules}
                                                                mult
                                                                value={payRollModules}
                                                                onChange={(e) => {
                                                                    setPayRollModules(e)
                                                                }}
                                                                selectedLabel={'name'}
                                                                id={`selectedPayRollModules`} />
                                                        </LabelInput>
                                                    }
                                                </>
                                            }
                                        </div>
                                    }
                                </form>
                            </>
                    }
                </Body>
                <Footer>
                    <div className="w-full items-center h-full justify-between flex flex-row">
                        <Button shadow={true} approval={false} onClick={() => navigate('/configuration')}>
                            Voltar
                        </Button>
                        <Button shadow={true} approval={true} onClick={() => handleSubmit()}>
                            Cadastrar
                        </Button>
                    </div>
                </Footer>
            </>


        </Container>
    )
}
