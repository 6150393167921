import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/bundle'
import { Navigation, EffectFade, Autoplay } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import { EffectCards } from "swiper";
import { useState } from 'react'

export function SwiperAgreementsContainer({ imgs }) {

    const [backgroundImage, setBackgroundImage] = useState(imgs[0])

    const handleSlideChange = (swiper) => {
        const activeSlideIndex = swiper.activeIndex;
        const activeSlide = swiper.slides[activeSlideIndex];
        const activeSlideImage = activeSlide.querySelector('img').src
        setBackgroundImage(activeSlideImage);
    }


    return (
        <div
            className='overflow-hidden relative flex flex-col items-center justify-center rounded-lg w-[192px] h-[192px]' >
            <div className='absolute z-10 w-full h-full flex items-center justify-center'>
                <Swiper
                    modules={[Navigation, EffectCards, Autoplay]}
                    onSlideChange={handleSlideChange}
                    speed={200}
                    autoplay={imgs?.length > 1 ? true : false}
                    slidesPerView={1}
                    loop={true}
                    className='w-full h-full flex flex-col items-center justify-center rounded-lg'
                    zoom={true}
                    autoHeight={true}
                >
                    {
                        imgs.map((img, index) => {
                            return <SwiperSlide className='flex flex-col items-center justify-center rounded-lg overflow-hidden'>
                                <div className='w-full relative h-[192px] bg-cover flex flex-col items-center justify-center bg-no-repeat '>
                                    <div className='absolute inset-0 flex items-center justify-center w-full h-full z-50'>
                                        <img id={`swiper-${imgs[index]}`} src={imgs[index]}></img>
                                    </div>
                                    <div className='blur-lg z-10 w-full h-[192px] bg-cover flex flex-col items-center bg-no-repeat '
                                        style={{ backgroundImage: `url(${imgs[index]})`, backgroundPosition: '2% 30%', }}                                                        >
                                    </div>
                                </div>
                            </SwiperSlide>
                        })
                    }

                </Swiper>
            </div>
            <div style={{ backgroundImage: `url(${backgroundImage})` }} className='blur-md flex flex-col items-center justify-center rounded-lg w-[192px] h-[192px]' >

            </div>
        </div>

    )
}