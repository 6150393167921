import React, { useEffect, useState } from "react";

export function ProgressBar({ progress }) {
    const [loading, setLoading] = useState(false);
    const [internalProgress, setInternalProgress] = useState(0);
    const [statusMessage, setStatusMessage] = useState('Aguarde')
    const messages = [
        'Aguarde dados sendo carregados',
        'Aguarde',
        'Em carregamento',
        'Não feche esta tela',
    ]

    useEffect(() => {
        setInternalProgress(progress)
    }, [progress]);

    useEffect(() => {
        let intervalId;
        let activeMessage = 0
        intervalId = setInterval(() => {
            if (activeMessage < 3) {
                setStatusMessage(messages[activeMessage + 1])
                activeMessage++
            } else {
                activeMessage = 0
                setStatusMessage(messages[0])
            }
        }, 5000);
        return () => {
            clearInterval(intervalId);
        }
    }, [])

    return (

        <div className=" w-full flex flex-col gap-2 items-center justify-center">
            <div className="w-full h-full items-start justify-start flex flex-col">
                <div style={{ width: progress + '%' }} className="transition-all shadow-lg duration-150 animate-pulse rounded-lg bg-emerald-500 h-5"></div>
            </div>
            {
                progress >= 90 ?
                    <div className="flex animate-pulse"><p>{statusMessage}</p><p className="">...</p></div>
                    :
                    <p>{progress?.toFixed(2)} %</p>
            }
        </div>
    )
}

