import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/useAuth";
//compoentns
import { Container } from "../../components/container/container";
import { TitlePage } from "../../components/titlePages/title.page";
import { Title } from "../../components/titlePages/title";
import { Body } from "../../components/container/Body";
import Tippy from "@tippyjs/react";
import { FaList, FaRegNewspaper, FaRegWindowClose } from "react-icons/fa";
//services
import { api } from "../../services/api/api";
import { getCustomersAttendance } from "../../services/api/callAPIsFunctions/defaultCalls.api";
//utils
import moment from "moment";
import { ScreenModal } from "../../components/modals/notification/screenModal";
import { Button } from "../../components/buttons/button.default";
import { useThemeContext } from "../../contexts/themeContext";
import { responseError } from "../../utils/responsesFunctions/error.response";
import { DefaultLoader } from "../../components/loaders/defaultLoader";
import { useNavigate } from "react-router-dom";

export function MySchedulesDrawDetails() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const {
        setShowUniversalModal,
        setShowNotificationModal,
        setShowNotificationModalText,
        setShowNotificationModalSuccess,
    } = useThemeContext()
    const [myScheduleDetails, setMyScheduleDetails] = useState('')
    const [loading, setLoading] = useState(false)
    const [notPermittedCancel, setNotPermittedCancel] = useState(false)
    const [customersAttendanceDetails, setCustomersAttendanceDetails] = useState('')


    async function getLinksAndFormatData() {
        setLoading(true)
        try {
            const details = localStorage.getItem('@mySchedulesDrawDetails')
            if (!details) navigate('/mySchedulesDrawDetails')
            const mySchedule = JSON.parse(details)

            setNotPermittedCancel(moment(mySchedule?.original_dateToCancel).utcOffset(60 * (userData[0]?.timezone)).isBefore(moment()))
            const linkedByPayments = await api.get(`/api/v1/schedules/client?payments_id=${mySchedule.payments_id}`, {
                headers: {
                    authorization: `Bearer ${userData[0].token}`
                }
            })

            const customersAttendance = await getCustomersAttendance(userData[0]?.token)

            if (linkedByPayments?.data?.data?.length > 1) {
                const linkedSchedules = linkedByPayments?.data?.data?.filter(schedule => {
                    schedule.original_created_at = schedule.created_at
                    schedule.created_at = formattTimeToShow(schedule.created_at)
                    schedule.original_startDate = schedule.startDate
                    schedule.startDate = formattTimeToShow(schedule.startDate)
                    schedule.original_finishDate = schedule.finishDate
                    schedule.finishDate = formattTimeToShow(schedule.finishDate)
                    schedule.original_startHour = schedule.startHour
                    schedule.startHour = maskHours({ hour: schedule.startHour })
                    schedule.original_finishHour = schedule.finishHour
                    schedule.finishHour = maskHours({ hour: schedule.finishHour })
                    schedule.original_dateToCancel = schedule?.dataToCancel
                    schedule.dateToCancel = formattTimeToShow(schedule?.dataToCancel)
                    schedule.original_payments_dueDate = schedule?.payments_dueDate
                    schedule.payments_dueDate = formattTimeToShow(schedule?.payments_dueDate)
                    schedule.startDate = `${schedule.startDate} - ${schedule.startHour} - ${schedule.finishHour}`

                    return schedule.id !== mySchedule.id
                })

                mySchedule.associateSchedules = linkedSchedules?.map(anotherHour => anotherHour.startDate),
                    mySchedule.associateSchedulesValue = linkedSchedules?.map(anotherHour => anotherHour.serviceValue)

            } else {
                mySchedule.associateSchedules = []
                mySchedule.associateSchedulesValue = []
            }
            setMyScheduleDetails(mySchedule)
            setCustomersAttendanceDetails(customersAttendance?.data[0])
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        setShowUniversalModal(false)
        getLinksAndFormatData()
    }, [])

    async function handleExitDraw() {

        setLoading(true)
        try {
            await api.delete(`api/v1/schedulesDraw/${myScheduleDetails?.id}/client`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Você foi desenscrito com sucesso deste sorteio')
            setLoading(false)
            navigate('/mySchedulesDraw')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal>
                <div className="flex flex-col gap-2 items-center justify-center p-4">
                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Deseja realmente sair do sorteio?</p>
                    <div className="flex gap-4 items-center justify-center">
                        <Button shadow={true} onClick={() => handleExitDraw()}>Sim</Button>
                        <Button shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                    </div>

                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Detalhes do sorteio'} />
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className="h-96 flex items-center justify-center">
                                <DefaultLoader />
                            </div>
                            :
                            <div className="w-full flex flex-col gap-1 justify-start items-start">
                                <div className='mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                    <h1 className='text-primaryDefaultLight text-base'>Dados da inscrição</h1>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>ID da inscrição: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">{myScheduleDetails?.id}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>Status: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">{myScheduleDetails?.isOpen ? 'Pendente sorteio' : 'Encerrado'}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>Status da sua inscrição: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">{myScheduleDetails?.winner}</p>
                                </div>
                                <div className='mt-4 mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                    <h1 className='text-primaryDefaultLight text-base'>Dados da reserva</h1>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>Espaço: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">{myScheduleDetails?.services_name}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44"><strong>Data/Horário: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight">{myScheduleDetails?.originalScheduleDate}</p>
                                </div>
                                {
                                    myScheduleDetails?.isOpen &&
                                    <div className="mt-6 flex flex-row items-start justify-start w-full">
                                        <Tippy content={<p>'Clique para se desinscrever e não competir mais por este sorteio'</p>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div onClick={() => setShowUniversalModal(true)} className={`cursor-pointer p-2 hover:bg-gray-100 rounded-md flex flex-row gap-2 items-center justify-center`}>
                                                <FaRegWindowClose className={`text-lg text-titleBlackTextLight dark:text-titleGrayTextDark cursor-pointer font-light`} />
                                                <a className={`text-sm text-titleBlackTextLight dark:text-titleGrayTextDark`}>Sair do sorteio</a>
                                            </div>
                                        </Tippy>
                                    </div>
                                }

                            </div>
                    }
                </Body>
            </Container>
        </>
    )
}