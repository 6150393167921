import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/bundle'
import { Navigation, EffectFade, Autoplay } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import { EffectCards } from "swiper";

export function SwiperFullWidthContainer({ imgs }) {

    return (
        <div className='flex flex-col items-start justify-start rounded-lg w-[400px] h-[400px]' >
            <Swiper
                modules={[Navigation, EffectCards, Autoplay]}
                // effect={"cards"}
                speed={200}
                autoplay={true}
                slidesPerView={1}
                loop={true}
                className='w-full h-full flex flex-col items-start justify-start rounded-lg'
                zoom={true}
                autoHeight={true}
            >
                {
                    imgs.map((img, index) => {
                        return <SwiperSlide className='flex flex-col items-start justify-start rounded-lg overflow-hidden'>
                            <div className='w-full relative h-[400px] bg-cover flex flex-col items-center justify-center bg-no-repeat '>
                                <div className='absolute inset-0 flex items-center justify-center w-full h-full z-50'>
                                    <img id={`swiper-${imgs[index]}`} src={imgs[index]}></img>
                                </div>
                                <div className='blur-lg z-10 w-full h-[400px] bg-cover flex flex-col items-center bg-no-repeat '
                                    style={{ backgroundImage: `url(${imgs[index]})`, backgroundPosition: '2% 30%', }}                                                        >
                                </div>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    )
}