//hooks
import { useEffect, useState } from "react";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Input } from "../../../components/input/input";
import { LabelInput } from "../../../components/label/label.input";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Footer } from "../../../components/footer/Footer";
import { Button } from "../../../components/buttons/button.default";
import { LogonLoader } from "../../../components/loaders/logonLoader"
//utils
import { justNumber } from "../../../utils/validators/justNumber";
import { api } from "../../../services/api/api";
import { useAuth } from "../../../contexts/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { useThemeContext } from "../../../contexts/themeContext";
import { validateFields } from "../../../utils/form.validator";
import { getCompanies, getCompanyById } from "../../../services/api/callAPIsFunctions/defaultCalls.api";

export function CompaniesEdit() {

    const commpanyId = useParams()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [companyData, setCompanyData] = useState({})
    const [loadingCep, setLoadingCep] = useState(false)


    async function getData(){

        const companieDataApi = await getCompanyById(userData[0].token, commpanyId.id)
        if (companieDataApi.error) {
            setLoadingCep(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(companyData.data)
            return
        }
        setCompanyData(companieDataApi.data[0])
        setLoadingCep(false)
    }

    async function getAddress() {
        if (companyData?.cep) {
            setLoadingCep(true)

            fetch(`https://viacep.com.br/ws/${companyData?.cep}/json`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Erro na requisição à API Via CEP');
                    }
                    return response.json();
                })
                .then(data => {
                    setCompanyData(prev => ({ ...prev, addressLine: data.logradouro, district: data.bairro, city: data.localidade, state: data.uf }))
                    setLoadingCep(false)
                    console.log(data);
                })
                .catch(error => {
                    setLoadingCep(false)
                    console.error(error);
                });
        }

    }

    async function editCompany() {

        if(!companyData?.name){            
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            document.getElementById('name').style.border = '1px solid red'
            return setShowNotificationModalText('Campos obrigatórios não preenchidos')
        }

        setLoadingCep(true)
        try {
            const newCompanyData = await api.post(`/api/v1/companies`, {
                ...companyData,
                country: 'Brasil'
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setLoadingCep(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            history.back()
            return setShowNotificationModalText('Cadastro realizado com sucesso!')
        } catch (error) {
            setLoadingCep(false)
            if (responseError(error).length > 0) {
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoadingCep(false)
                animateLoading(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }

        }
    }

    useEffect(()=> {

        getData()

    },[])

    return (

        <Container>
            <TitlePage>
                <Title text={'Empresas'} />
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loadingCep &&
                    <div className="top-0 absolute w-[100vw] h-[100vh] bg-zinc-700 opacity-75 z-50 flex items-center justify-center">
                        <LogonLoader />
                    </div>
                }
                <div className='flex flex-col items-start justify-start w-full'>
                    <form className='flex flex-col items-center justify-center sm:grid sm:grid-cols-1 md:grid-cols-2 gap-4'>
                        <LabelInput text={'Razão Social'}>
                            <Input id="name" charLimit={200} value={companyData?.name} onChange={(e) => setCompanyData(prev => ({ ...prev, name: e.target.value }))} />
                        </LabelInput>
                        <LabelInput text={'Nome fantasia'}>
                            <Input charLimit={100} value={companyData?.fantasyName} onChange={(e) => setCompanyData(prev => ({ ...prev, fantasyName: e.target.value }))} />
                        </LabelInput>
                        <LabelInput text={'CNPJ'}>
                            <Input charLimit={18} value={companyData?.cnpj} onChange={(e) => setCompanyData(prev => ({ ...prev, cnpj: justNumber(e.target.value) }))} />
                        </LabelInput>
                        <div className='flex flex-col sm:flex-row gap-3'>
                            <LabelInput text={'DDD'}>
                                <Input value={companyData?.dddPhone} charLimit={2} width={'12'} type={'text'} onChange={(e) => setCompanyData(prev => ({ ...prev, dddPhone: justNumber(e.target.value) }))}></Input>
                            </LabelInput>
                            <LabelInput text={'Telefone'}>
                                <Input value={companyData?.phone} charLimit={8} width={'80'} type={'text'} onChange={(e) => setCompanyData(prev => ({ ...prev, phone: justNumber(e.target.value) }))}></Input>
                            </LabelInput>
                        </div>
                        <LabelInput text={'CEP'}>
                            <Input charLimit={8} value={companyData?.cep} onBlur={() => getAddress()} onChange={(e) => setCompanyData(prev => ({ ...prev, cep: justNumber(e.target.value) }))} />
                        </LabelInput>
                        <LabelInput text={'Endereço'}>
                            <Input charLimit={100} value={companyData?.addressLine} onChange={(e) => setCompanyData(prev => ({ ...prev, addressLine: e.target.value }))} />
                        </LabelInput>
                        <LabelInput text={'Número'}>
                            <Input charLimit={100} value={companyData?.number} onChange={(e) => setCompanyData(prev => ({ ...prev, number: justNumber(e.target.value) }))} />
                        </LabelInput>
                        <LabelInput text={'Bairro'}>
                            <Input charLimit={50} value={companyData?.district} onChange={(e) => setCompanyData(prev => ({ ...prev, district: e.target.value }))} />
                        </LabelInput>
                        <LabelInput text={'Cidade'}>
                            <Input charLimit={100} value={companyData?.city} onChange={(e) => setCompanyData(prev => ({ ...prev, city: e.target.value }))} />
                        </LabelInput>
                        <LabelInput text={'Estado'}>
                            <Input charLimit={50} value={companyData?.state} onChange={(e) => setCompanyData(prev => ({ ...prev, state: e.target.value }))} />
                        </LabelInput>
                        {/* <LabelInput text={'País'}>
                            <Input charLimit={100} value={companyData?.country} onChange={(e) => setCompanyData(prev => ({ ...prev, country: e.target.value }))} />
                        </LabelInput> */}
                        <LabelInput text={'Id externo'}>
                            <Input charLimit={50} value={companyData?.externalId} onChange={(e) => setCompanyData(prev => ({ ...prev, externalId: e.target.value }))} />
                        </LabelInput>
                    </form>
                </div>
            </Body>
            <Footer>
                <div className="flex h-full w-full items-center justify-between">
                    <Button shadow={true} approval={false} onClick={()=> history.back()}>Cancelar</Button>
                    <Button onClick={() => editCompany()} shadow={true} >Salvar</Button>
                </div>
            </Footer>
        </Container>
    )
}