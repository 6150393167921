import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Container } from '../../components/container/container'
import { TitlePage } from '../../components/titlePages/title.page'
import { Title } from '../../components/titlePages/title'
import { Body } from '../../components/container/Body'
import { useAuth } from '../../contexts/useAuth'
import { TableDefault } from '../../components/table/table.default'
import { api } from '../../services/api/api'
import { LabelInput } from '../../components/label/label.input'
import { InputAutoComplete } from '../../components/input/input.autocomplete'
import { Input } from '../../components/input/input'
import { FaBan, FaFilter, FaSearch } from 'react-icons/fa'
import { Button } from '../../components/buttons/button.default'
import Tippy from '@tippyjs/react'
import { DefaultLoader } from '../../components/loaders/defaultLoader'
//utils
import moment from 'moment'
import { maskHoursService } from '../../utils/mask/maskHoursService'
//services
import { getServices } from '../../services/api/callAPIsFunctions/defaultCalls.api'

export function MySchedulesDraw() {

    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    const [mySchedulesList, setMySchedulesList] = useState([])
     //filters search
     const [usersActiveButton, setUsersActiveButton] = useState(1)
     const [showFilterModal, setShowFilterModal] = useState(false)
     const [showInternalFilterModal, setShowInternalFilterModal] = useState(false)
     const [paginationFilteredFields, setPaginationFilteredFields] = useState({})
     const [paginationHistFilteredFields, setPaginationHistFilteredFields] = useState({})
     const [paginationHistFilteredFieldsArr, setPaginationHistFilteredFieldsArr] = useState([])
     const [servicesList, setServicesList] = useState([])
     const [cleanFilter, setCleanFilter] = useState(false)
     const tableFilterModal = useRef()
     const [totalPages, setTotalPages] = useState(1)
     const [quantity, setQuantity] = useState(0)
     const [inEdit, setInEdit] = useState(false)

     const filters = [
        {
            "name": 'Serviço',
            "original_name": 'services_id',
            "type": 'object',
            "data": servicesList,
            "optionList": ['name'],
            "selectedLabel": 'name',
            "optionToFilter": 'id'
        },
        {
            "name": 'Data da reserva',
            "original_name": 'scheduleDate',
            "type": 'date',
        }
    ]

    const tableThead = [
        {
            "name": "ID da inscricão",
            "original_name": "id"
        },{
            "name": 'Sorteio',
            "original_name": 'scheduleDate',
        },{
            "name": 'Serviço/Espaço',
            "original_name": 'name',
            "table": "services"
        },{
            "name": "Data de inscrição",
            "original_name": "created_at",
        }
    ]

    async function handleClearFilter() {

        setLoading(true)
        setPaginationHistFilteredFieldsArr([]);
        setPaginationHistFilteredFields([])
        let fields = {}
        filters.map((column, index) => {
            fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
        })
        setPaginationFilteredFields(fields)
        getData();
        setLoading(false)

    }

    useEffect(() => {
        if (!inEdit) {
            getData()
        }
    }, [inEdit])

    useEffect(() => {

        let modal = document.getElementById('tableFilterModal')
        if (modal) {
            modal.addEventListener('click', (e) => handleChangeModalStatus(e, ''))
            document.addEventListener('keydown', (e) => handleChangeModalStatus(e, 'keyboard'))
        }

        async function animateFilterModal(status) {

            let fields = {}
            filters.map((column, index) => {
                fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
            })
            setPaginationFilteredFields(fields)
            if (status) {
                let histFields = {}
                filters.map((column, index) => {
                    histFields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
                })
                setPaginationHistFilteredFields(fields)
                setShowFilterModal(true)
                await new Promise(resolve => setTimeout(resolve, 200))
                return setShowInternalFilterModal(true)
            }
            setShowFilterModal(false)
            setShowInternalFilterModal(false)
        }

        animateFilterModal(showFilterModal)

        if (modal) {
            return () => { modal.removeEventListener('click', (e) => handleChangeModalStatus(e)); modal.removeEventListener('keydown', (e) => handleChangeModalStatus(e)) }
        }

    }, [showFilterModal])

    function handleChangeModalStatus(e, type) {
        if (e.code === 'Escape' && type === 'keyboard') {
            setShowFilterModal(false)
        }

        if (!tableFilterModal?.current?.contains(e.target) && type !== 'keyboard') {
            setShowFilterModal(false)
        }
    }

    function orderTableData(e) {
        const ToFilterData = []
        const fieldsKeys = Object.keys(paginationHistFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationHistFilteredFields[key].value !== '') {
                ToFilterData.push(paginationHistFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
            return getData({ filter: { filterObject: ToFilterData }, direction: e?.direction, page: e.page })
        }
        getData(e)
    }

    async function getData(e){
        try {
            setLoading(true)
            if(servicesList.length == 0){
                const services = await getServices(userData[0]?.token, true)
                setServicesList(services.data)
            }

            const page = e?.page ? e?.page : 1
            const direction = e?.direction == false ? 'DESC' : 'ASC'
            const queryFormat = []
            queryFormat.push(`page=${page}`)
            
            if(e?.filter?.column){
                queryFormat.push(`orderBy=${e?.filter?.column}`)
                if(e?.filter?.table){
                    queryFormat.push(`orderByTable=${e?.filter?.table}`)
                }
                queryFormat.push(`direction=${direction}`)
            }
            
            if(e?.filter?.filterObject?.length > 0){    
                e?.filter?.filterObject?.forEach((filter) => {
                    queryFormat.push(`${filter?.original_name}=${filter?.value}`)
                })
            }

            const mySchedulesDraw = await api.get('/api/v1/schedulesDraw/mySubscriptions?' + queryFormat.join('&'), {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            const data = mySchedulesDraw?.data?.data?.map(draw => {
                const startHour = maskHoursService(draw?.startHour)
                const finishHour = maskHoursService(draw?.finishHour)
                const schedulDate = moment(draw?.scheduleDate)?.utc()?.format('DD/MM/YYYY')
                const status = !draw.isOpen && !draw.winner  ? 'Perdeu' 
                    : draw.isOpen && !draw.winner ? 'Sorteio Pendente' 
                    : 'Ganhou'
                const color = !draw.isOpen && !draw.winner  ? 'text-red-800' 
                : draw.isOpen && !draw.winner ? 'text-yellow-600' 
                : 'text-green-800'
                return {
                    ...draw,
                    originalScheduleDate: `${schedulDate} | ${startHour} - ${finishHour}`,
                    scheduleDate: <p>{schedulDate} | {startHour} - {finishHour} <p className={color}>({status})</p></p>,
                    created_at: moment(draw?.created_at)?.utc()?.format('DD/MM/YYYY hh:mm'),
                    winner: draw?.winner ? 'Ganhou' : draw?.isOpen ? 'Sorteio não realizado' : 'Perdeu'
                }
            })
            setTotalPages(mySchedulesDraw?.data?.totalPages)
            setQuantity(mySchedulesDraw?.data?.totalRows)
            setMySchedulesList(data)
            setLoading(false)
            
        } catch (error) {
            setLoading(false)            
        }

    }

    function customFilter() {
        const ToFilterData = []
        const fieldsKeys = Object.keys(paginationFilteredFields)
        //percorre os filtros informados pelo usuario para enviar no endpoint da API
        fieldsKeys.map((key) => {
            if (paginationFilteredFields[key]?.value !== '') {
                ToFilterData.push(paginationFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
        }
        //chama a API com os parametros informados pelo usuario
        getData({ filter: { filterObject: ToFilterData }, direction: false })
        setShowFilterModal(false)
    }

    useEffect(()=> {

        getData()
        
    },[])

    function showDetails(draw){
        localStorage.setItem('@mySchedulesDrawDetails', JSON.stringify(draw))
        navigate(`/mySchedulesDrawDetails/${draw?.id}`)
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Meus sorteios'} />
                <p>Inscrições realizadas: {quantity}</p>
            </TitlePage>
            <Body>
            {
                    showFilterModal &&
                    <div id='tableFilterModal' className='fixed left-0 top-0 z-[500] w-full h-full flex flex-col items-center justify-start'>
                        <div ref={tableFilterModal} className={`${showInternalFilterModal ? 'rounded-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark opacity-1 translate-x-0' : 'translate-x-[1000px] bg-transparent opacity-0'} top-28 absolute z-[501] p-6  boxShadow transition-all duration-300 flex flex-col gap-2`}>
                            <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center mb-8'>
                                <FaSearch />
                                <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                            </div>
                            {

                                filters.map(column => {
                                    return <div className='flex sm:flex-row flex-col'>
                                        <div className='w-28'>
                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{column.name}</p>
                                        </div>
                                        {
                                            column.type === 'text' ?
                                                <LabelInput>
                                                    <Input value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                        setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                    }}></Input>
                                                </LabelInput>
                                                :
                                                column.type === 'object' ?
                                                    <LabelInput>
                                                        <InputAutoComplete data={column.data} optionList={column.optionList} selectedLabel={column.selectedLabel} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                        }}></InputAutoComplete>
                                                    </LabelInput>
                                                    :
                                                    column.type === 'date' &&
                                                    <LabelInput>
                                                        <Input type={`date`} value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        }} />
                                                    </LabelInput>
                                        }
                                    </div>
                                })}
                            <div className='flex w-full flex-col items-end justify-start mt-6'>
                                <Button onClick={() => customFilter()} shadow={true}>Pesquisar</Button>
                            </div>

                        </div>
                        <div className={`${showInternalFilterModal ? 'bg-gray-700 opacity-75' : 'bg-transparent opacity-0'} absolute h-full w-full transition-all duration-300`}>
                        </div>
                    </div>
                }
                <div className='flex flex-row w-full items-center justify-start gap-4'>
                    <Tippy content={'Filtros'}
                        arrow={true}
                        animation='shift-away'
                        placement='top'
                        delay={100}>
                        <div>
                            <Button shadow={true}>
                                <div className={`p-1 cursor-pointer flex flex-row items-center justify-center gap-2`} onClick={() => setShowFilterModal(true)}>
                                    <FaFilter />
                                    Filtrar
                                </div>
                            </Button>
                        </div>
                    </Tippy>
                    {
                        paginationHistFilteredFieldsArr?.length > 0 &&
                        <Tippy content={'Excluir filtro'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <div className='cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150' onClick={() => { handleClearFilter() }}>
                                <FaBan />
                            </div>
                        </Tippy>
                    }
                </div>
                <div id='mySchedulesDrawTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                    {loading && <DefaultLoader/>}
                    <TableDefault
                        pagination={true}
                        totalPages={totalPages}
                        onClick={(e) => showDetails(e)}
                        title={tableThead}
                        data={mySchedulesList}
                        collumns={['id', "scheduleDate", "services_name", 'created_at']}
                        loading={loading}
                        filter={(e) => orderTableData(e)}
                    />
                </div>
            </Body>
        </Container>
    )
}