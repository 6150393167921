//hooks
import React, { useEffect, useState } from 'react'
//components
import { Container } from '../../components/container/container'
import { Title } from '../../components/titlePages/title'
import { TitlePage } from '../../components/titlePages/title.page'
import { getStructures, getStructuresById } from '../../services/api/callAPIsFunctions/defaultCalls.api'
import { Body } from '../../components/container/Body'
import { useAuth } from '../../contexts/useAuth'
//utils
import { responseError } from '../../utils/responsesFunctions/error.response'
import { useThemeContext } from '../../contexts/themeContext'
import { api } from '../../services/api/api'
import { StructureShow } from './structureShow'
//imgs
import sportTools from '../../assets/default_assets/sports-tools.webp'

export function StructuresClient() {

    const { setShowNotificationModal, setShowNotificationModalSuccess } = useThemeContext()
    const { userData } = useAuth()

    const [structuresList, setStructuresList] = useState([])
    const [structuresAppendPictures, setStructuresAppendPictures] = useState([])
    const [selectedStructure, setSelectedStructure] = useState('')


    //terminar de implementar esta função
    async function formatAPIPhoto(apiData, pictures, setFunction) {
        if (apiData.structuresPictures?.length > 0) {

            let masterPicture = apiData[pictures].filter(pictureMaster => pictureMaster.isMaster)
            let photosAppendArray = []
            let photosArray = []
            //Pega a foto de capa se foi definido pelo usuario
            if (masterPicture.length > 0) {
                const file = await api.get(`api/v1/structuresPictures/structures_id/${apiData.id}/picture/${masterPicture[0].picture}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                const fileObj = URL.createObjectURL(file.data)
                photosArray.push(fileObj)
                photosAppendArray.push({
                    photo: fileObj
                })
                //caso nao tenha foto de capa, pega a primeira foto
            } else {
                await Promise.all(apiData[pictures].map(async (picture, index) => {
                    if (index === 0) {
                        const file = await api.get(`api/v1/structuresPictures/structures_id/${apiData.id}/picture/${picture.picture}`, {
                            responseType: 'blob',
                            responseEncoding: 'base64',
                            headers: {
                                Authorization: `Bearer ${userData[0].token}`
                            }
                        })
                        const fileObj = URL.createObjectURL(file.data)
                        photosArray.push(fileObj)
                        photosAppendArray.push({
                            photo: fileObj
                        })
                    }
                }))
            }

            //se tem foto publicada traz com base nas regras acima
            if (photosAppendArray?.length > 0) {
                setFunction((prev) => ([...prev, { apiData, photosAppendArray }]))
                return true
            }
            //caso o usuario nao defina foto, irá utilizar a foto padrao da gastronomia
        } else {
            let photosAppendArray = [{ photo: sportTools }]
            setFunction((prev) => ([...prev, { apiData, photosAppendArray }]))
            return true
        }
    }

    async function getData() {

        const structuresData = await getStructures(userData[0].token, 1)
        if (structuresData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(structuresData.data)
            return
        }

        if (structuresData.data.length > 0) {
            Promise.all(structuresData.data.map(async structures => {
                let thisStructures = await getStructuresById(userData[0]?.token, structures.id)
                await formatAPIPhoto(thisStructures.data, 'structuresPictures', setStructuresAppendPictures)
            }))
        }
    }

    useEffect(() => {
        getData()
    }, [])

    async function handleSelectThisCard(card, toSelectActivitie) {
        structuresAppendPictures.map(structure => {
            if (structure.apiData.id !== card) {
                let otherActivitie = document.getElementById(`structure${structure.apiData.id}`)
                otherActivitie.style.boxShadow = ''
            }
        })
        setSelectedStructure(toSelectActivitie)
        let thisCard = document.getElementById(card)
        thisCard.style.boxShadow = '0 0 10px 2px var(--primaryDefaultLight)'

        let thisPhoto = document.getElementById(card)
        thisPhoto.scrollIntoView({ behavior: 'smooth' })

    }

    return (
        <>
            {
                selectedStructure?.apiData?.id > 0 ?
                    <StructureShow structure={selectedStructure.apiData} back={(e) => setSelectedStructure(e)} />
                    :
                    <Container>
                        <TitlePage>
                            <Title text={'Estruturas'} />
                        </TitlePage>
                        <Body>


                            <h1 className='text-2xl font-light text-primaryDefaultLight dark:text-titleGrayTextDark mb-14'>Conheça nossa estrutura</h1>
                            <div className='flex flex-col'>
                                <div id='structureArea' className=' flex flex-row gap-2 items-center justify-center w-full overflow-auto scrollbar-none' style={{
                                    maxWidth: screenX > 640 ? screenX - 300 : 'auto'
                                }}>
                                    <div className='flex flex-row gap-8 items-center justify-center w-full p-6'>
                                        <div id='moveRow' style={{
                                        }} className='flex flex-row flex-wrap gap-8 items-center justify-center transition-all duration-300 ease-in-out'>
                                            {
                                                structuresAppendPictures.length > 3 ?
                                                    structuresAppendPictures.map((structure, index) => {
                                                        return <div
                                                            style={{
                                                                backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), rgba(27, 27, 27, 0.9)), url(${structure.photosAppendArray[0].photo})`,
                                                                transform: selectedStructure?.apiData?.id === structure?.apiData?.id ? 'scale(1.06)' : 'scale(1)',
                                                                filter: selectedStructure?.apiData?.id > 0 ? selectedStructure?.apiData?.id === structure?.apiData?.id ? 'grayscale(0)' : 'grayscale(0.90)' : 'grayscale(0)',
                                                            }}
                                                            id={`structure${structure.apiData.id}`}
                                                            onClick={() => handleSelectThisCard(`structure${structure.apiData.id}`, structure)}
                                                            className='px-2 bg-cover animate-[wiggleLeft_1s_ease-in-out] cursor-pointer hover:shadow-borderShadow-xl 
                                                    w-[200px] 2xl:w-[200px]  h-48 2xl:h-48 hover:scale-105 transition-all duration-300 ease-in-out boxShadow 
                                                    rounded-xl text-lg font-light items-center justify-center flex flex-col flex-wrap'>
                                                            <div className='w-[95%]'>
                                                                <a className='text-xl text-white text-center font-light'>{structure.apiData.title}</a>
                                                            </div>
                                                        </div>
                                                    })
                                                    :
                                                    structuresAppendPictures.map((structure, index) => {
                                                        return <div
                                                            style={{
                                                                backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), rgba(27, 27, 27, 0.9)), url(${structure.photosAppendArray[0].photo})`,
                                                                transform: selectedStructure?.apiData?.id === structure?.apiData?.id ? 'scale(1.06)' : 'scale(1)',
                                                                filter: selectedStructure?.apiData?.id > 0 ? selectedStructure?.apiData?.id === structure?.apiData?.id ? 'grayscale(0)' : 'grayscale(0.90)' : 'grayscale(0)',
                                                            }}
                                                            id={`structure${structure.apiData.id}`}
                                                            onClick={() => handleSelectThisCard(`structure${structure.apiData.id}`, structure)}
                                                            className='px-2 bg-cover animate-[wiggleLeft_1s_ease-in-out] cursor-pointer hover:shadow-borderShadow-xl w-[250px] 2xl:w-[300px]  h-56 2xl:h-64 hover:scale-105 transition-all duration-300 ease-in-out boxShadow rounded-xl text-lg font-light items-center justify-center flex flex-col'>
                                                            <a className='text-xl text-white text-center font-light'>{structure.apiData.title}</a>
                                                        </div>
                                                    })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Body>
                    </Container>
            }
        </>
    )
}