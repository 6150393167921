// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { api } from '../../../services/api/api'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { LoginLoader } from '../../../components/loaders/loginLoader'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { useThemeContext } from '../../../contexts/themeContext'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { Footer } from '../../../components/footer/Footer'

export function UserProfileCreate() {

    const navegate = useNavigate()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const { userData } = useAuth()
    const [apiData, setApiData] = useState([])

    const [selectedPermissionName, setSelectedPermissionName] = useState('')
    const [selectedPermissionDescription, setSelectedPermissionDescription] = useState('')
    const [userPermissions, setUserPermissions] = useState([])

    useEffect(() => {

        async function getPermissions() {

            try {
                const permissionsData = await api.get('/api/v1/permissions', {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                setApiData(permissionsData.data.data)
                setLoading(false)
            } catch (error) {

                setLoading(false)
                if (responseError(error).length > 0) {
                    setShowNotificationModalText(responseError(error))
                } else {
                    setShowNotificationModalText('Erro inesperado')
                }
            }

        }
        getPermissions()
    }, [])

    function changePermission(permission) {
        if (userPermissions.includes(permission)) {
            const position = (userPermissions.indexOf(permission))
            userPermissions.splice(position, 1)
            setUserPermissions([...userPermissions])
        }
        else {
            setUserPermissions([...userPermissions, permission])
        }
    }

    function checkAll(insert, permissionType) {

        let newPermissions = []

        apiData.map((feature) => {
            if (feature.feature === permissionType) {
                feature.permissions.map((permission) => newPermissions.push(permission))
            }
        })

        if (insert) {
            newPermissions.forEach((newPermission) => {
                if (userPermissions.includes(newPermission.value) === false) {
                    userPermissions.push(newPermission.value)
                }
            })
            setUserPermissions([...userPermissions])
        }
        else {
            newPermissions.forEach((newPermission) => {
                if (userPermissions.includes(newPermission.value) === true) {
                    const position = (userPermissions.indexOf(newPermission.value))
                    userPermissions.splice(position, 1)
                }
            })
        }
        setUserPermissions([...userPermissions])
    }

    async function handleSubmit() {

        const fields = [
            { "name": 'selectedPermissionName', "value": selectedPermissionName, "required": true, "type": 'string' },
            { "name": 'selectedPermissionDescription', "value": selectedPermissionDescription, "required": true, "type": 'string' }
        ]

        if (validateFields(fields).length > 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Campos obrigatórios não preenchidos!')
            return
        }
        if (userPermissions.length === 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Selecione ao menos uma permissão')
        }
        setLoading(true)
        try {

            await api.post(`/api/v1/permissionGroups`, {
                "name": selectedPermissionName,
                "description": selectedPermissionDescription,
                "permissions": userPermissions
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            cleanState()
            setLoading(false)
            navegate('/usersProfile')

        } catch (error) {
            setShowNotificationModal(true)
            if (responseError(error).length > 0) {
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalText('Erro inesperado')
            }
            setLoading(false)
            setShowNotificationModalSuccess(false)
        }
    }

    function cleanState() {
        setSelectedPermissionDescription('')
        setSelectedPermissionName('')
        setUserPermissions([])
        setApiData([...apiData])
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Criação de grupo'}></Title>
            </TitlePage>
            <Body hasFooter={true}>
                <div className='flex flex-col items-center md:items-start w-full gap-6'>
                    <LabelInput required={true} text='Nome'>
                        <Input id='selectedPermissionName' value={selectedPermissionName} onChange={(e) => setSelectedPermissionName(e.target.value)} type={'text'}></Input>
                    </LabelInput>
                    <LabelInput required={true} text='Descrição'>
                        <Input id='selectedPermissionDescription' value={selectedPermissionDescription} onChange={(e) => setSelectedPermissionDescription(e.target.value)} type={'text'}></Input>
                    </LabelInput>
                    <h1 className='text-titleBlackTextLight dark:text-titleGrayTextDark'>Selecione as permissões do grupo:</h1>
                    {
                        loading ?

                            <div className='flex flex-col w-full h-full items-center justify-center'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='flex flex-row flex-wrap mt-4 text-sm mb-4 gap-4 md:justify-start justify-center items-center md:items-start w-full'>
                                {
                                    apiData.map((typePermission) => {
                                        return <div key={typePermission.name} className='flex flex-col w-80 h-80 border border-gray-300 dark:border-primaryBorderDark p-6 boxShadow'>
                                            <div className='flex flex-row gap-6 items-center justify-start mb-8 w-full'>
                                                <InputCheckBox onClick={(e) => checkAll(e, typePermission.feature)} value={
                                                    typePermission.permissions.filter((permissionApi) => {
                                                        if (userPermissions.includes(permissionApi.value)) {
                                                            return permissionApi
                                                        }
                                                    }).length === typePermission.permissions.length ?
                                                        true : false
                                                } />
                                                <h2 className='border-b border-primaryDefaultLight text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>{typePermission?.feature}</h2>
                                            </div>
                                            <div className='w-full flex flex-col gap-4 items-start justify-center'>
                                                {
                                                    typePermission.permissions.map((permission) => {
                                                        return <div className='w-full items-start justify-start flex-row flex gap-4' >
                                                            <InputCheckBox value={userPermissions.includes(`${permission.value}`) ? true : false} onClick={(e) => changePermission(`${permission.value}`)}></InputCheckBox>
                                                            <a className='text-sm sm:text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>{permission?.name}</a>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                    }
                </div>
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button
                        approval={false}
                        shadow={true}
                        onClick={() => navegate('/usersProfile')}
                    >Voltar
                    </Button>
                    <Button
                        approval={true}
                        shadow={true}
                        onClick={() => handleSubmit()}
                    >Cadastrar
                    </Button>
                </div>
            </Footer>
        </Container>
    )
}