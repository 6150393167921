import { v4 as uuid } from 'uuid'

export class FormatHours{

    hours

    constructor(hours){
        this.hours = hours
    }

    format(){
        const sundayhours = []
        const mondayhours = []
        const tuesdayhours = []
        const wednesdayhours = []
        const thursdayhours = []
        const fridayhours = []
        const saturdayhours = []

        this.hours.map((serviceHour) => {
            switch (serviceHour.weekday) {
                case 0:
                    sundayhours.push({...serviceHour, blockedDescription: serviceHour?.isBlocked ?  serviceHour.blockedDescription : undefined, isDrawn: serviceHour.isDrawn ? {id: true, description: 'Sim'} : {id: false, description: 'Não'}})
                    break;
                case 1:
                    mondayhours.push({...serviceHour, blockedDescription: serviceHour?.isBlocked ?  serviceHour.blockedDescription : undefined, isDrawn: serviceHour.isDrawn ? {id: true, description: 'Sim'} : {id: false, description: 'Não'}})
                    break;
                case 2:
                    tuesdayhours.push({...serviceHour, blockedDescription: serviceHour?.isBlocked ?  serviceHour.blockedDescription : undefined, isDrawn: serviceHour.isDrawn ? {id: true, description: 'Sim'} : {id: false, description: 'Não'}})
                    break;
                case 3:
                    wednesdayhours.push({...serviceHour, blockedDescription: serviceHour?.isBlocked ?  serviceHour.blockedDescription : undefined, isDrawn: serviceHour.isDrawn ? {id: true, description: 'Sim'} : {id: false, description: 'Não'}})
                    break;
                case 4:
                    thursdayhours.push({...serviceHour, blockedDescription: serviceHour?.isBlocked ?  serviceHour.blockedDescription : undefined, isDrawn: serviceHour.isDrawn ? {id: true, description: 'Sim'} : {id: false, description: 'Não'}})
                    break;
                case 5:
                    fridayhours.push({...serviceHour, blockedDescription: serviceHour?.isBlocked ?  serviceHour.blockedDescription : undefined, isDrawn: serviceHour.isDrawn ? {id: true, description: 'Sim'} : {id: false, description: 'Não'}})
                    break;
                case 6:
                    saturdayhours.push({...serviceHour, blockedDescription: serviceHour?.isBlocked ?  serviceHour.blockedDescription : undefined, isDrawn: serviceHour.isDrawn ? {id: true, description: 'Sim'} : {id: false, description: 'Não'}})
                    break;
                default:
                    break;
            }
        })

        const days =
        {
            [uuid()]: {
                description: 'Domingo',
                weekDay: 0,
                active: sundayhours.length > 0 ? true : false,
                hours: sundayhours?.length > 0 ? sundayhours : []
            },
            [uuid()]: {
                description: 'Segunda',
                weekDay: 1,
                active: mondayhours.length > 0 ? true : false,
                hours: mondayhours?.length > 0 ? mondayhours : []
            },
            [uuid()]: {
                description: 'Terça',
                weekDay: 2,
                active: tuesdayhours.length > 0 ? true : false,
                hours: tuesdayhours?.length > 0 ? tuesdayhours : []
            },
            [uuid()]: {
                description: 'Quarta',
                weekDay: 3,
                active: wednesdayhours.length > 0 ? true : false,
                hours: wednesdayhours?.length > 0 ? wednesdayhours : []
            },
            [uuid()]: {
                description: 'Quinta',
                weekDay: 4,
                active: thursdayhours.length > 0 ? true : false,
                hours: thursdayhours?.length > 0 ? thursdayhours : []
            },
            [uuid()]: {
                description: 'Sexta',
                weekDay: 5,
                active: fridayhours.length > 0 ? true : false,
                hours: fridayhours?.length > 0 ? fridayhours : []
            },
            [uuid()]: {
                description: 'Sábado',
                weekDay: 6,
                active: saturdayhours.length > 0 ? true : false,
                hours: saturdayhours?.length > 0 ? saturdayhours : []
            },
        }

        return days
    }

}