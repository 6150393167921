import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
//components
import { Body } from '../../../components/container/Body'
import { FaArrowCircleLeft, FaArrowAltCircleRight, FaEdit, FaPlus } from 'react-icons/fa'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import ReactQuill from 'react-quill'
import { Quillmodules } from '../../../components/richText/quill.modules'
import { TableDefault } from '../../../components/table/table.default'
import { TableMobile } from '../../../components/table/table.mobile'
import { Button } from '../../../components/buttons/button.default'
import { useThemeContext } from '../../../contexts/themeContext'
import { Toogle } from '../../../components/toogle/Toogle'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//utils
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import { permittedFiles } from '../../../utils/permittedFiles/permittedFiles'
import { resizePhoto } from '../../../utils/formatImages/resizeImage'
import { validateFields } from '../../../utils/form.validator'
import { useAuth } from '../../../contexts/useAuth'
import { api } from '../../../services/api/api'
import { useNavigate, useParams } from 'react-router-dom'
import { getStructuresById } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { formattTimeToShow } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { UrlToImage } from '../../../utils/formatImages/urlToImgFile'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { verifyFileSize } from '../../../utils/validators/verifyFileSize'
import { resizeImageTest } from '../../../utils/formatImages/resizeImageTest'

export function StrucutresEdit() {

    const structuresId = useParams()

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    //contexts
    const { userData } = useAuth()
    const [loadingFile, setLoadingFile] = useState(false)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    //estrutura
    const [title, setTitle] = useState('')
    const [structuresActive, setStructuresActive] = useState(false)
    const [structuresActiveHistory, setStructuresActiveHistory] = useState(false)
    //topicos
    const [topicTitle, setTopicTitle] = useState('')
    const [description, setDescription] = useState('')
    const [deltaDescription, setDeltaDescription] = useState('')
    //tabelas
    const [tableTitle, setTableTitle] = useState('')
    const [tableDescription, setTableDescription] = useState('')
    const [tableDeltaDescription, setTableDeltaDescription] = useState('')

    const [showTableCreate, setShowTableCreate] = useState(false)
    const [tableList, setTableList] = useState([])
    const [tableListHistory, setTableListHistory] = useState([])
    const [tableListToRemove, setTableListToRemove] = useState([])
    const [editTableValue, setEditTableValue] = useState({ edit: false, id: null })
    //photos
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    const [showPhoto, setShowPhoto] = useState(<a>Sem dados</a>)
    const [structurePhoto, setStructurePhoto] = useState([])
    const [structureAppendPhoto, setStructureAppendPhoto] = useState([])
    const [structureAppendPhotoHistory, setStructureAppendPhotoHistory] = useState([])
    const [structuresAppendTDeletePictures, setStructuresAppendTDeletePictures] = useState([])
    const [showTopicCreate, setShowTopicCreate] = useState(false)
    const [topicList, setTopicList] = useState([])
    const [topicListHistory, setTopicListHistory] = useState([])
    const [topicListToRemove, setTopicListToRemove] = useState([])
    const [editValue, setEditValue] = useState({ edit: false, id: null })

    const tableTheadTopics = [
        {
            "name": 'Nome',
            "original_name": 'title'
        },
    ]

    const tableTheadTables = [
        {
            "name": 'Nome',
            "original_name": 'title'
        },

    ]

    async function formatAPIPhoto(apiData) {

        if (apiData.structuresPictures?.length > 0) {

            let photosArray = []
            let photosAppendArray = []
            await Promise.all(apiData.structuresPictures.map(async (picture) => {
                const file = await api.get(`api/v1/structuresPictures/structures_id/${structuresId.structuresId}/picture/${picture.picture}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                const fileObj = URL.createObjectURL(file.data)
                photosArray.push(picture.picture)
                photosAppendArray.push({ photosAppendArray: fileObj, isMaster: picture.isMaster, photosArray: picture.picture, file: { name: picture.originalName } })

            }))
            setStructurePhoto(photosArray)
            setStructureAppendPhoto(photosAppendArray)
            setStructureAppendPhotoHistory(photosAppendArray)
        }
    }

    async function getData() {

        setShowUniversalModal(false)
        setLoading(true)
        const structuresData = await getStructuresById(userData[0].token, structuresId.structuresId)
        let formattedStructuresTopics = []
        let formattedStructuresTables = []

        try {
            if (structuresData.data?.topics) {
                structuresData.data.topics.map(value => {
                    formattedStructuresTopics.push({
                        ...value,
                        title: value.title,

                    })
                }
                )
                setTopicList(formattedStructuresTopics)
                setTopicListHistory(structuredClone(structuresData?.data.topics))
            }

            if (structuresData.data?.tables) {
                structuresData.data.tables.map(value => {
                    formattedStructuresTables.push({
                        ...value,
                        title: value.title,

                    })
                })
                setTableList(formattedStructuresTables)
                setTableListHistory(structuredClone(structuresData?.data?.topics))
            }
        } catch (error) {
            setTopicList(structuresData?.data?.topics)
            setTopicListHistory(structuredClone(structuresData?.data?.topics))

            setTableList(structuresData?.data.tables)
            setTableListHistory(structuredClone(structuresData?.data?.tables))
            setTitle(structuresData.data?.title)
            setStructuresActive(structuresData?.data[0]?.active)
            setStructuresActiveHistory(structuredClone(structuresData?.data?.active))
            setLoading(false)
        }
        setTitle(structuresData.data?.title)
        setStructuresActive(structuresData?.data?.active)
        setStructuresActiveHistory(structuredClone(structuresData?.data?.active))
        await formatAPIPhoto(structuresData.data)
        setLoading(false)
    }

    async function handleSubmit() {
        //campos obrigatórios
        const fields = [
            { "name": 'title', "value": title, "required": true, "type": 'string' },
        ]

        if (validateFields(fields)?.length > 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setLoading(false)
            return setShowNotificationModalText('Campos Obrigatórios não preenchidos, verifique os campos em vermelho')
        }
        try {

            setLoading(true)
            //atualiza
            let formattedTopics = []
            topicList.map(topic => formattedTopics.push({ title: topic.title, description: topic.description }))

            let formattedTables = []
            tableList.map(table => formattedTables.push({ title: table.title, description: table.description }))
            const updateStructure = await api.put(`/api/v1/structures/structures_id/${structuresId.structuresId}`, {
                title,
                topics: topicList.length > 0 ? formattedTopics : undefined,
                tables: tableList?.length > 0 ? formattedTables : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            //verifica se teve alterações nas fotos, caso sim altera via API, caso contrario a API não será chamada
            if (structureAppendPhoto.length > 0 && (structureAppendPhotoHistory !== structureAppendPhoto)) {
                const formData = new FormData();
                await Promise.all(structureAppendPhoto.map(async (photo, index) => {
                    const file = await UrlToImage(photo.photosAppendArray, photo.file.name)
                    formData.append('images', file);
                    if (photo.isMaster) {
                        formData.append('isMasterName', photo?.file.name)
                    }
                }))

                await api.post(`/api/v1/structuresPictures/structures_id/${structuresId.structuresId}`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                })
                //remove todas as fotos, caso o usuario remova todas da tela
            } else if (structureAppendPhoto.length === 0) {
                await Promise.all(structuresAppendTDeletePictures.map(async (photo, index) => {
                    await api.delete(`/api/v1/structuresPictures/structures_id/${structuresId.structuresId}/picture/${photo}`, {
                        headers: {
                            Authorization: `Bearer ${userData[0]?.token}`
                        }
                    })
                }))
            }
            //Atualiza o status da estrutura caso tenha sido alterada pelo usuario
            if (structuresActive !== structuresActiveHistory) {
                await api.put(`/api/v1/structures/structures_id/${structuresId.structuresId}/active/${structuresActive ? 1 : 0}`, {
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            }
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            navigate('/structures')
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function handleDescriptionText(content, delta, source, editor) {
        setDescription(content)
        setDeltaDescription(editor.getContents());
    }

    function handleDescriptionTable(content, delta, source, editor) {
        setTableDescription(content)
        setTableDeltaDescription(editor.getContents());
    }

    function handleNewTopic() {

        if (!editValue.edit) {
            setTopicList(prev => ([...prev, {
                id: uuid(),
                title: topicTitle,
                new: true,
                description: deltaDescription,
            }]))
        } else {

            let formattedValues = topicList.filter(topic => topic.id !== editValue.id)

            topicList.map(topic => {
                if (topic.id === editValue.id) {
                    formattedValues.push(
                        topic = {
                            id: topic.id,
                            title: topicTitle,
                            edit: true,
                            description: deltaDescription
                        })
                }
            })
            setTopicList(formattedValues)
        }
        setTopicTitle('')
        setDescription('')
        setDeltaDescription('')
        setEditValue({ edit: false, id: null })
        setShowTopicCreate(false)
    }

    function handleNewTable() {

        if (!editTableValue.edit) {
            setTableList(prev => ([...prev, {
                id: uuid(),
                title: tableTitle,
                new: true,
                description: tableDeltaDescription,
                created_at: moment().format('DD/MM/YYYY HH:MM')
            }]))
        } else {

            let formattedValues = tableList.filter(table => table.id !== editTableValue.id)

            tableList.map(table => {
                if (table.id === editTableValue.id) {
                    formattedValues.push(
                        table = {
                            id: table.id,
                            title: tableTitle,
                            edit: true,
                            description: tableDeltaDescription,
                            created_at: table.created_at
                        })
                }
            })
            setTableList(formattedValues)
        }
        setTableTitle('')
        setTableDescription('')
        setTableDeltaDescription('')
        setEditTableValue({ edit: false, id: null })
        setShowTableCreate(false)
    }

    function handleEditTopic(e) {
        setEditValue({ edit: true, topic: e })
        setTopicTitle(e.title)
        setDescription(e.description)
        setDeltaDescription(e.description)
        setShowTopicCreate(true)
    }

    function handleEditTable(e) {
        setEditTableValue({ edit: true, table: e })
        setTableTitle(e.title)
        setTableDescription(e.description)
        setTableDeltaDescription(e.description)
        setShowTableCreate(true)
    }

    const handleRemoveTopic = (e) => {

        let formattedValues = topicList.filter(topic => JSON.stringify(topic) !== JSON.stringify(editValue.topic))
        setTopicList(formattedValues)
        setTopicTitle('')
        setDescription('')
        setDeltaDescription('')
        setEditValue({ edit: false, id: null })
        setShowTopicCreate(false)
    }

    const handleRemoveTable = (e) => {

        let formattedValues = tableList.filter(table => JSON.stringify(table) !== JSON.stringify(editTableValue.table))

        setTableList(formattedValues)
        setTableTitle('')
        setTableDescription('')
        setTableDeltaDescription('')
        setEditTableValue({ edit: false, id: null })
        setShowTableCreate(false)
    }

    //pictures
    function handleClickInputFile() {
        let inputEl = document.getElementById('pictureAddInput')
        inputEl.click()
    }

    async function handleNewPicture(e) {

        const isValid = verifyFileSize(e.target.files[0])
        if (!isValid) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Arquivo maior do que o permitido (20MB)')
        }
        setShowNotificationModal(false)

        const validFile = permittedFiles(e.target.files[0], 'image')
        if (validFile.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(validFile.response)
        }

        const formattedImg = await new Promise((resolve, reject) => {
            resizeImageTest(e, (err, resizedFile) => {
                if (err) {
                    console.error(err);
                    return;
                }
                resolve(resizedFile)
            })
        })

        if (structurePhoto.length < 5) {
            const photoObj = URL.createObjectURL(formattedImg)
            setStructureAppendPhoto([{ photosAppendArray: photoObj, file: formattedImg, isMaster: structureAppendPhoto.filter(photo => photo?.isMaster)?.length === 0 ? true : false, isMasterName: e.target.files[0].name }, ...structureAppendPhoto])
        }

    }

    const prevPhoto = () => {

        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = structureAppendPhoto[showPhoto - 1].photosAppendArray
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto - 1)
    }

    const nextPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = structureAppendPhoto[showPhoto + 1].photosAppendArray
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto + 1)

    }

    async function showThisPhoto(photo, indexPhoto) {

        setShowPhotoModal(true)

        const modalElement = document.getElementById('showPhotoModal')

        let img = document.createElement('img')
        img.src = structureAppendPhoto[indexPhoto].photosAppendArray
        img.id = 'appendedPreviewPhoto'
        modalElement.appendChild(img)
        setShowPhoto(indexPhoto)
        await new Promise(resolve => setTimeout(resolve, 100))
        document.getElementById('photoContainer').style.opacity = '1'
        document.getElementById('photoContainer').style.transform = 'translateY(0px)'

    }

    async function closeThisPhoto() {

        document.getElementById('photoContainer').style.opacity = '0'
        document.getElementById('photoContainer').style.transform = 'translateY(-100px)'
        await new Promise(resolve => setTimeout(resolve, 300))

        setShowPhotoModal(false)
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)
        setShowPhoto('')
    }

    async function removeThisPhoto(photo, indexPhoto) {

        document.getElementById(photo.photosAppendArray + '-' + indexPhoto).style.transform = 'scale(.5)'
        await new Promise(resolve => setTimeout(resolve, 500))

        if (photo.isMaster) {
            let photos = []
            structureAppendPhoto.filter(photoToRemove => photoToRemove.file.name !== photo.file.name).map((newPhotos, index) => {
                if (index === 0) {
                    photos.push({
                        ...newPhotos,
                        isMaster: true,
                        isMasterName: newPhotos.file.name
                    })
                } else {
                    photos.push({
                        ...newPhotos,
                        isMaster: false
                    })
                }
            })
            setStructureAppendPhoto(photos)
        } else {
            setStructureAppendPhoto(structureAppendPhoto.filter(photoToRemove => photoToRemove.file.name !== photo.file.name))
        }

        document.getElementById(photo.photosAppendArray + '-' + indexPhoto).style.transform = 'scale(1)'
        setStructuresAppendTDeletePictures([...structuresAppendTDeletePictures, photo.photosArray])

    }

    async function handleDelete() {

        setLoading(true)
        try {
            const structuresData = await getStructuresById(userData[0].token, structuresId.structuresId)
            // return
            const remove = await api.delete(`/api/v1/structures/structures_id/${structuresData.data.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Estrutura removida com sucesso!')
            navigate(`/structures`)
            setLoading(false)
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    useEffect(() => {
        getData()
    }, [])

    function handleMasterPhoto(photo) {

        let newFoto = []

        structureAppendPhoto.map(structure => {
            if (structure.file.name !== photo) {
                newFoto.push(
                    {
                        ...structure,
                        isMaster: false
                    }
                )
            } else {
                newFoto.push(
                    {
                        ...structure,
                        isMaster: true,
                        isMasterName: structure.file.name
                    }
                )
            }
        })
        setStructureAppendPhoto(newFoto)
    }

    return (
        <>
            <ScreenModal>
                <div className='flex flex-col p-4 items-center gap-4'>
                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente excluir a estrutura?</p>
                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Este processo é <strong>irreversível</strong></p>
                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Caso deseja desativar temporariamente, clique no botão <strong>"Ativo/Inativo"</strong> ao lado do titulo</p>
                    <div className='flex flex-row gap-2'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button approval={false} shadow={true} onClick={() => setShowUniversalModal(false)}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Editar estrutura'} />
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex flex-col h-96 items-center justify-center'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='flex flex-col items-start justify-start w-full gap-8'>
                                <div className='flex flex-row gap-2 items-center justify-center'>
                                    <LabelInput text={'Titulo'}>
                                        <Input id='title' value={title} onChange={(e) => setTitle(e.target.value)} />
                                    </LabelInput>
                                    <LabelInput text={'Ativo'}>
                                        <Toogle status={structuresActive} onClick={() => setStructuresActive(!structuresActive)} />
                                    </LabelInput>
                                </div>
                                {
                                    showTopicCreate ?
                                        <div className='flex flex-col w-full gap-2'>
                                            <LabelInput text={'Titulo do topico'}>
                                                <Input value={topicTitle} onChange={(e) => setTopicTitle(e.target.value)} />
                                            </LabelInput>
                                            <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96 mb-12' readOnly={false} theme={"snow"} value={description} onChange={handleDescriptionText} />
                                            <div className='mb-8 md:mb-2 xl:hidden'></div>
                                            <div className="flex flex-row w-full items-start justify-start gap-2">
                                                <Button shadow={true} onClick={handleNewTopic} >Salvar</Button>
                                                <Button shadow={true} approval={false} onClick={() => { setShowNotificationModal(false); setEditValue({ edit: false, id: null }); setShowTopicCreate(false) }}>Cancelar</Button>
                                                {
                                                    editValue?.edit &&
                                                    <Button shadow={true} approval={false} onClick={() => handleRemoveTopic(editValue)}>Remover</Button>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className=" w-full gap-4 flex flex-col items-start justify-start">
                                            <div className="flex flex-row w-full justify-between items-start">
                                                <div className='flex flex-row gap-4 items-center justify-start w-full mb-4'>
                                                    <h1 className='text-primaryDefaultLight text-base'>Tópicos</h1>
                                                    <Tippy content={<p>Novo tópico</p>}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}>
                                                        <div onClick={() => { setTopicTitle(''); setDescription(''); setDeltaDescription(''); setShowTopicCreate(true) }} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                            <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Adicionar</a>
                                                        </div>
                                                    </Tippy>
                                                </div>
                                                {/* <Button shadow={true} onClick={() => { setTopicTitle(''); setDescription(''); setDeltaDescription(''); setShowTopicCreate(true) }}>Novo</Button> */}
                                            </div>
                                            <div id='topicTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                                <TableDefault data={topicList} title={tableTheadTopics} collumns={["title"]} onClick={(e) => handleEditTopic(e)} />
                                            </div>
                                            <div id='topicTableContent' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                                <TableMobile
                                                    onClick={(e) => e.func.execute(e.data)}
                                                    title={tableTheadTopics}
                                                    data={topicList}
                                                    collumns={["title"]}
                                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEditTopic }]}
                                                />
                                            </div>
                                        </div>
                                }
                                {
                                    showTableCreate ?
                                        <div className='flex flex-col w-full gap-2'>
                                            <LabelInput text={'Titulo da tabela'}>
                                                <Input value={tableTitle} onChange={(e) => setTableTitle(e.target.value)} />
                                            </LabelInput>
                                            <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96 mb-12' readOnly={false} theme={"snow"} value={tableDescription} onChange={handleDescriptionTable} />
                                            <div className='mb-8 md:mb-2 xl:hidden'></div>
                                            <div className="flex flex-row w-full items-start justify-start gap-2">
                                                <Button shadow={true} onClick={handleNewTable} >Salvar</Button>
                                                <Button shadow={true} approval={false} onClick={() => { setShowNotificationModal(false); setEditTableValue({ edit: false, id: null }); setShowTableCreate(false) }}>Cancelar</Button>
                                                {
                                                    editTableValue?.edit &&
                                                    <Button shadow={true} approval={false} onClick={() => handleRemoveTable(editTableValue)}>Remover</Button>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className=" w-full gap-4 flex flex-col items-start justify-start">
                                            <div className="flex flex-row w-full justify-between items-start">
                                                <div className='flex flex-row gap-4 items-center justify-start w-full mb-4'>
                                                    <h1 className='text-primaryDefaultLight text-base'>Tabelas</h1>
                                                    <Tippy content={<p>Nova tabela</p>}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}>
                                                        <div onClick={() => { setTableTitle(''); setTableDescription(''); setTableDeltaDescription(''); setShowTableCreate(true) }} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                            <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Adicionar</a>
                                                        </div>
                                                    </Tippy>
                                                </div>
                                                {/* <Button shadow={true} onClick={() => { setTableTitle(''); setTableDescription(''); setTableDeltaDescription(''); setShowTableCreate(true) }}>Novo</Button> */}
                                            </div>
                                            <div id='topicTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                                <TableDefault dontShowFilter={true} data={tableList} title={tableTheadTables} collumns={["title"]} onClick={(e) => handleEditTable(e)} />
                                            </div>
                                            <div id='tablesTableContentMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                                <TableMobile
                                                    onClick={(e) => e.func.execute(e.data)}
                                                    title={tableTheadTables}
                                                    data={tableList}
                                                    collumns={["title"]}
                                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEditTable }]}
                                                />
                                            </div>
                                        </div>
                                }
                                <div id='structuresCreatePictures' className='w-full flex flex-col items-start justify-start gap-4 user-select-none'>
                                    <div className='flex flex-col w-full '>
                                        <h1 className='text-primaryDefaultLight text-base'>Galeria de fotos</h1>
                                    </div>
                                    <div className='flex flex-row gap-2 items-start justify-start'>
                                        <div className='flex flex-row gap-2 items-start justify-start'>
                                            <div className={`${showPhotoModal ? 'flex' : 'hidden'} flex-row items-center justify-center w-full h-full fixed top-0 left-0 z-[101]`}>
                                                <div id='photoContainer' className={`opacity-0 transition-all duration-500 -translate-y-10 flex gap-4 flex-row items-center justify-center w-full h-full`}>
                                                    {
                                                        showPhoto > 0 ?
                                                            <FaArrowCircleLeft onClick={prevPhoto} className='text-primaryDefaultLight hover:-translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                            :
                                                            <FaArrowCircleLeft className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                    }
                                                    <div className='bg-bgPrimaryLight dark:bg-primaryDefaultDark boxShadow p-8 w-full h-full md:w-[700px] md:h-[500px] lg:w-[1000px] lg:h-[600px] flex flex-col items-center justify-start'>
                                                        <div className='flex flex-row justify-end w-full'>
                                                            <button className='bg-primaryDefaultLight hover:brightness-125 transition-all duration-200 h-8 w-8 rounded-full flex flex-col items-center justify-center text-titleGrayTextLight' onClick={() => closeThisPhoto()}>X</button>
                                                        </div>
                                                        <div id='showPhotoModal' className='flex flex-col items-center justify-center overflow-auto w-full h-full'>
                                                        </div>
                                                    </div>
                                                    {
                                                        showPhoto < structurePhoto.length - 1 ?
                                                            <FaArrowAltCircleRight onClick={nextPhoto} className='text-primaryDefaultLight hover:translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                            :
                                                            <FaArrowAltCircleRight className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                    }
                                                </div>
                                            </div>
                                            <div className={`${showPhotoModal ? 'flex' : 'hidden'}  bg-gray-600 opacity-80 w-full h-full fixed z-[100] left-0 top-0`}></div>
                                            <h1 className='text-sm text-inputPlaceholderLight'>Fotos</h1>
                                            <a className='text-inputPlaceholderLight dark:text-titleGrayTextDark text-sm'>{structureAppendPhoto?.length} de 5</a>
                                            <p className='font-semibold text-base cursor-pointer' onClick={() => handleClickInputFile()}>+</p>
                                        </div>

                                    </div>

                                    {
                                        !loadingFile &&
                                        <input id='pictureAddInput' type='file' accept='.png, .jpeg, .jpg' className='hidden' onChange={(e) => handleNewPicture(e)}></input>
                                    }
                                    <div className='flex flex-row gap-2 items-center justify-start'>
                                        {
                                            structureAppendPhoto.map((photo, indexPhoto) => {
                                                return <div id={photo.photosAppendArray + '-' + indexPhoto} className='cursor-pointer transition-all duration-500 ease-in-out relative flex flex-row gap-4 items-center justify-center'>
                                                    <Tippy content={'Remover foto'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='right'
                                                        delay={300}>
                                                        <button onClick={() => removeThisPhoto(photo, indexPhoto)} className='text-titleGrayTextLight absolute -left-2 -top-2 w-10 h-10 sm:w-6 sm:h-6 rounded-full bg-gray-400'>x</button>
                                                    </Tippy>
                                                    <img onClick={() => showThisPhoto(photo.photosAppendArray, indexPhoto)} className='border border-gray-500 rounded-xl w-20 h-20' src={structureAppendPhoto[indexPhoto].photosAppendArray}></img>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4">
                                    <div className='flex flex-col gap-4'>
                                        <h1 className='text-primaryDefaultLight text-base'>Foto de capa</h1>
                                        <select onChange={(e) => handleMasterPhoto(e.target.value)}>
                                            {
                                                structureAppendPhoto.map(gastronomy => {
                                                    return <option selected={gastronomy?.isMaster ? true : false} className="text-sm text-inputPlaceholderLight">{gastronomy?.file.name}</option>
                                                })
                                            }
                                        </select>
                                        <a className='text-inputPlaceholderLight dark:text-titleGrayTextDark text-sm'>Foto exibida em destaque</a>
                                    </div>
                                    <div className='flex flex-row gap-2 items-center justify-start'>
                                        {
                                            structureAppendPhoto.map((photo, indexPhoto) => {
                                                if (photo?.isMaster) {
                                                    return <div id={photo.photosAppendArray + '-isMaster' + indexPhoto} className='transition-all duration-500 ease-in-out relative flex flex-row gap-4 items-center justify-center'>
                                                        <Tippy content={'Remover foto'}
                                                            arrow={true}
                                                            animation='shift-away'
                                                            placement='right'
                                                            delay={300}>

                                                        </Tippy>

                                                        <img className='border border-gray-500 rounded-xl w-20 h-20' src={photo.photosAppendArray}></img>
                                                    </div>
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button
                            id='cancelStructuresCreate'
                            approval={false}
                            shadow={true}
                            onClick={() => { history.back(); setShowNotificationModal(false) }}
                        >Voltar
                        </Button>
                        <div className='flex flex-row gap-2'>
                            <Button
                                module={"structures:delete"}
                                userData={userData}
                                id='cancelStructuresCreate'
                                approval={false}
                                shadow={true}
                                onClick={() => { setShowUniversalModal(true) }}
                            >Excluir
                            </Button>
                            {
                                !showTopicCreate && !showTableCreate &&
                                <Button
                                    module={"structures:create"}
                                    userData={userData}
                                    id='approvalStructuresCreate'
                                    approval={true}
                                    shadow={true}
                                    onClick={() => handleSubmit()}
                                >Salvar
                                </Button>
                            }
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
}