import React from "react";

export function MembershipCard({ userData }) {
    
    return (

        <div className="w-full flex flex-col gap-1 items-start h-full rounded-lg shadow-lg bg-gradient-to-r from-zinc-300 to-zinc-200 p-2">            
            <div className="w-full mb-2">
                <div className="w-10 h-10 rounded-full border bg-zinc-300 border-zinc-100 "></div>
            </div>
            <div className="flex flex-col w-full">
                <a className="text-titleBlackTextLight dark:text-titleGrayTextDark text-xs">{`${userData?.firstName} ${userData?.lastName}`}</a>
                <a className="text-titleBlackTextLight dark:text-titleGrayTextDark text-xs">Meu CPF</a>
                <a className="text-titleBlackTextLight dark:text-titleGrayTextDark text-xs">{userData?.birthday}</a>
            </div>
        </div>
    )
}