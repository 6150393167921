export const permittedFiles = (file, type) => {

    const permittedImageExtensios = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/gif',
        'image/pjpeg',
    ]

    const permittedDocsExtensios = [
      
        'application/pdf',
    ]

    const maxFileSize = 20 * 1024 * 1024

    let returnMsg = {}

    if (type === 'image' && !permittedImageExtensios.includes(file.type)) {
        returnMsg['error'] = true
        returnMsg['response'] = 'Formato não aceito'
        returnMsg['fileTypes'] = type === permittedImageExtensios
        returnMsg['maxSize'] = maxFileSize

    }

    if (type === 'pdf' && !permittedDocsExtensios.includes(file.type)) {
        returnMsg['error'] = true
        returnMsg['response'] = 'Formato não aceito'
        returnMsg['fileTypes'] = type === permittedDocsExtensios
        returnMsg['maxSize'] = maxFileSize

    }

    if (file.size / 1000 > maxFileSize) {
        console.log('Arquivo maior do que o limite (6MB)')
        returnMsg['error'] = true
        returnMsg['response'] = 'Arquivo muito grande'
        returnMsg['fileTypes'] = permittedImageExtensios
        returnMsg['maxSize'] = maxFileSize
    }

    return returnMsg
}