import React, { useEffect, useState } from "react";
//components
import { FaEdit } from "react-icons/fa";
import { Container } from "../../../components/container/container";
import { Body } from "../../../components/container/Body";
import { TableDefault } from "../../../components/table/table.default";
import { TableMobile } from "../../../components/table/table.mobile";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { Button } from "../../../components/buttons/button.default";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
import { useNavigate } from "react-router-dom";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { getActivitiesPlans } from "../../../services/api/callAPIsFunctions/defaultCalls.api";

export function ActivitiesPlans() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [activitiesActiveButton, setActivitiesActiveButton] = useState(1)
    const [loading, setLoading] = useState(true)
    const tableThead = [
        {
            "name": 'Plano',
            "original_name": 'plansName'
        },
        {
            "name": 'Parcelamento',
            "original_name": 'typeOfCharge'
        },
        {
            "name": 'Criado em',
            "original_name": "created_at"
        },

    ]

    const [plansList, setPlansList] = useState([])

    async function getData() {

        setLoading(true)
        const plansData = await getActivitiesPlans(userData[0].token, 1)
        if (plansData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(plansData.data)
            return
        }
        setShowNotificationModalSuccess(true)
        setPlansList(plansData.data)
        setLoading(false)

    }

    function handleEdit(e) {

        navigate(`/activitiesPlansEdit/${e.id}`)

    }

    async function handleFilterActiveActivities(status) {

        if (status !== activitiesActiveButton) {
            setLoading(true)

            const newActivitiesList = await getActivitiesPlans(userData[0]?.token, status)

            status !== undefined ? setPlansList(newActivitiesList.data) : setFilteredActivities(plansList)
            status > 0 ? setActivitiesActiveButton(1) : setActivitiesActiveButton(0)

            setLoading(false)
        }
    }

    useEffect(() => {

        getData()

    }, [])

    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between items-center w-full">
                    <Title text={'Planos para atividades'}></Title>
                    <Button shadow={true} onClick={() => navigate('/activitiesPlansCreate')}>+ Novo</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                        <div className="flex flex-col h-96 w-full items-center justify-center">
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div className='flex flex-row items-center justify-start w-full mb-6'>
                                <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${activitiesActiveButton === 1 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveActivities(1)}>Ativos</button>
                                <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${activitiesActiveButton === 0 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveActivities(0)}>Inativos</button>
                            </div>
                            <div id='activitiesPlansTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                <TableDefault
                                    onClick={(e) => handleEdit(e)}
                                    title={tableThead}
                                    data={plansList}
                                    collumns={["planName", "typeOfCharge", "created_at"]}
                                />
                            </div>
                            <div id='activitiesPlansTableContent' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                <TableMobile
                                    onClick={(e) => e.func.execute(e.data)}
                                    title={tableThead}
                                    data={plansList}
                                    collumns={["planName", "typeOfCharge", "created_at"]}
                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                />
                            </div>
                        </>

                }
            </Body>
        </Container>
    )
}