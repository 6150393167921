import React, { useEffect, useState } from "react";
import { Container } from "../../components/container/container";
import { TitlePage } from "../../components/titlePages/title.page";
import { Title } from "../../components/titlePages/title";
import { Body } from "../../components/container/Body";
import { LabelInput } from "../../components/label/label.input";
import { Input } from "../../components/input/input";
import { getActivities, getCategories, getFilteredActivitiesSubscriptionsReport, getFilteredSchedulesReports, getServices } from "../../services/api/callAPIsFunctions/defaultCalls.api";
import { Button } from "../../components/buttons/button.default";
import { useAuth } from "../../contexts/useAuth";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { DefaultLoader } from "../../components/loaders/defaultLoader";
import moment from "moment";
import { useThemeContext } from "../../contexts/themeContext";
import { ScreenModal } from "../../components/modals/notification/screenModal";
import { UsersToFind } from "../../components/table/usersToFind.table";
import Multiselect from "../../components/selects/mult.select";
import { useScreenSizeContext } from "../../contexts/screenSizeContext";


export function ActivitiesSubscriptionsReport() {

    const { screenX } = useScreenSizeContext()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, showUniversalModal, setShowUniversalModal } = useThemeContext()

    const [loading, setLoading] = useState(true)
    const [activitiesList, setActivitiesList] = useState([])

    const [selectedStartDate, setSelectedStartDate] = useState('')
    const [selectedFinishDate, setSelectedFinishDate] = useState('')
    const [selectedActivitie, setSelectedActivitie] = useState([])
    const [selectedCreatedAt, setsSelectedCreatedAt] = useState('')
    const [selectedStatus, setSelectedStatus] = useState({ id: true, name: 'Ativo' })
    const [selectedUser, setSelectedUser] = useState('')
    const [selectedReportType, setSelectedReportType] = useState({ id: 0, name: 'Excel', extension: 'xlsx' })

    const scheduleStatusList = [
        { name: 'Aguardando pagamento', id: 0 },
        { name: 'Pago', id: 1 },
        { name: 'Cancelado', id: 2 },
    ]

    useEffect(() => {
        getData()
    }, [])

    async function getData() {

        setLoading(true)
        const activitiesListAPI = await getActivities(userData[0]?.token, 1)
        setActivitiesList(activitiesListAPI.data)
        setLoading(false)
    }

    async function getReport() {
        if (moment(selectedFinishDate).diff(moment(selectedStartDate), 'days') > 31) {

            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Perído máximo é de 31 dias')
        }

        try {
            setLoading(true)
            const report = await getFilteredActivitiesSubscriptionsReport(userData[0]?.token, [
                { original_name: 'startDate', value: selectedStartDate },
                { original_name: 'finishDate', value: selectedFinishDate },
                { original_name: 'activities_id', value: selectedActivitie?.length > 0 ? selectedActivitie : [] },
                { original_name: 'created_at', value: selectedCreatedAt },
                { original_name: 'active', value: selectedStatus?.id },
                { original_name: 'users_id', value: selectedUser?.id ? selectedUser.id : '' },
                { original_name: 'reportType', value: selectedReportType?.name ? selectedReportType.name : '' },
            ])
            if (report.data.status === 204) {
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText('Nenhum dado encontrado para esta pesquisa')
                setLoading(false)
                return
            }
            setLoading(false)
            await new Promise(resolve => setTimeout(resolve, 100))
            const blob = report.data // Seu Blob recebido
            const blobURL = URL.createObjectURL(blob);

            const downloadLink = document.getElementById('downloadLink');
            downloadLink.href = blobURL;
            downloadLink.download = `relatorio-${moment().format("DD/MM/YYYY")}_
            ${moment().hour()}_${moment().minutes()}_${moment().seconds()}.${selectedReportType?.extension}`;
            downloadLink.click();

            return URL.revokeObjectURL(blobURL);
        } catch (error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(responseError(error))
            setLoading(false)
        }
    }

    return (
        <>
            <ScreenModal>
                <UsersToFind handleSelect={(e) => { setSelectedUser(e); setShowUniversalModal(false) }} />
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Relatórios de inscrições'} />
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className="w-full h-96 flex items-center justify-center">
                                <DefaultLoader />
                            </div>
                            :
                            <div className="flex flex-col gap-2 w-full">
                                <div className="flex gap-1 text-titleBlackTextLight">
                                    <a className="text-sm ">Cliente:</a>
                                    {
                                        selectedUser?.id ?
                                            <div className="flex gap-1">
                                                <a className="text-sm text-black">{selectedUser?.firstName}</a>
                                                <a onClick={() => setShowUniversalModal(true)} className="text-sm underline cursor-pointer">Alterar</a>
                                                <a onClick={() => setSelectedUser('')} className="text-sm underline cursor-pointer">Limpar</a>
                                            </div>
                                            :
                                            <a onClick={() => setShowUniversalModal(true)} className="text-sm underline cursor-pointer text-primaryDefaultLight">Selecionar</a>
                                    }
                                </div>
                                <LabelInput text={'Data início'}>
                                    <Input type={'date'} value={selectedStartDate} onChange={(e) => { setSelectedStartDate(e.target.value); setShowNotificationModal(false) }}></Input>
                                </LabelInput>
                                <LabelInput text={'Data fim'}>
                                    <Input type={'date'} value={selectedFinishDate} onChange={(e) => { setSelectedFinishDate(e.target.value); setShowNotificationModal(false) }}></Input>
                                </LabelInput>                           
                                <div className="flex items-start justify-start w-full">
                                    <LabelInput text={'Categoria'}>
                                        <Multiselect
                                            width={screenX < 640 ? '[100%]' : ''}
                                            items={activitiesList}
                                            mult
                                            value={selectedActivitie}
                                            onChange={(e) => {
                                                setSelectedActivitie(e)
                                            }}
                                            selectedLabel={'activitiesName'}
                                            id={`selectedActivitie`} />
                                    </LabelInput>
                                </div>
                                <LabelInput text={'Criado em'}>
                                    <Input type={'date'} value={selectedCreatedAt} onChange={(e) => setsSelectedCreatedAt(e.target.value)}></Input>
                                </LabelInput>
                                <LabelInput text={'Status'}>
                                    <InputAutoComplete preSelectedValue={selectedStatus?.name} data={[{ id: true, name: 'Ativo' }, { id: false, name: 'Inativo' }]} optionList={['name']} selectedLabel={'name'} onChange={(e) => setSelectedStatus(e)} />
                                </LabelInput>
                                <LabelInput text={'Tipo de relatório'}>
                                    <InputAutoComplete preSelectedValue={selectedReportType?.name} data={[{ id: 0, name: 'Excel', extension: 'xlsx' }, { id: 1, name: 'PDF', extension: 'pdf' }]} optionList={['name']} selectedLabel={'name'} onChange={(e) => setSelectedReportType(e)} />
                                </LabelInput>
                                <a id="downloadLink"></a>
                                <div className="w-full sm:w-48 mt-6">
                                    <Button onClick={() => getReport()}>Download</Button>

                                </div>
                            </div>
                    }
                </Body>
            </Container>
        </>
    )
}