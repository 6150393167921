//hooks
import React, { useEffect } from 'react'
import { Route, Routes, HashRouter, Navigate } from 'react-router-dom'
import { CalendarMain } from '../components/calendar/calendar.main'
import { ProtectedRoute } from '../pages/privateRoute'
//components
import { Menu } from '../components/menu/menu'
//contexts
import { useAuth } from '../contexts/useAuth'
import { useScreenSizeContext } from '../contexts/screenSizeContext'
//configuration
import { Configuration } from '../pages/configuration/configurationHome/configuration.home'
import { ThemesCenter } from '../pages/configuration/themes/themes.center'
import { ThemesDefaultCenter } from '../pages/configuration/themes/themes.default.center'
import { Customer } from '../pages/configuration/customer/customer.profile'
import { MyProfileEdit } from '../pages/configuration/myProfile/myProfile'
//home
import { Home } from '../pages/home'
//users
import { UsersTable } from '../pages/configuration/users/users.table'
import { UserCreate } from '../pages/configuration/users/users.creation'
import { UserEdit } from '../pages/configuration/users/user.edit'
//profiles
import { UserProfile } from '../pages/configuration/users/user.profile'
import { UserProfileCreate } from '../pages/configuration/users/users.profile.create'
import { UserProfileEdit } from '../pages/configuration/users/users.profile.edit'
//clients
import { ClientsCreate } from '../pages/configuration/clients/clients.create'
import { ClientsTable } from '../pages/configuration/clients/clients.table'
import { ClientEdit } from '../pages/configuration/clients/clients.edit'
//associations
import { Associations } from '../pages/configuration/associations/associations'
import { AssociatiosCreate } from '../pages/configuration/associations/associations.create'
import { AssociatiosEdit } from '../pages/configuration/associations/associations.edit'
//categories
import { Categories } from '../pages/configuration/categories/categories'
import { CategoriesCreate } from '../pages/configuration/categories/categories.create'
import { CategoriesEdit } from '../pages/configuration/categories/categories.edit'
//services
import { Services } from '../pages/configuration/services/service'
import { ServicesCreate } from '../pages/configuration/services/service.create'
import { ServicesEdit } from '../pages/configuration/services/service.edit'
//paymentGateway
import { PaymentGatewayConfig } from '../pages/configuration/paymentGateways/paymentGateway.config'
import { PaymentGatewayCreate } from '../pages/configuration/paymentGateways/paymentGatewayCreate'
import { PaymentGatewayEdit } from '../pages/configuration/paymentGateways/paymentGatewayEdit'
//public-reservation
import { Reservation } from '../pages/reservation/reservation'
import { PaymentMethods } from '../pages/configuration/paymentMethods/paymentMethods'
import { ServiceTermsOfUse } from '../pages/configuration/servicesTermsOfUse/servicesTermsOfUse'
import { ServiceTermsCreate } from '../pages/configuration/servicesTermsOfUse/serviceTermsCreate'
import { HomeClient } from '../pages/home.client'
import { ServiceTermsEdit } from '../pages/configuration/servicesTermsOfUse/serviceTermsEdit'
import { HolidaysCalendar } from '../pages/configuration/holidaysCalendar/holidaysCalendar'
import { HolidayCalendarCreate } from '../pages/configuration/holidaysCalendar/holidaysCalendar.create'
import { HolidayCalendarEdit } from '../pages/configuration/holidaysCalendar/holidaysCalendarEdit'
import { CustomerAttendance } from '../pages/configuration/customerAttendance/custtomerAttendance'
import { ReservationCreate } from '../pages/reservation/reservation.create'
import { Activities } from '../pages/configuration/activities/activities'
import { ActivitiesCreate } from '../pages/configuration/activities/activities.create'
import { ActivitiesPlans } from '../pages/configuration/activitiesPlans/activitiesPlans'
import { ActivitiesPlansCreate } from '../pages/configuration/activitiesPlans/activitiesPlans.create'
import { ActivitiesPlansEdit } from '../pages/configuration/activitiesPlans/activitiesPlans.edit'
import { ActivitiesEdit } from '../pages/configuration/activities/activities.edit'
import { ActivitiesActions } from '../pages/activitiesActions/activitiesActions'
import { ActivitiesActionsCreate } from '../pages/activitiesActions/activitiesActionsCreate'
import { ActivitiesActionsCreateClients } from '../pages/activitiesActions/activitiesActionsCreate.clients'
import { MyBalance } from '../pages/myBalance/myBalance'
import { Gastronomy } from '../pages/configuration/gastronomy/gastronomy'
import { GastronomyCreate } from '../pages/configuration/gastronomy/gastronomy.create'
import { GastronomyEdit } from '../pages/configuration/gastronomy/gastronomy.edit'
import { GastronomyClient } from '../pages/gastronomy/gastronomy.clients'
import { ReservationEdit } from '../pages/reservation/reservation.edit'
import { ReceiptConfirmation } from '../components/receipt/receiptConfirmation'
import { Receipt } from '../components/receipt/receipt'
import { Structures } from '../pages/configuration/structures/structures.table'
import { StrucutresCreate } from '../pages/configuration/structures/structuresCreate'
import { StrucutresEdit } from '../pages/configuration/structures/structuresEdit'
import { ReservationClient } from '../pages/reservation/reservation.create client'
import { StructuresClient } from '../pages/structures/structures.clients'
import { Agreements } from '../pages/configuration/agreements/agreements'
import { AgreementsCreate } from '../pages/configuration/agreements/agreements.create'
import { AgreementsClient } from '../pages/agreements/agreements.client'
import { Events } from '../pages/configuration/events/events'
import { EventsCreate } from '../pages/configuration/events/events.create'
import { EventsEdit } from '../pages/configuration/events/events.edit'
import { EventsClient } from '../pages/events/events.client'
import { MySchedules } from '../pages/mySchedules/mySchedules'
import { MySubscriptions } from '../pages/mySubscriptions/mySubscriptions'
import { ReservationFailed } from '../components/reservationStatus/reservationFailed'
import { MySchedulesDetails } from '../pages/mySchedules/mySchedulesDetails'
import { UsersBlocked } from '../pages/configuration/usersBlocked/usersBlocked'
import { UsersBlockedCreate } from '../pages/configuration/usersBlocked/usersBlocked.create'
import { UsersBalance } from '../pages/configuration/usersBalance/usersBalance'
import { UsersBlockedEdit } from '../pages/configuration/usersBlocked/usersBlocked.edit'
import { SchedulesDraw } from '../pages/configuration/schedulesDraw/schedulesDraw'
import { SiteBanners } from '../pages/configuration/siteIntegrations/siteBanners'
import { SiteBannerCreate } from '../pages/configuration/siteIntegrations/siteBannersCreate'
import { SiteBannerEdit } from '../pages/configuration/siteIntegrations/siteBanners.edit'
import { ActivitiesActionsEdit } from '../pages/activitiesActions/activitiesActions.edit'
import { UsersImport } from '../pages/configuration/usersImport/usersImport'
import { MySchedulesDraw } from '../pages/mySchedules/mySchedulesDraw'
import { MySchedulesDrawDetails } from '../pages/mySchedules/mySchedulesDrawDetails'
import { ActivitiesActionsCreateFailed } from '../components/ActivitiesActionsCreateStatus/ActivitiesActionsCreateFailed'
import { MySubscriptionsDetails } from '../pages/mySubscriptions/mySubscriptionsDetails'
import { MyMembershipCard } from '../pages/configuration/myMembership/myMembershipCard'
import { ValidUser } from '../pages/configuration/users/validUser'
import { SchedulesReports } from '../pages/reports/schedulesReports'
import { Reports } from '../pages/reports/reports'
import { SchedulesPaymetsReport } from '../pages/reports/schedulesPayments'
import { ActivitiesSubscriptionsReport } from '../pages/reports/activitiesSubscriptionsReport'
import { PaymentsReport } from '../pages/reports/paymentsReports'
import { SchedulesBlockReport } from '../pages/reports/schedulesBlockReport'
import { Companys } from '../pages/configuration/companys/companys'
import { CompaniesCreate } from '../pages/configuration/companys/companies.create'
import { CompaniesEdit } from '../pages/configuration/companys/companies.edit'

export function AppRoutes() {

    const { userData } = useAuth()
    const { screenX } = useScreenSizeContext()
    const notInApp = document.URL.includes('receipt')
    const customersModules = userData[0]?.customersData?.modules?.map(module => { return module.id })

    return (
        <>
            <HashRouter>
                <Routes>
                    <Route path='/receiptConfirmation' element={
                        <ReceiptConfirmation />
                    }>
                    </Route>
                    <Route path='/receipt' element={
                        <Receipt />
                    }>
                    </Route>
                </Routes>
                {
                    !notInApp &&
                    <div className={`${screenX < 640 ? '' : 'h-[100vh]'}  flex flex-col-reverse sm:flex-row justify-between md:justify-items-start overflow-hidden`}>
                        {
                            !document.URL.includes('receipt') &&
                            <Menu />
                        }
                        {
                            userData[0]?.email?.length === 0 || !userData[0]?.email ?
                                <Routes>
                                    <Route path={`/*`} element={
                                        <ProtectedRoute user={userData} func={[]}>
                                            <MyProfileEdit />
                                        </ProtectedRoute>
                                    } />
                                    <Route path='/myProfileEdit/:id' element={<MyProfileEdit />} />
                                </Routes>
                                :
                                userData[0]?.typeAccess_id < 4 ?
                                    //Our Customer Routes
                                    <Routes>

                                        <Route path='/' element={<Home />} />
                                        <Route path='/associations' element={
                                            <ProtectedRoute user={userData} func={['associations:read', "associations:create", "associations:update", 'asssociations:delete']}>
                                                <Associations />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/associationsCreate' element={
                                            <ProtectedRoute user={userData} func={['associations:read', "associations:create"]}>
                                                <AssociatiosCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/associationsEdit/:id' element={
                                            <ProtectedRoute user={userData} func={['associations:update', 'associations:read']}>
                                                <AssociatiosEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/activities' element={
                                            <ProtectedRoute module={2} user={userData} func={['activities:create', 'activities:update', 'activities:create', 'activities:delete']}>
                                                <Activities />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/activitiesActions' element={
                                            <ProtectedRoute module={2} user={userData} func={['"activitiesSubscription:read', 'activitiesSubscription:subscribe', "activitiesSubscription:unsubscribe"]}>
                                                <ActivitiesActions />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/activitiesActionsCreate' element={
                                            <ProtectedRoute module={2} user={userData} func={['activitiesSubscription:read', 'activitiesSubscription:subscribe']}>
                                                <ActivitiesActionsCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/activitiesActionsEdit/:id' element={
                                            <ProtectedRoute module={2} user={userData} func={['activitiesSubscription:read', 'activitiesSubscription:subscribe', 'activitiesSubscription:unsubscribe']}>
                                                <ActivitiesActionsEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/activitiesCreate' element={
                                            <ProtectedRoute module={2} user={userData} func={['activities:create']}>
                                                <ActivitiesCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/activitiesEdit/:id' element={
                                            <ProtectedRoute module={2} user={userData} func={['activities:update']}>
                                                <ActivitiesEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/activitiesPlans' element={
                                            <ProtectedRoute module={2} user={userData} func={['activitiesPlans:create', 'activitiesPlans:read', 'activitiesPlans:update', 'activitiesPlans:delete']}>
                                                <ActivitiesPlans />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/activitiesPlansCreate' element={
                                            <ProtectedRoute module={2} user={userData} func={['activitiesPlans:create']}>
                                                <ActivitiesPlansCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/activitiesPlansEdit/:id' element={
                                            <ProtectedRoute module={2} user={userData} func={['activitiesPlans:update', 'activitiesPlans:delete']}>
                                                <ActivitiesPlansEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/agreements' element={
                                            <ProtectedRoute user={userData} func={['agreements:read', 'agreements:create', 'agreements:update', 'agreements:delete']}>
                                                <Agreements />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/agreementsShow' element={
                                            <ProtectedRoute user={userData} func={['agreements:read', 'agreements:create', 'agreements:update', 'agreements:delete']}>
                                                <AgreementsClient />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/agreementsCreate' element={
                                            <ProtectedRoute user={userData} func={['agreements:read', 'agreements:create', 'agreements:update', 'agreements:delete']}>
                                                <AgreementsCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/categories' element={
                                            <ProtectedRoute user={userData} func={['categories:read', 'categories:create', 'categories:update', 'categories:delete']}>
                                                <Categories />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/categoriesCreate' element={
                                            <ProtectedRoute user={userData} func={['categories:create']}>
                                                <CategoriesCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/categoriesEdit/:id' element={
                                            <ProtectedRoute user={userData} func={['categories:update', 'categories:delete']}>
                                                <CategoriesEdit />
                                            </ProtectedRoute>}
                                        />
                                        <Route path='/clients' element={
                                            <ProtectedRoute user={userData} func={['clients:read']}>
                                                <ClientsTable />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/clientsCreate' element={
                                            <ProtectedRoute user={userData} func={['clients:create']}>
                                                <ClientsCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/clientsEdit/:id' element={
                                            <ProtectedRoute user={userData} func={['clients:update']}>
                                                <ClientEdit />
                                            </ProtectedRoute>
                                        } />
                                          <Route path='/clientsEdit/:id' element={
                                            <ProtectedRoute user={userData} func={['clients:update']}>
                                                <ClientEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/companys' element={
                                            <ProtectedRoute user={userData} func={['companies:read']}>
                                               <Companys />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/companiesCreate' element={
                                            <ProtectedRoute user={userData} func={['companies:create']}>
                                               <CompaniesCreate />
                                            </ProtectedRoute>
                                        } />
                                          <Route path='/companiesEdit/:id' element={
                                            <ProtectedRoute user={userData} func={['companies:update']}>
                                               <CompaniesEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/configuration' element={
                                            <Configuration />
                                        } />
                                        <Route path='/customer' element={
                                            <ProtectedRoute user={userData} func={["customers:read", "customers:update", "customers:delete"]}>
                                                <Customer />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/customerAttendance' element={
                                            <ProtectedRoute user={userData} func={["customersAttendance:read", "customersAttendance:create", "customersAttendance:update", "customersAttendance:delete"]}>
                                                <CustomerAttendance />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/defaultTheme' element={
                                            <ProtectedRoute user={userData} func={''}>
                                                <ThemesDefaultCenter />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/events' element={
                                            <ProtectedRoute user={userData} func={['events:read', 'events:update', "events:delete"]}>
                                                <Events />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/eventsCreate' element={
                                            <ProtectedRoute user={userData} func={['events:read', 'events:update', "events:delete"]}>
                                                <EventsCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/eventsEdit' element={
                                            <ProtectedRoute user={userData} func={['events:read', 'events:update', "events:delete"]}>
                                                <EventsEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/eventsShow' element={<EventsClient />} />
                                        <Route path='/gastronomy' element={
                                            <ProtectedRoute user={userData} func={['gastronomy:read', 'gastronomy:create', 'gastronomy:update', "gastronomy:delete"]}>
                                                <Gastronomy />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/gastronomyCreate' element={
                                            <ProtectedRoute user={userData} func={['gastronomy:read', 'gastronomy:create', 'gastronomy:update', "gastronomy:delete"]}>
                                                <GastronomyCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/gastronomyEdit/:id' element={
                                            <ProtectedRoute user={userData} func={['gastronomy:read', 'gastronomy:create', 'gastronomy:update', "gastronomy:delete"]}>
                                                <GastronomyEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/holidayCalendar' element={
                                            <ProtectedRoute user={userData} func={["holidays:read", "holidays:update", "holidays:delete"]}>
                                                <HolidaysCalendar />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/holidayCalendarCreate' element={
                                            <ProtectedRoute user={userData} func={["holidays:create"]}>
                                                <HolidayCalendarCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/holidayCalendarEdit/id/:id' element={
                                            <ProtectedRoute user={userData} func={["holidays:update", "holidays:delete", "holidays:read"]}>
                                                <HolidayCalendarEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/myMembershipCard/:id' element={<MyMembershipCard />} />
                                        <Route path='/myProfileEdit/:id' element={<MyProfileEdit />} />

                                        <Route path='/paymentMethods' element={
                                            <ProtectedRoute user={userData} func={['paymentMethods:create', 'paymentMethods:update']}>
                                                <PaymentMethods />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/paymentGatewayConfig' element={
                                            <ProtectedRoute user={userData} func={['paymentGateway:create', 'paymentGateway:update', 'paymentGateway:read', 'paymentGateway:delete']}>
                                                <PaymentGatewayConfig />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/paymentGatewayConfigCreate' element={
                                            <ProtectedRoute user={userData} func={['paymentGateway:create', 'paymentGateway:update', 'paymentGateway:read', 'paymentGateway:delete']}>
                                                <PaymentGatewayCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/paymentGatewayEdit/:id' element={
                                            <ProtectedRoute user={userData} func={['paymentGateway:create', 'paymentGateway:update', 'paymentGateway:read', 'paymentGateway:delete']}>
                                                <PaymentGatewayEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/reports/schedulesPayments' element={
                                            <ProtectedRoute module={1} user={userData} func={['reports:read']}>
                                                <SchedulesPaymetsReport />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/reports' element={
                                            <ProtectedRoute module={1} user={userData} func={['reports:read', 'reports:create', 'reports:delete', 'reports:update']}>
                                                <Reports />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/reports/activitiesSubscriptions' element={
                                            <ProtectedRoute module={2} user={userData} func={['reports:read', 'reports:create', 'reports:delete', 'reports:update']}>
                                                <ActivitiesSubscriptionsReport />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/reports/paymentsReports' element={
                                            <ProtectedRoute module={2} user={userData} func={['reports:read', 'reports:create', 'reports:delete', 'reports:update']}>
                                                <PaymentsReport />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/reports/schedules' element={
                                            <ProtectedRoute module={1} user={userData} func={['reports:read', 'schedules:create', 'schedules:delete', 'schedules:update']}>
                                                <SchedulesReports />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/reports/schedulesBlock' element={
                                            <ProtectedRoute module={1} user={userData} func={['reports:read', 'schedules:create', 'schedules:delete', 'schedules:update']}>
                                                <SchedulesBlockReport />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/reservation' element={
                                            <ProtectedRoute module={1} user={userData} func={['schedules:read', 'schedules:create', 'schedules:delete', 'schedules:update']}>
                                                <Reservation />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/reservationCreate' element={
                                            <ProtectedRoute module={1} user={userData} func={['schedules:read', 'schedules:create']}>
                                                <ReservationCreate />
                                            </ProtectedRoute>
                                        } />

                                        <Route path='/reservationEdit/:id' element={
                                            <ProtectedRoute module={1} user={userData} func={['reservations:read', 'reservations:update']}>
                                                <ReservationEdit />
                                            </ProtectedRoute>
                                        } />

                                        <Route path='/services' element={
                                            <ProtectedRoute module={1} user={userData} func={['services:read', 'services:create', 'services:update', 'services:delete']}>
                                                <Services />
                                            </ProtectedRoute>
                                        }>
                                        </Route>
                                        <Route path='/servicesCreate' element={
                                            <ProtectedRoute module={1} user={userData} func={['services:read', 'services:create']}>
                                                <ServicesCreate />
                                            </ProtectedRoute>
                                        }></Route>
                                        <Route path='/servicesEdit/:id' element={
                                            <ProtectedRoute module={1} user={userData} func={['services:read', 'services:update']}>
                                                <ServicesEdit />
                                            </ProtectedRoute>
                                        }></Route>
                                        <Route path='/serviceTermsOfUse' element={
                                            <ProtectedRoute module={1} user={userData} func={['termsOfUse:create', 'termsOfUse:update', 'termsOfUse:read', 'termsOfUse:delete']}>
                                                <ServiceTermsOfUse />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/serviceTermsOfUseCreate' element={
                                            <ProtectedRoute module={1} user={userData} func={['termsOfUse:create']}>
                                                <ServiceTermsCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/serviceTermsOfUseEdit/:id' element={
                                            <ProtectedRoute module={1} user={userData} func={['termsOfUse:update', 'termsOfUse:read', 'termsOfUse:create', 'termsOfUse:delete']}>
                                                <ServiceTermsEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/schedulesDraw' element={
                                            <ProtectedRoute module={1} user={userData} func={['schedulesDraw:read', 'schedulesDraw:delete', 'schedulesDraw:update']}>
                                                <SchedulesDraw />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/siteBanners' element={
                                            <ProtectedRoute user={userData} func={['siteBanners:read', 'siteBanners:delete', 'siteBanners:update']}>
                                                <SiteBanners />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/siteBannerscreate' element={
                                            <ProtectedRoute user={userData} func={['siteBanners:read', 'siteBanners:create']}>
                                                <SiteBannerCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/siteBannersEdit/:id' element={
                                            <ProtectedRoute user={userData} func={['siteBanners:read', 'siteBanners:create', 'siteBanners:update']}>
                                                <SiteBannerEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/structures' element={
                                            <ProtectedRoute user={userData} func={['structures:read', 'structures:create', 'structures:delete']}>
                                                <Structures />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/structuresCreate' element={
                                            <ProtectedRoute user={userData} func={['structures:create']}>
                                                <StrucutresCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/structuresEdit/:structuresId' element={
                                            <ProtectedRoute user={userData} func={['structures:update', 'structures:delete']}>
                                                <StrucutresEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/calendar' element={<CalendarMain />} />
                                        <Route path='/theme' element={
                                            <ProtectedRoute isThemeComponent={true} user={userData} func={['']}>
                                                <ThemesCenter />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/users' element={
                                            <ProtectedRoute user={userData} func={['users:read', 'users:create']}>
                                                <UsersTable />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/userCreate' element={
                                            <ProtectedRoute user={userData} func={['users:create']}>
                                                <UserCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/usersEdit/:id' element={
                                            <ProtectedRoute user={userData} func={['users:read', 'users:update']}>
                                                <UserEdit />
                                            </ProtectedRoute>
                                        } />
                                        {
                                            userData[0]?.customersData?.useBalance &&
                                            <Route path='/usersBalance' element={
                                                <ProtectedRoute user={userData} func={['balance:read', 'balance:create', 'balance:update', 'balance:delete']}>
                                                    <UsersBalance />
                                                </ProtectedRoute>
                                            } />
                                        }
                                        <Route path='/usersBlocked' element={
                                            <ProtectedRoute user={userData} func={['blockedUsers:read', 'blockedUsers:create', 'blockedUsers:update', 'blockedUsers:delete']}>
                                                <UsersBlocked />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/usersBlockedEdit/:id' element={
                                            <ProtectedRoute user={userData} func={['blockedUsers:read', 'blockedUsers:update']}>
                                                <UsersBlockedEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/usersBlockedCreate' element={
                                            <ProtectedRoute user={userData} func={['blockedUsers:read', 'blockedUsers:create']}>
                                                <UsersBlockedCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/usersImport' element={
                                            <ProtectedRoute user={userData} func={['users:import']}>
                                                <UsersImport />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/usersProfile' element={
                                            <ProtectedRoute user={userData} func={['permissionGroups:read', 'permissionGroups:create', 'permissionGroups:update', 'permissionGroups:delete']}>
                                                <UserProfile />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/usersProfileCreate' element={
                                            <ProtectedRoute user={userData} func={['permissionGroups:create']}>
                                                <UserProfileCreate />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/usersProfileEdit/:profileData' element={
                                            <ProtectedRoute user={userData} func={['permissionGroups:update', 'permissionGroups:read']}>
                                                <UserProfileEdit />
                                            </ProtectedRoute>
                                        } />
                                        <Route path='/validUser/:usersId/:userTypeAccess' element={<ValidUser />} />
                                    </Routes>
                                    :
                                    //Client Routes
                                    <Routes>
                                        <Route path='/' element={<HomeClient />} />
                                        {
                                            customersModules.includes(2) &&
                                            <>
                                                <Route path='/activitiesActionsCreate' element={<ActivitiesActionsCreateClients />} />
                                                <Route path='/activitiesActionsCreate/:paymentId' element={<ActivitiesActionsCreateClients />} />
                                                <Route path='/activitiesActionsCreateFailed/:id' element={<ActivitiesActionsCreateFailed />} />
                                                <Route path='/mySubscriptions' element={<MySubscriptions />} />
                                                <Route path='/mySubscriptionsDetails/:id' element={<MySubscriptionsDetails />} />
                                            </>
                                        }
                                        <Route path='/agreements' element={<AgreementsClient />} />
                                        <Route path='/events' element={<EventsClient />} />
                                        <Route path='/gastronomy' element={<GastronomyClient />} />
                                        {
                                            userData[0]?.customersData?.useBalance &&
                                            <Route path='/myBalance' element={<MyBalance />} />
                                        }

                                        <Route path='/myProfileEdit/:id' element={<MyProfileEdit />} />
                                        <Route path='/mySchedules' element={<MySchedules />} />
                                        <Route path='/mySchedulesDraw' element={<MySchedulesDraw />} />
                                        <Route path='/mySchedulesDrawDetails/:id' element={<MySchedulesDrawDetails />} />
                                        <Route path='/mySchedulesDetails' element={<MySchedulesDetails />} />
                                        <Route path='/reservation' element={<ReservationClient />} />
                                        <Route path='/reservation/:paramsPaymentId' element={<ReservationClient />} />
                                        <Route path='/reservationFailed/:id' element={<ReservationFailed />} />
                                        <Route path='/structures' element={<StructuresClient />} />
                                    </Routes>
                        }
                    </div>
                }
            </HashRouter>
        </>
    )
}