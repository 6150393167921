import { colors } from "./colorsSchema";

export function changeThemeColor(colorTheme, color) {    
    colors.filter((colorScheme)=> {

        if(colorScheme.name === colorTheme){
            document.documentElement.style.setProperty('--primaryDefaultLight', colorScheme.primaryColor)
            localStorage.setItem('primaryDefaultLight', colorScheme.primaryColor)

            document.documentElement.style.setProperty('--secondaryDefaultLight', colorScheme.secundaryColor)
            localStorage.setItem('secondaryDefaultLight', colorScheme.secundaryColor)
        }
        if(colorTheme === 'primaryClientDefault'){
            document.documentElement.style.setProperty('--primaryDefaultLight', color)
            localStorage.setItem('primaryDefaultLight', color)
        }
        if(colorTheme === 'secondaryClientDefault'){
            document.documentElement.style.setProperty('--secondaryDefaultLight', color)
            localStorage.setItem('secondaryDefaultLight', color)
        }
    })
}