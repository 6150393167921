import React from 'react'

export function Container({ children, id }) {

    return (
        <div id={id} className='p-0 flex flex-col w-full bg-bgSecondaryLight md:gap-1 items-center dark:border-primaryBorderDark dark:bg-primaryDefaultDark'>
            {children}
        </div>
    )
}
