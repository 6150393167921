export function handleFilterTable(status, apiData) {

    const newList = apiData.filter((data) => {
        if (status === 1 && data?.active) {
            return data
        }
        if (status === 0 && !data?.active) {
            return data
        }
        if (status === undefined) {
            return data
        }
    })

    if(status !== undefined){
        return {list: newList, activeButton: status > 0 ? 1 : 0}
    }else{
        return {list: apiData, activeButton: status > 0 ? 1 : 0}
    }
}