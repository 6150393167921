export async function UrlToImage(imgURL, fileName) {

    const url = imgURL
    const name = fileName
    const type = 'image/png'
    
    try {
        const fileApi = await fetch(url)
        const contentType = fileApi.headers.get('content-type')
        const blob = await fileApi.blob()

        const file = new File([blob], name, { type: type, contentType })
        return file
    } catch (error) {
        console.log(error)
    }
}