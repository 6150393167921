import React, { useEffect, useState } from 'react'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { FaTimes } from 'react-icons/fa'

export function NotificationModal({ children }) {

    const { showNotificationModal, setShowNotificationModal, showNotificationModalText, setShowNotificationModalText, showNotificationModalSuccess, setShowNotificationModalSuccess } = useThemeContext()
    const [notificationContainer, setNotificationContainer] = useState(false)

    function handleDisplay() {
        setShowNotificationModal(false)
        setShowNotificationModalText('')
    }

    useEffect(() => {

        if (showNotificationModalSuccess) {
            awaitToHide()
        }

        async function awaitToHide() {
            await new Promise(resolve => setTimeout(resolve, 5000))
            handleDisplay()
        }

    }, [showNotificationModalSuccess])

    useEffect(() => {

        if(showNotificationModal){
            showContainer()
        }else{
            hideContainer()
        }
        async function showContainer() {
            await new Promise(resolve => setTimeout(resolve, 300))
            setNotificationContainer(true)
        }

        async function hideContainer() {
            await new Promise(resolve => setTimeout(resolve, 100))
            setNotificationContainer(false)   
        }

    }, [showNotificationModal])

    return (
        <div id='notificationModalContainer' className={`${showNotificationModal ? 'flex' : 'hidden h-0 '} left-0 md:left-[60px] w-full top-0 absolute z-50  flex flex-row items-center `}>
            <div id='notificationModal' className={`${notificationContainer ? 'opacity-90' : 'opacity-0 h-0 '} transition-all duration-200 py-3 w-full bg-${showNotificationModalSuccess ? 'primaryLightSuccess' : 'red-800'} h-full flex flex-row items-center`}>
                <div className='text-center font-light text-sm flex flex-row items-center justify-center w-[100%] overflow-auto translate-x-30'>
                    <h2 className='text-titleGrayTextLight'>{showNotificationModalText}</h2>
                </div>
                <button onClick={() => handleDisplay()} className='border border-titleGrayTextLight rounded-full p-1 flex flex-col items-center justify-center text-titleGrayTextLight hover:bg-titleGrayTextLight hover:text-titleBlackTextLight mr-0 md:mr-28 text-sm cursor-pointer'><FaTimes/></button>
            </div>
        </div>
    )
}