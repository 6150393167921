import React, { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/useAuth'
import { FaRegBuilding } from 'react-icons/fa'
import { getTermsOfUse, getTermsOfUseByServicesId, getTermsOfUseEspecific } from '../../services/api/callAPIsFunctions/defaultCalls.api'
import ReactQuill from 'react-quill'
import { Quillmodules } from '../../components/richText/quill.modules'
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { DefaultLoader } from '../loaders/defaultLoader'

export function Receipt(dataPath) {

    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const data = JSON.parse(localStorage.getItem('receipt'))
    const [termOfUse, setTermOfUse] = useState('')

    async function getData() {
        const terms = await getTermsOfUseByServicesId(userData[0]?.token, data.services_id)
        if (terms?.data?.length > 0) {
            setTermOfUse(terms?.data?.filter(term => term?.active)[0])
        }
        setLoading(false)

    }

    async function handlePrint() {

        await new Promise(resolve => setTimeout(resolve, 1000))
        window.print()

    }

    useEffect(() => {

        getData()

    }, [])


    useEffect(() => {

        if (!loading) {
            handlePrint()
        }
    }, [loading])

    if (loading) {
        return <div>
            <DefaultLoader />
        </div>
    }
    return (
        <div id='printArea' className='w-[960px] min-h-[100vh] gap-2 overflow-auto flex flex-col items-center justify-start bg-white'>
            <div className='w-full border-b overflow-hidden py-2 border-gray-700 flex flex-col items-start justify-start'>
                <div className='font-bold gap-4 flex flex-row px-4 justify-center items-center'>
                    {
                        userData[0].customersData.photo?.length > 0 ?
                            <div className=''>
                                <img width={100} className='grayscale-0' src={userData[0].customersData.photo}></img>
                            </div>
                            :
                            <FaRegBuilding className='text-5xl' />
                    }
                    <p className='text-base font-bold'>{userData[0].customersData.name}</p>
                </div>
            </div>
            <div className='w-full flex flex-col items-start justify-start'>
                <div className=' border-b border-b-gray-700 flex flex-row gap-2 w-full h-20 items-center'>
                    <div className='flex flex-col px-4'>
                        <p className='text-base font-bold underline'>Recibo de pagamento</p>
                        <div className='flex flex-row gap-2'>
                            <p className='text-sm font-bold'>Nome:</p>
                            <p className='text-sm'>{`${data?.original_user_firstName} ${data?.original_user_lastName}`}</p>
                        </div>
                        <div className='flex flex-row gap-2'>
                            <p className='text-sm font-bold'>Associação:</p>
                            <p className='text-sm'>{data?.associations_name}</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-2 w-full py-2 items-center mb-10'>
                    <div className='flex flex-col h-full items-start justify-center px-4 w-full '>
                        <div className='flex flex-row gap-2'>
                            <p className='text-sm font-bold'>Protocolo:</p>
                            <p className='text-sm'>{data?.id}</p>
                        </div>
                        <div className='flex flex-row gap-2 mb-4'>
                            <p className='text-sm font-bold'>Valor:</p>
                            <p className='text-sm'>{data?.payments_totalValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                        </div>
                        <div className='flex flex-row gap-2 flex-wrap'>
                            <p className='text-sm font-bold'>Referente:</p>
                            <p className='text-sm'>Aluguel de {data?.local} - </p>
                            <p className='text-sm'>{data?.startDate}</p>
                            {
                                data?.associateSchedules?.length > 0 &&
                                data?.associateSchedules?.map(anoterHour => {
                                    return <p className='text-sm'> | {anoterHour}</p>
                                })
                            }
                        </div>
                        {
                            data?.manualReceiptNumber?.length > 0 &&
                            <div className='flex flex-row gap-2 mb-4'>
                                <p className='text-sm font-bold'>Número do recibo:</p>
                                <p className='text-sm'>{data?.manualReceiptNumber}</p>
                            </div>
                        }

                    </div>
                </div>
                <div className=' flex flex-row gap-2 w-full py-2 items-center px-4'>
                    <div className='text-sm flex flex-col items-center justify-start gap-2'>
                        <p>...................................................................................</p>
                        <p>Caixa recebimento</p>

                    </div>
                </div>
           
            </div>

            {
                termOfUse?.title?.length > 0 &&
                <div className='mb-10 w-full flex flex-col px-4 mt-10'>
                    <strong className='underline mb-10'>{termOfUse?.title}</strong>
                    <ReactQuill readOnly theme='bubble' value={termOfUse?.termsOfUse} />
                </div>
            }

            {/* <div className='px-4 absolute bottom-0 border-b border-b-gray-700 flex flex-row gap-2 w-[960px] h-16 items-center'>
                <p className='text-sm'>{userData[0].customersData?.name}</p>
            </div> */}
        </div>
        // </Container>

    )
}