export async function resizeImageTest(fileInput, callback) {
    const maxFileSize = 700 * 1024; // 300kb in bytes
    const file = fileInput.target.files[0];
  
    // Check if file is an image
    if (!file.type.startsWith('image/')) {
      callback(new Error('Selected file is not an image'));
      return;
    }
  
    // Create a new image element
    const img = new Image();
  
    // Set the image source to the input file
    img.src = URL.createObjectURL(file);
  
    // Wait for the image to load
    img.onload = () => {
      // Calculate the new image dimensions
      const maxSize = Math.sqrt(maxFileSize);
      let width = img.width;
      let height = img.height;
      if (width > maxSize || height > maxSize) {
        const aspectRatio = width / height;
        if (width > height) {
          width = maxSize;
          height = Math.round(maxSize / aspectRatio);
        } else {
          height = maxSize;
          width = Math.round(maxSize * aspectRatio);
        }
      }
  
      // Create a canvas element
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
  
      // Draw the image onto the canvas
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
  
      // Get the resized image as a data URL
      const dataUrl = canvas.toDataURL(file.type, 0.8);
  
      // Convert the data URL to a blob
      const byteString = atob(dataUrl.split(',')[1]);
      const mimeString = file.type;
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const resizedBlob = new Blob([ab], { type: mimeString });
  
      // Create a new file object with the resized blob and the original file name
      const resizedFile = new File([resizedBlob], file.name, {
        type: file.type,
        lastModified: file.lastModified
      });
      // Call the callback with the resized file
      callback(null, resizedFile);
    };
  
    // Handle image loading errors
    img.onerror = () => {
      callback(new Error('Failed to load image'));
    };
  }
  