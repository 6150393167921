//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { FaEdit, FaTrash } from 'react-icons/fa'
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Button } from '../../../components/buttons/button.default'
import { TableDefault } from '../../../components/table/table.default'
import { TableMobile } from '../../../components/table/table.mobile'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
//services
import { getTermsOfUse } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'

export function ServiceTermsOfUse() {

    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [serviceTermsList, setServiceTermsList] = useState([])

    const [listOfTerms, setListOfTerms] = useState([])
    const [termsActiveButton, setTermsActiveButton] = useState(1)
    const [filteredTerms, setFilteredTerms] = useState([])

    function handleFilterActiveTerms(status) {

        const newTermsList = listOfTerms.filter((term) => {
            if (status === 1 && term?.active) {
                return term
            }
            if (status === 0 && !term?.active) {
                return term
            }
            if (status === undefined) {
                return term
            }
        })

        setFilteredTerms(getData(status))
        status > 0 ? setTermsActiveButton(1) : setTermsActiveButton(0)
    }
    async function getData(status) {

        setLoading(true)
        const termsOfUse = await getTermsOfUse(userData[0].token, status)
        if (termsOfUse.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(termsOfUse.data)
            return
        }
        setListOfTerms(termsOfUse.data)
        setFilteredTerms(termsOfUse.data)
        setShowNotificationModalSuccess(true)
        setLoading(false)
    }

    useEffect(() => {    
        getData(true)
    }, [])

    const tableThead = [
        {
            "name": 'Termo',
            "original_name": 'title'
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        },
    ]

    function handleEdit(termOfUse) {
        navigate(`/serviceTermsOfUseEdit/${termOfUse?.id}`)
    }

    return (
        <Container>
            <TitlePage>
                <div className='flex flex-row items-center justify-between w-full'>
                    <Title text={'Termos de uso para serviços'}></Title>
                    <Button module={'termsOfUse:create'} userData={userData} onClick={() => navigate('/serviceTermsOfUseCreate')} shadow={true}>+ Novo</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                        <div className='flex flex-col items-center justify-center w-full h-96'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div className='flex flex-row items-center justify-start w-full'>
                                <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${termsActiveButton === 1 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveTerms(1)}>Ativos</button>
                                <button className={` font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${termsActiveButton === 0 ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`} onClick={() => handleFilterActiveTerms(0)}>Inativos</button>
                            </div>
                            <div id='serviceTermsTable' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                <TableDefault onClick={(e) => handleEdit(e)} data={filteredTerms} title={tableThead} collumns={["title", "created_at"]} />
                            </div>
                            <div id='serviceTermsMobileTableContent' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                {
                                    !loading &&
                                    <TableMobile
                                        onClick={(e) => e.func.execute(e.data)}
                                        title={tableThead}
                                        data={filteredTerms}
                                        collumns={["title", "created_at"]}
                                        functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                    />
                                }
                            </div>
                        </>
                }

            </Body>
        </Container>
    )
}