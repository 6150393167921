import React, { useEffect } from 'react'
import { FaRegBuilding } from 'react-icons/fa'
import { useAuth } from '../../contexts/useAuth'

export function ReceiptConfirmation(dataPath) {

    const { userData } = useAuth()

    const data = JSON.parse(localStorage.getItem('receipt'))

    useEffect(() => {
          window.print()
    }, [])

    return (
        <div className={`w-[960px] h-[100vh] flex flex-col items-center justify-start bg-white`}>
            <div className='w-full border-b border-gray-700 flex flex-col items-start justify-start'>
            <div className='font-bold h-full gap-4 flex flex-row px-4 justify-center items-center'>
                    {
                        userData[0].customersData.photo?.length > 0 ? <img width={100} className='grayscale-0' src={userData[0].customersData.photo}></img> :
                            <FaRegBuilding className='text-5xl' />
                    }
                    <p className='text-base font-bold'>{userData[0].customersData.name}</p>
                </div>
            </div>
            <div className='w-full flex flex-col items-start justify-start'>
                <div className=' border-b border-b-gray-700 flex flex-row gap-2 w-full h-16 items-center'>
                    <div className='flex flex-col  px-4'>
                        <p className='text-base font-bold'>Ficha de confirmação {data?.textComplement}</p>
                    </div>
                </div>
                <div className=' border-b border-b-gray-700 flex flex-row gap-2 w-full items-center'>
                    <div className='flex flex-col border-r border-gray-700 h-full items-start justify-center px-4 py-2 w-96'>
                        <p className='font-bold text-sm'>{data?.local}</p>
                        {
                            data?.startDate &&
                            <p className='text-sm'>{data?.startDate}</p>
                        }
                        {
                            data.associateSchedules?.length > 0 &&
                            data.associateSchedules?.map(anoterHour => {
                                return <p className='text-sm'>{anoterHour}</p>

                            })
                        }
                    </div>
                    <div className='flex flex-col border-r border-gray-700 h-full items-start justify-center px-4 w-96'>
                        <p className='font-bold text-sm'>Protocolo</p>
                        <p className='text-sm'>{data?.id}</p>
                    </div>
                </div>
                <div className=' border-b border-b-gray-700 flex flex-row gap-2 w-full h-16 items-center'>
                    <div className='flex flex-col border-r border-gray-700 h-full items-start justify-center px-4 w-96'>
                        <p className='text-sm font-bold'>Usuário</p>
                        <p className='text-sm'>{data?.userName}</p>
                    </div>
                </div>
                <div className=' border-b border-b-gray-700 flex flex-row gap-2 w-full h-16 items-center'>
                    <div className='flex flex-col border-r border-gray-700 h-full items-start justify-center px-4 w-96'>
                        <p className='text-sm font-bold'>Pagamento</p>
                        <p className='text-sm'>
                            {
                                data?.originalPaymentStatus === 0 ? 'Reservando' : 
                                data?.originalPaymentStatus === 1 ? 'Pago' : 
                                data?.originalPaymentStatus === 2 ? 'Cancelado' : 
                                data?.originalPaymentStatus === 3 && 'Aguardando pagamento'
                            }
                        </p>
                    </div>
                </div>
            </div>
            <div className='absolute bottom-0 border-b border-b-gray-700 flex flex-row gap-2 w-[960px] h-16 items-center'>
                <p className='text-sm'>{userData[0].customersData?.name}</p>
            </div>
        </div>
    )
}