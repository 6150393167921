// hooks
import React, { useEffect, useState } from 'react'
import { FaPlus, FaArrowCircleLeft, FaArrowAltCircleRight, FaTrash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Body } from '../../../components/container/Body'
// components
import { Container } from '../../../components/container/container'
import { Toogle } from '../../../components/toogle/Toogle'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { LabelInput } from '../../../components/label/label.input'
import { Button } from '../../../components/buttons/button.default'
import { Footer } from '../../../components/footer/Footer'
import { Section } from '../../../components/container/section'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { resizePhoto } from '../../../utils/formatImages/resizeImage'
//class
import { getAssociations, getCategories } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { api } from '../../../services/api/api'
import { DaysModal } from '../../../components/modals/notification/daysModal'
import { Stepper } from '../../../components/stepper/stepper'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import { maskHours } from '../../../utils/mask/maskHours'
import { permittedFiles } from '../../../utils/permittedFiles/permittedFiles'
import { resizeImageTest } from '../../../utils/formatImages/resizeImageTest'

export function ServicesCreate() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(true)
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    const [showPhoto, setShowPhoto] = useState(<a>Sem dados</a>)
    const [showCreateModalite, setShowCreateModalite] = useState(false)

    const [name, setName] = useState('')
    const [modalities, setModalities] = useState([])
    const [newModalite, setNewModalite] = useState('')
    const [description, setDescription] = useState('')
    const [scheduleType, setScheduleType] = useState({ 'id': 2, 'value': 'S', "description": 'Por serviço' })
    const [reservationType, setReservationType] = useState('')

    const [selectedCategorie, setSelectedCategorie] = useState('')
    const [selectedDays, setSelectedDays] = useState([])
    const [servicePhotos, setServicesPhotos] = useState([])
    const [serviceAppendPhotos, setServicesAppendPhotos] = useState([])
    const [showOnSite, setShowOnSite] = useState(false)

    const [associationsList, setAssociationsList] = useState([])
    const [categoriesList, setCategoriesList] = useState([])
    const [selectedAssociationsList, setSelectedAssociationsList] = useState({})
    const [associationsListRows, setAssociationsListRows] = useState(Object.keys(selectedAssociationsList))
    const reservationTypeList = [
        { 'id': 1, 'value': 'H', "description": 'Horário' },
        { 'id': 2, 'value': 'M', "description": 'Mensal' }
    ]
    const requiredFields = [
        { "name": 'selectedCategorie', "value": selectedCategorie, "required": true, "type": 'string' },
        { "name": 'name', "value": name, "required": true, "type": 'string' },
        { "name": 'description', "value": description, "required": true, "type": 'string' },
    ]

    const handleSelectedCategorie = (categorie) => {

        setSelectedCategorie(categorie)
    }

    useEffect(() => {

        async function getData() {

            const associationsData = await getAssociations(userData[0].token, 1)
            //inicializa com valor padrao 0 para todas as associacoes
            let formattedAssociations = []
            associationsData.data.map(association => formattedAssociations.push({ ...association, value: 0, nightValue: 0, valueWeekend: 0, nightValueWeekend: 0 }))
            if (associationsData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(associationsData.data)
            }

            const categoriesData = await getCategories(userData[0]?.token)
            if (categoriesData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(categoriesData.data)
            }
            setAssociationsList(formattedAssociations)
            setAssociationsListRows(Object.keys(associationsData.data))
            setCategoriesList(categoriesData.data.filter(categorie => categorie.active))
            setLoading(false)
        }
        getData()
    }, [])

    const [steps, setSteps] = useState([
        {
            id: 0, text: 'Serviço', valid: false
        },
        {
            id: 1, text: 'Valores', valid: false
        },
        {
            id: 2, text: 'Horários', valid: false
        }
    ])
    const [filteredSteps, setFilteredSteps] = useState(steps)
    const [activeStep, setActiveStep] = useState({ id: 0 })


    function validateHoursOfDays() {
        let hoursActive = []
        let daysIds = Object.keys(selectedDays)
        let blockedError = false
        let uniqueValueError = false
        let forSpecificError = false

        let hasHour = false
        const errors = daysIds.filter((day) => {
            const hoursWithErrors = selectedDays[day].hours.filter((hour, indexHour) => {
                hasHour = true
                const hourEl = document.getElementById(`${day}-${indexHour}`)

                if (hour.startHour === '' || hour.finisHour === '' || isNaN(parseFloat(hour.finisHour))) {
                    if (hourEl) {
                        hourEl.style.background = '#f87171'
                    }
                    return hour
                }
                if (hour.isBlocked && !hour.blockedDescription) {
                    if (hourEl) {
                        hourEl.style.background = '#f87171'
                    }
                    blockedError = true
                }
                if (hour.isUniqueValue?.id && !hour.uniqueValue) {
                    if (hourEl) {
                        hourEl.style.background = '#f87171'
                    }
                    uniqueValueError = true
                }
                if (hour?.forSpecific && hour.associations?.length === 0) {
                    if (hourEl) {
                        hourEl.style.background = '#f87171'
                    }
                    forSpecificError = true
                }
            })

            if (hoursWithErrors?.length > 0) {
                return day
            }
            if (selectedDays[day].active) {
                hoursActive.push(day)
            }
        })

        if (forSpecificError) {
            setLoading(false)
            return { error: true, message: 'Selecione as associações na linha em vermelho' }
        }

        if (blockedError) {
            setLoading(false)
            return { error: true, message: 'Preencha o motivo do bloqueio' }
        }
        if (uniqueValueError) {
            setLoading(false)
            return { error: true, message: 'Preencha o valor exclusivo na linha vermelha' }
        }
        if (!hasHour) {
            setLoading(false)
            return { error: true, message: 'Necessário ao menos configurar 1 horário' }
        }

        if (errors?.length > 0) {
            setLoading(false)
            return { error: true, message: 'Preencha os horários em vermelho' }
        }

        return { error: false, message: '' }
    }

    function validateThisFields(id) {

        switch (id) {
            case 0:
                return validateFields(requiredFields)
            case 1:
                return validateAssociationsValues()
            case 2:
                return validateHoursOfDays()
            default:
                break;
        }
    }

    function validateAssociationsValues() {
        let errors = []
        associationsListRows.map((associationsDiscountId) => {

            let thisAssociacElement = document.getElementById(associationsDiscountId)
            if ((associationsList[associationsDiscountId]?.value === `` || (isNaN(parseFloat(associationsList[associationsDiscountId]?.value) && associationsList[associationsDiscountId]?.value !== 0)))
                ||
                (associationsList[associationsDiscountId]?.nightValue === `` || (isNaN(parseFloat(associationsList[associationsDiscountId]?.nightValue) && associationsList[associationsDiscountId]?.nightValue !== 0)))
                ||
                (associationsList[associationsDiscountId]?.valueWeekend === `` || (isNaN(parseFloat(associationsList[associationsDiscountId]?.valueWeekend) && associationsList[associationsDiscountId]?.valueWeekend !== 0)))
                ||
                (associationsList[associationsDiscountId]?.nightValueWeekend === `` || (isNaN(parseFloat(associationsList[associationsDiscountId]?.nightValueWeekend) && associationsList[associationsDiscountId]?.nightValueWeekend !== 0)))
            ) {
                errors.push({ error: true, type: 'associationsDiscountList', id: associationsDiscountId })
                if (thisAssociacElement) {
                    thisAssociacElement.style.background = '#f87171'
                }
            } else {
                thisAssociacElement.style.background = ''
            }
        })
        return errors
    }

    const handleAddPhoto = () => {

        const inputPhoto = document.getElementById('photoAddInput')
        inputPhoto.click()
    }

    const handleNewPhoto = async (photoFile) => {
        const validImage = permittedFiles(photoFile?.target?.files[0], 'image')
        if (validImage?.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(validImage?.response)
        }

        if (servicePhotos.length < 5) {
            const formattedImg = await new Promise((resolve, reject) => {
                resizeImageTest(photoFile, (err, resizedFile) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    resolve(resizedFile)
                })
            })

            const photoObj = URL.createObjectURL(formattedImg)
            setServicesPhotos([photoObj, ...servicePhotos])
            setServicesAppendPhotos([formattedImg, ...serviceAppendPhotos])
        }
    }

    const prevPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = servicePhotos[showPhoto - 1]
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto - 1)
    }

    const nextPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = servicePhotos[showPhoto + 1]
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto + 1)
    }


    async function showThisPhoto(photo, indexPhoto) {

        setShowPhotoModal(true)

        const modalElement = document.getElementById('showPhotoModal')

        let img = document.createElement('img')
        img.src = photo
        img.id = 'appendedPreviewPhoto'
        modalElement.appendChild(img)
        setShowPhoto(indexPhoto)
        await new Promise(resolve => setTimeout(resolve, 100))
        document.getElementById('photoContainer').style.opacity = '1'
        document.getElementById('photoContainer').style.transform = 'translateY(0px)'

    }

    async function closeThisPhoto() {

        document.getElementById('photoContainer').style.opacity = '0'
        document.getElementById('photoContainer').style.transform = 'translateY(-100px)'
        await new Promise(resolve => setTimeout(resolve, 300))

        setShowPhotoModal(false)
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)
        setShowPhoto('')
    }

    async function removeThisPhoto(photo, indexPhoto) {

        document.getElementById(photo + '-' + indexPhoto).style.transform = 'scale(.5)'
        await new Promise(resolve => setTimeout(resolve, 500))
        setServicesPhotos(servicePhotos.filter(photoToRemove => photoToRemove !== photo))
        document.getElementById(photo + '-' + indexPhoto).style.transform = 'scale(1)'
    }

    function formatHours(hour) {
        if (hour !== undefined) {
            let startStartHour = parseInt(hour?.slice(0, 2))
            let endStartHour = parseInt(hour?.slice(3, 5))
            if (endStartHour >= 30) {
                endStartHour = .5
            } else {
                endStartHour = 0
            }
            return startStartHour + endStartHour
        } else {
            return ``
        }


    }

    async function handleSubmit() {

        let message = 'Serviço cadastrado com sucesso!'

        const isValid = validateThisFields(activeStep.id)
        if (isValid?.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(isValid?.message)
            return
        } else if (isValid?.length > 0) {
            return
        }

        setLoading(true)

        try {
            const serviceCreate = await api.post(`/api/v1/services`, {
                "name": name,
                "description": description,
                "reservationType": "H",
                "modalities": modalities,
                showOnSite,
                "categories_id": selectedCategorie?.id
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            const serviceId = serviceCreate.data.data[0].id

            let hasValueAssociation = []
            associationsListRows.map((association) => {
                hasValueAssociation.push({
                    associations_id: associationsList[association].id,
                    value: associationsList[association].value,
                    nightValue: associationsList[association].nightValue,
                    valueWeekend: associationsList[association].valueWeekend,
                    nightValueWeekend: associationsList[association].nightValueWeekend,
                })
            })

            if (hasValueAssociation.length > 0) {
                const servicesValues = await api.post(`/api/v1/servicesValuePerAssociations`, {
                    services_id: serviceId,
                    values: hasValueAssociation
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            }

            // create a hour configuration by categorie
            if (reservationType.value !== 'M') {
                let daysIds = Object.keys(selectedDays)
                let arrayAPI = []
                daysIds.map((dayId) => {
                    selectedDays[dayId]?.hours?.map((hour) => {
                        const associations = []
                        if (hour.forSpecific) {
                            hour.associations.map((association) => {
                                associations.push(association.id)
                            })
                        }

                        arrayAPI.push({
                            weekday: selectedDays[dayId]?.weekDay,
                            startHour: formatHours(hour.startHour),
                            finishHour: formatHours(hour.finisHour),
                            forAll: hour.forAll,
                            isUniqueValue: hour.isUniqueValue.id,
                            uniqueValue: hour.uniqueValue,
                            forSpecific: hour.forSpecific,
                            isDrawn: hour?.isDrawn?.id ? hour?.isDrawn?.id : false,
                            associations: associations,
                            isBlocked: hour.isBlocked,
                            blockedDescription: hour.blockedDescription
                        })
                    })
                })
                await api.post(`/api/v1/servicesHours`, {
                    services_id: serviceId,
                    hours: arrayAPI
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
            }

            if (serviceAppendPhotos.length > 0) {

                const formData = new FormData();
                serviceAppendPhotos.map((photo) => {
                    formData.append('images', photo);
                })

                try {
                    const sendPhoto = await api.post(`/api/v1/servicesPictures/services_id/${serviceId}`, formData, {
                        headers: {
                            Authorization: `Bearer ${userData[0]?.token}`,
                            'Content-Type': 'multipart/form-data',
                        }
                    })
                } catch (error) {
                    console.log(error)
                    message = 'Sucesso ao cadastrar serviço, porém houve um erro ao cadastrar as fotos'
                    console.log(error)
                }

            }

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText(message)
            navigate('/services')

        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                console.log(error)
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }


    return (
        <Container>
            <TitlePage>
                <Title text={'Criar novo serviço'} />
            </TitlePage>
            <Body hasFooter={true} shadow={false} background={'bg-secondaryDefault'}>

                {
                    loading ?
                        <div className='w-full h-96 bg-bgPrimaryLight dark:bg-secondaryDefaultDark flex flex-col items-center justify-center'>
                            <DefaultLoader />
                        </div>
                        :
                        <Section>
                            {
                                categoriesList?.length === 0 ?
                                    <div className='p-6 bg-zinc-50 dark:bg-thirdDefaultDark border border-zinc-100 dark:border-secondaryBorderDark flex flex-col items-start justify-start w-full'>
                                        <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>Cliente não possui categorias criadas, deve ser criado primeiramente as <strong>categorias</strong> para criar um serviço</p>
                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark underline cursor-pointer hover:text-primaryDefaultLight' onClick={() => navigate('/categoriesCreate')}>Criar categoria</p>
                                    </div>
                                    :
                                    <div className='flex flex-col items-start justify-start w-full'>
                                        <div className='flex flex-col items-start justify-start w-full'>
                                            <Stepper steps={filteredSteps} activeStep={activeStep} changeStep={(e) => setActiveStep(e)} />
                                            {
                                                activeStep.id === 0 &&
                                                <div className='flex flex-col items-start justify-start w-full mt-8 h-full'>
                                                    <div className={`${showPhotoModal ? 'flex' : 'hidden'} flex-row items-center justify-center w-full h-full fixed top-0 left-0 z-50`}>
                                                        <div id='photoContainer' className={`opacity-0 transition-all duration-500 -translate-y-10 flex gap-2 flex-row items-center justify-center w-full h-full`}>
                                                            {
                                                                showPhoto > 0 ?
                                                                    <FaArrowCircleLeft onClick={prevPhoto} className='text-primaryDefaultLight hover:-translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                                    :
                                                                    <FaArrowCircleLeft className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                            }
                                                            <div className='bg-bgPrimaryLight dark:bg-primaryDefaultDark boxShadow p-8 w-full h-full md:w-[700px] md:h-[500px] lg:w-[1000px] lg:h-[600px] flex flex-col items-center justify-start'>
                                                                <div className='flex flex-row justify-end w-full'>
                                                                    <button className='bg-primaryDefaultLight hover:brightness-125 transition-all duration-200 h-8 w-8 rounded-full flex flex-col items-center justify-center text-titleGrayTextLight' onClick={() => closeThisPhoto()}>X</button>
                                                                </div>
                                                                <div id='showPhotoModal' className='flex flex-col items-center justify-center overflow-auto w-full h-full'>
                                                                </div>
                                                            </div>
                                                            {
                                                                showPhoto < servicePhotos.length - 1 ?
                                                                    <FaArrowAltCircleRight onClick={nextPhoto} className='text-primaryDefaultLight hover:translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                                    :
                                                                    <FaArrowAltCircleRight className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={`${showPhotoModal ? 'flex' : 'hidden'}  bg-gray-600 opacity-80 w-full h-full fixed z-40 left-0 top-0`}></div>
                                                    <form onSubmit={e => e.preventDefault()} className='flex flex-col justify-start sm:justify-center w-full items-center sm:items-start gap-4'>
                                                        <div className='w-full sm:w-auto flex flex-row flex-wrap items-start sm:justify-start gap-4'>
                                                            <LabelInput text={'Categoria'}>
                                                                <InputAutoComplete data={categoriesList}
                                                                    selectedLabel={['name']}
                                                                    optionList={['name']}
                                                                    id='selectedCategorie'
                                                                    placeHolder={'-'}
                                                                    preSelectedValue={selectedCategorie?.name}
                                                                    onChange={handleSelectedCategorie}
                                                                />
                                                            </LabelInput>
                                                            <LabelInput text={'Nome'}>
                                                                <Input id='name' type={'text'} onChange={(e) => setName(e.target.value)} value={name}></Input>
                                                            </LabelInput>

                                                        </div>
                                                        <div className='gap-4 w-full flex flex-col items-center sm:items-start sm:justify-start'>
                                                            <textarea id='description' wrap="hard" value={description} onChange={(e) => setDescription(e.target.value)} maxLength={2000} className='
                                                  p-2 border border-gray-400 border-opacity-75 
                                                  h-64
                                                  w-full
                                                  text-sm
                                                  outline-none transition-all duration-200 focus:shadow-borderShadow
                                                  dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark 
                                                   '></textarea>
                                                            <LabelInput text={'Exibir no site?'}>
                                                                <Toogle status={showOnSite} onClick={() => setShowOnSite(!showOnSite)} />
                                                            </LabelInput>
                                                        </div>
                                                        <div id='servicePhotos' className='flex flex-col gap-4 items-center justify-start'>
                                                            <div className='flex flex-col items-start justify-start w-full mb-5'>
                                                                <p className='text-xl text-primaryDefaultLight'>Fotos do espaço</p>
                                                            </div>
                                                            <div className='flex flex-row gap-2 items-start justify-start w-full'>
                                                                <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Fotos</p>
                                                                <input id='photoAddInput' type='file' onChange={handleNewPhoto} accept="image/png,image/jpeg,image/jpg,image/gif,image/jpeg" className='hidden'></input>
                                                                <a className='text-titleBlackTextLight dark:text-titleGrayTextDark'>{servicePhotos.length} de 5</a>
                                                                <Tippy content={'Adicionar fotos'}
                                                                    arrow={true}
                                                                    animation='shift-away'
                                                                    placement='right'
                                                                    delay={100}>
                                                                    <div>
                                                                        <FaPlus onClick={handleAddPhoto} className='text-xl text-primaryDefaultLight cursor-pointer' />
                                                                    </div>
                                                                </Tippy>
                                                            </div>
                                                            <div className='h-20 w-full'>
                                                                <div className='flex flex-row gap-4 items-center justify-start'>
                                                                    {
                                                                        servicePhotos.map((photo, indexPhoto) => {
                                                                            return <div id={photo + '-' + indexPhoto} className='transition-all duration-500 ease-in-out relative flex flex-row gap-2 items-center justify-center'>
                                                                                <Tippy content={'Remover foto'}
                                                                                    arrow={true}
                                                                                    animation='shift-away'
                                                                                    placement='right'
                                                                                    delay={300}>
                                                                                    <button onClick={() => removeThisPhoto(photo, indexPhoto)} className='text-titleGrayTextLight absolute -left-2 -top-2 w-10 h-10 sm:w-6 sm:h-6 rounded-full bg-gray-400'>x</button>
                                                                                </Tippy>
                                                                                <img onClick={() => showThisPhoto(photo, indexPhoto)} className='border border-gray-500 rounded-xl w-20 h-20' src={photo}></img>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id='modalitiesSpace' className='flex-col items-start justify-start'>
                                                            <div className='flex flex-row gap-2 items-start justify-start w-full mb-5'>
                                                                <p className='text-xl text-primaryDefaultLight'>Modalidades</p>
                                                                <FaPlus onClick={() => setShowCreateModalite(true)} className='text-xl text-primaryDefaultLight cursor-pointer' />
                                                            </div>
                                                            {
                                                                showCreateModalite &&
                                                                <div className='flex flex-col gap-4 items-start justify-start'>
                                                                    <LabelInput>
                                                                        <Input type={'text'} onChange={(e) => setNewModalite(e.target.value)} value={newModalite} />
                                                                    </LabelInput>
                                                                    <Button onClick={() => {
                                                                        setShowCreateModalite(false)
                                                                        setModalities([...modalities, newModalite])
                                                                        setNewModalite('')
                                                                    }}>Salvar</Button>
                                                                </div>
                                                            }
                                                            <div className='flex flex-col gap-2 items-start justify-start'>
                                                                {
                                                                    modalities.map(modalite => {
                                                                        if (!showCreateModalite) {
                                                                            return <div className='flex flex-row gap-2 items-start justify-start'>
                                                                                {modalite}
                                                                                <FaTrash className='text-xl text-primaryDefaultLight cursor-pointer' onClick={() => setModalities((prev) => (modalities.filter(modaliteToRemove => modaliteToRemove !== modalite)))} />
                                                                            </div>
                                                                        }
                                                                    })
                                                                }
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            }
                                            {
                                                activeStep.id === 1 &&
                                                <div className='flex flex-col items-start justify-center sm:justify-start w-full gap-2'>
                                                    {
                                                        associationsListRows?.length > 0 &&
                                                        <>
                                                            <h2 className='mt-8 text-lg text-primaryDefaultLight'>Valores</h2>
                                                            <div></div>
                                                            <div></div>
                                                            <div className='w-full flex flex-col flex-wrap items-start gap-2'>
                                                                {
                                                                    associationsListRows.map((thisAssociation, index) => {

                                                                        return <div id={thisAssociation} className='py-2 sm:p-1 dark:bg-thirdDefaultDark bg-zinc-100 sm:bg-bgPrimaryLight sm:dark:bg-primaryDefaultDark rounded-lg transition-all duration-200 flex flex-wrap flex-row gap-2 items-center justify-start'>
                                                                            <LabelInput text={'Associação'}>
                                                                                <Input disabled={true} value={associationsList[thisAssociation].name}></Input>
                                                                            </LabelInput>
                                                                            <LabelInput text={'Valor diurno'}>
                                                                                <CurrencyInput placeHolder='R$ 0,00' onChange={(e) => {
                                                                                    associationsList[thisAssociation].value = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                                    setAssociationsList(associationsList)
                                                                                    setAssociationsListRows(Object.keys(associationsList))
                                                                                }}></CurrencyInput>
                                                                            </LabelInput>
                                                                            <LabelInput text={'Valor noturno'}>
                                                                                <CurrencyInput placeHolder='R$ 0,00' onChange={(e) => {
                                                                                    associationsList[thisAssociation].nightValue = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                                    setAssociationsList(associationsList)
                                                                                    setAssociationsListRows(Object.keys(associationsList))
                                                                                }}></CurrencyInput>
                                                                            </LabelInput>
                                                                            <LabelInput text={'Valor diurno final de semana'}>
                                                                                <CurrencyInput placeHolder='R$ 0,00' onChange={(e) => {
                                                                                    associationsList[thisAssociation].valueWeekend = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                                    setAssociationsList(associationsList)
                                                                                    setAssociationsListRows(Object.keys(associationsList))
                                                                                }}></CurrencyInput>
                                                                            </LabelInput>
                                                                            <LabelInput text={'Valor noturno final de semana'}>
                                                                                <CurrencyInput placeHolder='R$ 0,00' onChange={(e) => {
                                                                                    associationsList[thisAssociation].nightValueWeekend = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                                    setAssociationsList(associationsList)
                                                                                    setAssociationsListRows(Object.keys(associationsList))
                                                                                }}></CurrencyInput>
                                                                            </LabelInput>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            }
                                            {
                                                activeStep.id === 2 &&
                                                <DaysModal
                                                    reservationTypeList={reservationTypeList}
                                                    associationsList={associationsList}
                                                    changeReservationType={(e) => setReservationType(e)}
                                                    scheduleType={scheduleType}
                                                    onChange={(days) => setSelectedDays(days)}
                                                    showModal={(e) => setShowModal(e)}
                                                    changeModalStatus={showModal}
                                                />
                                            }
                                        </div>
                                    </div>
                            }

                        </Section>
                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    {
                        activeStep.id === 0 &&
                        <Button shadow={true} approval={false} onClick={() => navigate('/services')}>Voltar</Button>
                    }
                    {
                        activeStep.id > 0 &&
                        <Button shadow={true} onClick={() => {
                            filteredSteps.map((thisStep, indexStep) => {
                                if (thisStep.id === activeStep.id) {
                                    setActiveStep({ id: filteredSteps[indexStep - 1].id })
                                }
                            })
                        }}>Voltar</Button>
                    }
                    {
                        activeStep.id < filteredSteps[filteredSteps.length - 1].id ?
                            <Button shadow={true} onClick={() => {
                                filteredSteps.map((thisStep, indexStep) => {
                                    if (thisStep.id === activeStep.id) {
                                        if (validateThisFields(thisStep?.id)?.length > 0) {
                                            return
                                        }
                                        filteredSteps[indexStep].valid = true
                                        setFilteredSteps(filteredSteps)
                                        setActiveStep({ id: filteredSteps[indexStep + 1].id })
                                    }
                                })
                            }}>Próximo</Button>
                            :
                            activeStep.id === filteredSteps[filteredSteps.length - 1].id &&
                            <Button shadow={true} onClick={() => handleSubmit()}>Cadastrar</Button>
                    }
                </div>
            </Footer>
        </Container>
    )
}