// hooks
import React, { useEffect, useState, useRef } from 'react'
import { FaEdit, FaPlus, FaInfoCircle, FaTrashAlt, FaArrowCircleLeft, FaArrowAltCircleRight, FaTrash, FaSearch, FaFilter, FaBan } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { Body } from '../../../components/container/Body'
import { useNavigate } from 'react-router-dom'
// components
import { Container } from '../../../components/container/container'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import Multiselect from '../../../components/selects/mult.select'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { LabelInput } from '../../../components/label/label.input'
import { Button } from '../../../components/buttons/button.default'
import Tippy from '@tippyjs/react';
import { Toogle } from '../../../components/toogle/Toogle'
import { Footer } from '../../../components/footer/Footer'
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { TableDefault } from '../../../components/table/table.default'
import { TableMobile } from '../../../components/table/table.mobile'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
//utils
import { v4 as uuid } from 'uuid'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { formattTimeToShow } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
//class
import { getAssociations, getCategories, getSchedulesBlocked, getServicesEspecific, getServicesHoursEspecific, getServicesValuePerAssociation } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { api } from '../../../services/api/api'
import { Stepper } from '../../../components/stepper/stepper'
import { UrlToImage } from '../../../utils/formatImages/urlToImgFile'
import { Section } from '../../../components/container/section'
import { FormatHours } from '../../../utils/formatObjects/formatHoursService'
import moment from 'moment'
import { maskHours } from '../../../utils/mask/maskHours'
import { permittedFiles } from '../../../utils/permittedFiles/permittedFiles'
import { resizeImageTest } from '../../../utils/formatImages/resizeImageTest'

export function ServicesEdit() {

    const navigate = useNavigate()
    const modalPhotoRef = useRef()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    const [showPhoto, setShowPhoto] = useState(<a>Sem dados</a>)
    const [showCreateModalite, setShowCreateModalite] = useState(false)
    const serviceId = useParams()

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [modalities, setModalities] = useState([])
    const [newModalite, setNewModalite] = useState('')
    const [serviceStatus, setServiceStatus] = useState(false)
    const [serviceStatusHistory, setServiceStatusHistory] = useState(false)
    const [reservationType, setReservationType] = useState('')
    const [selectedCategorie, setSelectedCategorie] = useState('')
    const [selectedBlocked, setSelectedBlocked] = useState('')

    const [selectedDays, setSelectedDays] = useState([])
    const [historyHours, setHistoryHours] = useState({})
    const [servicePhotos, setServicesPhotos] = useState([])
    const [serviceAppendPhotos, setServicesAppendPhotos] = useState([])
    const [showOnSite, setShowOnSite] = useState(false)

    const [associationsList, setAssociationsList] = useState([])
    const [historyAssociationsValue, setHistoryAssociationsValue] = useState([])
    const [associationsListRows, setAssociationsListRows] = useState([])
    const [categoriesList, setCategoriesList] = useState([])

    const reservationTypeList = [
        { 'id': 1, 'value': 'H', "description": 'Horário' },
        { 'id': 2, 'value': 'M', "description": 'Mensal' }
    ]
    const requiredFields = [
        { "name": 'selectedCategorie', "value": selectedCategorie, "required": true, "type": 'string' },
        { "name": 'name', "value": name, "required": true, "type": 'string' },
    ]
    const [blockedPlusHour, setBlockedPlusHour] = useState([])
    const [newBlockedDescription, setNewBlockedDescription] = useState(false)
    const [blockedException, setBlockedException] = useState([])
    const tableBlockedHoursTHead = [
        {
            "name": 'Data início',
            "original_name": 'startDate'
        },
        {
            "name": 'Data final',
            "original_name": 'finishDate'
        },
        {
            "name": 'Motivo',
            "original_name": "description"
        },
        {
            "name": 'Criado em',
            "original_name": "created_at"
        },
        {
            "name": "Remover",
            "original_name": "delete"
        }
    ]
    const questionList = [
        { id: true, description: 'Sim' },
        { id: false, description: 'Não' },
    ]

    //hours
    const [timeDays, setTimeDays] = useState({})
    const [timeDaysRows, setTimeDaysRows] = useState(Object.keys(timeDays))
    const [daysArr, setDaysArr] = useState([])
    const [hoursPush, setHoursPush] = useState([])
    const [hoursEnd, setHoursEnd] = useState([])
    const [selectedEndHour, setSelectedEndHour] = useState('3')
    const [thisReservationType, setThisReservationType] = useState(reservationType)
    const hourAssociationIncludedList = [
        { 'id': 1, 'description': 'Todos', 'value': 1 },
        { 'id': 2, 'description': 'Personalizado', 'value': 2 },
    ]

    //filters search
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showInternalFilterModal, setShowInternalFilterModal] = useState(false)
    const [paginationFilteredFields, setPaginationFilteredFields] = useState({})
    const [paginationHistFilteredFields, setPaginationHistFilteredFields] = useState({})
    const [paginationHistFilteredFieldsArr, setPaginationHistFilteredFieldsArr] = useState([])
    const [cleanFilter, setCleanFilter] = useState(false)
    const tableFilterModal = useRef()
    const [totalPages, setTotalPages] = useState(1)

    const filters = [
        {
            "name": 'Data início',
            "original_name": 'startDate',
            "type": 'date'
        },
        {
            "name": 'Data fim',
            "original_name": 'finishDate',
            "type": 'date'
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at',
            "type": 'date'
        },
        {
            "name": 'Motivo',
            "original_name": 'description',
            "type": 'text'
        },

    ]

    useEffect(() => {

        let totalHours = 0
        while (totalHours < 24) {
            Array(48).fill(0).map(() => {
                let thisDescription = totalHours.toString().indexOf('.5') > -1 ? totalHours?.toString()?.replace('.5', '') + ':30' : totalHours + ':00'
                hoursPush.push({ 'hour': totalHours, 'description': thisDescription })
                totalHours = totalHours + (1 / 2)
            })
        }
        setHoursPush(hoursPush)
        setHoursEnd(hoursPush)

    }, [])

    function mapDays(timeDays) {

        const rows = Object.keys(timeDays)
        let newDaysArray = []

        rows.map((day) => {
            newDaysArray.push({
                description: timeDays[day]?.description,
                id: day
            })

        })
        setDaysArr(newDaysArray)
    }

    function formatExceptionBlockedHours() {

        let startDate = blockedException.startDate
        let finishDate = blockedException.finishDate
        let startHour = blockedException.startHour
        let finishHour = blockedException.finishHour
        let description = blockedException.description
        let errors = false

        let startStartHour = parseInt(startHour?.slice(0, 2))
        let endStartHour = parseInt(startHour?.slice(3, 5))
        if (endStartHour >= 30) {
            endStartHour = .5
        } else {
            endStartHour = 0
        }

        let startFinishHour = parseInt(finishHour?.slice(0, 2))
        let endFinishHour = parseInt(finishHour?.slice(3, 5))
        if (endFinishHour >= 30) {
            endFinishHour = .5
        } else {
            endFinishHour = 0
        }

        let startDayDate = new Date(startDate)
        let finishDayDate = new Date(finishDate)
        let dateDiff = moment(startDayDate).diff(finishDayDate, 'days')

        if (startDate == null || finishDate == null || finishHour == null || startHour == null) {
            errors = true
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Preencha todas as datas')
            return setShowNotificationModal(true)
        }

        if (startHour === finishHour && (startDate === finishDate)) {
            errors = true
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Hora inicial não pode ser igual a hora final')
            return setShowNotificationModal(true)
        }
        if (startHour > finishHour && (startDate === finishDate)) {
            errors = true
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Hora inicial não pode ser maior que a hora final')
            return setShowNotificationModal(true)
        }

        if (dateDiff > 0) {
            errors = true
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText('Data inicial não pode ser maior que data final')
            return setShowNotificationModal(true)
        }

        let exceptionHours = {
            startDate,
            finishDate,
            startHour: startStartHour + endStartHour,
            finishHour: startFinishHour + endFinishHour,
            description
        }

        return { error: errors, hours: exceptionHours }
    }

    const handleNewExceptionalBlockedHour = async () => {

        const validhours = formatExceptionBlockedHours()

        if (validhours.error) {
            return
        }

        let apiData = ({
            ...formatExceptionBlockedHours().hours,
            services_id: serviceId.id,
        })

        try {
            const newBlock = await api.post(`/api/v1/schedulesBlock`, apiData, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setBlockedPlusHour([...blockedPlusHour, {
                id: newBlock.data.data[0].id,
                created_at: formattTimeToShow(newBlock.data.data[0].created_at),
                startDate: `${formattTimeToShow(validhours.hours.startDate)} - ${maskHours({ hour: validhours.hours.startHour })}`,
                finishDate: `${formattTimeToShow(validhours.hours.finishDate)} - ${maskHours({ hour: validhours.hours.finishHour })}`,
                description: validhours.hours.description,
                delete: <div className='flex flex-col items-center justify-center w-full' onClick={() => { setSelectedBlocked({ id: newBlock.data.data[0].id }); setShowUniversalModal(true) }}>
                    <FaTrash />
                </div>
            }
            ])
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                console.log(error)
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setNewBlockedDescription(false)
        setShowNotificationModalSuccess(true)
        setShowNotificationModalText('Horário cadastrado com sucesso!')
        return setShowNotificationModal(true)
    }

    function handleHours(type, hour, selectedHour, id, indexHour) {

        setLoading(true)

        if (selectedHour === 'start') {

            const hourEl = document.getElementById(`${id}-${indexHour}`)
            hourEl.style.border = '1px transparent'

            if (hour > selectedEndHour && hour !== 23.5) {

                // const validTimePeriod = new ValidPeriodTime(hour, hour + .5, timeDays[id].hours[indexHour]?.id, timeDays[id]?.hours)
                // validTimePeriod.validTime()
                // if (validTimePeriod.validTime().error) {
                //     alert(validTimePeriod.validTime().message)
                // } else {

                // }
                timeDays[id].hours[indexHour].startHour = hour
                timeDays[id].hours[indexHour].finishHour = hour + .5
                setTimeDays(timeDays)
                setTimeDaysRows(Object.keys(timeDays))
                return setLoading(false)
            }
            if (hour === selectedEndHour || hour !== 23.5) {

                // const validTimePeriod = new ValidPeriodTime(hour, hour + .5, timeDays[id].hours[indexHour]?.id, timeDays[id]?.hours)
                // validTimePeriod.validTime()
                // if (validTimePeriod.validTime().error) {
                //     alert(validTimePeriod.validTime().message)
                // } else {
                // }
                timeDays[id].hours[indexHour].startHour = hour
                timeDays[id].hours[indexHour].finishHour = hour + 0.5
                setTimeDays(timeDays)
                setTimeDaysRows(Object.keys(timeDays))
                return setLoading(false)
            }
            if (hour === 23.5) {

                // const validTimePeriod = new ValidPeriodTime(hour, hour + .5, timeDays[id].hours[indexHour]?.id, timeDays[id]?.hours)
                // validTimePeriod.validTime()
                // if (validTimePeriod.validTime().error) {
                //     alert(validTimePeriod.validTime().message)
                // } else {

                // }
                timeDays[id].hours[indexHour].startHour = hour
                timeDays[id].hours[indexHour].finishHour = hour + 0.5
                setTimeDays(timeDays)
                setTimeDaysRows(Object.keys(timeDays))
                return setLoading(false)
            }
        }

        if (selectedHour === 'end') {
            // const validTimePeriod = new ValidPeriodTime(timeDays[id].hours[indexHour]?.startHour, hour, timeDays[id].hours[indexHour]?.id, timeDays[id]?.hours)
            // validTimePeriod.validTime()
            // if (validTimePeriod.validTime().error) {
            //     alert(validTimePeriod.validTime().message)
            // } else {

            // }
            timeDays[id].hours[indexHour].finishHour = hour
            setTimeDays(timeDays)
            setTimeDaysRows(Object.keys(timeDays))
            return setLoading(false)
        }
        if (type === 'full') {
            timeDays[id].hours[indexHour].finishHour = parseInt(hour.hour) + 1 / 2
            timeDays[id].hours[indexHour].startHour = hour.hour
            setTimeDays(timeDays)
            setTimeDaysRows(Object.keys(timeDays))

        } else if (type === 'half') {
            timeDays[id].hours[indexHour].finishHour = parseInt(hour.hour) + 1 / 2
            timeDays[id].hours[indexHour].startHour = hour.hour
            setTimeDays(timeDays)
            setTimeDaysRows(Object.keys(timeDays))
        }
        setLoading(false)
    }

    function orderHoursDay(day) {
        if (timeDays[day]?.hours?.length > 0) {
            timeDays[day].hours.sort(function (a, b) {
                return +(a.startHour < b.startHour) || +(a.startHour === b.startHour) - 1;
            });
        }
        setTimeDays(timeDays)
        setTimeDaysRows(Object.keys(timeDays))
    }

    function createNewHour(day) {
        setLoading(true)
        const errors = timeDays[day].hours.filter((hour, indexHour) => {
            if (hour.startHour === '' || hour.finisHour === '') {
                const hourEl = document.getElementById(`${day}-${indexHour}`)
                if (hourEl) {
                    hourEl.style.background = '#f87171'
                }
                return hour
            }
        })

        if (errors?.length > 0) {
            setLoading(false)
            return alert('Preenche os horarios acima')
        }

        timeDays[day].hours.push(
            {
                categories_id: '',
                id: uuid(),
                last: true,
                isBlocked: false,
                blockedDescription: undefined,
                weekDay: timeDays[day].weekDay,
                startHour: '',
                finishHour: '',
                forAll: true,
                isUniqueValue: { id: false, description: "Não" },
                uniqueValue: undefined,
                isDrawn: { id: false, description: "Nao" },
                forSpecific: false,
                associations: [
                ],
            }
        )

        setTimeDays(timeDays)
        setTimeDaysRows(Object.keys(timeDays))
        setLoading(false)
    }

    function handleRemoveHour(day, hour) {
        const filtered = timeDays[day].hours.filter((e) => e !== hour)
        timeDays[day].hours = filtered
        if (timeDays[day].hours?.length === 0) {
            timeDays[day].active = false
        }
        orderHoursDay(day)
        setTimeDays(timeDays)
        setTimeDaysRows(Object.keys(timeDays))
    }

    function createNewDay(day) {

        const objDays = timeDays
        objDays[day].active = !timeDays[day].active
        setTimeDays(objDays)
        setTimeDaysRows(Object.keys(objDays))
    }

    function duplicateDay(day, dayToPush) {
        //cachtes de dayToCpoy ID
        const dayId = day.id
        if (timeDays[dayId]?.hours?.length === 0) {
            return alert(`${timeDays[dayId]?.description} não tem horários configurados`)
        }
        const hoursToDuplicate = []
        //Alter de ID of the hours to the new day
        timeDays[dayId].hours.map((hours) => {
            hoursToDuplicate.push({ ...hours, id: uuid() })
        })
        const dayDescription = timeDays[dayToPush]?.description
        const weekDay = timeDays[dayToPush].weekDay
        //create a new formatted object
        const newObjDay = {
            'active': true,
            'description': dayDescription,
            'hours': hoursToDuplicate,
            'weekDay': weekDay
        }
        //inserts the new formatted object in the new day
        timeDays[dayToPush] = newObjDay
        setTimeDays(timeDays)
        setTimeDaysRows(Object.keys(timeDays))
        setLoading(false)
    }

    function handleBlockedHour(day, indexHour, value) {
        const newTimeDays = timeDays

        setHistoryHours(Object.freeze(historyHours))
        newTimeDays[day].hours[indexHour].isBlocked = value
        if (!value) {
            newTimeDays[day].hours[indexHour].blockedDescription = undefined
            newTimeDays[day].hours[indexHour].forAll = true
            newTimeDays[day].hours[indexHour].forSpecific = false
        } else {
            newTimeDays[day].hours[indexHour].forAll = false
            newTimeDays[day].hours[indexHour].forSpecific = false
        }
        setHistoryHours(Object.freeze(historyHours))
        setTimeDays({ ...newTimeDays })
        setTimeDaysRows(Object.keys(newTimeDays))
    }

    function validateHoursOfDays() {

        let hoursActive = []
        let hasHour = false
        let blockedError = false
        let uniqueValueError = false
        let forSpecificError = false

        const errors = timeDaysRows.filter((day) => {
            const hoursWithErrors = timeDays[day].hours.filter((hour, indexHour) => {
                hasHour = true
                const hourEl = document.getElementById(`${day}-${indexHour}`)
                if (hour.startHour === '' || hour.finisHour === '') {
                    if (hourEl) {
                        hourEl.style.background = '#f87171'
                    }
                    return hour
                }
                if (hour.isBlocked && !hour.blockedDescription) {
                    if (hourEl) {
                        hourEl.style.background = '#f87171'
                    }
                    blockedError = true
                }
                if (hour.isUniqueValue?.id && hour.uniqueValue !== 0 && !hour.uniqueValue) {
                    if (hourEl) {
                        hourEl.style.background = '#f87171'
                    }
                    uniqueValueError = true
                }
                if (hour?.forSpecific && hour.associations?.length === 0) {
                    if (hourEl) {
                        hourEl.style.background = '#f87171'
                    }
                    forSpecificError = true
                }
            })
            if (hoursWithErrors?.length > 0) {
                return day
            }
            if (timeDays[day].active) {
                hoursActive.push(day)
            }
        })

        if (forSpecificError) {
            setLoading(false)
            return { error: true, message: 'Selecione as associações na linha em vermelho' }
        }

        if (blockedError) {
            setLoading(false)
            return { error: true, message: 'Preencha o motivo do bloqueio' }
        }
        if (uniqueValueError) {
            setLoading(false)
            return { error: true, message: 'Preencha o valor exclusivo na linha vermelha' }
        }

        if (!hasHour) {
            setLoading(false)
            return { error: true, message: 'Necessário ao menos configurar 1 horário' }
        }

        if (errors?.length > 0) {
            setLoading(false)
            return { error: true, message: 'Preencha os horários em vermelho' }
        }

        return { error: false, message: '' }
    }

    function handleAssociationsIncluded(data, day, hourId) {

        setLoading(true)
        switch (data.id) {
            case 1:
                timeDays[day].hours[hourId].forSpecific = false
                timeDays[day].hours[hourId].forAll = true
                timeDays[day].hours[hourId].associations = []

                setTimeDays(timeDays)
                setTimeDaysRows(Object.keys(timeDays))
                setLoading(false)
                break;
            case 2:
                timeDays[day].hours[hourId].forSpecific = true
                timeDays[day].hours[hourId].forAll = false
                setTimeDays(timeDays)
                setTimeDaysRows(Object.keys(timeDays))
                setLoading(false)
                break;
            default:
                break;
        }
    }

    async function formatAPINewPhoto(pictures) {
        let photosAppendArray = []

        await Promise.all(pictures.map(async (picture) => {
            photosAppendArray.push(picture)
        }))
        setServicesAppendPhotos(photosAppendArray)

    }

    async function formatAPIPhoto(apiData) {
        if (apiData.pictures?.length > 0) {

            let photosArray = []
            let photosAppendArray = []

            await Promise.all(apiData.pictures.map(async (picture) => {
                const file = await api.get(`api/v1/servicesPictures/services_id/${serviceId.id}/picture/${picture}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                const fileObj = URL.createObjectURL(file.data)
                photosArray.push(picture)
                photosAppendArray.push(fileObj)
            }))
            setServicesPhotos(photosArray)
            setServicesAppendPhotos(photosAppendArray)
        }
    }

    async function validadeHistory() {

        let historyArr = []

        Object.keys(historyHours).map((historyHour) => {
            historyArr.push(historyHours[historyHour])
        })


        let selectedArr = []
        Object.keys(timeDays).map((selectedHours) => {
            selectedArr.push(timeDays[selectedHours])
        })
        return JSON.stringify(historyArr) === JSON.stringify(selectedArr)
    }

    function handleSelectedCategorie(categorie) {
        let formattedCategorieSteps = steps
        setFilteredSteps(formattedCategorieSteps)
        setSelectedCategorie(categorie)
    }

    function linkServicesValues(associationData, valuesData) {
        if (valuesData?.length > 0) {

            let newAssociationsList = []
            let historyAssociation = []

            associationData.map((association) => {
                const hasValue = valuesData.filter((valueAssociation) => {
                    if (valueAssociation.associations_id === association.id) {
                        newAssociationsList.push({
                            ...association,
                            value: valueAssociation.value,
                            valueWeekend: valueAssociation.valueWeekend,
                            nightValue: valueAssociation.nightValue,
                            nightValueWeekend: valueAssociation.nightValueWeekend,
                        })
                        historyAssociation.push({
                            associations_id: valueAssociation.associations_id,
                            value: valueAssociation.value,
                            valueWeekend: valueAssociation.valueWeekend,
                            nightValue: valueAssociation.nightValue,
                            nightValueWeekend: valueAssociation.nightValueWeekend,
                        })
                        return valueAssociation
                    }
                })
                if (hasValue.length === 0) {
                    newAssociationsList.push(association)
                }
            })
            setHistoryAssociationsValue(historyAssociation)
            setAssociationsList(newAssociationsList)
            setAssociationsListRows(Object.keys(associationData))
        } else {

            setAssociationsList(associationData)
            setAssociationsListRows(Object.keys(associationData))
        }


    }

    useEffect(() => {

        async function getData(e) {

            setShowUniversalModal(false)
            const getServices = await getServicesEspecific(userData[0]?.token, serviceId.id)
            const getServicesScheduleBlockedData = await getSchedulesBlocked(userData[0].token, serviceId.id)
            if (getServices.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(getServices.data)
            }

            const associationsData = await getAssociations(userData[0].token, 1)
            if (associationsData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(associationsData.data)
            }

            const servicesValues = await getServicesValuePerAssociation(userData[0].token, serviceId.id)
            if (servicesValues.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(servicesValues.data)
            }

            const servicesHours = await getServicesHoursEspecific(userData[0].token, parseInt(serviceId.id))

            if (servicesHours.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(servicesHours.data)
            }

            const hours = new FormatHours(servicesHours.data).format()
            setTimeDays(hours)
            setTimeDaysRows(Object.keys(hours))
            setHistoryHours(structuredClone(new FormatHours(servicesHours.data).format()))
            mapDays(hours)

            const categoriesData = await getCategories(userData[0]?.token)
            if (categoriesData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(categoriesData.data)
            }
            const categorieForEdit = categoriesData.data.filter(categorie =>
                getServices.data[0].categories_id === categorie.id
            )[0]
            await formatAPIPhoto(getServices.data[0])
            setName(getServices.data[0].name)
            setDescription(getServices.data[0].description)
            setShowOnSite(getServices.data[0]?.showOnSite)
            setModalities(getServices.data[0]?.modalities)
            setServiceStatus(getServices.data[0]?.active ? true : false)
            setServiceStatusHistory(structuredClone(getServices.data[0]?.active ? true : false))
            setReservationType(getServices.data[0]?.reservationType === "M" ? { id: 1, value: "M", description: "Mensal" } : { id: 1, value: "H", description: "Horário" })
            setSelectedCategorie(categorieForEdit)
            handleSelectedCategorie(categorieForEdit)
            linkServicesValues(associationsData.data, servicesValues.data)
            setCategoriesList(categoriesData.data)
//teste
            if (!getServicesScheduleBlockedData.error) {
                setTotalPages(getServicesScheduleBlockedData.data.totalPages)
                getServicesScheduleBlockedData.data.data.map(block => {
                    block.delete = <div className='flex flex-col w-full items-center justify-center' onClick={(e) => { setSelectedBlocked(block); setShowUniversalModal(true) }}><FaTrash /></div>
                })
                setBlockedPlusHour(getServicesScheduleBlockedData.data.data)
            }
            setLoading(false)
        }
        getData()
    }, [])

    async function getSchedulesBlockData(e) {

        const getServicesScheduleBlockedData = await getSchedulesBlocked(userData[0].token, serviceId.id, e?.filter, e?.page, e?.direction, '')
        if (!getServicesScheduleBlockedData.error) {
            getServicesScheduleBlockedData.data.data.map(block => {
                block.delete = <div className='flex flex-col w-full items-center justify-center' onClick={(e) => { setSelectedBlocked(block); setShowUniversalModal(true) }}><FaTrash /></div>
            })
            setBlockedPlusHour(getServicesScheduleBlockedData.data.data)
        }
        setLoading(false)
    }

    const [steps, setSteps] = useState([
        {
            id: 0, text: 'Serviço', valid: false
        },
        {
            id: 1, text: 'Valores', valid: false
        },
        {
            id: 2, text: 'Horários', valid: false
        }
    ])
    const [filteredSteps, setFilteredSteps] = useState(steps)
    const [activeStep, setActiveStep] = useState({ id: 0 })

    async function handleSubmit() {

        let message = 'Serviço cadastrado com sucesso!'

        const isValid = validateThisFields(activeStep.id)
        if (isValid?.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(isValid?.message)
            return
        } else if (isValid?.length > 0) {
            alert('Erro inesperado')
        }

        setLoading(true)
        try {
            //update service status
            if (serviceStatus !== serviceStatusHistory) {
                await api.put(`/api/v1/services/${serviceId.id}/active/${serviceStatus ? 1 : 0}`, {
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            }
            await api.put(`/api/v1/services/${serviceId.id}`, {
                "name": name,
                "description": description,
                modalities,
                showOnSite,
                "reservationType": reservationType.value,
                "categories_id": selectedCategorie?.id
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            let hasValueAssociation = []
            associationsListRows.map((association) => {
                hasValueAssociation.push({
                    associations_id: associationsList[association].id,
                    value: Object.keys(associationsList[association]).indexOf('value') > -1 ? associationsList[association].value : 0,
                    nightValue: Object.keys(associationsList[association]).indexOf('nightValue') > - 1 ? associationsList[association].nightValue : 0,
                    valueWeekend: Object.keys(associationsList[association]).indexOf('valueWeekend') > - 1 ? associationsList[association].valueWeekend : 0,
                    nightValueWeekend: Object.keys(associationsList[association]).indexOf('nightValueWeekend') > - 1 ? associationsList[association].nightValueWeekend : 0,
                })
            })
            if (hasValueAssociation.length > 0 && (JSON.stringify(historyAssociationsValue) !== JSON.stringify(hasValueAssociation))) {
                await api.post(`/api/v1/servicesValuePerAssociations`, {
                    services_id: serviceId.id,
                    values: hasValueAssociation
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            }
            if (reservationType.value !== 'M' && await validadeHistory() === false) {
                let daysIds = Object.keys(timeDays)
                let arrayAPI = []
                daysIds.map((dayId) => {
                    if (timeDays[dayId]?.active) {
                        timeDays[dayId]?.hours?.map((hour) => {
                            const associations = []
                            if (hour.forSpecific) {
                                hour.associations.map((association) => {
                                    associations.push(association.id)
                                })
                            }
                            let isUniqueValueHour = Object.keys(hour.isUniqueValue).includes('id') ? hour.isUniqueValue.id : hour.isUniqueValue
                            arrayAPI.push({
                                weekday: timeDays[dayId]?.weekDay,
                                startHour: hour.startHour,
                                finishHour: hour.finishHour,
                                forAll: hour.forAll,
                                forSpecific: hour.forSpecific,
                                isUniqueValue: Object.keys(hour.isUniqueValue).includes('id') ? hour.isUniqueValue.id : hour.isUniqueValue,
                                uniqueValue: isUniqueValueHour ? hour.uniqueValue : undefined,
                                isDrawn: hour?.isDrawn?.id ? hour?.isDrawn?.id : false,
                                associations: associations,
                                isBlocked: hour.isBlocked,
                                blockedDescription: hour.blockedDescription
                            })
                        })
                    }
                })
                //create a hour configuration by categorie
                await api.post(`/api/v1/servicesHours`, {
                    services_id: serviceId.id,
                    hours: arrayAPI
                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
            }

            if (serviceAppendPhotos.length > 0) {

                const formData = new FormData();
                await Promise.all(serviceAppendPhotos.map(async (photo, index) => {
                    formData.append('images', await UrlToImage(photo, servicePhotos[index]));
                }))

                try {
                    await api.post(`/api/v1/servicesPictures/services_id/${serviceId.id}`, formData, {
                        headers: {
                            Authorization: `Bearer ${userData[0]?.token}`,
                            'Content-Type': 'multipart/form-data',
                        }
                    })
                } catch (error) {
                    message = 'Cadastro realizado com sucesso, porém não foi possível cadastrar as fotos'
                }
            }
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText(message)
            navigate('/services')
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                console.log(error)
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function validateThisFields(id) {
        switch (id) {
            case 0:
                return validateFields(requiredFields)
            case 2:
                return validateHoursOfDays()
            default:
                break;
        }
    }

    function validateAssociationsValues() {
        let errors = []
        associationsListRows.map((associationsDiscountId) => {
            let thisAssociacElement = document.getElementById(associationsDiscountId)
            if ((associationsList[associationsDiscountId]?.value === `` || (isNaN(parseFloat(associationsList[associationsDiscountId]?.value) && associationsList[associationsDiscountId]?.value !== 0)))
                ||
                (associationsList[associationsDiscountId]?.nightValue === `` || (isNaN(parseFloat(associationsList[associationsDiscountId]?.nightValue) && associationsList[associationsDiscountId]?.nightValue !== 0)))
                ||
                (associationsList[associationsDiscountId]?.valueWeekend === `` || (isNaN(parseFloat(associationsList[associationsDiscountId]?.valueWeekend) && associationsList[associationsDiscountId]?.valueWeekend !== 0)))
                ||
                (associationsList[associationsDiscountId]?.nightValueWeekend === `` || (isNaN(parseFloat(associationsList[associationsDiscountId]?.nightValueWeekend) && associationsList[associationsDiscountId]?.nightValueWeekend !== 0)))
            ) {
                errors.push({ error: true, type: 'associationsDiscountList', id: associationsDiscountId })
                if (thisAssociacElement) {
                    thisAssociacElement.style.background = '#f87171'
                }
            } else {
                thisAssociacElement.style.background = ''
            }
        })
        return errors
    }

    const handleAddPhoto = () => {
        const inputPhoto = document.getElementById('photoAddInput')
        inputPhoto.click()
    }

    const handleNewPhoto = async (photoFile) => {

        const validImage = permittedFiles(photoFile?.target?.files[0], 'image')
        if (validImage?.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(validImage?.response)
        }

        if (servicePhotos.length < 5) {

            const formattedImg = await new Promise((resolve, reject) => {
                resizeImageTest(photoFile, (err, resizedFile) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    resolve(resizedFile)
                })
            })

            const photoObj = URL.createObjectURL(formattedImg)
            setServicesPhotos([...servicePhotos, photoFile.target.files[0].name])

            await formatAPINewPhoto([...serviceAppendPhotos, photoObj])
        }
    }

    const prevPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = serviceAppendPhotos[showPhoto - 1]
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto - 1)
    }

    function nextPhoto() {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = serviceAppendPhotos[showPhoto + 1]
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto + 1)
    }

    async function showThisPhoto(photo, indexPhoto) {

        setShowPhotoModal(true)
        const modalElement = document.getElementById('showPhotoModal')

        let img = document.createElement('img')
        img.src = photo
        img.id = 'appendedPreviewPhoto'
        modalElement.appendChild(img)
        setShowPhoto(indexPhoto)
        await new Promise(resolve => setTimeout(resolve, 100))
        document.getElementById('photoContainer').style.opacity = '1'
        document.getElementById('photoContainer').style.transform = 'translateY(0px)'

        const serviceModal = document.getElementById('servicePhotoModal')

        const closePhotoModal = (e) => {
            if (e.code === 'Escape') {
                closeThisPhoto()
            }
        }

        if (serviceModal) {
            document.addEventListener('keydown', closePhotoModal)

            return () => document.removeEventListener('keydown', closePhotoModal)
        }

    }

    async function closeThisPhoto() {

        document.getElementById('photoContainer').style.opacity = '0'
        document.getElementById('photoContainer').style.transform = 'translateY(-100px)'
        await new Promise(resolve => setTimeout(resolve, 300))

        setShowPhotoModal(false)
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)
        setShowPhoto('')
    }

    async function removeThisPhoto(photo, indexPhoto) {
        document.getElementById(photo + '-' + indexPhoto).style.transform = 'scale(.5)'
        await new Promise(resolve => setTimeout(resolve, 500))

        setServicesPhotos(servicePhotos.filter(photoToRemove => photoToRemove !== photo))
        document.getElementById(photo + '-' + indexPhoto).style.transform = 'scale(1)'
        await formatAPINewPhoto(serviceAppendPhotos.filter((photoToRemove, index) => indexPhoto !== index))
    }

    async function handleDeleteBlockedSchedule() {
        setLoading(true)

        try {
            await api.delete(`/api/v1/schedulesBlock/${selectedBlocked.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            let newBlockedShedule = []
            newBlockedShedule = blockedPlusHour.filter(blocked => blocked.id !== selectedBlocked.id)
            setBlockedPlusHour(newBlockedShedule)
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowUniversalModal(false)
            return setShowNotificationModalText('Bloqueio removido com sucesso!')
        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                console.log(error)
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    //filter functions
    async function handleClearFilter() {

        setCleanFilter(true)
        setLoading(true)
        setPaginationHistFilteredFieldsArr([]);
        setPaginationHistFilteredFields([])
        let fields = {}
        filters.map((column, index) => {
            fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
        })
        setPaginationFilteredFields(fields)
        getSchedulesBlockData();
        await new Promise(resolve => setTimeout(resolve, 200))
        setLoading(false)
        setCleanFilter(false)

    }

    useEffect(() => {

        let modal = document.getElementById('tableFilterModal')
        if (modal) {
            modal.addEventListener('click', (e) => handleChangeModalStatus(e, ''))
            document.addEventListener('keydown', (e) => handleChangeModalStatus(e, 'keyboard'))
        }

        async function animateFilterModal(status) {

            let fields = {}
            filters.map((column, index) => {
                fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
            })
            setPaginationFilteredFields(fields)
            if (status) {
                let histFields = {}
                filters.map((column, index) => {
                    histFields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
                })
                setPaginationHistFilteredFields(fields)
                setShowFilterModal(true)
                await new Promise(resolve => setTimeout(resolve, 200))
                return setShowInternalFilterModal(true)
            }
            setShowFilterModal(false)
            setShowInternalFilterModal(false)
        }

        animateFilterModal(showFilterModal)

        if (modal) {
            return () => { modal.removeEventListener('click', (e) => handleChangeModalStatus(e)); modal.removeEventListener('keydown', (e) => handleChangeModalStatus(e)) }
        }

    }, [showFilterModal])

    function customFilter() {

        let ToFilterData = []
        let fieldsKeys = Object.keys(paginationFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationFilteredFields[key].value !== '') {
                ToFilterData.push(paginationFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
        }
        getSchedulesBlockData({ filter: { filterObject: ToFilterData }, direction: false, })
        setShowFilterModal(false)
    }

    function handleChangeModalStatus(e, type) {

        if (e.code === 'Escape' && type === 'keyboard') {
            setShowFilterModal(false)
        }

        if (!tableFilterModal?.current?.contains(e.target) && type !== 'keyboard') {
            setShowFilterModal(false)
        }
    }

    function orderTableData(e) {
        const ToFilterData = []
        const fieldsKeys = Object.keys(paginationHistFilteredFields)
        fieldsKeys.map((key) => {
            if (paginationHistFilteredFields[key].value !== '') {
                ToFilterData.push(paginationHistFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
            return getData({ filter: { filterObject: ToFilterData }, direction: e?.direction, page: e.page })
        }
        getSchedulesBlockData(e)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            customFilter()
        }
    }

    return (
        <>
            <ScreenModal title={"Excluir bloqueio"}>
                <div className='flex flex-col w-full p-6 items-center justify-center'>
                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark mb-5'>Deseja excluir o bloqueio?</p>
                    <div className='flex flex-row items-center justify-center gap-2 w-full'>
                        <Button shadow={true} onClick={() => handleDeleteBlockedSchedule()}>Sim</Button>
                        <Button shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Editar serviço'} />
                </TitlePage>
                <Body hasFooter={true} shadow={false} background={'bg-secondaryDefault'}>
                    {
                        showFilterModal &&
                        <div id='tableFilterModal' className='fixed left-0 top-0 z-[500] w-full h-full flex flex-col items-center justify-start'>
                            <div ref={tableFilterModal} className={`${showInternalFilterModal ? 'rounded-lg bg-bgPrimaryLight dark:bg-secondaryDefaultDark opacity-1 translate-x-0' : 'translate-x-[1000px] bg-transparent opacity-0'} top-28 absolute z-[501] p-6  boxShadow transition-all duration-300 flex flex-col gap-2`}>
                                <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center mb-8'>
                                    <FaSearch />
                                    <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                                </div>
                                {

                                    filters.map(column => {
                                        return <form onKeyUp={(e) => handleKeyPress(e)} className='flex sm:flex-row flex-col'>
                                            <div className='w-28'>
                                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{column.name}</p>
                                            </div>
                                            {
                                                column.type === 'text' ?
                                                    <LabelInput>
                                                        <Input value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                            setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                            setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                        }}></Input>
                                                    </LabelInput>
                                                    :
                                                    column.type === 'object' ?
                                                        <LabelInput>
                                                            <InputAutoComplete data={column.data} optionList={column.optionList} selectedLabel={column.selectedLabel} onChange={(e) => {
                                                                setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                                setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
                                                            }}></InputAutoComplete>
                                                        </LabelInput>
                                                        :
                                                        column.type === 'date' &&
                                                        <LabelInput>
                                                            <Input type={`date`} value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                                setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                                setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                            }} />
                                                        </LabelInput>
                                            }
                                        </form>
                                    })}
                                <div className='flex w-full flex-col items-end justify-start mt-6'>
                                    <Button onClick={() => customFilter()} shadow={true}>Pesquisar</Button>
                                </div>

                            </div>
                            <div className={`${showInternalFilterModal ? 'bg-gray-700 opacity-75' : 'bg-transparent opacity-0'} absolute h-full w-full transition-all duration-300`}>
                            </div>
                        </div>
                    }
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-96 bg-bgPrimaryLight shadow-lg'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='flex flex-col items-start justify-start w-full'>
                                <div className='flex flex-col items-start justify-start w-full gap-4'>
                                    <Section>
                                        <Stepper steps={filteredSteps} activeStep={activeStep} changeStep={(e) => setActiveStep(e)} />
                                    </Section>
                                    {
                                        activeStep.id === 0 &&
                                        <div className='flex flex-col gap-4 w-full'>
                                            <Section>
                                                <div className='flex flex-col items-start justify-start w-full py-4 h-full'>
                                                    <div className={`${showPhotoModal ? 'flex' : 'hidden'} flex-row items-center justify-center w-full h-full fixed top-0 left-0 z-50`}>
                                                        <div id='photoContainer' className={`opacity-0 transition-all duration-500 -translate-y-10 flex gap-2 flex-row items-center justify-center w-full h-full`}>
                                                            {
                                                                showPhoto > 0 ?
                                                                    <FaArrowCircleLeft onClick={prevPhoto} className='text-primaryDefaultLight hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                                    :
                                                                    <FaArrowCircleLeft className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                            }
                                                            <div id='servicePhotoModal' ref={modalPhotoRef} className='bg-white boxShadow p-8 w-full h-full md:w-[700px] md:h-[500px] lg:w-[1000px] lg:h-[600px] flex flex-col items-center rounded-lg select-none justify-start'>
                                                                <div className='flex flex-row justify-end w-full'>
                                                                    <button className='bg-primaryDefaultLight hover:brightness-125 transition-all duration-200 h-8 w-8 rounded-full flex flex-col items-center justify-center text-titleGrayTextLight' onClick={() => closeThisPhoto()}>X</button>
                                                                </div>
                                                                <div id='showPhotoModal' className='flex flex-col items-center justify-center overflow-auto w-full h-full'>
                                                                </div>
                                                            </div>
                                                            {
                                                                showPhoto < servicePhotos.length - 1 ?
                                                                    <FaArrowAltCircleRight onClick={() => nextPhoto()} className='text-primaryDefaultLight hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                                    :
                                                                    <FaArrowAltCircleRight className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>

                                                            }
                                                        </div>
                                                    </div>

                                                    <div className={`${showPhotoModal ? 'flex' : 'hidden'}  bg-gray-600 opacity-80 w-full h-full fixed z-40 left-0 top-0`}></div>
                                                    <form onSubmit={e => e.preventDefault()} className='flex flex-col justify-center items-start gap-4 w-full'>
                                                        <div className='flex flex-row flex-wrap items-start justify-start gap-4'>
                                                            <LabelInput text={'Categoria'}>
                                                                <InputAutoComplete data={categoriesList}
                                                                    selectedLabel={['name']}
                                                                    optionList={['name']}
                                                                    id='selectedCategorie'
                                                                    placeHolder={'-'}
                                                                    preSelectedValue={selectedCategorie?.name}
                                                                    onChange={(e) => handleSelectedCategorie(e)}
                                                                />
                                                            </LabelInput>
                                                            <LabelInput text={'Nome'}>
                                                                <Input id='name' type={'text'} onChange={(e) => setName(e.target.value)} value={name}></Input>
                                                            </LabelInput>
                                                        </div>
                                                        <textarea wrap="hard" value={description} onChange={(e) => setDescription(e.target.value)} maxLength={2000} className='
                                                  p-2 border border-gray-400 border-opacity-75 
                                                  h-64
                                                  w-full
                                                  text-sm
                                                  outline-none transition-all duration-200 focus:shadow-borderShadow
                                                  dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark 
                                                  '></textarea>
                                                        <div className='w-full flex flex-row flex-wrap gap-4'>
                                                            <LabelInput text={'Ativo'}>
                                                                <Toogle onClick={() => setServiceStatus(!serviceStatus)} status={serviceStatus}></Toogle>
                                                            </LabelInput>
                                                            <LabelInput text={'Exibir no site?'}>
                                                                <Toogle status={showOnSite} onClick={() => setShowOnSite(!showOnSite)} />
                                                            </LabelInput>
                                                        </div>
                                                    </form>
                                                </div>
                                            </Section>
                                            <Section>
                                                <div id='servicePhotos' className='w-full flex flex-col gap-4 items-center py-4 justify-start'>
                                                    <div className='flex flex-col items-start justify-start w-full mb-5'>
                                                        <p className='text-lg text-primaryDefaultLight'>Fotos do espaço</p>
                                                    </div>
                                                    <div className='flex flex-row gap-2 items-start justify-start w-full'>
                                                        <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Fotos</p>
                                                        <input id='photoAddInput' type='file' onChange={handleNewPhoto} accept="image/png,image/jpeg,image/jpg,image/gif,image/jpeg" className='hidden'></input>
                                                        <a className='text-titleBlackTextLight dark:text-titleGrayTextDark'>{servicePhotos.length} de 5</a>
                                                        <Tippy content={'Adicionar fotos'}
                                                            arrow={true}
                                                            animation='perspective'
                                                            placement='right'
                                                            delay={100}>
                                                            <div>
                                                                <FaPlus onClick={handleAddPhoto} className='text-xl text-primaryDefaultLight cursor-pointer' />
                                                            </div>
                                                        </Tippy>
                                                    </div>
                                                    <div className='h-20 w-full'>
                                                        <div className='flex flex-row gap-4 items-center justify-start'>
                                                            {
                                                                servicePhotos.map((photo, indexPhoto) => {
                                                                    return <div id={photo + '-' + indexPhoto} className='cursor-pointer transition-all duration-500 ease-in-out relative flex flex-row gap-2 items-center justify-center'>
                                                                        <Tippy content={'Remover foto'}
                                                                            arrow={true}
                                                                            animation='perspective'
                                                                            placement='right'
                                                                            delay={300}>
                                                                            <button onClick={() => removeThisPhoto(photo, indexPhoto)} className='text-titleGrayTextLight absolute -left-2 -top-2 w-10 h-10 sm:w-6 sm:h-6 rounded-full bg-gray-400'>x</button>
                                                                        </Tippy>
                                                                        <img onClick={() => showThisPhoto(serviceAppendPhotos[indexPhoto], indexPhoto)} className='border border-gray-500 rounded-xl w-20 h-20' src={serviceAppendPhotos[indexPhoto]}></img>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Section>
                                            <Section>
                                                {
                                                    newBlockedDescription ?
                                                        <div className='w-full flex flex-col items-start justify-start py-4 gap-4'>
                                                            <p className='text-lg text-primaryDefaultLight'>Cadastrar novo bloqueio</p>
                                                            <div className='flex flex-row gap-4'>
                                                                <div className='flex flex-row gap-1 items-center justify-start'>
                                                                    <p className='px-2'>de</p>
                                                                    <LabelInput text={''}>
                                                                        <Input value={blockedException?.startDate} onChange={(e) => setBlockedException((prev) => ({ ...prev, startDate: e.target.value }))} width={40} type={'date'}></Input>
                                                                    </LabelInput>
                                                                    <LabelInput text={''}>
                                                                        <Input value={blockedException?.startHour} onChange={(e) => setBlockedException((prev) => ({ ...prev, startHour: e.target.value }))} width={40} type={'time'}></Input>
                                                                    </LabelInput>
                                                                </div>
                                                                <div className='flex flex-row gap-1 items-center justify-start'>
                                                                    <p className='px-2'>até</p>
                                                                    <LabelInput text={''}>
                                                                        <Input value={blockedException?.finishDate} onChange={(e) => setBlockedException((prev) => ({ ...prev, finishDate: e.target.value }))} width={40} type={'date'}></Input>
                                                                    </LabelInput>
                                                                    <LabelInput text={''}>
                                                                        <Input value={blockedException?.finishHour} onChange={(e) => setBlockedException((prev) => ({ ...prev, finishHour: e.target.value }))} width={40} type={'time'}></Input>
                                                                    </LabelInput>
                                                                </div>
                                                            </div>
                                                            <div className='flex flex-row gap-1 items-center justify-start w-full'>
                                                                <textarea
                                                                    maxLength={2000}
                                                                    value={blockedException?.description}
                                                                    onChange={(e) => setBlockedException((prev) => ({ ...prev, description: e.target.value }))}
                                                                    className='p-2 border border-gray-400 border-opacity-75 h-64 w-full text-sm outline-none transition-all duration-200 focus:shadow-borderShadow
                                                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark '>
                                                                </textarea>
                                                            </div>
                                                            <div className='flex flex-row gap-2 items-start justify-start'>
                                                                <Button onClick={handleNewExceptionalBlockedHour} shadow={true}>Salvar</Button>
                                                                <Button onClick={() => setNewBlockedDescription(false)} approval={false} shadow={true}>Cancelar</Button>
                                                            </div>

                                                        </div>
                                                        :
                                                        <div className='w-full flex flex-col items-start justify-start py-4 gap-4'>
                                                            <p className='text-lg text-primaryDefaultLight'>Bloqueios excepcionais</p>
                                                            <div className='flex gap-1 items-start justify-start'>
                                                                <Button shadow={true} onClick={() => setNewBlockedDescription(true)}>Adicionar</Button>
                                                                <div className='flex flex-row items-center cursor-pointer justify-start w-full py-1 bg-primaryDefaultLight rounded-lg hover:bg-secondaryDefaultLight transition-all duration-200 shadow-md shadow-gray-400 dark:shadow-xl dark:shadow-black/25'>
                                                                    <Tippy content={'Filtrar'}
                                                                        arrow={true}
                                                                        animation='shift-away'
                                                                        placement='top'
                                                                        delay={100}>
                                                                        <div className={`h-full rounded-lg w-32 items-center justify-center flex flex-row gap-2 text-xl text-primaryDefaultLight pl-2 pr-2  md:w-28`} onClick={() => setShowFilterModal(true)}>
                                                                            <FaFilter className='text-bgSecondaryLight text-base' />
                                                                            <a className='text-bgSecondaryLight text-sm'>Filtrar</a>
                                                                        </div>
                                                                    </Tippy>
                                                                    {
                                                                        paginationHistFilteredFieldsArr?.length > 0 &&
                                                                        <Tippy content={'Excluir filtro'}
                                                                            arrow={true}
                                                                            animation='shift-away'
                                                                            placement='top'
                                                                            delay={100}>
                                                                            <div className='ml-2 cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150' onClick={() => { handleClearFilter() }}>
                                                                                <FaBan />
                                                                            </div>
                                                                        </Tippy>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div id='serviceBlockedHoursException' className='flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                                                {
                                                                    !loading &&
                                                                    <>
                                                                        <div className='sm:flex hidden flex-col items-center justify-start w-full overflow-y-auto h-full'>
                                                                            <TableDefault
                                                                                pagination={true}
                                                                                totalPages={totalPages}
                                                                                loading={loading}
                                                                                filter={(e) => orderTableData(e)}
                                                                                data={blockedPlusHour}
                                                                                title={tableBlockedHoursTHead}
                                                                                collumns={["startDate", "finishDate", "description", "created_at", "delete"]}
                                                                                onClick={(e) => ``} />
                                                                        </div>

                                                                    </>
                                                                }
                                                            </div>
                                                            <div className='flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full'>
                                                                <TableMobile
                                                                    onClick={(e) => e.func.execute(e.data)}
                                                                    title={tableBlockedHoursTHead}
                                                                    data={blockedPlusHour}
                                                                    collumns={["startDate", "finishDate", "description", "created_at", "delete"]}
                                                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": () => setNewBlockedDescription(true) }]}
                                                                />
                                                            </div>
                                                        </div>
                                                }
                                            </Section>
                                            <Section>
                                                <div id='modalitiesSpace' className='w-full flex-col items-start justify-start'>
                                                    <div className='flex flex-row gap-2 items-start justify-start w-full mb-5'>
                                                        <p className='text-xl text-primaryDefaultLight'>Modalidades</p>
                                                        <FaPlus onClick={() => setShowCreateModalite(true)} className='text-xl text-primaryDefaultLight cursor-pointer' />
                                                    </div>
                                                    {
                                                        showCreateModalite &&
                                                        <div className='flex flex-col gap-4 items-start justify-start'>
                                                            <LabelInput>
                                                                <Input type={'text'} onChange={(e) => setNewModalite(e.target.value)} value={newModalite} />
                                                            </LabelInput>
                                                            <Button onClick={() => {
                                                                setShowCreateModalite(false)
                                                                setModalities([...modalities, newModalite])
                                                                setNewModalite('')
                                                            }}>Salvar</Button>
                                                        </div>
                                                    }
                                                    <div className='flex flex-col gap-2 items-start justify-start'>
                                                        {modalities?.length > 0 &&
                                                            modalities.map(modalite => {
                                                                if (!showCreateModalite) {
                                                                    return <div className='flex flex-row gap-2 items-start justify-start'>
                                                                        {modalite}
                                                                        <FaTrash className='text-xl text-primaryDefaultLight cursor-pointer' onClick={() => setModalities((prev) => (modalities.filter(modaliteToRemove => modaliteToRemove !== modalite)))} />
                                                                    </div>
                                                                }
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                            </Section>
                                        </div>
                                    }
                                    {
                                        activeStep.id === 1 &&
                                        <Section>
                                            <div className='flex flex-col items-start justify-start w-full gap-2'>

                                                {
                                                    associationsListRows?.length > 0 &&
                                                    <>
                                                        <h2 className='mt-10 text-lg text-primaryDefaultLight'>Valores</h2>
                                                        <div></div>
                                                        <div></div>
                                                        <div className='w-full flex flex-col flex-wrap items-start gap-2'>
                                                            {
                                                                associationsListRows.map((thisAssociation, index) => {
                                                                    return <div id={thisAssociation} className='mt-5 p-1 rounded-lg transition-all duration-200 flex flex-col sm:flex-row gap-2 items-start sm:items-center justify-start'>
                                                                        <LabelInput text={'Associação'}>
                                                                            <Input disabled={true} value={associationsList[thisAssociation].name}></Input>
                                                                        </LabelInput>
                                                                        <LabelInput text={'Valor diurno'}>
                                                                            <CurrencyInput placeHolder={associationsList[thisAssociation]?.value > 0 ? `R$ ${associationsList[thisAssociation]?.value?.toString()?.replace('.', ',')}` : `R$ -`} onChange={(e) => {
                                                                                associationsList[thisAssociation].value = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                                setAssociationsList(associationsList)
                                                                                setAssociationsListRows(Object.keys(associationsList))
                                                                            }}></CurrencyInput>
                                                                        </LabelInput>
                                                                        <LabelInput text={'Valor noturno'}>
                                                                            <CurrencyInput placeHolder={associationsList[thisAssociation]?.nightValue > 0 ? `R$ ${associationsList[thisAssociation]?.nightValue?.toString()?.replace('.', ',')}` : `R$ -`} onChange={(e) => {
                                                                                associationsList[thisAssociation].nightValue = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                                setAssociationsList(associationsList)
                                                                                setAssociationsListRows(Object.keys(associationsList))
                                                                            }}></CurrencyInput>
                                                                        </LabelInput>
                                                                        <LabelInput text={'Valor diurno final de semana'}>
                                                                            <CurrencyInput placeHolder={associationsList[thisAssociation]?.valueWeekend > 0 ? `R$ ${associationsList[thisAssociation]?.valueWeekend?.toString()?.replace('.', ',')}` : `R$ -`} onChange={(e) => {
                                                                                associationsList[thisAssociation].valueWeekend = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                                setAssociationsList(associationsList)
                                                                                setAssociationsListRows(Object.keys(associationsList))
                                                                            }}></CurrencyInput>
                                                                        </LabelInput>
                                                                        <LabelInput text={'Valor noturno final de semana'}>
                                                                            <CurrencyInput placeHolder={associationsList[thisAssociation]?.nightValueWeekend > 0 ? `R$ ${associationsList[thisAssociation]?.nightValueWeekend?.toString()?.replace('.', ',')}` : `R$ -`} onChange={(e) => {
                                                                                associationsList[thisAssociation].nightValueWeekend = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                                setAssociationsList(associationsList)
                                                                                setAssociationsListRows(Object.keys(associationsList))
                                                                            }}></CurrencyInput>
                                                                        </LabelInput>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </Section>
                                    }
                                </div>
                            </div>
                    }
                    {
                        activeStep.id === 2 && !loading &&
                        <Section>
                            <>
                                <div className={`flex m-auto left-0 right-0 flex-col top-0 w-full bottom-0`}>
                                    <div className={`opacity-1 gap-2 flex flex-col items-start justify-start rounded-lg relative z-[100]  border border-titleGrayTextLight dark:border-thirdDefaultDark p-4 transition-all duration-500`}>
                                        <div className='flex flex-row w-full justify-end items-center'>
                                        </div>
                                        <h2 className='text-lg text-primaryDefaultLight font-semibold'>Parametrização de horário</h2>
                                        <div className={`${reservationType?.value === 'H' ? 'flex' : 'hidden'} overflow-y-auto max-h-[65vh] flex-col w-full items-start justify-start`}>
                                            {
                                                timeDaysRows.map((day) => {
                                                    return <div className='flex flex-col items-start w-full justify-start py-4 border-t border-b border-zinc-200 dark:border-thirdDefaultDark'>
                                                        <div className='flex flex-row gap-2 items-start justify-start'>
                                                            <LabelInput text={timeDays[day].description}>
                                                                <InputCheckBox value={timeDays[day].active ? true : false} onClick={() => {
                                                                    createNewDay(day)
                                                                }} />
                                                            </LabelInput>
                                                            {
                                                                timeDays[day]?.hours?.length === 0 && timeDays[day]?.active && daysArr?.length > 0 &&
                                                                <div>
                                                                    <LabelInput text={'Copiar config'}>
                                                                        <InputAutoComplete
                                                                            width={28}
                                                                            typeInactive={true}
                                                                            height={120}
                                                                            data={daysArr}
                                                                            selectedLabel={'description'}
                                                                            optionList={['description']}
                                                                            placeHolder=' - '
                                                                            onChange={value => duplicateDay(value, day)}
                                                                        />
                                                                    </LabelInput>
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            timeDays[day].active && !loading &&
                                                            <div className='mt-5'>
                                                                <div className='flex flex-col mb-5 gap-4'>
                                                                    <div className='flex flex-col-reverse gap-6'>
                                                                        {
                                                                            timeDays[day].hours.map((hours, indexHour) => {
                                                                                if (!hours.last) {
                                                                                    return <div id={`${day}-${indexHour}`} className='flex flex-col sm:flex-row gap-2 border-x border-transparent items-start justify-start'>
                                                                                        <LabelInput text={'Início'}>
                                                                                            <InputAutoComplete
                                                                                                width={28}
                                                                                                typeInactive={true}
                                                                                                height={120}
                                                                                                value={timeDays[day]?.hours[indexHour]?.startHour?.toString()?.indexOf('.5') > -1 ? timeDays[day].hours[indexHour]?.startHour?.toString()?.replace('.5', '') + ':30' : timeDays[day].hours[indexHour]?.startHour === '' ? '-:-' : timeDays[day].hours[indexHour]?.startHour + ':00'}
                                                                                                data={hoursPush}
                                                                                                preSelectedValue={timeDays[day]?.hours[indexHour]?.startHour?.toString()?.indexOf('.5') > -1 ? timeDays[day].hours[indexHour]?.startHour?.toString()?.replace('.5', '') + ':30' : timeDays[day].hours[indexHour]?.startHour === '' ? '-:-' : timeDays[day].hours[indexHour]?.startHour + ':00'}
                                                                                                selectedLabel={'description'}
                                                                                                optionList={['description']}
                                                                                                placeHolder=' - '
                                                                                                onChange={value => handleHours('select', value.hour, 'start', day, indexHour)}
                                                                                            />
                                                                                        </LabelInput>
                                                                                        <LabelInput text={'Fim'}>
                                                                                            <InputAutoComplete
                                                                                                width={28}
                                                                                                height={120}
                                                                                                typeInactive={true}
                                                                                                value={timeDays[day].hours[indexHour]?.finishHour?.toString().indexOf('.5') > -1 ? timeDays[day].hours[indexHour]?.finishHour?.toString()?.replace('.5', '') + ':30' : timeDays[day].hours[indexHour]?.finishHour === '' ? '-:-' : timeDays[day].hours[indexHour]?.finishHour + ':00'}
                                                                                                preSelectedValue={timeDays[day]?.hours[indexHour]?.startHour?.toString()?.indexOf('.5') > -1 ? timeDays[day].hours[indexHour]?.startHour?.toString()?.replace('.5', '') + ':30' : timeDays[day].hours[indexHour]?.startHour === '' ? '-:-' : timeDays[day].hours[indexHour]?.startHour + ':00'}
                                                                                                data={hoursEnd}
                                                                                                selectedLabel={'description'}
                                                                                                optionList={['description']}
                                                                                                placeHolder=' - '
                                                                                                onChange={value => handleHours('select', value.hour, 'end', day, indexHour)}
                                                                                            />
                                                                                        </LabelInput>
                                                                                        {
                                                                                            !timeDays[day]?.hours[indexHour]?.isBlocked &&
                                                                                            <LabelInput text={'Incluso'}>
                                                                                                <InputAutoComplete
                                                                                                    width={36}
                                                                                                    height={120}
                                                                                                    typeInactive={true}
                                                                                                    value={timeDays[day]?.hours[indexHour]?.forSpecific ? 'Personalizado' : 'Todas'}
                                                                                                    preSelectedValue={timeDays[day]?.hours[indexHour]?.forSpecific ? 'Personalizado' : 'Todas'}
                                                                                                    data={hourAssociationIncludedList}
                                                                                                    selectedLabel={'description'}
                                                                                                    optionList={['description']}
                                                                                                    placeHolder=' - '
                                                                                                    onChange={(value) => handleAssociationsIncluded(value, day, indexHour)}
                                                                                                />
                                                                                            </LabelInput>
                                                                                        }
                                                                                        {
                                                                                            timeDays[day]?.hours[indexHour]?.forSpecific &&
                                                                                            <LabelInput text={'Associação'}>
                                                                                                <Multiselect
                                                                                                    items={associationsList}
                                                                                                    mult
                                                                                                    index={timeDays[day]?.hours[indexHour]}
                                                                                                    value={timeDays[day]?.hours[indexHour]?.associations?.length > 0 ? timeDays[day]?.hours[indexHour]?.associations : []}
                                                                                                    onChange={(e) => {
                                                                                                        timeDays[day].hours[indexHour].associations = e
                                                                                                        setTimeDays(timeDays)
                                                                                                    }}
                                                                                                    selectedLabel={'name'}
                                                                                                    id={`${day}-${indexHour}-selected-associations`} />
                                                                                            </LabelInput>
                                                                                        }
                                                                                        <LabelInput text={'Bloqueado'}>
                                                                                            <InputCheckBox onClick={(e) => {
                                                                                                handleBlockedHour(day, indexHour, e)
                                                                                            }} value={timeDays[day]?.hours[indexHour].isBlocked ? true : false} />
                                                                                        </LabelInput>

                                                                                        {
                                                                                            timeDays[day].hours[indexHour]?.isBlocked &&
                                                                                            <LabelInput text={'Motivo bloqueio'}>
                                                                                                <Input width={48} type={'text'} charLimit={255} onChange={(e) => {
                                                                                                    timeDays[day].hours[indexHour].blockedDescription = e.target.value
                                                                                                    setTimeDaysRows(Object.keys(timeDays))
                                                                                                }} value={timeDays[day].hours[indexHour].blockedDescription}></Input>
                                                                                            </LabelInput>
                                                                                        }

                                                                                        <div className='flex flex-row gap-1'>
                                                                                            <LabelInput text={'Valor exclusivo'}>
                                                                                                <InputAutoComplete width={40} preSelectedValue={timeDays[day].hours[indexHour]?.isUniqueValue ? 'Sim' : 'Não'} value={timeDays[day].hours[indexHour]?.isUniqueValue?.description} data={questionList} optionList={['description']} selectedLabel={'description'} onChange={(e) => {
                                                                                                    timeDays[day].hours[indexHour].isUniqueValue = e
                                                                                                    setTimeDaysRows(Object.keys(timeDays))
                                                                                                }}></InputAutoComplete>
                                                                                            </LabelInput>
                                                                                            <Tippy content={'Selecione sim, para colocar um valor diferente neste horário, este valor sobrepóe os valores configurados na etapa 2, e este valor será fixo para todas as associações'}
                                                                                                arrow={true}
                                                                                                animation='shift-away'
                                                                                                placement='right'
                                                                                                zIndex={'100'}
                                                                                                delay={100}>
                                                                                                <div className="mr-4">
                                                                                                    <FaInfoCircle />
                                                                                                </div>
                                                                                            </Tippy>
                                                                                        </div>
                                                                                        <div className='flex flex-row gap-1'>
                                                                                            <LabelInput text={'Horário sorteio'}>
                                                                                                <InputAutoComplete width={40}
                                                                                                    preSelectedValue={
                                                                                                        timeDays[day].hours[indexHour]?.isDrawn?.id ? 'Sim' : 'Não'
                                                                                                    }
                                                                                                    value={timeDays[day].hours[indexHour]?.isDrawn?.id ? 'Sim' : 'Não'}
                                                                                                    data={questionList} optionList={['description']}
                                                                                                    selectedLabel={'description'}
                                                                                                    onChange={(e) => {
                                                                                                        timeDays[day].hours[indexHour].isDrawn = e
                                                                                                        setTimeDaysRows(Object.keys(timeDays))
                                                                                                    }} />
                                                                                            </LabelInput>
                                                                                            <Tippy content={'Se marcado sim, este horário será de sorteio, com isso poderão se inscrever diversas pessoas, e deverá ocorrer o sorteio na tela de sorteios'}
                                                                                                arrow={true}
                                                                                                animation='shift-away'
                                                                                                placement='right'
                                                                                                zIndex={'100'}
                                                                                                delay={100}>
                                                                                                <div className="mr-4">
                                                                                                    <FaInfoCircle />
                                                                                                </div>
                                                                                            </Tippy>
                                                                                        </div>
                                                                                        {
                                                                                            (timeDays[day].hours[indexHour]?.isUniqueValue?.id === true || timeDays[day].hours[indexHour]?.isUniqueValue) === true &&
                                                                                            <LabelInput text={'Valor'}>
                                                                                                <CurrencyInput placeHolder={timeDays[day].hours[indexHour]?.isUniqueValue === true ? `R$ ${timeDays[day].hours[indexHour]?.uniqueValue?.toString()?.replace('.', ',')}` : `R$ -`} onChange={(e) => {
                                                                                                    isNaN(e.target.value) ? timeDays[day].hours[indexHour].uniqueValue = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) : ``
                                                                                                    setTimeDaysRows(Object.keys(timeDays))
                                                                                                }}></CurrencyInput>
                                                                                            </LabelInput>
                                                                                        }
                                                                                        <LabelInput text={'Remover'}>
                                                                                            <div className='text-2xl mt-1 cursor-pointer flex flex-col items-center justify-center text-primaryDefaultLight ' onClick={() => handleRemoveHour(day, hours)}>
                                                                                                <FaTrashAlt />
                                                                                            </div>
                                                                                        </LabelInput>
                                                                                    </div>
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>

                                                                    <div className="mb-5 flex flex-col">
                                                                        {
                                                                            timeDays[day].hours.map((hours, indexHour) => {
                                                                                if (hours.last) {
                                                                                    return <div id={`${day}-${indexHour}`} className='flex flex-col sm:flex-row gap-2 border-x border-transparent items-start justify-start'>
                                                                                        <LabelInput text={'Início'}>
                                                                                            <InputAutoComplete
                                                                                                width={28}
                                                                                                typeInactive={true}
                                                                                                height={120}
                                                                                                value={timeDays[day]?.hours[indexHour]?.startHour?.toString()?.indexOf('.5') > -1 ? timeDays[day].hours[indexHour]?.startHour?.toString()?.replace('.5', '') + ':30' : timeDays[day].hours[indexHour]?.startHour === '' ? '-:-' : timeDays[day].hours[indexHour]?.startHour + ':00'}
                                                                                                preSelectedValue={timeDays[day]?.hours[indexHour]?.startHour?.toString()?.indexOf('.5') > -1 ? timeDays[day].hours[indexHour]?.startHour?.toString()?.replace('.5', '') + ':30' : timeDays[day].hours[indexHour]?.startHour === '' ? '-:-' : timeDays[day].hours[indexHour]?.startHour + ':00'}
                                                                                                data={hoursPush}
                                                                                                selectedLabel={'description'}
                                                                                                optionList={['description']}
                                                                                                placeHolder=' - '
                                                                                                onChange={value => handleHours('select', value.hour, 'start', day, indexHour)}
                                                                                            />
                                                                                        </LabelInput>
                                                                                        <LabelInput text={'Fim'}>
                                                                                            <InputAutoComplete
                                                                                                width={28}
                                                                                                height={120}
                                                                                                typeInactive={true}
                                                                                                value={timeDays[day].hours[indexHour]?.finishHour?.toString().indexOf('.5') > -1 ? timeDays[day].hours[indexHour]?.finishHour?.toString()?.replace('.5', '') + ':30' : timeDays[day].hours[indexHour]?.finishHour === '' ? '-:-' : timeDays[day].hours[indexHour]?.finishHour + ':00'}
                                                                                                preSelectedValue={timeDays[day].hours[indexHour]?.finishHour?.toString().indexOf('.5') > -1 ? timeDays[day].hours[indexHour]?.finishHour?.toString()?.replace('.5', '') + ':30' : timeDays[day].hours[indexHour]?.finishHour === '' ? '-:-' : timeDays[day].hours[indexHour]?.finishHour + ':00'}
                                                                                                data={hoursEnd}
                                                                                                selectedLabel={'description'}
                                                                                                optionList={['description']}
                                                                                                placeHolder=' - '
                                                                                                onChange={value => handleHours('select', value.hour, 'end', day, indexHour)}
                                                                                            />
                                                                                        </LabelInput>
                                                                                        <LabelInput text={'Incluso'}>
                                                                                            <InputAutoComplete
                                                                                                width={36}
                                                                                                height={120}
                                                                                                typeInactive={true}
                                                                                                value={timeDays[day]?.hours[indexHour]?.forSpecific ? 'Personalizado' : 'Todas'}
                                                                                                preSelectedValue={timeDays[day]?.hours[indexHour]?.forSpecific ? 'Personalizado' : 'Todas'}
                                                                                                data={hourAssociationIncludedList}
                                                                                                selectedLabel={'description'}
                                                                                                optionList={['description']}
                                                                                                placeHolder=' - '
                                                                                                onChange={(value) => handleAssociationsIncluded(value, day, indexHour)}
                                                                                            />
                                                                                        </LabelInput>
                                                                                        {
                                                                                            timeDays[day]?.hours[indexHour]?.forSpecific &&
                                                                                            <LabelInput text={'Associação'}>

                                                                                                <Multiselect
                                                                                                    items={associationsList}
                                                                                                    mult
                                                                                                    index={timeDays[day]?.hours[indexHour]}
                                                                                                    value={timeDays[day]?.hours[indexHour]?.associations?.length > 0 ? timeDays[day]?.hours[indexHour]?.associations : []}
                                                                                                    onChange={(e) => {
                                                                                                        timeDays[day].hours[indexHour].associations = e
                                                                                                    }}
                                                                                                    selectedLabel={'name'}
                                                                                                    id={`${day}-${indexHour}-selected-associations`} />
                                                                                            </LabelInput>
                                                                                        }
                                                                                        <LabelInput text={'Bloqueado'}>
                                                                                            <InputCheckBox onClick={(e) => {
                                                                                                handleBlockedHour(day, indexHour, e)
                                                                                            }} value={timeDays[day]?.hours[indexHour].isBlocked ? true : false} />
                                                                                        </LabelInput>
                                                                                        {
                                                                                            timeDays[day].hours[indexHour].isBlocked &&
                                                                                            <LabelInput text={'Motivo bloqueio'}>
                                                                                                <Input width={40} type={'text'} charLimit={255} onChange={(e) => {
                                                                                                    timeDays[day].hours[indexHour].blockedDescription = e.target.value
                                                                                                    setTimeDaysRows(Object.keys(timeDays))
                                                                                                }} value={timeDays[day].hours[indexHour].blockedDescription}></Input>
                                                                                            </LabelInput>
                                                                                        }
                                                                                        <div className='flex flex-row gap-1'>

                                                                                            <LabelInput text={'Valor exclusivo'}>
                                                                                                <InputAutoComplete width={40} preSelectedValue={timeDays[day].hours[indexHour]?.isUniqueValue ? 'Sim' : 'Não'} value={timeDays[day].hours[indexHour]?.isUniqueValue?.description} data={questionList} optionList={['description']} selectedLabel={'description'} onChange={(e) => {
                                                                                                    timeDays[day].hours[indexHour].isUniqueValue = e
                                                                                                    setTimeDaysRows(Object.keys(timeDays))
                                                                                                }}></InputAutoComplete>
                                                                                            </LabelInput>
                                                                                            <Tippy content={'Selecione sim, para colocar um valor diferente neste horário, este valor sobrepóe os valores configurados na etapa 2, e este valor será fixo para todas as associações'}
                                                                                                arrow={true}
                                                                                                animation='shift-away'
                                                                                                placement='right'
                                                                                                zIndex={'100'}
                                                                                                delay={100}>
                                                                                                <div className="mr-4">
                                                                                                    <FaInfoCircle />
                                                                                                </div>
                                                                                            </Tippy>
                                                                                        </div>
                                                                                        <div className='flex flex-row gap-1'>
                                                                                            <LabelInput text={'Horário sorteio'}>
                                                                                                <InputAutoComplete width={40}
                                                                                                    preSelectedValue={
                                                                                                        timeDays[day].hours[indexHour]?.isDrawn?.id ? 'Sim' : 'Não'
                                                                                                    }
                                                                                                    value={timeDays[day].hours[indexHour]?.isDrawn?.id ? 'Sim' : 'Não'}
                                                                                                    data={questionList} optionList={['description']}
                                                                                                    selectedLabel={'description'}
                                                                                                    onChange={(e) => {
                                                                                                        timeDays[day].hours[indexHour].isDrawn = e
                                                                                                        setTimeDaysRows(Object.keys(timeDays))
                                                                                                    }} />
                                                                                            </LabelInput>
                                                                                            <Tippy content={'Se marcado sim, este horário será de sorteio, com isso poderão se inscrever diversas pessoas, e deverá ocorrer o sorteio na tela de sorteios'}
                                                                                                arrow={true}
                                                                                                animation='shift-away'
                                                                                                placement='right'
                                                                                                zIndex={'100'}
                                                                                                delay={100}>
                                                                                                <div className="mr-4">
                                                                                                    <FaInfoCircle />
                                                                                                </div>
                                                                                            </Tippy>
                                                                                        </div>
                                                                                        {
                                                                                            (timeDays[day].hours[indexHour]?.isUniqueValue?.id === true || timeDays[day].hours[indexHour]?.isUniqueValue) === true &&
                                                                                            <LabelInput text={'Valor'}>
                                                                                                <CurrencyInput placeHolder={timeDays[day].hours[indexHour]?.isUniqueValue === true ? `R$ ${timeDays[day].hours[indexHour]?.uniqueValue?.toString()?.replace('.', ',')}` : `R$ -`} onChange={(e) => {
                                                                                                    isNaN(e.target.value) ? timeDays[day].hours[indexHour].uniqueValue = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) : ``
                                                                                                    setTimeDaysRows(Object.keys(timeDays))
                                                                                                }}></CurrencyInput>
                                                                                            </LabelInput>
                                                                                        }
                                                                                        <LabelInput text={'Remover'}>
                                                                                            <div className='text-2xl mt-1 cursor-pointer flex flex-col items-center justify-center text-primaryDefaultLight ' onClick={() => handleRemoveHour(day, hours)}>
                                                                                                <FaTrashAlt />
                                                                                            </div>
                                                                                        </LabelInput>
                                                                                    </div>
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <Button onClick={() => {
                                                                    createNewHour(day)
                                                                }}>Adicionar
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Section>
                    }

                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        {
                            activeStep.id === 0 &&
                            <Button shadow={true} approval={false} onClick={() => navigate('/services')}>Voltar</Button>
                        }
                        {
                            activeStep.id > 0 &&
                            <Button shadow={true} approval={false} onClick={() => {
                                filteredSteps.map((thisStep, indexStep) => {
                                    if (thisStep.id === activeStep.id) {
                                        setActiveStep({ id: filteredSteps[indexStep - 1].id })
                                    }
                                })
                            }}>Voltar</Button>
                        }
                        {
                            activeStep.id < filteredSteps[filteredSteps.length - 1].id ?
                                <Button shadow={true} onClick={() => {
                                    !loading &&
                                        filteredSteps.map((thisStep, indexStep) => {
                                            if (thisStep.id === activeStep.id) {
                                                if (validateThisFields(thisStep?.id)?.length > 0) {
                                                    return
                                                }
                                                filteredSteps[indexStep].valid = true
                                                setFilteredSteps(filteredSteps)
                                                setActiveStep({ id: filteredSteps[indexStep + 1].id })
                                            }
                                        })
                                }}>Próximo</Button>
                                :
                                activeStep.id === filteredSteps[filteredSteps.length - 1].id &&
                                <Button module={"schedules:update"} userData={userData} shadow={true} onClick={() => { !loading && handleSubmit() }}>Confirmar</Button>
                        }
                    </div>
                </Footer>
            </Container>
        </>

    )
}