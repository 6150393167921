import React, { useEffect, useState } from 'react'
import { Button } from '../buttons/button.default'


export function Stepper({ steps, children, changeStep, activeStep }) {

    const [thisActiveStep, setThisActiveStep] = useState(activeStep)
    const [thisSteps, setThisSteps] = useState(steps)

    async function alterActiveStep(alterToStep) {

        changeStep(alterToStep)

    }

    useEffect(() => {
        setThisActiveStep(activeStep)
    }, [activeStep])

    useEffect(() => {
        setThisSteps(steps)
    }, [steps])

    return (
        <div className='flex flex-col items-center justify-center w-full '>
            <div className={`justify-center flex flex-row w-full items-center`}>

                {
                    thisSteps.map((step, index) => {
                        return <div className='w-full flex items-center justify-center'>
                            {
                                index !== 0 &&
                                <span className={`border border-t-gray-200 dark:border dark:border-primaryBorderDark w-6 sm:w-12 transition-all duration-200 flex flex-row justify-center items-center gap-1  `}>
                                </span>
                            }
                            <div key={`stepper-${step.id}`} className={`${index === 0 ? 'w-20' : 'w-20'} flex flex-row items-center justify-center`}>
                                <div className='flex flex-col items-center justify-center'>
                                    <a className={`transition-all duration-200 cursor-default rounded-full w-8 h-8 ${step.id === thisActiveStep.id ? 'bg-primaryDefaultLight' : step.valid ? 'bg-green-600' : 'bg-gray-200 dark:bg-thirdDefaultDark'}  text-titleGrayTextLight flex flex-col items-center justify-center`}>{index + 1}</a>
                                    <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-xs'>{step.text}</a>
                                </div>
                            </div>
                        </div>

                    })
                }
            </div>
        </div>

    )
}