export function resizeImageCanvas(image, maxWidth, maxHeight) {
    const canvas = document.createElement('canvas');
  
    let width = image.width;
    let height = image.height;
  
    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
    }
  
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, width, height);
  
    return canvas.toDataURL();
  }
  