import React, { useEffect } from 'react'
import { FaRegFrown } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Button } from '../buttons/button.default'
import { Body } from '../container/Body'
import { Container } from '../container/container'
import { Title } from '../titlePages/title'
import { TitlePage } from '../titlePages/title.page'

export function ReservationFailed() {

    const navigate = useNavigate()

    useEffect(()=> {
        const failedId = localStorage?.getItem('@reservationFailed')
        
        if(!failedId){

            navigate('/')
        }
        localStorage.removeItem('@reservationFailed')
    },[])

    return (
        <Container>
            <TitlePage>
                <Title text={'Falha ao agendar'} />
            </TitlePage>

            <Body>
                <div className='flex flex-col h-96 items-center justify-center w-full gap-2'>
                    <FaRegFrown  className='text-5xl text-red-700 animate-pulse'/>
                    <a className='text-sm text-red-700 font-semibold animate-pulse'>Tempo esgotado</a>
                    <a className='mb-4 text-sm text-titleBlackTextLight'>Clique abaixo para agendar novamente</a>
                    <Button shadow={true} onClick={()=> navigate('/reservation')}>Voltar</Button>
                </div>
            </Body>
        </Container>
    )
}