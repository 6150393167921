import React from 'react'
import { DefaultLoader } from '../components/loaders/defaultLoader'
import { useAuth } from '../contexts/useAuth'
import { Preloader } from '../pages/security/pre-loader'
import { AppRoutes } from './app.routes'
import { LoginRoutes } from './login.routes'
import { TermsOfUseRoutes } from './terms.routes'
import { EmailRoutes } from './email.routes'

export function RedirectRoutes() {

    const { signed, loadingAuth, haveTermsOfUse, dontHaveEmail } = useAuth()

    return (
        <div>
            {
                loadingAuth ?
                    <Preloader />
                    :
                    signed ?
                        haveTermsOfUse ?
                            <TermsOfUseRoutes />
                            :
                            dontHaveEmail ?
                                <EmailRoutes />
                                :
                                <AppRoutes />
                        :
                        <LoginRoutes />
            }
        </div>
    )
}