// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './login.css'
// img
import AgendaFacilLogo from '../../assets/logos/agenda_facil_logo.svg'
import logoADE from '../../assets/logos/logoADE.png'
import LoginLogo from '../../assets/logos/loginLogo.svg'
import bgLogin from '../../assets/default_assets/bg-login.jpg'
import logoSoft from '../../assets/logos/logoSoft.png'
import logoSoftWhite from '../../assets/logos/logoSoftWhite.png'
import logoMailouDev from '../../assets/logos/MailouDevLogo.png'
// contexts
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { useAuth } from '../../contexts/useAuth'
import { useThemeContext } from '../../contexts/themeContext'
//components
import { LoginLoader } from '../../components/loaders/loginLoader'
import { LabelInput } from '../../components/label/label.input'
import { Input } from '../../components/input/input'
import { Button } from '../../components/buttons/button.default'
import { InputAutoComplete } from '../../components/input/input.autocomplete'
//services
import { api } from '../../services/api/api'
import { FaArrowAltCircleLeft, FaQuestionCircle } from 'react-icons/fa'
import { getCustomerAlerts, getCustomersAttendance, getPaymentGateways, getPaymentMethods, getPaymentPublicGateways, getPermissionGroupEspecific, getUserTerms } from '../../services/api/callAPIsFunctions/defaultCalls.api'
//utils
import { mCPF } from '../../utils/validators/cpfMask'
import { removeEspecialChar } from '../../utils/validators/removeEspecialCharacter'
import { colors } from '../../theme/colorsSchema'
import { justNumber } from '../../utils/validators/justNumber'
import { responseError } from '../../utils/responsesFunctions/error.response'
import { validateFields } from '../../utils/form.validator'
import { DefaultLoader } from '../../components/loaders/defaultLoader'
import { LogonLoader } from '../../components/loaders/logonLoader'
import moment from 'moment'

export function Login() {

    const { usersId, userTypeAccess } = useParams()

    const [bgPrimaryLight, setBgPrimaryLight] = useState('#FFFF')
    const [bgSecondaryLight, setBgSecondaryLight] = useState('#ebf1f5')
    const [primaryDefaultLight, setPrimaryDefaultLight] = useState(colors.filter((color) => { if (color.name === 'default') { return color } })[0].primaryColor)
    const [secondaryDefaultLight, setSecondaryDefaultLight] = useState(colors.filter((color) => { if (color.name === 'default') { return color } })[0].primaryColor)
    const [hoverLight, setHoverLight] = useState('#d8e4e8')
    const [titleBlackTextLight, setTitleBlackTextLight] = useState('#303030')
    const [titleGrayTextLight, setTitleGrayTextLight] = useState('#FFFF')
    const [inputPlaceholderLight, setInputPlaceholderLight] = useState('#8F8F8F')
    const [primaryLightSuccess, setPrimaryLightSuccess] = useState('#64B694')
    const [primaryDefaultDark, setPrimaryDefaultDark] = useState(colors.filter((color) => { if (color.name === 'default') { return color } })[0].primaryColor)
    const [secondaryDefaultDark, setSecondaryDefaultDark] = useState(colors.filter((color) => { if (color.name === 'default') { return color } })[0].primaryColor)
    const [thirdDefaultDark, setThirdDefaultDark] = useState('#373e47')
    const [hoverDark, setHoverDark] = useState('#2a2f36')
    const [titleBlackTextDark, setTitleBlackTextDark] = useState('#2a2f36')
    const [titleGrayTextDark, setTitleGrayTextDark] = useState('#C4C4C4')
    const [primaryBorderDark, setPrimaryBorderDark] = useState('#363E48')
    const [secondaryBorderDark, setSecondaryBorderDark] = useState('#444c56')
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const [customers_code, setCustomers_code] = useState('')
    const [customersData, setCustomersData] = useState('')
    const { screenX, screenY } = useScreenSizeContext()
    const { setSigned, userData, setUserData, setCustomersAlerts, haveTermsOfUse, setHaveTermsOfUse, setDontHaveEmail } = useAuth()
    const [signUp, setSignUp] = useState(false)
    const [showSignUp, setShowSignUp] = useState(false)
    const [errorFields, setErrorFields] = useState([])
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [recoverPassword, setRecoverPassword] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [showResendActivateAccount, setShowResendActivateAccount] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showHelp, setShowHelp] = useState(false)
    const [forgetPassword, setForgetPassword] = useState(false)
    const [animateHelp, setAnimateHelp] = useState(false)
    const [animateErrorModal, setAnimateErrorModal] = useState(false)
    const [animateSuccessModal, setAnimateSuccessModal] = useState(false)
    const [clientFinded, setClientFinded] = useState(true)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [user, setUser] = useState('')
    const date = Date.now()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [cpf, setCpf] = useState('')
    const [birthday, setBirthday] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [dddcell, setDddcell] = useState('')
    const [cell, setCell] = useState('')

    function handleErrorModal(status, message) {
        setErrorMessage(message)
    }

    function handleRequiredFields() {

        let errors = false
        if (errorFields.includes('password')) {
            errors = true
            document.getElementById('password').style.border = "1px solid red"
        } else {
            document.getElementById('password').style.border = ""
        }

        if (errorFields.includes('user')) {
            errors = true
            document.getElementById('user').style.border = "1px solid red"
        } else {
            document.getElementById('user').style.border = ""
        }
        return errors
    }

    async function handleLogin() {
        if (user === '') {
            if (!errorFields.includes('user')) {
                errorFields.push('user')
                setErrorFields(errorFields)
            }
        } else {
            errorFields.indexOf('user') > -1 && errorFields.splice(errorFields.indexOf('user'), 1)
            setErrorFields([...errorFields])
        }

        if (password === '') {
            if (!errorFields.includes('password')) {
                errorFields.push('password')
                setErrorFields(errorFields)
            }
        } else {
            errorFields.indexOf('password') > -1 && errorFields.splice(errorFields.indexOf('password'), 1)
            setErrorFields([...errorFields])
        }

        if (handleRequiredFields()) {
            return
        }

        try {
            setLoading(true)
            setShowLoading(true)
            const login = await api.post('/auth/logon', {
                "user": user?.toLocaleLowerCase()?.trim(),
                "password": password,
                customers_code: !customers_code ? undefined : customers_code,
            })

            if (login.data.status === 201) {
                const apiUserData = []
                apiUserData.push(login.data.userData)
                localStorage.setItem('typeUser', 1)
                apiUserData[0].customers_s3_url = login?.data?.userData?.customers_s3_url
                apiUserData[0].photo = login.data.userData?.photo?.length > 0 ? `${login?.data?.userData?.customers_s3_url}/${login.data.userData?.customers_code}/${login.data.userData?.photo}` : undefined
                apiUserData[0].customersData = customersData
                apiUserData[0].token = login.data.token
                apiUserData[0].customers_id = 1

                const paymentGateway = await getPaymentPublicGateways(login.data.token)
                const alerts = await getCustomerAlerts(login.data.token)
                //alertas do sistema
                if (alerts.data.data[0]?.message?.length > 0) {
                    setCustomersAlerts(alerts.data.data)
                }
                apiUserData[0].customersData.hasGateway = paymentGateway.data?.data?.length > 0 ? true : false
                apiUserData[0].customersData.paymentGateway = paymentGateway.data.data?.length > 0 ? paymentGateway.data?.data[0] : []

                localStorage.setItem('@lastLogin', moment().format('YYYY-MM-DD'))
                localStorage.setItem('@auth:token', login.data.token)

                setUserData(apiUserData)
                setSigned(true)

                //leitura dos termos nao lidos
                const terms = await getUserTerms(login.data.token, apiUserData[0]?.id)
                if (terms.data?.data?.length > 0) {
                    setLoading(false)
                    if (apiUserData[0].email?.length === 0) {
                        setDontHaveEmail(true)
                    }
                    return setHaveTermsOfUse(true)
                }
                if (apiUserData[0].email?.length === 0 || !apiUserData[0]?.email) {
                    navigate(`/myProfileEdit/${apiUserData[0]?.id}`)
                    setDontHaveEmail(true)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    setLoading(false)
                    return setShowNotificationModalText('Você não possui e-mail configurado, obrigatório cadastrar uma conta de e-mail para poder utilizar o sistema')
                }else{
                    setDontHaveEmail(false)
                }
                navigate('/')

                //verifica se esta sendo validado o usuario (qrCode carteira digital)
                if (usersId?.length > 0) {
                    Number(userTypeAccess) > 2 ? navigate(`/clientsEdit/${usersId}`) : navigate(`/usersEdit/${usersId}`)
                }
                setLoading(false)
            }
        } catch (error) {
            console.log(error.response.data.message === 'Erro ao realizar logon, você não ativou a sua conta.')
            setShowError(true)
            if (error?.response?.data?.statusCode === 401 && error?.response?.data?.message === 'Usuário não autenticado.') {
                setLoading(false)
                return handleErrorModal(true, 'Seu usuário está desativado, contate o administrador')
            } else if(error.response.data.message === 'Erro ao realizar logon, você não ativou a sua conta.'){
                setLoading(false)
                setShowResendActivateAccount(true)
                return handleErrorModal(true, error?.response?.data?.action)
                
            } 
            else if (error?.response?.data?.statusCode === 401) {
                setLoading(false)
                return handleErrorModal(true, error?.response?.data?.action)
            } else {
                setLoading(false)
                return handleErrorModal(true, error?.response?.data?.message.concat(' ').concat(error?.response?.data?.action))
            }
        }
    }

    async function handleSubmitClient() {

        const fields = [
            { "name": 'firstName', "value": firstName, "required": true, "type": 'string' },
            { "name": 'lastName', "value": lastName, "required": true, "type": 'string' },
            { "name": 'email', "value": email, "required": true, "type": 'string' },
            { "name": 'birthday', "value": birthday, "required": true, "type": 'string' },
            { "name": 'password', "value": password, "required": true, "type": 'string' },
            { "name": 'dddcell', "value": dddcell, "required": true, "type": 'string' },
            { "name": 'cell', "value": cell, "required": true, "type": 'string' },
        ]
        if (customersData?.requireCpf) {
            fields.push({ "name": 'cpf', "value": cpf, "required": true, "type": 'string' })
        }

        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowErrorModal(true)
            return setErrorMessage('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (password !== passwordConfirmation) {
            setShowErrorModal(true)
            return setErrorMessage('Senha e confirmação de senha não estão iguais, veriique')
        }

        setLoading(true)
        try {

            const newClient = await api.post(`/signUp/user`, {
                firstName,
                lastName,
                email: email?.toLocaleLowerCase()?.trim(),
                birthday,
                cpf: cpf.length > 0 ? removeEspecialChar(cpf) : undefined,
                password,
                customers_code,
                dddcell,
                cell,
                customers_code
            })
            setShowSuccessModal(true)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setShowErrorModal(true)
            handleErrorModal(true, responseError(error))
        }
    }

    async function handleRessendActiveEmail(){
        setLoading(true)
        try {
            const data = await api.post(`/api/public/users/resendActivateEmail`,{
                customers_code,
                email: user
            })
            setRecoverPassword(true)
            setForgetPassword(false)
            setShowError(false)
            console.log(data)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const handlePassword = async () => {
        try {

            await api.post(`/auth/forgetPassword`, {
                email: email?.toLowerCase(),
                customers_code
            })
            setEmail('')
            setRecoverPassword(true)
            setForgetPassword(false)

        } catch (error) {
            setErrorMessage('Ocorreu um erro ao alterar a senha, se o problema persistir entre em contato com o suporte')
            setShowErrorModal(true)
        }
    }

    useEffect(() => {
        
        let dotPosition = window.location.hostname.indexOf('.')
        const thisCustomers = window.location.hostname.slice(0, dotPosition)
        setCustomers_code(thisCustomers)

    }, [])

    useEffect(() => {

        setShowNotificationModal(false)
        setLoading(true)
        async function getCustomersData() {
            let dotPosition = window.location.hostname.indexOf('.')
            const thisCustomers = window.location.hostname.slice(0, dotPosition)

            try {
                const customersData = await api.get(`api/public/customers/${thisCustomers}`)
                if (customersData.data.data[0].photo?.length === 0) {
                    setCustomersData({
                        ...customersData?.data?.data[0],
                        photo: ''
                    })
                } else {
                    setCustomersData(customersData?.data?.data[0])
                }

                document.documentElement.style.setProperty('--bgPrimaryLight', bgPrimaryLight)

                if (customersData?.data?.data[0]?.blockColor) {
                    document.documentElement.style.setProperty('--primaryDefaultLight', !customersData?.data?.data[0]?.primaryColor ? primaryDefaultLight : customersData?.data?.data[0]?.primaryColor)
                    document.documentElement.style.setProperty('--secondaryDefaultLight', !customersData?.data?.data[0]?.secundaryColor ? secondaryDefaultLight : customersData?.data?.data[0]?.secundaryColor)

                    setPrimaryDefaultLight(!customersData?.data?.data[0]?.primaryColor ? primaryDefaultLight : customersData?.data?.data[0]?.primaryColor)
                    setSecondaryDefaultLight(!customersData?.data?.data[0]?.secundaryColor ? secondaryDefaultLight : customersData?.data?.data[0]?.secundaryColor)
                } else {
                    if (!localStorage.getItem('primaryDefaultLight')) {
                        document.documentElement.style.setProperty('--primaryDefaultLight', customersData?.data?.data[0]?.primaryColor?.length === 0 ? primaryDefaultLight : customersData?.data?.data[0]?.primaryColor?.length > 0 ? customersData?.data?.data[0]?.primaryColor : primaryDefaultLight)
                        document.documentElement.style.setProperty('--secondaryDefaultLight', customersData?.data?.data[0]?.secundaryColor?.length === 0 ? secondaryDefaultLight : customersData?.data?.data[0]?.secundaryColor?.length > 0 ? customersData?.data?.data[0]?.secundaryColor : secondaryDefaultLight)
                    }
                    else {
                        document.documentElement.style.setProperty('--primaryDefaultLight', localStorage.getItem('primaryDefaultLight'))
                        setPrimaryDefaultLight(localStorage.getItem('primaryDefaultLight'))
                        document.documentElement.style.setProperty('--secondaryDefaultLight', localStorage.getItem('secondaryDefaultLight'))
                        setSecondaryDefaultLight(localStorage.getItem('secondaryDefaultLight'))
                    }
                }
                document.documentElement.style.setProperty('--bgPrimaryLight', bgPrimaryLight)
                document.documentElement.style.setProperty('--bgSecondaryLight', bgSecondaryLight)
                document.documentElement.style.setProperty('--titleGrayTextLight', titleGrayTextLight)
                document.documentElement.style.setProperty('--inputPlaceholderLight', inputPlaceholderLight)
                document.documentElement.style.setProperty('--primaryLightSuccess', primaryLightSuccess)

                //darkColors
                document.documentElement.style.setProperty('--hoverDark', hoverDark)
                document.documentElement.style.setProperty('--titleBlackTextDark', titleBlackTextDark)
                document.documentElement.style.setProperty('--titleGrayTextDark', titleGrayTextDark)
                document.documentElement.style.setProperty('--primaryBorderDark', primaryBorderDark)
                document.documentElement.style.setProperty('--secondaryBorderDark', secondaryBorderDark)
                document.documentElement.style.setProperty('--primaryDefaultDark', '#FFF')
                // document.documentElement.style.setProperty('--secondaryDefaultDark', secondaryDefaultDark)
                setPrimaryDefaultDark(primaryDefaultDark)
                setSecondaryDefaultDark(secondaryDefaultDark)
                setLoading(false)
            } catch (error) {
                window.location.assign('https://www.agendarapido.com')
                setLoading(false)
            }
        }

        getCustomersData()

    }, [])

    useEffect(() => {

        if (signUp) {
            setShowSignUp(true)
        } else {
            setShowSignUp(false)
        }

    }, [signUp])

    useEffect(() => {
        async function changeHelpDisplay() {
            await new Promise(resolve => setTimeout(resolve, 100))
            showHelp ? setAnimateHelp(true) : setAnimateHelp(false)

        }
        changeHelpDisplay()
    }, [showHelp])

    useEffect(() => {
        async function changeHelpDisplay() {
            await new Promise(resolve => setTimeout(resolve, 100))
            showErrorModal ? setAnimateErrorModal(true) : setAnimateErrorModal(false)
        }
        changeHelpDisplay()
    }, [showErrorModal])

    useEffect(() => {
        async function changeHelpDisplay() {
            await new Promise(resolve => setTimeout(resolve, 100))
            showSuccessModal ? setAnimateSuccessModal(true) : setAnimateSuccessModal(false)
        }
        changeHelpDisplay()
    }, [showSuccessModal])

    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }
    if (loading) {
        return <div style={{
            overflow: screenX < 600 ? 'auto' : 'hidden', height: '100vh',
            backgroundImage: `linear-gradient(to bottom, rgba(${hexToRgb(primaryDefaultLight).r},${hexToRgb(primaryDefaultLight).g}, ${hexToRgb(primaryDefaultLight).b}, 0.80), 
        rgba(${hexToRgb(secondaryDefaultLight).r},${hexToRgb(secondaryDefaultLight).g}, ${hexToRgb(secondaryDefaultLight).b}, .85)), url(${bgLogin})`
        }}
            className={`w-full bg-cover flex flex-col bg-primaryDefaultLight 
    items-center justify-end sm:justify-center `}>
            <LogonLoader />
        </div>
    }

    return (

        <div style={{
            overflow: screenX < 600 ? 'hidden' : 'hidden', height: '100vh',
            backgroundImage: `linear-gradient(to bottom, rgba(${hexToRgb(primaryDefaultLight).r},${hexToRgb(primaryDefaultLight).g}, ${hexToRgb(primaryDefaultLight).b}, 0.80), 
                rgba(${hexToRgb(secondaryDefaultLight).r},${hexToRgb(secondaryDefaultLight).g}, ${hexToRgb(secondaryDefaultLight).b}, .85)), url(${bgLogin})`
        }}
            className={`w-full bg-cover flex flex-col bg-primaryDefaultLight items-center justify-end  sm:justify-center `}
        >
            <div className='flex flex-col'>
                <div className={`${showErrorModal ? 'flex' : 'hidden'} w-[100%] h-[100%] ${animateErrorModal ? '' : 'bg-transparent'} transition-all duration-100 z-50 absolute inset-0 boxShadow flex flex-col items-center justify-center`}>
                    <div className={`${animateErrorModal ? 'translate-y-0 opacity-1 bg-bgPrimaryLight' : 'translate-y-10 opacity-0 bg-transparent'} transition-all duration-300  w-96 h-80 shadow-xl shadow-zinc-700 flex flex-col items-center justify-start p-4 rounded-xl absolute z-30`}>
                        <div className='w-full flex flex-row justify-between h-6'>
                            <h2 className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'>Atenção</h2>
                            <button onClick={() => setShowErrorModal(false)} className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'>x</button>
                        </div>
                        <div className='h-[100%] flex flex-col items-center justify-center'>
                            <p className='text-titleBlackTextLight mb-10 text-center'>{errorMessage}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col'>
                <div className={`${showSuccessModal ? 'flex' : 'hidden'} w-[100%] h-[100%] ${animateSuccessModal ? '' : 'bg-transparent'} transition-all duration-100 z-50 absolute inset-0 boxShadow flex flex-col items-center justify-center`}>
                    <div className={`${animateSuccessModal ? 'translate-y-0 opacity-1 bg-bgPrimaryLight' : 'translate-y-10 opacity-0 bg-transparent'} transition-all duration-300  w-96 h-80 shadow-xl shadow-zinc-700 flex flex-col items-center justify-start p-4 rounded-xl absolute z-30`}>
                        <div className='w-full flex flex-row justify-between h-6'>
                            <h2 className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'></h2>
                            <button onClick={() => setShowSuccessModal(false)} className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'>x</button>
                        </div>
                        <div className='h-[100%] flex flex-col items-center justify-center'>
                            <p className='text-titleBlackTextLight mb-10 text-center'>
                                Um e-mail de confirmação foi enviado para o email de cadastro: <a className='text-sm bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight font-bold bg-clip-text text-transparent'>{email}</a>, para efeutar o login, acesse o e-mail e clique no link enviado para ativar sua conta.
                            </p>
                            <button onClick={() => { setShowSignUp(false); setShowSuccessModal(false) }} className='bg-gradient-to-r from-primaryDefaultLight to-secondaryDefaultLight hover:shadow-lg hover:shadow-zinc-400/60 hover:brightness-90 transition-all duration-200 ease-in-out w-40 h-12 text-titleGrayTextLight mt-5 rounded-3xl '>Login</button>
                        </div>
                    </div>
                </div>
                <div className={`${showSuccessModal ? 'flex' : 'hidden'} w-[100%] h-[100%] ${animateSuccessModal ? 'bg-zinc-700' : 'bg-transparent'} transition-all duration-100 opacity-75 z-40 absolute inset-0 boxShadow flex flex-col items-center justify-center`}>
                </div>
            </div>
            {
                clientFinded ?
                    <>
                        <div className='absolute border-t border-bgSecondaryLight dark:border-primaryBorderDark bottom-0 flex flex-row gap-2 items-center justify-center w-full py-px rounded-t-3xl dark:bg-secondaryDefaultDark bg-white' id='logoImage'>
                            <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-xs font-base'>Desenvolvido por</p>
                            <a className='flex dark:hidden' href='https://www.mailoudev.com.br' target='__blank'>
                                <img width={screenX > 1400 ? '100px' : '100px'} src={logoMailouDev}></img>
                            </a>
                            <a className='dark:flex hidden' href='https://www.mailoudev.com.br' target='__blank'>
                                <img width={'80px'} src={logoMailouDev}></img>
                            </a>
                        </div>
                        {
                            showError ?
                                <div id='messageModal' className='px-2 sm:px-0 bg-transparent flex flex-col h-full items-center justify-center'>
                                    <p className='text-lg text-titleGrayTextLight'>Erro ao efetuar login:</p>
                                    <p className='text-lg text-titleGrayTextLight text-center'>{errorMessage}</p>
                                    <div className='mt-10 flex gap-2'>
                                        <Button shadow={true} onClick={() => { setShowError(false); setLoading(false) }}>Voltar</Button>
                                        {
                                            showResendActivateAccount &&
                                            <Button shadow={true} onClick={() => {handleRessendActiveEmail();  setShowError(false); setLoading(false) }}>Reenviar e-mail</Button>
                                        }
                                    </div>
                                </div>
                                :
                                <>
                                    <div className='flex flex-col'>
                                        <div className={`${showHelp ? 'flex' : 'hidden'} w-[100%] h-[100%] ${animateHelp ? '' : 'bg-transparent'} transition-all duration-100 z-50 absolute inset-0 boxShadow flex flex-col items-center justify-center`}>
                                            <div className={`${animateHelp ? 'translate-y-0 opacity-1 bg-bgPrimaryLight' : 'translate-y-10 opacity-0 bg-transparent'} transition-all duration-300  w-96 h-80 shadow-xl shadow-zinc-700 flex flex-col items-center justify-start p-4 rounded-xl absolute z-30`}>
                                                <div className='w-full flex flex-row justify-between h-6'>
                                                    <h2 className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'>Ajuda</h2>
                                                    <button onClick={() => setShowHelp(false)} className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'>x</button>
                                                </div>
                                                <div className='h-[100%] flex flex-col items-center justify-center'>
                                                    <h2 className='text-titleBlackTextLight font-base'>Precisa de ajuda?</h2>
                                                    <p className='text-titleBlackTextLight mb-10'>Entre em contato com 47 3026-6006</p>
                                                    <button className='bg-gradient-to-r from-primaryDefaultLight to-secondaryDefaultLight hover:shadow-lg hover:shadow-zinc-400/60 hover:brightness-90 transition-all duration-200 ease-in-out w-40 h-12 text-titleGrayTextLight mt-5 rounded-3xl '>Chamar Whatsapp</button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${showHelp ? 'flex' : 'hidden'} w-[100%] h-[100%] ${animateHelp ? 'bg-zinc-700' : 'bg-transparent'} transition-all duration-100 opacity-75 z-40 absolute inset-0 boxShadow flex flex-col items-center justify-center`}>
                                        </div>
                                    </div>

                                    {/* <div onClick={() => setShowHelp(!showHelp)} className='absolute bottom-0 right-4 w-16 h-16 rounded-full text-2xl text-titleGrayTextLight cursor-pointer hover:brightness-50' title='Ajuda'>
                                        <FaQuestionCircle />
                                    </div> */}
                                    {

                                        loading ?
                                            <LoginLoader />
                                            :

                                            showSignUp ?
                                                <div className={`animate-[wiggle_1s_ease-in-out] sm:max-h-[650px] bg-white p-10 2xl:p-12 rounded-lg boxShadow overflow-auto`}>
                                                    {
                                                        loading ?
                                                            <DefaultLoader />
                                                            :
                                                            <>
                                                                <div className='flex flex-col w-full items-start justify-center mb-4 2xl:mb-10'>
                                                                    <a className='text-xl font-bold text-secondaryDefaultLight mb-2'>Cadastre-se</a>
                                                                    <p><strong className='text-center sm:text-left text-secondaryDefaultLight'>Funcionários:</strong> Já possuem cadastro, faça o <a onClick={() => setSignUp(false)} className='cursor-pointer text-lg text-primaryDefaultLight'>Login</a>.</p>
                                                                    <p><strong className='text-center text-secondaryDefaultLight'>Comunidade:</strong> Para realizar reservas ou inscrições em atividades, preencha o formulário abaixo para obter acesso.</p>
                                                                </div>
                                                                <div className='flex flex-col items-start justify-start w-full'>
                                                                    <form className='flex flex-col gap-4  items-center justify-center w-full'>
                                                                        <div className='flex flex-col sm:flex-row gap-4 items-center justify-center w-full'>
                                                                            <LabelInput text={'Nome *'}>
                                                                                <Input width={screenX < 1400 ? `350px` : ''} autoFocus={true} id='firstName' value={firstName} type='text' onChange={(e) => setFirstName(e.target.value)}></Input>
                                                                            </LabelInput>
                                                                            <LabelInput text={'Sobrenome *'}>
                                                                                <Input width={screenX < 1400 ? `350px` : ''} id='lastName' value={lastName} type='text' onChange={(e) => setLastName(e.target.value)}></Input>
                                                                            </LabelInput>
                                                                        </div>
                                                                        <div className='flex flex-col sm:flex-row gap-4 items-center justify-center w-full' >
                                                                            <LabelInput text={'E-mail *'}>
                                                                                <Input width={screenX < 1400 ? `350px` : ''} id='email' value={email} type='e-mail' onChange={(e) => setEmail(e.target.value)}></Input>
                                                                            </LabelInput>
                                                                            <LabelInput text={`CPF ${customersData?.requireCpf ? '*' : ''}`}>
                                                                                <Input width={screenX < 1400 ? `350px` : ''} id='cpf' charLimit={14} value={mCPF(cpf)} type='text' onChange={(e) => setCpf(e.target.value)}></Input>
                                                                            </LabelInput>
                                                                        </div>

                                                                        <div className='flex flex-col sm:flex-row gap-1 sm:gap-4 items-center justify-center w-full'>
                                                                            <div className='flex sm:flex-row gap-4'>
                                                                                <LabelInput text={'DDD *'}>
                                                                                    <Input width={12} charLimit={2} id='dddcell' value={dddcell} type='phone' onChange={(e) => setDddcell(justNumber(e.target.value))}></Input>
                                                                                </LabelInput>
                                                                                <LabelInput text={'Celular *'}>
                                                                                    <Input width={screenX < 1400 ? 72 : 80} charLimit={9} id='cell' value={cell} type='phone' onChange={(e) => setCell(justNumber(e.target.value))}></Input>
                                                                                </LabelInput>
                                                                            </div>
                                                                            <div className='flex flex-col sm:flex-row gap-4 items-center justify-center w-full'>
                                                                                <LabelInput text={'Data de nascimento *'}>
                                                                                    <Input width={screenX < 1400 ? `350px` : ''} id='birthday' value={birthday} type='date' onChange={(e) => setBirthday(e.target.value)}></Input>
                                                                                </LabelInput>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex flex-col sm:flex-row gap-4 items-center justify-center w-full'>
                                                                            <LabelInput text={'Senha *'}>
                                                                                <Input width={screenX < 1400 ? `350px` : ''} type='password' charLimit={100} id='password' value={password} onChange={(e) => setPassword(e.target.value)}></Input>
                                                                            </LabelInput>
                                                                            <LabelInput text={'Confirmar senha *'}>
                                                                                <Input width={screenX < 1400 ? `350px` : ''} type='password' charLimit={100} id='passwordConfirmation' value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)}></Input>
                                                                            </LabelInput>
                                                                        </div>
                                                                    </form>
                                                                    <div className='flex flex-row gap-1 mb-20 mt-10 w-full items-center justify-center'>
                                                                        <button type='submit' id='loginButton' className='shadow-lg mt-2 bg-primaryDefaultLight hover:bg-secondaryDefaultLight outline-none focus:bg-secondaryDefaultLight transition-all duration-300 w-[350px] sm:w-64 h-16 sm:h-10 rounded-xl text-white text-base' onClick={() => handleSubmitClient()}>Cadastrar </button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }

                                                </div>
                                                :
                                                <>

                                                    <div className={`animate-[wiggle_1s_ease-in-out] bg-bgPrimaryLight dark:bg-primaryDefaultDark w-full ${screenX > 640 ? 'h-full' : 'h-full'} md:w-[450px] 2xl:w-[500px] md:h-[550px] 2xl:h-[600px] shadow-lg shadow-zinc-600  flex flex-row items-center justify-center sm:rounded-2xl`}>
                                                        <div className='flex flex-col md:w-[400px] 2xl:w-[500px] sm:w-[50%] gap-2 items-center h-full justify-center'>
                                                            <form className='px-6 w-full relative h-full flex flex-col items-center gap-2 justify-center' onSubmit={e => e.preventDefault()}>
                                                                {
                                                                    forgetPassword ?
                                                                        <div className='flex flex-col items-center justify-center gap-4 w-full flex-wrap'>
                                                                            <p className='mb-4 text-2xl text-titleBlackTextLight'><strong>Esqueceu</strong> sua senha?</p>
                                                                            <p className='mb-8 text-titleBlackTextLight text-center'>Enviaremos um e-mail com instruções para redefinir sua senha</p>
                                                                            <div onClick={() => setForgetPassword(false)} className='cursor-pointer hover:brightness-150 absolute top-6 left-0 sm:left-8 text-2xl text-primaryDefaultLight flex flex-col w-full items-start justify-start'>
                                                                                <FaArrowAltCircleLeft />
                                                                            </div>
                                                                            <LabelInput text={'E-mail *'}>
                                                                                <Input width={screenX < 1400 ? 80 : ''} id='email' value={email} type='e-mail' onChange={(e) => setEmail(e.target.value?.toLowerCase())}></Input>
                                                                            </LabelInput>
                                                                            <Button onClick={handlePassword} shadow={true} approval={true}>Redefinir</Button>
                                                                        </div>
                                                                        :
                                                                        recoverPassword ?
                                                                            <div className='animate-wiggle flex flex-col items-center justify-center w-full'>
                                                                                <p className='mb-4 text-2xl text-titleBlackTextLight'><strong>Sucesso</strong>, verifique seu e-mail!</p>
                                                                                <p className='mb-10 text-titleBlackTextLight'></p>
                                                                                <Button shadow={true} onClick={() => { setForgetPassword(false); setRecoverPassword(false) }}>Login</Button>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <div className='flex flex-col items-center justify-center max-h-32 max-w-28 overflow-hidden'>
                                                                                    <img className='mb-5' src={clientFinded ? customersData?.photo?.length > 0 ? customersData?.photo + '?' + date : LoginLogo : LoginLogo} width={screenX < 600 || screenY < 800 ? 250 : 200} />
                                                                                </div>
                                                                                <a className='mb-2 text-xs text-titleBlackTextLight'>* Caso entre com CPF, digite somente os números *</a>
                                                                                <LabelInput text={'Usuário'}>
                                                                                    <Input id='user' autoFocus={true} width={screenX < 760 ? '' : '64'} onChange={(e) => setUser(e.target.value?.toLowerCase())} value={user}></Input>
                                                                                </LabelInput>
                                                                                <LabelInput text={'Senha'}>
                                                                                    <Input id='password' type={'password'} width={screenX < 760 ? '' : '64'} onChange={(e) => setPassword(e.target.value)} value={password}></Input>
                                                                                </LabelInput>
                                                                                <button type='submit' id='loginButton' className='mt-2 bg-primaryDefaultLight hover:bg-secondaryDefaultLight outline-none focus:bg-secondaryDefaultLight transition-all duration-300 w-[350px] mb-2 sm:mb-4 sm:w-64 h-10 sm:h-10 rounded-lg sm:rounded-lg text-white text-base' onClick={() => handleLogin()}>Avançar </button>
                                                                                <p className='text-primaryDefaultLight text-sm underline cursor-pointer' onClick={() => setForgetPassword(true)}>Esqueci minha senha</p>
                                                                                {
                                                                                    customersData?.openToCommunity &&
                                                                                    <p onClick={() => setSignUp(true)} className='text-primaryDefaultLight cursor-pointer underline text-sm'>Não possui conta? crie uma agora</p>
                                                                                }
                                                                                {
                                                                                    customersData?.phone > 0 &&
                                                                                    <div className='flex flex-col absolute bottom-16 sm:bottom-6 items-center justify-center w-full'>
                                                                                        <p className='text-center text-inputPlaceholderLight text-sm sm:text-sm'>{`Problemas com acesso`}</p>
                                                                                        <p className='text-center text-inputPlaceholderLight text-sm sm:text-sm'>{`entre em contato: ${customersData?.dddPhone} - ${customersData?.phone}`}</p>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                }

                                                            </form>
                                                        </div>
                                                    </div>
                                                </>
                                    }
                                </>
                        }
                    </>
                    :
                    <div className='text-lg text-titleBlackTextLight'>Cliente não localizado com este ID</div>
            }

        </div>

    )
}