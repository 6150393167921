import React from "react";
import { FaBitcoin, FaBook, FaCalendar, FaRunning, FaWallet } from "react-icons/fa";
import { useScreenSizeContext } from "../../contexts/screenSizeContext";
import { useNavigate } from "react-router-dom";

export function Reports() {

    const navigate = useNavigate()
    const { screenX, screenY } = useScreenSizeContext()

    return (
        <div className="overflow-x-hidden flex flex-col gap-2 w-full h-full bg-bgSecondaryLight dark:bg-primaryDefaultDark pt-10 px-4" style={{ height: screenX < 640 ? screenY - 67 : 'auto' }}>
            <a className="text-lg text-primaryDefaultLight dark:text-titleGrayTextDark mb-10">Relatórios do sistema</a>
            <div className="flex flex-wrap gap-6 w-full">
                <div style={{ maxHeight: screenY - 200 }} className="animate-[wiggleLeft_.8s_ease-in-out] flex-col flex gap-4 p-2 border-2-l rounded-lg bg-zinc-50 dark:bg-secondaryDefaultDark shadow-xl border-primaryDefaultLight overflow-y-auto py-6 px-8 border-l-4">
                    <a className="text-lg border-l-4 pl-2 border-primaryDefaultLight text-titleBlackTextLight dark:text-titleGrayTextDark mb-2">Reservas</a>
                    <div onClick={() => navigate('/reports/schedules')} className="hover:shadow-borderShadow w-full sm:w-72 h-44 border border-zinc-100 dark:border-primaryBorderDark bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg rounded-lg transition-all duration-200 cursor-pointer">
                        <div className="bg-zinc-100 p-2 flex gap-1 dark:bg-thirdDefaultDark items-center justify-start text-primaryDefaultLight dark:text-titleGrayTextDark">
                            <FaCalendar className="text-xl" />
                            <a className="text-sm">Relatório das reservas</a>
                        </div>
                        <div className="p-2 leading-tight">
                            <a className="text-sm  text-zinc-500 dark:text-titleGrayTextLight">Possibilita a impressão de relatórios de reservas, em PDF e Excel</a>
                        </div>
                    </div>
                    <div onClick={() => navigate('/reports/schedulesPayments')} className="hover:shadow-borderShadow w-full sm:w-72 h-44 border border-zinc-100 dark:border-primaryBorderDark bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg rounded-lg transition-all duration-200 cursor-pointer">
                        <div className="bg-zinc-100 p-2 flex gap-1 dark:bg-thirdDefaultDark items-center justify-start text-primaryDefaultLight dark:text-titleGrayTextDark">
                            <FaWallet className="text-xl" />
                            <a className="text-sm ">Pagamentos de reservas</a>
                        </div>
                        <div className="p-2 leading-tight">
                            <a className="text-sm  text-zinc-500 dark:text-titleGrayTextLight">Possibilita a impressão de relatórios dos pagamentos realizados de reservas, em PDF e Excel</a>
                        </div>
                    </div>
                    <div onClick={() => navigate('/reports/schedulesBlock')} className="hover:shadow-borderShadow w-full sm:w-72 h-44 border border-zinc-100 dark:border-primaryBorderDark bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg rounded-lg transition-all duration-200 cursor-pointer">
                        <div className="bg-zinc-100 p-2 flex gap-1 dark:bg-thirdDefaultDark items-center justify-start text-primaryDefaultLight dark:text-titleGrayTextDark">
                            <FaWallet className="text-xl" />
                            <a className="text-sm ">Espaços bloqueados</a>
                        </div>
                        <div className="p-2 leading-tight">
                            <a className="text-sm  text-zinc-500 dark:text-titleGrayTextLight">Possibilita a impressão de relatórios dos espaços que tem bloqueios cadastrados, em PDF e Excel</a>
                        </div>
                    </div>
                </div>
                <div style={{ maxHeight: screenY - 200 }} className="animate-[wiggleLeft_.8s_ease-in-out] flex-col flex gap-4 p-2 border-2-l rounded-lg bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg border-primaryDefaultLight overflow-x-hidden py-6 px-8 border-l-4">
                    <a className="text-lg border-l-4 pl-2 border-primaryDefaultLight text-titleBlackTextLight dark:text-titleGrayTextDark mb-2">Inscrições</a>
                    <div onClick={() => navigate('/reports/activitiesSubscriptions')} className="hover:shadow-borderShadow w-full sm:w-72 h-44 border border-zinc-100 dark:border-primaryBorderDark bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg rounded-lg transition-all duration-200 cursor-pointer">
                        <div className="bg-zinc-100 p-2 flex gap-1 dark:bg-thirdDefaultDark items-center justify-start text-primaryDefaultLight dark:text-titleGrayTextDark">
                            <FaRunning className="text-xl" />
                            <a className="text-sm">Relatório das inscrições</a>
                        </div>
                        <div className="p-2 leading-tight">
                            <a className="text-sm  text-zinc-500 dark:text-titleGrayTextLight">Possibilita a impressão de relatórios de inscricões, em PDF e Excel</a>
                        </div>
                    </div>
                </div>
                <div style={{ maxHeight: screenY - 200 }} className="animate-[wiggleLeft_.8s_ease-in-out] flex-col flex gap-4 p-2 border-2-l rounded-lg bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg border-primaryDefaultLight overflow-x-hidden py-6 px-8 border-l-4">
                    <a className="text-lg border-l-4 pl-2 border-primaryDefaultLight text-titleBlackTextLight dark:text-titleGrayTextDark mb-2">Pagamentos</a>
                    <div onClick={() => navigate('/reports/paymentsReports')} className="hover:shadow-borderShadow w-full sm:w-72 h-44  dark:border-primaryBorderDark bg-zinc-50 dark:bg-secondaryDefaultDark shadow-lg rounded-lg transition-all duration-200 cursor-pointer">
                        <div className="bg-zinc-100 p-2 flex gap-1 dark:bg-thirdDefaultDark items-center justify-start text-primaryDefaultLight dark:text-titleGrayTextDark">
                            <FaRunning className="text-xl" />
                            <a className="text-sm">Relatório de Pagamentos</a>
                        </div>
                        <div className="p-2 leading-tight">
                            <a className="text-sm  text-zinc-500 dark:text-titleGrayTextLight">Possibilita a impressão de relatórios de pagamentos, neste relatório é centralizado reservas e inscrições, em PDF e Excel</a>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    )
}