import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import { Button } from "../../../components/buttons/button.default";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Footer } from "../../../components/footer/Footer";
import { Input } from "../../../components/input/input";
import { InputCheckBox } from "../../../components/input/input.checkBox";
import { LabelInput } from "../../../components/label/label.input";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import { UsersToFind } from "../../../components/table/usersToFind.table";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
import { api } from "../../../services/api/api";
import { responseError } from "../../../utils/responsesFunctions/error.response";

export function UsersBlockedCreate() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowUniversalModal, setShowNotificationModalSuccess, setShowNotificationModal, setShowNotificationModalText } = useThemeContext()
    const [loading, setLoading] = useState(false)
    const [userBlocked, setUserBlocked] = useState({
        users_id: '',
        schedules: false,
        activitiesSubscription: false,
        reason: '',
        expires_in: ''
    })

    useEffect(() => {
        setShowUniversalModal(false)
    }, [])

    async function handleSubmit() {

        let formattedUserBlock = {
            users_id: userBlocked?.users_id,
            reason: userBlocked.reason,
            expires_in: userBlocked.expires_in,
            schedules: userBlocked.schedules,
            activitiesSubscription: userBlocked.activitiesSubscription
        }

        if(!formattedUserBlock?.schedules && !formattedUserBlock?.activitiesSubscription){
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Obrigatório informar uma das opções: Inscrições | Reservas')

        }

        if(!formattedUserBlock?.expires_in){
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Obrigatório informar uma data de expiração')
        }

        try {
            const blocked = await api.post(`/api/v1/blockedUsers`, formattedUserBlock, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            navigate('/usersBlocked')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const handleSelectUser = (e) => {

        setUserBlocked(prev => ({ ...prev, users_id: e.id, firstName: e.firstName }))
        setShowUniversalModal(false)
    }

    return (
        <>
            <ScreenModal>
                <UsersToFind handleSelect={handleSelectUser} />
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Novo bloqueio'} />
                </TitlePage>
                <Body hasFooter={true}>
                    <div className="flex flex-col gap-4 w-full items-start justify-start px-2 sm:px-0">
                        <div className='p-6 bg-zinc-50 dark:bg-thirdDefaultDark border border-zinc-100 dark:border-secondaryBorderDark flex flex-col items-start justify-start w-full'>
                            <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>Utilize o formuláario abaixo para efetuar bloqueio de clientes no sistema, ao selecionar o cliente <strong>não irá conseguir fazer nova reserva ou inscrição até a data estipulada</strong></p>
                            {/* <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark underline cursor-pointer hover:text-primaryDefaultLight' onClick={() => navigate('/categoriesCreate')}>Criar categoria</p> */}
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2">
                            <a className="text-sm font-bold w-56 dark:text-titleGrayTextDark">Usuário para bloqueio:</a>
                            <a onClick={() => setShowUniversalModal(true)} className="cursor-pointer underline text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{
                                userBlocked?.firstName ? userBlocked?.firstName : 'Selecionar'
                            }</a>
                        </div>
                        {
                            userBlocked?.firstName &&
                            <>
                                <div className="flex flex-col sm:flex-row gap-2">
                                    <a className="text-sm font-bold w-56 dark:text-titleGrayTextDark">Bloqueio para:</a>
                                    <div className="flex flex-row gap-2">
                                        <LabelInput text={'Reservas'}>
                                            <InputCheckBox value={userBlocked?.schedules} onClick={() => setUserBlocked(prev => ({ ...prev, schedules: !prev?.schedules }))} />
                                        </LabelInput>
                                        <LabelInput text={'Inscrições'}>
                                            <InputCheckBox value={userBlocked?.activitiesSubscription} onClick={() => setUserBlocked(prev => ({ ...prev, activitiesSubscription: !prev?.activitiesSubscription }))} />
                                        </LabelInput>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row gap-2">
                                    <a className="text-sm font-bold w-56 dark:text-titleGrayTextDark">Motivo</a>
                                    <div className="flex flex-col items-start justify-start gap-2">
                                        <textarea wrap="hard" value={userBlocked?.reason} onChange={(e) => setUserBlocked(prev => ({ ...prev, reason: e.target.value }))} maxLength={255}
                                            className='p-2 border border-gray-400 border-opacity-75 
                                       h-64
                                       w-96
                                       text-sm
                                       outline-none transition-all duration-200 focus:shadow-borderShadow
                                       dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-'>
                                        </textarea>
                                        <a className="text-xt dark:text-titleGrayTextDark text-titleBlackTextLight">{userBlocked?.reason?.length} de 255</a>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row gap-2">
                                    <a className="text-sm font-bold w-56 dark:text-titleGrayTextDark">Bloqueado até</a>
                                    <LabelInput text={''}>
                                        <Input type={'date'} value={userBlocked?.expires_in} onChange={(e) => setUserBlocked(prev => ({ ...prev, expires_in: e.target.value }))} />
                                    </LabelInput>
                                </div>
                            </>
                        }
                    </div>
                </Body>
                <Footer>
                    <div className="flex flex-row w-full items-center h-full justify-between">
                        <Button shadow={true} approval={false} onClick={() => history.back()} >Voltar</Button>
                        <Button module={"blockedUsers:create"} userData={userData} shadow={true} onClick={() => handleSubmit()} >Salvar</Button>
                    </div>
                </Footer>
            </Container>
        </>
    )
}