import React from "react";
import { LoginLoader } from "../../components/loaders/loginLoader";

export function Preloader() {

    return (

        <div className="h-[100vh] w-[100vw] flex flex-col items-center justify-center bg-bgSecondaryLight">

            <LoginLoader />

        </div>
    )
}