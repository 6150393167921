// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// components
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { LabelInput } from '../../../components/label/label.input'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Toogle } from '../../../components/toogle/Toogle'
import { Footer } from '../../../components/footer/Footer'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
// services 
import { api } from '../../../services/api/api'
import { deleteUserEspecific, getPermissionGroups, getTypeAccess, getUserEspecific, getAssociations } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
//utils
import { validateEmail } from '../../../utils/validators/email.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { mCPF } from '../../../utils/validators/cpfMask'
import { removeEspecialChar } from '../../../utils/validators/removeEspecialCharacter'
import { InputCheckBox } from '../../../components/input/input.checkBox'

export function UserEdit() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [updatedUser, setUpdatedUser] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [showNotification, setShowNotication] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [successData, setSuccessData] = useState(false)
    const [typeAccessList, setTypeAccessList] = useState([])
    const [associationsList, setAssociationsList] = useState([])
    const [permissionGroupsData, setPermissionsGroupsData] = useState([])
    const [thisUserData, setThisUserData] = useState([])
    const [userActive, setUserActive] = useState(true)
    const [userPermitted, setUserPermiited] = useState(userData[0]?.permissions?.includes('users:update') || userData[0]?.typeAccess_id < 3 ? true : false)

    //selectedVariables
    const [selectedUserName, setSelectedUserName] = useState('')
    const [selectedUserSubName, setSelectedUserSubName] = useState('')
    const [selectedUserEmail, setSelectedUserEmail] = useState('')
    const [selectedBirthDay, setSelectedBirthDay,] = useState('')
    const [selectedTypeAccesss, setSelectedTypeAccess] = useState('')
    const [selectedPermissionGroup, setSelectedPermissionGroup] = useState('')
    const [selectedUserGender, setSelectedUserGender] = useState('')
    const [selecteduserCpf, setSelectedUserCpf] = useState('')
    const [selectedDDDCell, setSelectedDDDCell] = useState('')
    const [selectedMobilePhone, setSelectedMobilePhone,] = useState('')
    const [selectedDDDPhone, setSelectedDDDPhone] = useState('')
    const [selectedPhone, setSelectedPhone,] = useState('')
    const [selectedAssociate, setSelectedAssociate] = useState('')
    const [changePassWord, setChangePassword] = useState(false)

    //temporary API
    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        }
    ]

    async function getData() {
        const typeAccessData = await getTypeAccess(userData[0].token)
        if (typeAccessData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(typeAccessData.data)
            return
        }

        setTypeAccessList(typeAccessData.data.filter((type) => {
            if (type?.id === 2 || type?.id === 3) {
                return type
            }
        }))

        const userDataApi = await getUserEspecific(userData[0].token, id)
        if (userDataApi.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(userDataApi.data)
            return
        }

        typeAccessData.data.filter((typeAccess) => {
            if (typeAccess.id === userDataApi.data[0].typeAccess_id) {
                return typeAccess
            }
        })

        const permissionGroupsData = await getPermissionGroups(userData[0].token)
        if (permissionGroupsData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(permissionGroupsData.data)
            return
        }
        setPermissionsGroupsData(permissionGroupsData.data)
        permissionGroupsData.data.filter((permissionGroups) => {
            if (permissionGroups.id === userDataApi.data[0].permissionGroups_id) {
                return permissionGroups
            }
        })

        const associationsGet = await getAssociations(userData[0]?.token, 1)
        if (associationsGet.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(associationsGet.data)
            return
        }

        const thiUserGender = sexTypes.filter((gender) => {
            if (gender.id === userDataApi.data[0].gender) {
                return gender
            }
        })
        setThisUserData(userDataApi.data[0])
        setSelectedUserName(userDataApi.data[0].firstName)
        setSelectedUserSubName(userDataApi.data[0].lastName)
        setSelectedUserEmail(userDataApi.data[0].email)
        setSelectedTypeAccess({ id: userDataApi.data[0]?.typeAccess_id, description: userDataApi.data[0]?.typeAccessDescription })
        setSelectedPermissionGroup({ id: userDataApi.data[0]?.permissionGroups_id, description: userDataApi.data[0]?.permissionGroupsName })
        setSelectedBirthDay(userDataApi.data[0].birthday.indexOf('T') > -1 ? userDataApi.data[0].birthday.slice(0, userDataApi.data[0].birthday.indexOf('T')) : userDataApi.data[0].birthday)
        setSelectedUserGender(thiUserGender[0])
        setSelectedUserCpf(userDataApi.data[0].cpf)
        setSelectedDDDCell(userDataApi.data[0].dddcell)
        setSelectedMobilePhone(userDataApi.data[0].cell)
        setSelectedDDDPhone(userDataApi.data[0].dddphone)
        setSelectedPhone(userDataApi.data[0].phone)
        setUserActive(userDataApi.data[0]?.active)
        setSelectedAssociate({ associations_id: userDataApi.data[0]?.associations_id, associationsName: userDataApi.data[0]?.associationsName })
        setUserPermiited(
            (userData[0]?.permissions?.indexOf('users:update') > -1 || userData[0]?.typeAccess_id < 3)
                && (userData[0]?.typeAccess_id <= userDataApi.data[0]?.typeAccess_id)
                && (userDataApi.data[0]?.id !== userData[0]?.id)
                ? true : false
        )
        setAssociationsList(associationsGet.data)
        setLoading(false)
    }

    useEffect(() => {
        setShowUniversalModal(false)
        setLoading(true)
        getData()

    }, [updatedUser])

    async function handleSubmit() {

        const fields =
            [
                // { "name": 'selectedUserName', "value": selectedUserName, "required": true, "type": 'string' },
                { "name": 'selectedUserSubName', "value": selectedUserSubName, "required": true, "type": 'string' },
                { "name": 'selectedUserEmail', "value": selectedUserEmail, "required": true, "type": 'string' },
                { "name": 'selectedBirthDay', "value": selectedBirthDay, "required": true, "type": 'string' },
                { "name": 'selectedTypeAccesss', "value": selectedTypeAccesss, "required": true, "type": 'string' },
                { "name": 'selectedPermissionGroup', "value": selectedPermissionGroup, "required": selectedTypeAccesss.id === 3 ? true : false, "type": 'object' },

            ]

        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Campos obrigatórios não preenchidos!')
            return setShowNotificationModalSuccess(false)
        }

        if (selectedUserName?.length < 2) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Verifique o nome, deve ser nome inteiro')
        }

        if (selectedUserSubName?.length < 2) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Verifique o sobrenome, deve ser nome inteiro')
        }

        try {

            await api.put(`/api/v1/users/${id}`, {
                firstName: selectedUserName ? selectedUserName : undefined,
                lastName: selectedUserSubName ? selectedUserSubName : undefined,
                birthday: selectedBirthDay ? selectedBirthDay : undefined,
                gender: selectedUserGender ? selectedUserGender.id : undefined,
                dddphone: selectedDDDPhone ? selectedDDDPhone : undefined,
                dddcell: selectedDDDCell ? selectedDDDCell : undefined,
                phone: selectedPhone ? selectedPhone : undefined,
                cell: selectedMobilePhone ? selectedMobilePhone : undefined,
                cpf: selecteduserCpf ? removeEspecialChar(selecteduserCpf) : undefined,
                email: selectedUserEmail ? selectedUserEmail : undefined,
                password: undefined,
                typeAccess_id: selectedTypeAccesss ? selectedTypeAccesss.id : undefined,
                permissionGroups_id: selectedPermissionGroup?.id ? selectedPermissionGroup.id : undefined,
                associations_id: selectedAssociate.id
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            await api.patch(`/api/v1/users/${id}/active/${userActive ? 1 : 0}`, {

            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setShowNotificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/users')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDeleteUser() {

        setLoading(true)
        const deleteUser = await deleteUserEspecific(userData[0]?.token, id)

        if (deleteUser.error) {
            setLoading(false)
            setShowUniversalModal(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText(deleteUser.data)
            return
        }
        setShowNotificationModal(true)
        setShowNotificationModalSuccess(true)
        setShowNotificationModalText('Usuário removido com sucesso!')
        setShowUniversalModal(false)
        navigate('/users')
        setLoading(true)
    }

    async function handleInactiveUser() {

        setUserActive(!userActive)
        try {

            await api.patch(`/api/v1/users/${id}/active/${thisUserData?.active ? 0 : 1}`, {

            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText(`Status alterado com sucesso!`)
            navigate(`/users`)
        } catch (error) {
            setUserActive(!userActive)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleResendPassword() {

        try {
            setShowUniversalModal(false)
            setLoading(true)
            const resendPass = await api.put(`/api/v1/users/${id}/resendAccess`, {

            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setChangePassword(false)        
            setShowNotificationModal(true)
            setShowNotificationModalText(`Senha reenviada com sucesso para o e-mail: ${selectedUserEmail}`)
            setShowNotificationModalSuccess(true)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function handleInactveUserInfo() {

        setShowNotificationModalSuccess(false)
        setShowNotificationModal(true)
        return setShowNotificationModalText('Este usuário está desativado, ative o mesmo para poder editar')
    }

    return (
        <>
            <ScreenModal title={'Exlusão de usuário'} width={400} height={200} >
                {
                    changePassWord ?
                        <div className='w-full h-96 flex flex-col items-center justify-center'>
                            <p className='text-base text-center text-titleBlackTextLight'>Deseja realmente gerar uma nova senha para o usuário {selectedUserName} ?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button id='modalConfirmUsersEdit' shadow={true} onClick={() => handleResendPassword()}>Sim</Button>
                                <Button id='modalCancelUsersEdit' shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                            </div>
                        </div>
                        :
                        <div className='w-full h-96 flex flex-col items-center justify-center'>
                            <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir o usuário {selectedUserName} ?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button id='modalConfirmUsersEdit' shadow={true} onClick={() => handleDeleteUser()}>Sim</Button>
                                <Button id='modalCancelUsersEdit' shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                            </div>
                        </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage >
                    <Title text={`Editar usuário - ${selectedUserName}`} />
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex w-full h-96 flex-col justify-center items-center'>
                                <DefaultLoader></DefaultLoader>
                            </div>
                            :
                            <div className='flex flex-col items-start justify-start w-full'>
                                <form className='flex flex-col items-center justify-center sm:grid sm:grid-cols-1 md:grid-cols-2 gap-4'>
                                    <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                        <Input disabled={!userPermitted && true} charLimit={30} id='selectedUserName' type='text' value={selectedUserName} onChange={(e) => setSelectedUserName(e.target.value)} autoFocus={true}></Input>
                                    </label>
                                    <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Sobrenome *</a>
                                        <Input disabled={!userPermitted && true} charLimit={30} id='selectedUserSubName' type='text' value={selectedUserSubName} onChange={(e) => setSelectedUserSubName(e.target.value)}></Input>
                                    </label>
                                    <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                        <Input disabled={!userPermitted && true} id='selectedUserEmail' type='e-mail' value={selectedUserEmail} onChange={(e) => { validateEmail(e.target.value); setSelectedUserEmail(e.target.value) }}></Input>
                                    </label>
                                    <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-start'>
                                        <a className='text-sm text-inputPlaceholderLight'>Data de nascimento *</a>
                                        <Input disabled={!userPermitted && true} id='selectedBirthDay' value={selectedBirthDay} type={'date'} onChange={(e) => { setSelectedBirthDay(e.target.value) }}></Input>
                                    </label>
                                    <label className='relative w-full sm:w-auto flex flex-col gap-1 items-start justify-start'>
                                        <a className='text-sm text-inputPlaceholderLight'>Tipo de acesso *</a>
                                        <InputAutoComplete
                                            data={typeAccessList}
                                            inactive={userData[0]?.typeAccess_id > 2 || !userPermitted ? true : false}
                                            preSelectedValue={selectedTypeAccesss?.description}
                                            selectedLabel={'description'}
                                            optionList={['description']}
                                            onChange={selectedTypeAccess => setSelectedTypeAccess(selectedTypeAccess)}
                                            id='selectedTypeAccesss'
                                        />
                                    </label>
                                    {
                                        selectedTypeAccesss.id === 3 &&
                                        <label className='relative w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                            <a className='text-sm text-inputPlaceholderLight'>Permissão *</a>
                                            <InputAutoComplete
                                                disabled={!userPermitted && true}
                                                data={permissionGroupsData}
                                                preSelectedValue={selectedPermissionGroup?.description}
                                                selectedLabel={'name'}
                                                optionList={['name']}
                                                onChange={value => { setSelectedPermissionGroup(value) }}
                                                id='selectedPermissionGroup'
                                            />
                                        </label>
                                    }
                                    <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-start'>
                                        <a className='text-sm text-inputPlaceholderLight'>Genêro</a>
                                        <InputAutoComplete
                                            data={sexTypes}
                                            disabled={userPermitted && selectedUserGender?.id === undefined ? false : !userPermitted ? true : false}
                                            preSelectedValue={selectedUserGender?.name}
                                            selectedLabel={'name'}
                                            optionList={['id', 'name']}
                                            placeHolder=' - '
                                            onChange={e => setSelectedUserGender(e)}
                                            id='selectedUserGender'
                                        />
                                    </label>

                                    <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>CPF</a>
                                        <Input disabled={!userPermitted && true} charLimit={14} id='selecteduserCpf' type='text' value={mCPF(selecteduserCpf)} onChange={(e) => setSelectedUserCpf(e.target.value)}></Input>
                                    </label>
                                    <div className='flex flex-col sm:flex-row gap-3'>
                                        <LabelInput text={'DDD'}>
                                            <Input disabled={!userPermitted && true} id='selectedDDDCell' charLimit={2} width={'12'} type={'text'} value={selectedDDDCell} onChange={(e) => setSelectedDDDCell(e.target.value)}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Celular'}>
                                            <Input disabled={!userPermitted && true} id='selectedMobilePhone' charLimit={9} width={'80'} type={'text'} value={selectedMobilePhone} onChange={(e) => setSelectedMobilePhone(e.target.value)}></Input>
                                        </LabelInput>
                                    </div>
                                    <div className='flex flex-col sm:flex-row gap-3'>
                                        <LabelInput text={'DDD'}>
                                            <Input disabled={!userPermitted && true} id='selectedDDDPhone' charLimit={2} width={'12'} type={'text'} onChange={(e) => setSelectedDDDPhone(e.target.value)} value={selectedDDDPhone}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Telefone'}>
                                            <Input disabled={!userPermitted && true} id='selectedPhone' charLimit={8} width={'80'} type={'text'} onChange={(e) => setSelectedPhone(e.target.value)} value={selectedPhone}></Input>
                                        </LabelInput>
                                    </div>
                                    <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Tipo / Associação *</a>
                                        <InputAutoComplete
                                            data={associationsList}
                                            selectedLabel={'name'}
                                            optionList={['id', 'name']}
                                            placeHolder=' - '
                                            preSelectedValue={selectedAssociate.associationsName}
                                            onChange={value => setSelectedAssociate(value)}
                                            id='selectedAssociate'
                                        />
                                    </label>
                                </form>

                                <div className='flex flex-col pl-10 sm:pl-0 items-start justify-center mt-6 gap-2 w-full'>
                                    <p className='text-lg sm:text-sm text-titleBlackTextLight dark:text-titleGrayTextLight'>Ativo</p>
                                    {
                                        userPermitted ?
                                            <Toogle status={thisUserData?.active ? true : false} onClick={(e) => handleInactiveUser(e)}></Toogle>
                                            :
                                            <Toogle status={thisUserData?.active ? true : false} disabled={true} ></Toogle>

                                    }
                                </div>
                                <Tippy content={'Clique para gerar uma nova senha para este usuário. A senha será enviada por e-mail'}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='top'
                                    delay={100}>
                                    {userPermitted &&
                                        <div className='mt-6 flex flex-row gap-1 items-center justify-start'>
                                            <Button onClick={() => {
                                                setChangePassword(true)
                                                setShowUniversalModal(true)
                                            }} shadow={true}>Reenviar senha</Button>
                                        </div>
                                    }
                                </Tippy>
                            </div>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button
                            id='cancelUserEdit'
                            approval={false}
                            shadow={true}
                            onClick={() => { history.back(); setShowNotificationModal(false) }}
                        >Voltar
                        </Button>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            {
                                (thisUserData?.id !== userData[0]?.id && (thisUserData?.typeAccess_id >= userData[0]?.typeAccess_id || (userData[0]?.typeAccess_id < 3 &&
                                    (thisUserData?.typeAccess_id >= userData[0]?.typeAccess_id)))) &&
                                <Button
                                    module={'users:delete'} userData={userData}
                                    id='deleteUserEdit'
                                    approval={false}
                                    shadow={true}
                                    onClick={() => setShowUniversalModal(true)}
                                >Excluir
                                </Button>
                            }
                            {
                                userPermitted &&
                                <Button
                                    id='approvalUserEdit'
                                    approval={true}
                                    shadow={true}
                                    onClick={() => { !userActive ? handleInactveUserInfo() : handleSubmit() }}
                                >Editar usuário
                                </Button>
                            }

                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
}