export const numberInstallments = [
    {id: 0, description: '1x', value: 1},
    {id: 1, description: '1x', value: 2},
    {id: 2, description: '1x', value: 3},
    {id: 3, description: '1x', value: 4},
    {id: 4, description: '1x', value: 5},
    {id: 5, description: '1x', value: 6},
    {id: 6, description: '1x', value: 7},
    {id: 7, description: '1x', value: 8},
    {id: 8, description: '1x', value: 9},
    {id: 9, description: '1x', value: 10},
    {id: 10, description: '1x', value: 11},
    {id: 11, description: '1x', value: 12},
]