import { useEffect, useState } from "react";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { useAuth } from "../../../contexts/useAuth";
import { getCompanies } from "../../../services/api/callAPIsFunctions/defaultCalls.api";
import { useThemeContext } from "../../../contexts/themeContext";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { TableDefault } from "../../../components/table/table.default";
import { Button } from "../../../components/buttons/button.default";
import { useNavigate } from "react-router-dom";

export function Companys() {

    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, showUniversalModal, setShowUniversalModal } = useThemeContext()
    const navigate = useNavigate()
    const [companyList, setCompanyList] = useState([])
    const [loading, setLoading] = useState(true)


    const tableThead = [
        {
            "name": 'Código',
            "original_name": 'id'
        },
        {
            "name": 'Nome',
            "original_name": 'name'
        },
        {
            "name": 'Criado em',
            "original_name": "created_at"
        },

    ]


    async function getData() {

        const companyData = await getCompanies(userData[0].token)
        if (companyData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(companyData.data)
            return
        }
        setCompanyList(companyData.data)
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    function handleEdit(e){

        navigate(`/companiesEdit/${e.id}`)
    }

    return (
        <Container>
            <TitlePage>
                <div className="flex w-full items-center justify-between">
                <Title text={'Empresas'} />
                <Button shadow={true} onClick={()=> navigate('/companiesCreate')}>+ Nova</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                        <div className="h-96 w-full flex items-center justify-center">
                            <DefaultLoader />
                        </div>
                        :
                        <div id='companiesTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                            <TableDefault
                                onClick={(e) => handleEdit(e)}
                                title={tableThead}
                                data={companyList}
                                collumns={["id", "name", "created_at"]}
                            />
                        </div>
                }
            </Body>
        </Container>
    )
}