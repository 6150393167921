//hooks
import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaUserAlt, FaDoorOpen } from 'react-icons/fa'
import { useAuth } from '../../contexts/useAuth'
import { menuItems } from './menuItems'
import { clientMenuItems } from './clientMenuItems'
import styles from './styles/menu.module.css'
import { api } from '../../services/api/api'
//components
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { Button } from '../buttons/button.default'
//contexts
import { useThemeContext } from '../../contexts/themeContext'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { LogonLoader } from '../loaders/logonLoader'
import { getCustomersAttendance } from '../../services/api/callAPIsFunctions/defaultCalls.api'

export function Menu() {

    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [loadingExit, setLoadingExit] = useState(false)
    const [path, setPath] = useState(location.pathname)
    const { userData, setUserData, setSigned } = useAuth()
    let thisMenuItems = userData[0]?.typeAccess_id < 4 ? menuItems : clientMenuItems
    const { screenX } = useScreenSizeContext()
    const { setShowUniversalModal, showNotificationModal, setShowNotificationModal } = useThemeContext()
    const [animateThisModal, setAnimateThisModal] = useState(false)
    const navigate = useNavigate()
    const [showExitModal, setShowExitModal] = useState(false)
    const [showMyProfileInternalModal, setShowMyProfileInternalModal] = useState(false)
    const modalRef = useRef()
    const openModalRef = useRef()
    const [userPic, setUserPic] = useState('')
    const [showMyProfile, setShowMyProfile] = useState(false)
    const [menuHeight, setMenuHeight] = useState('0')
    const [filteredIconsItens, setFilteredIconsItens] = useState(thisMenuItems)
    const [removedMenuItens, setRemovedMenuItens] = useState([])
    const [displayMenuHide, setDisplayMenuHide] = useState('none')
    const [displayCom, setDisplayCom] = useState('flex')
    const [showSubMenu, setShowSubMenu] = useState(false)
    const [menuSubListDisplay, setMenuSubListDisplay] = useState('none')
    const [isToHideMenu, setIsToHideMenu] = useState(true)
    let newFilteredItens = filteredIconsItens
    let removedItens = []
    const navegate = useNavigate()

    useEffect(() => {

        const menu = document.getElementById('menuBar')
        checkWindowSize()
        window.addEventListener('resize', () => checkWindowSize())

        function checkWindowSize() {
            if (menu.getClientRects()[0]?.height < window.innerHeight && window.innerWidth > 640) {
                setMenuHeight('100vh')
                filteredIcons()
            } else if (window.innerWidth > 640) {
                setMenuHeight(`${window.innerHeight}px`)
                filteredIcons()
            } else {
                setMenuHeight(`68px`)
                setRemovedMenuItens([])
                setFilteredIconsItens(thisMenuItems)
            }
        }

        function filteredIcons() {

            const menuHide = document.getElementById('menuHideDiv')

            if (newFilteredItens.length < thisMenuItems.length) {
                menuHide.children[1].style.bottom = '10px'
            } else if (menuHide) {
                menuHide.children[1].style.bottom = ''
            }

            if ((thisMenuItems.length + 1) * 55 <= window.innerHeight) {
                removedItens = []
                newFilteredItens = thisMenuItems
                setRemovedMenuItens(removedItens)
                setFilteredIconsItens(newFilteredItens)
            }
            else if (newFilteredItens.length * 55 >= window.innerHeight) {

                while ((newFilteredItens.length + 1) * 55 >= window.innerHeight) {
                    removedItens.push(newFilteredItens.slice(newFilteredItens.length - 1, newFilteredItens.length)[0])
                    newFilteredItens = newFilteredItens.slice(0, newFilteredItens.length - 1)
                    setRemovedMenuItens(removedItens)
                    setFilteredIconsItens(newFilteredItens)
                }
            }
            else if ((newFilteredItens.length + 1) * 55 < window.innerHeight) {
                if (removedItens[removedItens.length - 1]) {
                    newFilteredItens.push(removedItens[removedItens.length - 1])
                    removedItens = removedItens.slice(0, removedItens.length - 1)
                    setRemovedMenuItens(removedItens)
                    setFilteredIconsItens(newFilteredItens)
                }
            }
        }

        return () => window.removeEventListener('resize', (e) => checkWindowSize(e))

    }, [])

    useEffect(() => {
        if (isToHideMenu) {
            handleStatusMenu(true)
        }
    }, [isToHideMenu])

    useEffect(() => {
        if (!userData[0]?.customersData?.useBalance) {
            thisMenuItems = thisMenuItems.filter(menu => {
                if (menu?.path.indexOf('Balance') === -1) {
                    return menu
                }
            })
        }
        setFilteredIconsItens(thisMenuItems)

        async function getPic() {

            try {

                const response = await api.get('/api/v1/myProfile/picture', {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: 'Bearer ' + userData[0].token
                    }
                })

                setUserPic(URL.createObjectURL(response.data))
                setLoading(false)
            } catch (error) {
                setUserPic('')
                setLoading(false)
            }
        }

        getPic()

    }, [userData])

    useEffect(() => {
        function handler(event) {
            if (!modalRef.current?.contains(event.target) && !openModalRef.current?.contains(event.target)) {
                if (modalRef.current.className.indexOf('hidden') === -1) {
                    setShowMyProfile(false)
                }
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    }, [])

    useEffect(() => {

        async function animateModal(status) {

            await new Promise(resolve => setTimeout(resolve, 60))
            showExitModal ? setAnimateThisModal(true) : setAnimateThisModal(false)
        }

        if (showExitModal) {
            animateModal(true)
        } else {
            animateModal(false)
        }
    }, [showExitModal])

    useEffect(() => {

        async function animateProfile(status) {

            if (status) {
                await new Promise(resolve => setTimeout(resolve, 200))
                setShowMyProfileInternalModal(true)
            } else {
                setShowMyProfileInternalModal(false)
                await new Promise(resolve => setTimeout(resolve, 200))
                setShowMyProfile(false)
            }
        }

        animateProfile(showMyProfile)

    }, [showMyProfile])

    useEffect(() => {
        setPath(location.pathname)
    }, [location])

    async function handleStatusMenu() {

        const ul = document.getElementById('ulHide')?.getAttribute('disabled')

        if (ul === 'true') {
            await new Promise(resolve => setTimeout(resolve, 500))

            setDisplayCom('flex')
            setDisplayMenuHide('none')
        }
    }

    const handleExit = async () => {
        setLoadingExit(true)
        try {

            const logout = await api.post('/auth/logout', {
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            localStorage.removeItem('@auth:token')
            navigate('/')
            setUserData('')
            setSigned(false)
            setLoadingExit(true)

        } catch (error) {
            localStorage.removeItem('@auth:token')
            navigate('/')
            setUserData('')
            setSigned(false)
            setLoadingExit(true)
            setLoadingExit(true)
            console.log(error)
        }

    }

    function changeUlHide(hide) {

        const ul = document.querySelectorAll('#ulHide')

        if (hide) {
            ul[0].setAttribute("disabled", "true");
            return
        }
        ul[0].setAttribute("disabled", "false");
    }

    async function handleMenuSubListDisplay() {
        await new Promise(resolve => setTimeout(resolve, 1000))
        setMenuSubListDisplay('')
    }

    return (
        <>
            {
                showExitModal &&
                <div className='flex flex-col'>
                    <div className={`${showExitModal ? 'flex' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] top-0 h-[100vh] sm:h-full z-[1000] `}>
                        {
                            loadingExit ?
                                <div className='flex flex-col'>
                                    <LogonLoader />
                                </div>
                                :
                                <div className={`${animateThisModal ? 'translate-y-0' : '-translate-y-96'} transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow`}>
                                    <div className='flex flex-row justify-between items-center p-2'>
                                        <h1 className='text-sm text-titleBlackTextLight dark:text-titleGrayTextLight'>Sair do sistema</h1>
                                        <button onClick={() => setShowExitModal(false)} className='bg-gray-300 w-6 h-6 rounded-full'>x</button>
                                    </div>
                                    <div className='flex flex-col items-center justify-center overflow-x-hidden'>
                                        <div className='flex flex-col items-center justify-center w-96 p-4'>
                                            <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm mb-6'>Deseja realmente sair do sistema?</p>
                                            <div className='flex flex-row w-full justify-center gap-4 items-center'>
                                                <Button approval={true} onClick={handleExit} shadow={true}>Sim</Button>
                                                <Button approval={false} onClick={() => setShowExitModal(false)} shadow={true}>Não</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className={`${showExitModal ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-full h-[100vh] sm:h-full z-[103]`}></div>
                </div>
            }
            <div ref={modalRef} className={`${showMyProfile ? 'flex' : 'hidden'} sm:items-baseline items-start justify-center sm:justify-start w-full bottom-20 sm:w-auto fixed top-0 md:top-2 left-0 sm:left-16 z-30 flex-col  `}>
                <div className={`${showMyProfileInternalModal ? 'opacity-1 translate-y-0' : 'translate-y-10 opacity-0 max-h-0'} flex flex-col sm:items-baseline items-start justify-center sm:justify-start  w-full h-full transition-all duration-300 bg-bgPrimaryLight dark:bg-primaryDefaultDark p-6 gap-8 sm:gap-2 boxShadow border border-secondaryDefaultLight dark:border dark:border-thirdDefaultDark sm:h-48`}>
                    <div className='flex flex-col w-full sm:items-start items-start justify-start sm:justify-center '>
                        <h2 className='text-primaryDefaultLight dark:text-titleGrayTextDark mt-2 mb-4 font-bold text-3xl sm:text-base'>{userData[0].firstName}</h2>
                    </div>
                    <div onClick={() => { navigate(`/myProfileEdit/${userData[0]?.id}`); setShowMyProfile(false) }} className='cursor-pointer flex flex-row sm:gap-2 gap-8 w-full text-2xl sm:text-base items-start justify-start hover:brightness-50 duration-200 transition-all text-primaryDefaultLight'>
                        <FaUserAlt />
                        <a className='sm:text-sm text-2xl text-primaryDefaultLight'>Meu perfil</a>
                    </div>
                    <div onClick={() => { setShowExitModal(true); }} title='Sair do sistema' className='text-2xl sm:text-base cursor-pointer flex flex-row sm:gap-2 gap-8 w-full items-start sm:justify-start justicenter hove duration-200 transition-all text-primaryDefaultLight'>
                        <FaDoorOpen />
                        <a className='sm:text-sm text-2xl text-primaryDefaultLight'>Sair</a>
                    </div>
                    <button onClick={() => setShowMyProfile(!showMyProfile)} className='sm:hidden flex flex-col items-center justify-center absolute right-8 top-8 border p-2 border-primaryDefaultLight rounded-full w-10 h-10 text-titleBlackTextLight font-bold bg-primaryDefaultLight'>X</button>
                </div>
            </div>
            <div id='menuBar' style={{ height: menuHeight }} className={`${styles.menuContainer} boxShadow z-[102] sm:boxShadow overflow-x-auto sm:overflow-hidden flex flex-row sm:flex-col gap-2 items-center justify-start sm:w-16 bg-gradient-to-t from-secondaryDefaultLight to-primaryDefaultLight dark:bg-gradient-to-t dark:from-secondaryDefaultDark dark:via-primaryDefaultDark dark:to-secondaryDefaultDark dark:text-titleGrayTextLight dark:border-x dark:border-primaryBorderDark sm:pr-0`}>
                <div ref={openModalRef} onClick={() => { setShowMyProfile(!showMyProfile); setShowNotificationModal(false) }} key={`menu-1-profile`} id={`myProfile`} className={`${userPic?.length > 0 ? 'bg-transparent' : 'bg-primaryDefaultLight dark:bg-secondaryDefaultDark'} w-12 hover:from-secondaryDefaultLight hover:to-primaryDefaultLight p-8 h-full sm:h-auto text-titleGrayTextLight dark:text-primaryDefaultLight flex flex-col gap-2 items-center justify-center text-2xl cursor-pointer md:hover:scale-110 transition-all duration-200 py-2`}>
                    <div className={`${userPic?.length > 0 ? 'bg-transparent' : 'bg-primaryLight dark:bg-secondaryDefaultDark'} h-10 w-10 bg-primaryDefaultLight rounded-full flex flex-col items-center justify-center`}>
                        {
                            userPic?.length > 0 ?
                                <div className='flex flex-col items-center justify-center rounded-full w-10 h-10 overflow-hidden'>
                                    <img src={userPic}></img>
                                </div>
                                :
                                <a className='text-titleGrayTextLight'>{userData[0]?.firstName.slice(0, 1)}</a>
                        }
                    </div>
                </div>
                {
                    !loading &&
                    <>
                        {
                            filteredIconsItens.map((item, index) => {
                                return (
                                    //verifica se tem permissão ao modulo na propriedade "module" do menuItems
                                    !item?.module ||
                                    userData[0]?.customersData?.modules?.map(module => { return module.id }).includes(item?.module)
                                ) &&
                                    (userData[0]?.permissions.filter((userPermission) => {
                                        return item?.permissionsGroup.includes(userPermission)
                                    }).length > 0 || userData[0]?.typeAccess_id != 3 || item.path === '/configuration') ?
                                    screenX > 640 ?
                                        <Tippy key={`${index}-tippy`} content={<span>{item?.name}</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='right'
                                            delay={100}>
                                            <div style={{ background: path === item?.path ? 'var(--secondaryDefaultLight)' : '' }} key={`${item.name}-${index}`} id={`${item.name}-${index}`} onClick={() => { navegate(item.path); setShowNotificationModal(false) }} className='animate-[wiggle_1s_ease-in-out] text-titleGrayTextLight dark:text-primaryDefaultLight flex flex-col items-center justify-center text-2xl cursor-pointer hover:bg-secondaryDefaultLight dark:hover:bg-thirdDefaultDark p-2 rounded-lg transition-all duration-200'>
                                                {item.icon}
                                                <a className='text-xt sm:hidden flex 2xl:text-xs font-light text-titleGrayTextLight dark:to-titleGrayTextDark'>{item.name}</a>
                                            </div>
                                        </Tippy>
                                        :
                                        <div style={{ background: path === item?.path ? 'var(--secondaryDefaultLight)' : '', minWidth: '90px' }} key={`${item.name}-${index}`} id={`${item.name}-${index}`} onClick={() => { navegate(item.path); setShowNotificationModal(false) }}
                                            className='text-titleGrayTextLight dark:text-primaryDefaultLight  flex flex-col 
                                items-center justify-start pt-2 text-xl cursor-pointer 
                                hover:bg-secondaryDefaultLight rounded-lg 
                                transition-all duration-200'>
                                            {item.icon}
                                            <a className='text-xt sm:hidden 2xl:text-xs font-light text-center text-titleGrayTextLight dark:to-titleGrayTextDark'>{item.name}</a>
                                        </div>
                                    :
                                    ''

                            })
                        }
                        {/* {
                            removedMenuItens.length > 0 ? (
                                <div onMouseOver={() => { setDisplayMenuHide('flex'); setDisplayCom('none') }} onMouseLeave={() => handleStatusMenu()} className={`${styles.subMenu} flex flex-col hover:flex-col-reverse justify-center items-center w-full h-12 '`} onClick={() => setShowSubMenu(!showSubMenu)}>
                                    <span style={{ display: displayCom }} className=' text-white font-extrabold cursor-pointer' >...</span>
                                    <div style={{ display: displayMenuHide }} onMouseOver={() => { setIsToHideMenu(false) }} onMouseLeave={() => { handleMenuSubListDisplay() }} id='menuHideDiv' className={`${styles.menuHideDiv} w-full h-36 flex flex-col items-center justify-center '`}>
                                        <a className='text-white font-bold'>...</a>
                                        <ul id='ulHide' onMouseOver={() => changeUlHide(false)} onMouseLeave={() => changeUlHide(true)} className={`${styles.menuHoverEffect} pt-4 pb-4 rounded-lg boxShadow flex flex-col gap-2 items-start justify-center`}>
                                            {
                                                removedMenuItens.map((item) => {
                                                    if (item.type === 'normal') {
                                                        return <li key={item.name} id={item.name} className='flex flex-row pt-2 pb-2 items-center justify-between pl-2 cursor-pointer w-full hover:bg-hoverLight transition-all duration-300' onClick={() => { navegate(item.path); setShowNotificationModal(false) }}>
                                                            <a className='text-titleBlackTextLight text-base 2xl:text-sm font-light'>{item.name}</a>
                                                        </li>
                                                    }
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>) : ''
                        } */}
                    </>
                }

            </div>
        </>

    )
}