import React from "react";
import { Container } from "../../../components/container/container";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { Body } from "../../../components/container/Body";
import { MembershipCard } from "../../../components/membershipCard/membershipCard";
import { Button } from "../../../components/buttons/button.default";
import QRCode from "react-qr-code";

export function MyMembershipCard() {

    return (
        <Container>
            <TitlePage>
                <Title text={'Minha carteirinha de associado'} />
            </TitlePage>
            <Body>
                <div className="flex flex-col items-center sm:items-start gap-8 sm:gap-2 w-full mt-6 sm:mt-0">
                    <a className="text-sm text-center sm:text-left">Apresente o QR code (no celular) caso solicitado ou imprima a carteirinha se desejar</a>
                    <div className="flex sm:hidden w-64 h-64">
                        <QRCode value="Teste"></QRCode>
                    </div>
                    <div className="w-full sm:w-80 h-40 sm:h-44 flex flex-col">
                        <MembershipCard userData={{
                            firstName: 'Rafael',
                            lastName: 'Cezário',
                            cpf: '068.033.999-07',
                            birthday: '30/05/2023'
                        }} />
                    </div>
                    <div className="w-12">
                        <Button shadow={true}>Imprimir</Button>
                    </div>
                </div>
            </Body>
        </Container>
    )
}