export function hourArr(){

    let hoursPush = []
    let totalHours = 0
        while (totalHours < 24) {
            const hourTimeForInput = Array(48).fill(0).map(() => {
                let thisDescription = totalHours.toString().indexOf('.5') > -1 ? totalHours?.toString()?.replace('.5', '') + ':30' : totalHours + ':00'
                hoursPush.push({ 'hour': totalHours, 'description': thisDescription })
                totalHours = totalHours + (1 / 2)
            })
        }

        return hoursPush
}