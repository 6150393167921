import React, { useEffect, useState } from 'react'

export function Toogle({ status, onClick, disabled }) {

    const [thisStatus, setThisStatus] = useState(status)

    useEffect(() => {
        setThisStatus(status)
    }, [status])

    return (
        <div onClick={() => {
            if (!disabled) {
                setThisStatus(!thisStatus)
                onClick(thisStatus)
            }
        }} className={`${disabled ? 'opacity-50' : ''} flex flex-col ${!thisStatus ? 'bg-zinc-300 dark:bg-zinc-600' : 'bg-primaryDefaultLight'} mb-4 sm:mb-0 items-start transition-all duration-200 ${!disabled && 'cursor-pointer'} justify-center h-6 sm:h-6 rounded-full sm:rounded-xl w-12 sm:w-10 shadow-md shadow-zinc-300 dark:shadow-zinc-800`}>
            <div className={`${thisStatus ? 'translate-x-6 sm:translate-x-4' : 'translate-x-0'} w-6 h-6 sm:w-6 sm:h-6 rounded-full bg-bgPrimaryLight border border-primaryDefaultLight transition-all duration-200`}></div>
        </div>
    )
}