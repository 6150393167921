import { HashRouter, Route, Routes } from "react-router-dom";
import { UserTerms } from "../pages/configuration/userTerms/userTerms";
import { MyProfileEdit } from "../pages/configuration/myProfile/myProfile";
import { Menu } from "../components/menu/menu";
import { useScreenSizeContext } from "../contexts/screenSizeContext";

export function EmailRoutes() {

    const { screenX } = useScreenSizeContext()

    return (
        <HashRouter>
            <div className={`${screenX < 640 ? '' : 'h-[100vh]'}  flex flex-col-reverse sm:flex-row justify-between md:justify-items-start overflow-hidden`}>
                <Menu />
                <Routes>
                    <Route path='/' element={<MyProfileEdit />} />
                    <Route path='/*' element={<MyProfileEdit />} />
                </Routes>
            </div>
        </HashRouter>
    )
}