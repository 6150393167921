import React, { useEffect, useState } from 'react'
//contexts
import { useThemeContext } from '../../contexts/themeContext'
import { useAuth } from '../../contexts/useAuth'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
//servies
import { getCategories, getFilteredSchedulesBlockReports, getFilteredSchedulesPaymentsReports, getServices, getServicesByCategoriesId } from '../../services/api/callAPIsFunctions/defaultCalls.api'
import { Container } from '../../components/container/container'
import { TitlePage } from '../../components/titlePages/title.page'
import { Body } from '../../components/container/Body'
import { DefaultLoader } from '../../components/loaders/defaultLoader'
import { LabelInput } from '../../components/label/label.input'
import { Input } from '../../components/input/input'
import Multiselect from '../../components/selects/mult.select'
import { Title } from '../../components/titlePages/title'
import { InputAutoComplete } from '../../components/input/input.autocomplete'
import { Button } from '../../components/buttons/button.default'
import moment from 'moment'
//components


export function SchedulesBlockReport() {

    const { userData } = useAuth()
    const { screenX } = useScreenSizeContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, showUniversalModal, setShowUniversalModal } = useThemeContext()

    const [loading, setLoading] = useState(false)

    const [loadingServices, setLoadingServices] = useState(true)

    const [categoriesList, setCategoriesList] = useState([])
    const [servicesList, setServicesList] = useState([])
    const [filteredServices, setFilteredServices] = useState([])
    const [selectedStartDate, setSelectedStartDate] = useState('')
    const [selectedFinishDate, setSelectedFinishDate] = useState('')
    const [selectedCreatedAt, setsSelectedCreatedAt] = useState('')
    const [selectedCategorie, setSelectedCategorie] = useState([])
    const [selectedServices, setSelectedServices] = useState([])
    const [selectedOrder, setSelectedOrder] = useState({ id: 'date', name: 'Data e Horário' })
    const [selectedReportType, setSelectedReportType] = useState({ id: 0, name: 'Excel', extension: 'xlsx' })

    useEffect(() => {
        getServicesByCategorie(selectedCategorie)
    }, [selectedCategorie])

    useEffect(() => {
        getData()
    }, [])

    async function getServicesByCategorie(categoriesList) {

        setLoadingServices(true)

        const categories_id = categoriesList.map(categorie => {
            return categorie.id
        })

        await new Promise(resolve => setTimeout(resolve, 60))
        setFilteredServices(servicesList.filter(service => {
            if (categories_id.includes(service.categories_id)) {
                return service
            }
        }))
        setLoadingServices(false)
    }

    async function getData() {

        setLoading(true)
        const categoriesListAPI = await getCategories(userData[0]?.token)
        setCategoriesList(categoriesListAPI.data)

        const servicesAPI = await getServices(userData[0]?.token)
        setServicesList(servicesAPI.data)

        setLoading(false)
    }

    async function getReport() {
        if (moment(selectedFinishDate).diff(moment(selectedStartDate), 'days') > 31) {

            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setLoading(false)
            return setShowNotificationModalText('Perído máximo é de 31 dias')
        }
        try {
            setLoading(true)
            const report = await getFilteredSchedulesBlockReports(userData[0]?.token, [
                { original_name: 'startDate', value: selectedStartDate },
                { original_name: 'finishDate', value: selectedFinishDate },
                { original_name: 'categories_id', value: selectedCategorie?.length > 0 ? selectedCategorie : [] },
                { original_name: 'services_id', value: selectedServices?.length > 0 ? selectedServices : [] },
                { original_name: 'created_at', value: selectedCreatedAt },
                { original_name: 'reportType', value: selectedReportType?.name ? selectedReportType.name : '' },
                { original_name: 'orderBy', value: selectedOrder?.id ? selectedOrder?.id: '' },
            ])
            if (report.data.status === 204) {
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText('Nenhum dado encontrado para esta pesquisa')
                setLoading(false)
                return
            }
            setLoading(false)
            await new Promise(resolve => setTimeout(resolve, 100))
            const blob = report.data // Seu Blob recebido
            const blobURL = URL.createObjectURL(blob);

            const downloadLink = document.getElementById('downloadLink');
            downloadLink.href = blobURL;
            downloadLink.download = `relatorio_bloqueios-${moment().format("DD/MM/YYYY")}_
            ${moment().hour()}_${moment().minutes()}_${moment().seconds()}.${selectedReportType?.extension}`;
            downloadLink.click();

            return URL.revokeObjectURL(blobURL);

        } catch (error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(responseError(error))
            setLoading(false)
        }
    }

    return (
        <>
            <Container>
                <TitlePage>
                    <Title text={'Relatório de espaços bloqueados'} />
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className="w-full h-96 flex items-center justify-center">
                                <DefaultLoader />
                            </div>
                            :
                            <div className="flex flex-col gap-2 w-full">
                                <LabelInput text={'Data início'}>
                                    <Input type={'date'} value={selectedStartDate} onChange={(e) => { setSelectedStartDate(e.target.value); setShowNotificationModal(false) }}></Input>
                                </LabelInput>
                                <LabelInput text={'Data fim'}>
                                    <Input type={'date'} value={selectedFinishDate} onChange={(e) => { setSelectedFinishDate(e.target.value); setShowNotificationModal(false) }}></Input>
                                </LabelInput>
                                <div className="flex items-start justify-start` w-full">
                                    <LabelInput text={'Categoria'}>
                                        <Multiselect
                                            width={screenX < 640 ? '80' : ''}
                                            items={categoriesList}
                                            mult
                                            value={selectedCategorie}
                                            onChange={(e) => {
                                                setSelectedCategorie(e)
                                            }}
                                            selectedLabel={'name'}
                                            id={`selectedCategorie`} />
                                    </LabelInput>
                                </div>
                                {
                                    !loadingServices && selectedCategorie?.length > 0 ?
                                        <div className="flex items-start justify-start` w-full">
                                            <LabelInput text={'Espaço'}>
                                                <Multiselect
                                                    width={screenX < 640 ? '[100%]' : ''}
                                                    items={filteredServices}
                                                    mult
                                                    value={selectedServices}
                                                    onChange={(e) => {
                                                        setSelectedServices(e)
                                                    }}
                                                    selectedLabel={'name'}
                                                    id={`selectedServices`} />
                                            </LabelInput>
                                        </div>
                                        :
                                        <div className="flex items-start justify-start` w-full">
                                            <LabelInput text={'Espaço'}>
                                                <Input disabled={true} />
                                            </LabelInput>
                                        </div>
                                }
                                <LabelInput text={'Ordenado por'}>
                                    <InputAutoComplete preSelectedValue={selectedOrder.name} data={[{ id: 'categories', name: 'Categoria' }, { id: 'services', name: 'Serviço/Espaço' }, , { id: 'date', name: 'Data e Horário' }]} optionList={['name']} selectedLabel={'name'} onChange={(e) => setSelectedOrder(e)} />
                                </LabelInput>
                                <LabelInput text={'Tipo de relatório'}>
                                    <InputAutoComplete preSelectedValue={selectedReportType?.name} data={[{ id: 0, name: 'Excel', extension: 'xlsx' }, { id: 1, name: 'PDF', extension: 'pdf' }]} optionList={['name']} selectedLabel={'name'} onChange={(e) => setSelectedReportType(e)} />
                                </LabelInput>
                                <a id="downloadLink"></a>
                                <div className="w-full sm:w-48 mt-6">
                                    <Button onClick={() => getReport()}>Download</Button>
                                </div>
                            </div>
                    }
                </Body>
            </Container>
        </>
    )
}