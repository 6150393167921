import React, { useEffect, useState } from 'react'
import { NotificationModal } from '../modals/notification/notification.modal'
import { useAuth } from '../../contexts/useAuth'

export function TitlePage({ children, setDisplayNotification }) {

    const { userData } = useAuth()
    const { customersAlerts, setCustomersAlerts, setReadCustomersAlerts } = useAuth()
    const [warnMessages, setWarnMessages] = useState([])
    const [alertMessages, setAlertMessages] = useState([])

    async function showAlerts() {
        await new Promise(resolve => setTimeout(resolve, 1000))
        if (customersAlerts?.length > 0 && userData[0]?.typeAccess_id < 4) {
            setWarnMessages(customersAlerts?.filter(alert => alert.type === 'warn'))
            setAlertMessages(customersAlerts?.filter(alert => alert.type === 'alert'))
        }
    }

    useEffect(() => {
        showAlerts()
    }, [customersAlerts])

    const handleCloseAlertMessage = async (message) => {
        const alertElement = document.getElementById('alertMessages')
        alertElement.style.opacity = '0'
        await new Promise(resolve => setTimeout(resolve, 500))
        const newAlerts = alertMessages.filter(remaingMessage => remaingMessage?.id !== message?.id)
        alertElement.style.opacity = '1'
        setAlertMessages(newAlerts)
        if (newAlerts?.length === 0 && warnMessages.length === 0) {
            setCustomersAlerts([])
            setReadCustomersAlerts(true)
        }
    }

    const handleCloseWarnMessage = async (message) => {

        const warnElement = document.getElementById('warnMessages')
        warnElement.style.opacity = '0'
        await new Promise(resolve => setTimeout(resolve, 500))

        const newWarnings = warnMessages.filter(remaingMessage => remaingMessage?.id !== message?.id)
        warnElement.style.opacity = '1'

        setWarnMessages(newWarnings)
        if (newWarnings?.length === 0 && alertMessages?.length === 0) {
            setCustomersAlerts([])
            setReadCustomersAlerts(true)
        }
    }

    return (
        <div className='flex flex-col w-full'>
            <div className='w-96 absolute right-8 top-20 gap-2 flex flex-col'>
                {
                    warnMessages?.length > 0 &&
                    warnMessages.map((message, index) => {
                        if (index === 0) {
                            return <div id='warnMessages' className='animate-[wiggleLeft_.8s_ease-in-out] relative flex flex-col items-center justify-start p-4 transition-all duration-200 dark:bg-zinc-500 bg-orange-100 h-28 rounded-lg shadow-lg z-[99999]'>
                                <button onClick={() => handleCloseWarnMessage(message)} className=' absolute right-2 top-2 flex flex-col items-center justify-center text-xt w-5 h-5 dark:text-titleGrayTextDark text-titleBlackTextLight'>x</button>
                                <div className='w-full flex flex-col items-start justify-start overflow-auto'>
                                    <a className='text-sm dark:text-titleGrayTextDark text-titleBlackTextLight'>{message?.message}</a>
                                </div>
                            </div>
                        }
                    })
                }
                {
                    alertMessages?.length > 0 &&
                    alertMessages.map((message, index) => {
                        if (index === 0) {
                            return <div id='alertMessages' className='animate-[wiggleLeft_.8s_ease-in-out] flex flex-col relative items-center justify-start p-4 transition-all duration-200 dark:bg-zinc-400 bg-yellow-200 h-28 rounded-lg shadow-lg z-[99999]'>
                                <button onClick={() => handleCloseAlertMessage(message)} className=' absolute right-2 top-2 flex flex-col items-center justify-center text-xt w-5 h-5 text-titleBlackTextLight'>x</button>
                                <div className='w-full flex flex-col items-start justify-start overflow-auto'>
                                    <a className='text-sm text-titleBlackTextLight'>{message?.message}</a>
                                </div>
                            </div>
                        }
                    })
                }
            </div>
            <div style={{ minHeight: '60px' }} className='shadow-sm mb-2 bg-bgPrimaryLight border-t border-zinc-200 dark:bg-primaryDefaultDark dark:border-b dark:border-primaryBorderDark flex flex-col items-center justify-center w-full pl-8 pr-8 min-h-14 h-14 2xl:h-20'>
                <NotificationModal>

                </NotificationModal>
                <div className='flex flex-col w-full justify-center items-start max-w-[1950px]'>
                    {children}
                </div>
            </div>
        </div>
    )
}