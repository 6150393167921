import React, { useEffect, useState } from 'react'
import { TooltipBars } from '../../components/toolTip/toolTipBars'
import ApexCharts from 'apexcharts';
//contexts
import { useThemeContext } from '../../contexts/themeContext';
import { checkDarkMode } from '../../utils/validators/checkDarMode';

export function AreaGraph({ data, data2, id, title, bgGradient, bgGradientFirstColor, bgGradientSecondColor, bgColor, }) {

    const { primaryDefaultLight, secondaryDefaultLight, bgSecondaryLight, thirdDefaultDark} = useThemeContext()

    useEffect(() => {

        const dataValue = data.map(apiData => Number(apiData.value))
        const data2Value = data2.map(apiData => Number(apiData.value))
        const namesValue = data.map(apiData => apiData.name)
        const darkModeActive = document.getElementsByClassName('dark')?.length ? true : false

        var options = {
            series: [
                {name: data[0]?.year?.toString(), data: dataValue},{name: data2[0]?.year?.toString(), data: data2Value}],
            chart: {
                height: 350,
                type: 'area',
            },
            plotOptions: {
                bar: {

                    backgroundBarColors: ['#fb00b9'],
                    // color: primaryDefaultLight,
                    borderRadius: 5,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: false,
                formatter: function (val) {
                    return val > 0 ? "R$" + val : 'R$ 0';
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    fontWeight: 'light',
                    colors: darkModeActive ? ['var(--titleBlackTextDark)'] : ['var(--titleGrayTextDark)'] 
                }
            },

            xaxis: {

                categories: ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'],
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    style: {
                        colors: darkModeActive ? 'var(--titleGrayTextDark)' : '' , // Cor da fonte (red)
                      },
                },

                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: primaryDefaultLight,
                            colorTo: secondaryDefaultLight,
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                },
                labels: {
                    style: {
                        colors: darkModeActive ? 'var(--titleGrayTextDark)' : '', // Cor da fonte (red)
                    },
                },
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            colors: [primaryDefaultLight, darkModeActive ? thirdDefaultDark :  bgSecondaryLight],
            // title: {
            //     text: title,
            //     floating: true,
            //     offsetY: 0,
            //     align: 'center',
            //     style: {
            //         color: primaryDefaultLight
            //     }
            // }
        };

        var chart = new ApexCharts(document.querySelector(`#${id}`), options);
        chart.render();

    }, [])

    return (
        <div id={id}>

        </div>
    )
}
