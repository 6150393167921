import React, { useState } from "react";

export function CopyButton({ textToCopy }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
  };

  return (
    <button className="p-2 bg-primaryDefaultLight text-xt text-white w-20 rounded-lg" onClick={handleClick}>
      {isCopied ? "Copiado!" : "Copiar"}
    </button>
  );
}

export default CopyButton;