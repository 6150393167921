import React, { useState, useEffect } from "react";
import { FaCopy, FaInfoCircle, FaQuestion, FaTrash, FaTrashAlt } from "react-icons/fa";
import { v4 as uuid } from 'uuid'
// components
import { InputAutoComplete } from '../../input/input.autocomplete'
import { LabelInput } from '../../label/label.input'
import { Button } from '../../buttons/button.default'
import { InputCheckBox } from '../../input/input.checkBox'
import Multiselect from "../../selects/mult.select";
import CurrencyInput from "../../input/inputCurrencyPtBr";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//class
import { ValidPeriodTime } from '../../../utils/hour/validHourPeriod'
import { Input } from "../../input/input";
import { maskHours } from "../../../utils/mask/maskHours";
import { maskHoursService } from "../../../utils/mask/maskHoursService";

export function DaysModal({ scheduleType, reservationTypeList, associationsList, onChange, showModal, changeModalStatus, changeReservationType, selectedDays, reservationType }) {

    const dayObj = {
        [uuid()]: {
            description: 'Domingo',
            weekDay: 0,
            hours: [

            ]
        },
        [uuid()]: {
            description: 'Segunda',
            weekDay: 1,
            hours: [

            ]
        },
        [uuid()]: {
            description: 'Terça',
            weekDay: 2,
            hours: [

            ]
        },
        [uuid()]: {
            description: 'Quarta',
            weekDay: 3,
            hours: [

            ]
        },
        [uuid()]: {
            description: 'Quinta',
            weekDay: 4,
            hours: [

            ]
        },
        [uuid()]: {
            description: 'Sexta',
            weekDay: 5,
            hours: [

            ]
        },
        [uuid()]: {
            description: 'Sábado',
            weekDay: 6,
            hours: [

            ]
        },
    }
    const [loading, setLoading] = useState(false)
    const [timeDays, setTimeDays] = useState(selectedDays !== undefined ? Object.keys(selectedDays)?.length > 0 ? selectedDays : dayObj : dayObj
    )
    const [timeDaysRows, setTimeDaysRows] = useState(Object.keys(timeDays))
    const [validDaysWithHours, setValidDaysWithHours] = useState([])
    const [thisScheduleType, setThisScheduleType] = useState(scheduleType)
    const [thisReservationTypeList, setReservationTypeList] = useState(reservationTypeList)

    const [daysArr, setDaysArr] = useState([])
    const [hoursPush, setHoursPush] = useState([])
    const [hoursEnd, setHoursEnd] = useState([])
    const [selectedStartHour, setSelectedStartHour] = useState('2')
    const [selectedEndHour, setSelectedEndHour] = useState('3')
    const [thisReservationType, setThisReservationType] = useState({
        "id": 1,
        "value": "H",
        "description": "Horário"
    })

    const hourAssociationIncludedList = [
        { 'id': 1, 'description': 'Todos', 'value': 1 },
        { 'id': 2, 'description': 'Personalizado', 'value': 2 },
    ]

    const questionList = [
        { id: true, description: 'Sim' },
        { id: false, description: 'Não' },
    ]

    useEffect(() => {

        function mapDays() {

            const rows = Object.keys(timeDays)
            let newDaysArray = []

            rows.map((day) => {
                newDaysArray.push({
                    description: timeDays[day]?.description,
                    id: day
                })
            })
            setDaysArr(newDaysArray)
        }
        mapDays()
    }, [])

    useEffect(() => {

        let totalHours = 0
        while (totalHours < 24) {
            const hourTimeForInput = Array(48).fill(0).map(() => {
                let thisDescription = totalHours.toString().indexOf('.5') > -1 ? totalHours?.toString()?.replace('.5', '') + ':30' : totalHours + ':00'
                hoursPush.push({ 'hour': totalHours, 'description': thisDescription })
                totalHours = totalHours + (1 / 2)
            })
        }
        setHoursPush(hoursPush)
        setHoursEnd(hoursPush)
    }, [])

    useEffect(() => {
        setThisScheduleType(scheduleType)
    }, [scheduleType])

    useEffect(() => {

        onChange(timeDays)
        const daysWithHours = Object.keys(timeDays).filter((day) => {

            if (timeDays[day].hours.length > 0) {
                return day
            }
        })

        setValidDaysWithHours(daysWithHours)

    }, [timeDaysRows])

    function formatHoursInput(hour, day, indexHour) {
        if (hour !== undefined) {
            let startStartHour = parseInt(hour?.slice(0, 2))
            let endStartHour = parseInt(hour?.slice(3, 5))
            if (endStartHour >= 30) {
                endStartHour = .5
            } else {
                endStartHour = 0
            }
            return maskHoursService(startStartHour + endStartHour)
        }
    }

    function handleHours(type, hour, selectedHour, id, indexHour) {

        setLoading(true)

        if (selectedHour === 'start') {

            const hourEl = document.getElementById(`${id}-${indexHour}`)
            hourEl.style.border = '1px transparent'

            timeDays[id].hours[indexHour].startHour = hour.target.value
            // timeDays[id].hours[indexHour].finishHour = hour + .5
            setTimeDays(timeDays)
            setTimeDaysRows(Object.keys(timeDays))
            return setLoading(false)

            if (hour > selectedEndHour && hour !== 23.5) {
                //desativado verificação de tempo colidindo devido regras da tuppy
                // const validTimePeriod = new ValidPeriodTime(hour, hour + .5, timeDays[id].hours[indexHour]?.id, timeDays[id]?.hours)
                // validTimePeriod.validTime()
                // if (validTimePeriod.validTime().error) {
                //     alert(validTimePeriod.validTime().message)
                // } else {
                // }
                timeDays[id].hours[indexHour].startHour = hour
                timeDays[id].hours[indexHour].finishHour = hour + .5
                setTimeDays(timeDays)
                setTimeDaysRows(Object.keys(timeDays))
                return setLoading(false)
            }
            if (hour === selectedEndHour || hour !== 23.5) {

                // const validTimePeriod = new ValidPeriodTime(hour, hour + .5, timeDays[id].hours[indexHour]?.id, timeDays[id]?.hours)
                // validTimePeriod.validTime()
                // if (validTimePeriod.validTime().error) {
                //     alert(validTimePeriod.validTime().message)
                // } else {
                // }
                timeDays[id].hours[indexHour].startHour = hour
                timeDays[id].hours[indexHour].finishHour = hour + 0.5
                setTimeDays(timeDays)
                setTimeDaysRows(Object.keys(timeDays))
                return setLoading(false)
            }
            if (hour === 23.5) {
                // const validTimePeriod = new ValidPeriodTime(hour, hour + .5, timeDays[id].hours[indexHour]?.id, timeDays[id]?.hours)
                // validTimePeriod.validTime()
                // if (validTimePeriod.validTime().error) {
                //     alert(validTimePeriod.validTime().message)
                // } else {

                // }
                timeDays[id].hours[indexHour].startHour = hour
                timeDays[id].hours[indexHour].finishHour = hour + 0.5
                setTimeDays(timeDays)
                setTimeDaysRows(Object.keys(timeDays))
                return setLoading(false)
            }
        }

        if (selectedHour === 'end') {

            timeDays[id].hours[indexHour].finisHour = hour.target.value
            // timeDays[id].hours[indexHour].finishHour = hour + .5
            setTimeDays(timeDays)
            setTimeDaysRows(Object.keys(timeDays))
            return setLoading(false)
            // const validTimePeriod = new ValidPeriodTime(timeDays[id].hours[indexHour]?.startHour, hour, timeDays[id].hours[indexHour]?.id, timeDays[id]?.hours)
            // validTimePeriod.validTime()
            // if (validTimePeriod.validTime().error) {
            //     alert(validTimePeriod.validTime().message)
            // } else {

            // }
        }
        if (type === 'full') {
            timeDays[id].hours[indexHour].finishHour = parseInt(hour.hour) + 1 / 2
            timeDays[id].hours[indexHour].startHour = hour.hour
            setTimeDays(timeDays)
            setTimeDaysRows(Object.keys(timeDays))

        } else if (type === 'half') {
            timeDays[id].hours[indexHour].finishHour = parseInt(hour.hour) + 1 / 2
            timeDays[id].hours[indexHour].startHour = hour.hour
            setTimeDays(timeDays)
            setTimeDaysRows(Object.keys(timeDays))
        }
        setLoading(false)
    }

    function orderHoursDay(day) {
        if (timeDays[day]?.hours?.length > 0) {
            timeDays[day].hours.sort(function (a, b) {
                return +(a.startHour < b.startHour) || +(a.startHour === b.startHour) - 1;
            });

            timeDays[day].hours.map((hour) => {
                hour['last'] = false
            })
        }
        setTimeDays(timeDays)
        setTimeDaysRows(Object.keys(timeDays))
    }

    function createNewHour(day) {
        setLoading(true)
        const errors = timeDays[day].hours.filter((hour, indexHour) => {
            if (hour.startHour === '' || hour.finisHour === '') {
                const hourEl = document.getElementById(`${day}-${indexHour}`)
                if (hourEl) {
                    hourEl.style.background = '#f87171'
                }
                return hour
            }
        })

        if (errors?.length > 0) {
            setLoading(false)
            return alert('Preenche os horarios acima')
        }

        timeDays[day].hours.push(
            {
                categories_id: '',
                id: uuid(),
                last: true,
                isBlocked: false,
                blockedDescription: undefined,
                weekDay: timeDays[day].weekDay,
                startHour: '',
                finishHour: '',
                forAll: true,
                isUniqueValue: { id: false, description: "Não" },
                uniqueValue: undefined,
                isDraw: { id: false, description: 'Não' },
                forSpecific: false,
                associations: [
                ],
            }
        )

        setTimeDays(timeDays)
        setTimeDaysRows(Object.keys(timeDays))
        setLoading(false)
    }

    function handleRemoveHour(day, hour) {
        const filtered = timeDays[day].hours.filter((e) => e !== hour)
        timeDays[day].hours = filtered
        orderHoursDay(day)
        setTimeDays(timeDays)
        setTimeDaysRows(Object.keys(timeDays))
    }

    function createNewDay(day) {
        const objDays = timeDays
        objDays[day].active = !timeDays[day].active
        setTimeDays(objDays)
        setTimeDaysRows(Object.keys(objDays))
    }

    function duplicateDay(day, dayToPush) {
        //seleciona o dia para copiar pelo id
        const dayId = day.id
        if (timeDays[dayId]?.hours?.length === 0) {
            return alert(`${timeDays[dayId]?.description} não tem horários configurados`)
        }
        const hoursToDuplicate = []
        //altera o id da hora para um novo id
        timeDays[dayId].hours.map((hours) => {
            hoursToDuplicate.push({ ...hours, id: uuid() })
        })
        const dayDescription = timeDays[dayToPush]?.description
        const weekDay = timeDays[dayToPush].weekDay
        //cria novo objeto formatado
        const newObjDay = {
            'active': true,
            'description': dayDescription,
            'hours': hoursToDuplicate,
            'weekDay': weekDay
        }
        //insere o novo objeto no array de Dias
        timeDays[dayToPush] = newObjDay
        setTimeDays(timeDays)
        setTimeDaysRows(Object.keys(timeDays))
        setLoading(false)
    }

    function handleBlockedHour(day, indexHour, value) {

        const newTimeDays = timeDays

        newTimeDays[day].hours[indexHour].isBlocked = value
        if (!value) {
            newTimeDays[day].hours[indexHour].blockedDescription = undefined
            newTimeDays[day].hours[indexHour].forAll = true
            newTimeDays[day].hours[indexHour].forSpecific = false
        } else {
            newTimeDays[day].hours[indexHour].forAll = false
            newTimeDays[day].hours[indexHour].forSpecific = false
        }
        setTimeDays({ ...newTimeDays })
        setTimeDaysRows(Object.keys(newTimeDays))
    }

    function handleAssociationsIncluded(data, day, hourId) {

        setLoading(true)
        switch (data.id) {
            case 1:
                timeDays[day].hours[hourId].forSpecific = false
                timeDays[day].hours[hourId].forAll = true
                timeDays[day].hours[hourId].associations = []

                setTimeDays(timeDays)
                setTimeDaysRows(Object.keys(timeDays))
                setLoading(false)
                break;
            case 2:
                timeDays[day].hours[hourId].forSpecific = true
                timeDays[day].hours[hourId].forAll = false
                setTimeDays(timeDays)
                setTimeDaysRows(Object.keys(timeDays))
                setLoading(false)
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className={`overflow-hidden flex m-auto left-0 right-0 flex-col top-0 w-full bottom-0`}>
                <div className={`px-1 opacity-1 gap-2 flex flex-col items-start justify-start rounded-lg relative z-[100] bg-bgPrimaryLight dark:bg-secondaryDefaultDark border border-titleGrayTextLight dark:border-thirdDefaultDark p-4 transition-all duration-500`}>
                    <div className='flex flex-row w-full justify-end items-center'>
                    </div>
                    <h2 className='text-lg text-primaryDefaultLight font-semibold'>Parametrização de horário</h2>
                    <div className={`flex overflow-y-auto flex-col w-full items-start justify-start`}>
                        <h2 className='text-lg text-primaryDefaultLight font-semibold mt-6 mb-6'>Selecione os dias</h2>
                        {
                            timeDaysRows.map((day) => {
                                return <div className='flex flex-col items-start w-full justify-start py-4 border-t border-b border-zinc-200 dark:border-thirdDefaultDark'>
                                    <div className='flex flex-row gap-2 items-start justify-start'>
                                        <LabelInput text={timeDays[day].description}>
                                            <InputCheckBox value={timeDays[day].active ? true : false} onClick={() => {
                                                createNewDay(day)
                                            }} />
                                        </LabelInput>
                                        {
                                            timeDays[day]?.hours?.length === 0 && timeDays[day]?.active && daysArr?.length > 0 && validDaysWithHours?.length > 0 &&
                                            <div>
                                                <LabelInput text={'Copiar config'}>
                                                    <InputAutoComplete
                                                        width={28}
                                                        typeInactive={true}
                                                        height={120}
                                                        data={daysArr}
                                                        selectedLabel={'description'}
                                                        optionList={['description']}
                                                        placeHolder=' - '
                                                        onChange={value => duplicateDay(value, day)}
                                                    />
                                                </LabelInput>
                                            </div>
                                        }
                                    </div>
                                    {
                                        timeDays[day].active && !loading &&
                                        <div className='mt-5'>
                                            <div className='flex flex-col mb-5 gap-4'>
                                                <div className='flex flex-col-reverse gap-6'>
                                                    {
                                                        timeDays[day].hours.map((hours, indexHour) => {
                                                            const endHour =
                                                                hoursPush.filter((hours) => {
                                                                    if ((hours.hour > timeDays[day]?.hours[indexHour]?.startHour)) {
                                                                        return hours
                                                                    }
                                                                })
                                                            if (!hours.last) {
                                                                return <div id={`${day}-${indexHour}`} className='flex flex-col sm:flex-row gap-2 border-x border-transparent items-start justify-start'>
                                                                    <LabelInput text={'Início'}>
                                                                        <Input
                                                                            width={20}
                                                                            onBlur={() => {
                                                                                const hour = formatHoursInput(timeDays[day]?.hours[indexHour]?.startHour, day, indexHour)
                                                                                timeDays[day].hours[indexHour].startHour = hour
                                                                                setTimeDays(timeDays)
                                                                                setTimeDaysRows(Object.keys(timeDays))
                                                                            }}
                                                                            value={timeDays[day]?.hours[indexHour]?.startHour}
                                                                            onChange={value => { handleHours('select', value, 'start', day, indexHour); console.log(value) }}
                                                                            type={'time'} />
                                                                    </LabelInput>
                                                                    <LabelInput text={'Fim'}>
                                                                        <Input
                                                                            width={20}
                                                                            onBlur={() => {
                                                                                const hour = formatHoursInput(timeDays[day]?.hours[indexHour]?.finisHour, day, indexHour)
                                                                                timeDays[day].hours[indexHour].finisHour = hour
                                                                                setTimeDays(timeDays)
                                                                                setTimeDaysRows(Object.keys(timeDays))
                                                                            }}
                                                                            value={timeDays[day]?.hours[indexHour]?.finisHour}
                                                                            onChange={value => { handleHours('select', value, 'end', day, indexHour); console.log(value) }}
                                                                            type={'time'} />
                                                                    </LabelInput>
                                                                    <LabelInput text={'Incluso'}>
                                                                        <InputAutoComplete
                                                                            width={36}
                                                                            height={120}
                                                                            typeInactive={true}
                                                                            value={timeDays[day]?.hours[indexHour]?.forSpecific ? 'Personalizado' : 'Todas'}
                                                                            data={hourAssociationIncludedList}
                                                                            selectedLabel={'description'}
                                                                            optionList={['description']}
                                                                            placeHolder=' - '
                                                                            onChange={(value) => handleAssociationsIncluded(value, day, indexHour)}
                                                                        />
                                                                    </LabelInput>
                                                                    {
                                                                        timeDays[day]?.hours[indexHour]?.forSpecific &&
                                                                        <LabelInput text={'Associação'}>
                                                                            <Multiselect
                                                                                items={associationsList}
                                                                                mult
                                                                                index={timeDays[day]?.hours[indexHour]}
                                                                                value={timeDays[day]?.hours[indexHour]?.associations?.length > 0 ? timeDays[day]?.hours[indexHour]?.associations : []}
                                                                                onChange={(e) => {
                                                                                    timeDays[day].hours[indexHour].associations = e
                                                                                    setTimeDays(timeDays)
                                                                                }}
                                                                                selectedLabel={'name'}
                                                                                id={`${day}-${indexHour}-selected-associations`} />
                                                                        </LabelInput>
                                                                    }
                                                                    <LabelInput text={'Bloqueado'}>
                                                                        <InputCheckBox onClick={(e) => {
                                                                            handleBlockedHour(day, indexHour, e)
                                                                        }} value={timeDays[day]?.hours[indexHour].isBlocked ? true : false} />
                                                                    </LabelInput>
                                                                    {
                                                                        timeDays[day].hours[indexHour]?.isBlocked &&
                                                                        <LabelInput text={'Motivo bloqueio'}>
                                                                            <Input width={48} type={'text'} charLimit={255} onChange={(e) => {
                                                                                timeDays[day].hours[indexHour].blockedDescription = e.target.value
                                                                                setTimeDaysRows(Object.keys(timeDays))
                                                                            }} value={timeDays[day].hours[indexHour].blockedDescription}></Input>
                                                                        </LabelInput>
                                                                    }
                                                                    <div className='flex flex-row gap-1'>
                                                                        <LabelInput text={'Valor exclusivo'}>
                                                                            <InputAutoComplete width={40} value={timeDays[day].hours[indexHour]?.isUniqueValue?.description} data={questionList} optionList={['description']} selectedLabel={'description'} onChange={(e) => {
                                                                                timeDays[day].hours[indexHour].isUniqueValue = e
                                                                                setTimeDaysRows(Object.keys(timeDays))
                                                                            }}></InputAutoComplete>
                                                                        </LabelInput>
                                                                        {
                                                                            timeDays[day].hours[indexHour]?.isUniqueValue?.id &&
                                                                            <LabelInput text={'Valor'}>
                                                                                <CurrencyInput placeHolder='R$ 0,00' onChange={(e) => {
                                                                                    timeDays[day].hours[indexHour].uniqueValue = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                                    setTimeDaysRows(Object.keys(timeDays))

                                                                                }}></CurrencyInput>
                                                                            </LabelInput>
                                                                        }
                                                                        <Tippy content={'Selecione sim, para colocar um valor diferente neste horário, este valor sobrepóe os valores configurados na etapa 2, e este valor será fixo para todas as associações'}
                                                                            arrow={true}
                                                                            animation='shift-away'
                                                                            placement='right'
                                                                            zIndex={'100'}
                                                                            delay={100}>
                                                                            <div className="mr-4">
                                                                                <FaInfoCircle />
                                                                            </div>
                                                                        </Tippy>
                                                                    </div>
                                                                    <div className='flex flex-row gap-1'>
                                                                        <LabelInput text={'Horário sorteio'}>
                                                                            <InputAutoComplete width={40} value={timeDays[day].hours[indexHour]?.isDraw?.id ? 'Sim' : 'Não'} data={questionList} optionList={['description']} selectedLabel={'description'} onChange={(e) => {
                                                                                timeDays[day].hours[indexHour].isDraw = e
                                                                                setTimeDaysRows(Object.keys(timeDays))
                                                                            }}></InputAutoComplete>
                                                                        </LabelInput>
                                                                        <Tippy content={'Se marcado sim, este horário será de sorteio, com isso poderão se inscrever diversas pessoas, e deverá ocorrer o sorteio na tela de sorteios'}
                                                                            arrow={true}
                                                                            animation='shift-away'
                                                                            placement='right'
                                                                            zIndex={'100'}
                                                                            delay={100}>
                                                                            <div className="mr-4">
                                                                                <FaInfoCircle />
                                                                            </div>
                                                                        </Tippy>
                                                                    </div>

                                                                    <LabelInput text={'Remover'}>
                                                                        <div className='text-2xl mt-1 cursor-pointer flex flex-col items-center justify-center text-primaryDefaultLight ' onClick={() => handleRemoveHour(day, hours)}>
                                                                            <FaTrashAlt />
                                                                        </div>
                                                                    </LabelInput>
                                                                </div>
                                                            }
                                                        })
                                                    }
                                                </div>

                                                <div className="mb-5 flex flex-col">
                                                    {
                                                        timeDays[day].hours.map((hours, indexHour) => {
                                                            const endHour =
                                                                hoursPush.filter((hours) => {
                                                                    return hours
                                                                })
                                                            if (hours.last) {
                                                                return <div id={`${day}-${indexHour}`} className='flex flex-col sm:flex-row gap-2 border-x border-transparent items-start justify-start'>
                                                                    <LabelInput text={'Início'}>
                                                                        <Input
                                                                            width={20}
                                                                            onBlur={() => {
                                                                                const hour = formatHoursInput(timeDays[day]?.hours[indexHour]?.startHour, day, indexHour)
                                                                                timeDays[day].hours[indexHour].startHour = hour
                                                                                setTimeDays(timeDays)
                                                                                setTimeDaysRows(Object.keys(timeDays))
                                                                            }}
                                                                            value={timeDays[day]?.hours[indexHour]?.startHour}
                                                                            onChange={value => { handleHours('select', value, 'start', day, indexHour); console.log(value) }}
                                                                            type={'time'} />
                                                                    </LabelInput>
                                                                    <LabelInput text={'Fim'}>
                                                                        <Input
                                                                            width={20}
                                                                            onBlur={() => {
                                                                                const hour = formatHoursInput(timeDays[day]?.hours[indexHour]?.finisHour, day, indexHour)
                                                                                timeDays[day].hours[indexHour].finisHour = hour
                                                                                setTimeDays(timeDays)
                                                                                setTimeDaysRows(Object.keys(timeDays))
                                                                            }}
                                                                            value={timeDays[day]?.hours[indexHour]?.finisHour}
                                                                            onChange={value => { handleHours('select', value, 'end', day, indexHour); console.log(value) }}
                                                                            type={'time'} />
                                                                    </LabelInput>
                                                                    <LabelInput text={'Incluso'}>
                                                                        <InputAutoComplete
                                                                            width={36}
                                                                            height={120}
                                                                            typeInactive={true}
                                                                            value={timeDays[day]?.hours[indexHour]?.forSpecific ? 'Personalizado' : 'Todas'}
                                                                            data={hourAssociationIncludedList}
                                                                            selectedLabel={'description'}
                                                                            optionList={['description']}
                                                                            placeHolder=' - '
                                                                            onChange={(value) => handleAssociationsIncluded(value, day, indexHour)}
                                                                        />
                                                                    </LabelInput>
                                                                    {
                                                                        timeDays[day]?.hours[indexHour]?.forSpecific &&
                                                                        <LabelInput text={'Associação'}>
                                                                            <Multiselect
                                                                                items={associationsList}
                                                                                mult
                                                                                index={timeDays[day]?.hours[indexHour]}
                                                                                value={timeDays[day]?.hours[indexHour]?.associations?.length > 0 ? timeDays[day]?.hours[indexHour]?.associations : []}
                                                                                onChange={(e) => {
                                                                                    timeDays[day].hours[indexHour].associations = e
                                                                                }}
                                                                                selectedLabel={'name'}
                                                                                id={`${day}-${indexHour}-selected-associations`} />
                                                                        </LabelInput>
                                                                    }
                                                                    <LabelInput text={'Bloqueado'}>
                                                                        <InputCheckBox onClick={(e) => {
                                                                            handleBlockedHour(day, indexHour, e)
                                                                        }} value={timeDays[day]?.hours[indexHour].isBlocked ? true : false} />
                                                                    </LabelInput>
                                                                    {
                                                                        timeDays[day].hours[indexHour].isBlocked &&
                                                                        <LabelInput text={'Motivo bloqueio'}>
                                                                            <Input width={40} type={'text'} charLimit={255} onChange={(e) => {
                                                                                timeDays[day].hours[indexHour].blockedDescription = e.target.value
                                                                                setTimeDaysRows(Object.keys(timeDays))
                                                                            }} value={timeDays[day].hours[indexHour].blockedDescription}></Input>
                                                                        </LabelInput>
                                                                    }

                                                                    <div className='flex flex-row gap-1'>
                                                                        <LabelInput text={'Valor exclusivo'}>
                                                                            <InputAutoComplete width={40} value={timeDays[day].hours[indexHour]?.isUniqueValue?.description} data={questionList} optionList={['description']} selectedLabel={'description'} onChange={(e) => {
                                                                                timeDays[day].hours[indexHour].isUniqueValue = e
                                                                                setTimeDaysRows(Object.keys(timeDays))
                                                                            }}></InputAutoComplete>
                                                                        </LabelInput>
                                                                        <Tippy content={'Selecione sim, para colocar um valor diferente neste horário, este valor sobrepóe os valores configurados na etapa 2, e este valor será fixo para todas as associações'}
                                                                            arrow={true}
                                                                            animation='shift-away'
                                                                            placement='right'
                                                                            zIndex={'100'}
                                                                            delay={100}>
                                                                            <div className="mr-4">
                                                                                <FaInfoCircle />
                                                                            </div>
                                                                        </Tippy>
                                                                    </div>
                                                                    {
                                                                        timeDays[day].hours[indexHour]?.isUniqueValue?.id &&
                                                                        <LabelInput text={'Valor'}>
                                                                            <CurrencyInput placeHolder='R$ 0,00' onChange={(e) => {
                                                                                timeDays[day].hours[indexHour].uniqueValue = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                                setTimeDaysRows(Object.keys(timeDays))
                                                                            }}></CurrencyInput>
                                                                        </LabelInput>
                                                                    }
                                                                    <div className='flex flex-row gap-1'>
                                                                        <LabelInput text={'Horário sorteio'}>
                                                                            <InputAutoComplete width={40} value={timeDays[day].hours[indexHour]?.isDraw?.id ? 'Sim' : 'Não'} data={questionList} optionList={['description']} selectedLabel={'description'} onChange={(e) => {
                                                                                timeDays[day].hours[indexHour].isDraw = e
                                                                                setTimeDaysRows(Object.keys(timeDays))
                                                                            }}></InputAutoComplete>
                                                                        </LabelInput>
                                                                        <Tippy content={'Se marcado sim, este horário será de sorteio, com isso poderão se inscrever diversas pessoas, e deverá ocorrer o sorteio na tela de sorteios'}
                                                                            arrow={true}
                                                                            animation='shift-away'
                                                                            placement='right'
                                                                            zIndex={'100'}
                                                                            delay={100}>
                                                                            <div className="mr-4">
                                                                                <FaInfoCircle />
                                                                            </div>
                                                                        </Tippy>
                                                                    </div>
                                                                    <LabelInput text={'Remover'}>
                                                                        <div className='text-2xl mt-1 cursor-pointer flex flex-col items-center justify-center text-primaryDefaultLight ' onClick={() => handleRemoveHour(day, hours)}>
                                                                            <FaTrashAlt />
                                                                        </div>
                                                                    </LabelInput>
                                                                </div>
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <Button onClick={() => {
                                                createNewHour(day)
                                            }}>Adicionar Horário
                                            </Button>
                                        </div>
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
