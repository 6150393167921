import React, { useEffect, useState } from "react";
import { useThemeContext } from "../../contexts/themeContext";
import { useAuth } from "../../contexts/useAuth";
//components
import { Container } from "../../components/container/container";
import { TitlePage } from "../../components/titlePages/title.page";
import { Title } from "../../components/titlePages/title";
import { Body } from "../../components/container/Body";
import { LabelInput } from "../../components/label/label.input";
import { Input } from "../../components/input/input";
import { Button } from "../../components/buttons/button.default";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { DefaultLoader } from "../../components/loaders/defaultLoader";
import { ScreenModal } from "../../components/modals/notification/screenModal";
import { UsersToFind } from "../../components/table/usersToFind.table";
import Multiselect from '../../components/selects/mult.select'
//utils
import moment from "moment";
import { responseError } from "../../utils/responsesFunctions/error.response";
//services
import { getCategories, getFilteredSchedulesReports, getServices } from "../../services/api/callAPIsFunctions/defaultCalls.api";
import { useScreenSizeContext } from "../../contexts/screenSizeContext";


export function SchedulesReports() {

    const { userData } = useAuth()
    const { screenX } = useScreenSizeContext()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, showUniversalModal, setShowUniversalModal } = useThemeContext()

    const [loading, setLoading] = useState(true)
    const [loadingServices, setLoadingServices] = useState(true)
    const [categoriesList, setCategoriesList] = useState([])
    const [servicesList, setServicesList] = useState([])
    const [filteredServices, setFilteredServices] = useState([])

    const [selectedStartDate, setSelectedStartDate] = useState('')
    const [selectedFinishDate, setSelectedFinishDate] = useState('')
    const [selectedCategorie, setSelectedCategorie] = useState([])
    const [selectedServices, setSelectedServices] = useState([])
    const [selectedCreatedAt, setsSelectedCreatedAt] = useState('')
    const [selectedScheduleStatus, setSelectedScheduleStatus] = useState([])
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([])
    const [selectedUser, setSelectedUser] = useState('')
    const [selectedOrder, setSelectedOrder] = useState({ id: 'date', name: 'Data e Horário' })
    const [selectedReportType, setSelectedReportType] = useState({ id: 0, name: 'Excel', extension: 'xlsx' })

    const scheduleStatusList = [
        { name: 'Pendente/Aguardando Pagamento', id: 0 },
        { name: 'Reservado', id: 1 },
        { name: 'Cancelado', id: 2 },
    ]

    const paymentStatusList = [
        { name: 'Aguardando pagamento', id: 3 },
        { name: 'Pago', id: 1 },
        { name: 'Cancelado', id: 2 },
    ]

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getServicesByCategorie(selectedCategorie)
    }, [selectedCategorie])

    async function getServicesByCategorie(categoriesList) {

        setLoadingServices(true)

        const categories_id = categoriesList.map(categorie => {
            return categorie.id
        })

        await new Promise(resolve => setTimeout(resolve, 60))
        setFilteredServices(servicesList.filter(service => {
            if (categories_id.includes(service.categories_id)) {
                return service
            }
        }))
        setLoadingServices(false)
    }

    async function getData() {

        setLoading(true)
        const categoriesListAPI = await getCategories(userData[0]?.token)
        setCategoriesList(categoriesListAPI.data)

        const servicesAPI = await getServices(userData[0]?.token)
        setServicesList(servicesAPI.data)

        setLoading(false)
    }

    async function getReport() {
        setLoading(true)
        if (moment(selectedFinishDate).diff(moment(selectedStartDate), 'days') > 31) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setLoading(false)
            return setShowNotificationModalText('Perído máximo é de 31 dias')
        }
        try {

            const report = await getFilteredSchedulesReports(userData[0]?.token, [
                { original_name: 'startDate', value: selectedStartDate },
                { original_name: 'finishDate', value: selectedFinishDate },
                { original_name: 'categories_id', value: selectedCategorie.length > 0 ? selectedCategorie : [] },
                { original_name: 'services_id', value: selectedServices.length > 0 ? selectedServices : [] },
                { original_name: 'created_at', value: selectedCreatedAt },
                { original_name: 'scheduleStatus', value: selectedScheduleStatus?.length > 0 ? selectedScheduleStatus : [] },
                { original_name: 'paymentStatus', value: selectedPaymentStatus?.length > 0 ? selectedPaymentStatus : [] },
                { original_name: 'schedule_users_id', value: selectedUser?.id ? selectedUser.id : '' },
                { original_name: 'reportType', value: selectedReportType?.name ? selectedReportType.name : '' },
                { original_name: 'orderBy', value: selectedOrder?.id ? selectedOrder?.id: '' },
            ])
            if (report.data.status === 204) {
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                setShowNotificationModalText('Nenhum dado encontrado para esta pesquisa')
                setLoading(false)
                return
            }
            setLoading(false)
            await new Promise(resolve => setTimeout(resolve, 100))
            const blob = report.data // Seu Blob recebido
            const blobURL = URL.createObjectURL(blob);
            const downloadLink = document.getElementById('downloadLink');

            downloadLink.href = blobURL;
            downloadLink.download = `relatorio_reservas-${moment().format("DD/MM/YYYY")}_
            ${moment().hour()}_${moment().minutes()}_${moment().seconds()}.${selectedReportType?.extension}`;
            downloadLink.click();
            return URL.revokeObjectURL(blobURL);
        } catch (error) {
            console.log(error)
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(responseError(error))
        }
    }

    return (
        <>
            <ScreenModal>
                <UsersToFind handleSelect={(e) => { setSelectedUser(e); setShowUniversalModal(false) }} />
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Relatórios de reservas'} />
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className="w-full h-96 flex items-center justify-center">
                                <DefaultLoader />
                            </div>
                            :
                            <div className="flex flex-col gap-2 w-full">
                                <div className="flex gap-1 text-titleBlackTextLight">
                                    <a className="text-sm ">Cliente:</a>
                                    {
                                        selectedUser?.id ?
                                            <div className="flex gap-1">
                                                <a className="text-sm text-black">{selectedUser?.firstName}</a>
                                                <a onClick={() => setShowUniversalModal(true)} className="text-sm underline cursor-pointer">Alterar</a>
                                                <a onClick={() => setSelectedUser('')} className="text-sm underline cursor-pointer">Limpar</a>
                                            </div>
                                            :
                                            <a onClick={() => setShowUniversalModal(true)} className="text-sm underline cursor-pointer text-primaryDefaultLight">Selecionar</a>
                                    }
                                </div>
                                <LabelInput text={'Data início'}>
                                    <Input type={'date'} value={selectedStartDate} onChange={(e) => { setSelectedStartDate(e.target.value); setShowNotificationModal(false) }}></Input>
                                </LabelInput>
                                <LabelInput text={'Data fim'}>
                                    <Input type={'date'} value={selectedFinishDate} onChange={(e) => { setSelectedFinishDate(e.target.value); setShowNotificationModal(false) }}></Input>
                                </LabelInput>
                                {/* <LabelInput text={'Categoria'}>
                                    <InputAutoComplete data={categoriesList} optionList={['name']} selectedLabel={'name'} onChange={(e) => setSelectedCategorie(e)} />
                                </LabelInput> */}
                                <div className="flex items-start justify-start w-full">
                                    <LabelInput text={'Categoria'}>
                                        <Multiselect
                                            width={screenX < 640 ? '[100%]' : ''}
                                            items={categoriesList}
                                            mult
                                            value={selectedCategorie}
                                            onChange={(e) => {
                                                setSelectedCategorie(e)
                                            }}
                                            selectedLabel={'name'}
                                            id={`selectedCategorie`} />
                                    </LabelInput>
                                </div>
                                {
                                    !loadingServices && selectedCategorie?.length > 0 ?
                                        <div className="flex items-start justify-start w-full">
                                            <LabelInput text={'Espaço'}>
                                                <Multiselect
                                                    width={screenX < 640 ? '[100%]' : ''}
                                                    items={filteredServices}
                                                    mult
                                                    value={selectedServices}
                                                    onChange={(e) => {
                                                        setSelectedServices(e)
                                                    }}
                                                    selectedLabel={'name'}
                                                    id={`selectedServices`} />
                                            </LabelInput>
                                        </div>
                                        :
                                        <div className="flex items-start justify-start` w-full">
                                            <LabelInput text={'Espaço'}>
                                                <Input disabled={true} />
                                            </LabelInput>
                                        </div>
                                }
                                <LabelInput text={'Criado em'}>
                                    <Input type={'date'} value={selectedCreatedAt} onChange={(e) => setsSelectedCreatedAt(e.target.value)}></Input>
                                </LabelInput>
                                <div className="flex items-start justify-start` w-full">
                                    <LabelInput text={'Status da reserva'}>
                                        <Multiselect
                                            width={screenX < 640 ? '[100%]' : ''}
                                            items={scheduleStatusList}
                                            mult
                                            value={selectedScheduleStatus}
                                            onChange={(e) => setSelectedScheduleStatus(e)}
                                            selectedLabel={'name'}
                                            id={`selectedScheduleStatus`} 
                                        />
                                    </LabelInput>
                                </div>
                                <div className="flex items-start justify-start` w-full">
                                    <LabelInput text={'Status de pagamento'}>
                                        <Multiselect
                                            width={screenX < 640 ? '[100%]' : ''}
                                            items={paymentStatusList}
                                            mult
                                            value={selectedPaymentStatus}
                                            onChange={(e) => setSelectedPaymentStatus(e)}
                                            selectedLabel={'name'}
                                            id={`selectedPaymentStatus`} 
                                        />
                                    </LabelInput>
                                </div>
                                <LabelInput text={'Ordenado por'}>
                                    <InputAutoComplete preSelectedValue={selectedOrder.name} data={[{ id: 'categories', name: 'Categoria' }, { id: 'services', name: 'Serviço/Espaço' }, , { id: 'date', name: 'Data e Horário' }]} optionList={['name']} selectedLabel={'name'} onChange={(e) => setSelectedOrder(e)} />
                                </LabelInput>
                                <LabelInput text={'Tipo de relatório'}>
                                    <InputAutoComplete preSelectedValue={selectedReportType?.name} data={[{ id: 0, name: 'Excel', extension: 'xlsx' }, { id: 1, name: 'PDF', extension: 'pdf' }]} optionList={['name']} selectedLabel={'name'} onChange={(e) => setSelectedReportType(e)} />
                                </LabelInput>
                                <a id="downloadLink"></a>
                                <div className="w-full sm:w-48 mt-6">
                                    <Button onClick={() => getReport()}>Download</Button>
                                </div>
                            </div>
                    }
                </Body>
            </Container>
        </>
    )
}