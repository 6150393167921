
export function resizePhoto(photo, maxWidth, maxHeight) {
    console.log(photo)
    const canvas = document.createElement('canvas')
    const filesToUpload = photo.target.files;
    const file = filesToUpload[0];

    const reader = new FileReader();
    const img = document.createElement("img");
    reader.onload = function (e) { img.src = e.target.result; }
    reader.readAsDataURL(file);

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    const MAX_WIDTH = maxWidth;
    const MAX_HEIGHT = maxHeight;
    const width = img.width;
    const height = img.height;

    if (width > height) {
        if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
        }
    } else {
        if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
        }
    }
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    var dataurl = canvas.toDataURL("image/png");
    return dataurl

}