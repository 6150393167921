// hooks 
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// components 
import { FaAddressCard, FaArrowLeft, FaArrowRight } from "react-icons/fa";
// utils
import { configItems } from "./configuration.items";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import { NotificationModal } from "../../../components/modals/notification/notification.modal";
import { useThemeContext } from "../../../contexts/themeContext";


export function Configuration() {

    const { userData } = useAuth()
    const navegate = useNavigate()
    const { screenX, screenY } = useScreenSizeContext()

    return (

        <div id='configuration-menu' className="overflow-y-auto flex flex-col bg-white dark:bg-primaryDefaultDark w-full p-4" style={{ height: screenX < 640 ? screenY - 67 : 'auto' }}>
            <NotificationModal />
            <div id='configuration-menu-items' className="mt-10 flex flex-row flex-wrap gap-4 items-start justify-start">
                {
                    configItems.map((configItem, index) => {
                        if (
                            (userData[0]?.customersData?.modules?.map(module => {return  module.id}).includes(configItem?.module) || !configItem?.module)
                            &&
                            ((configItem.permission.indexOf(userData[0].typeAccess_id) > -1)
                            ||
                            userData[0]?.permissions?.filter((userPermission) => {
                                if (configItem.permission.indexOf(userPermission) > -1) {
                                    return userPermission
                                }
                            }).length > 0)
                        ) {
                            return <div id={`${configItem.name.replace(' ', '-').toLocaleLowerCase()}-config`} key={`${configItem.name.replace(' ', '-').toLocaleLowerCase()}-config`} className="flex flex-col w-64 justify-between items-start">
                                <div key={`${index}-${configItem.name}`} className="flex flex-row gap-3 items-start justify-startr">
                                    <div className="flex flex-col text-primaryDefaultLight text-2xl w-5"> {configItem.icon}</div>
                                    <Tippy content={configItem.legend}
                                        animation="shift-away"
                                        arrow={true}
                                        placement="right"
                                        delay={700}
                                    >
                                        <h2 className="text-base text-primaryDefaultLight font-normal">{configItem.name}</h2>
                                    </Tippy>
                                </div>
                                {
                                    configItem.subItems.map((subItem, subIndex) => {
                                        if ((subItem.permission.indexOf(userData[0].typeAccess_id) > -1)
                                            ||
                                            userData[0]?.permissions?.filter((userPermission) => {
                                                if (subItem.permission.indexOf(userPermission) > -1) {
                                                    return userPermission
                                                }
                                            }).length > 0
                                        ) {
                                            return <div key={`${index}-${subIndex}`} className="flex flex-row items-start justify-start gap-1">
                                                <div className="flex flex-col w-7"></div>
                                                <h3 onClick={() => navegate(subItem.path)} className="cursor-pointer text-inputPlaceholderLight dark:text-titleGrayTextDark font-light hover:brightness-125 dark:hover:brightness-50 transition-all duration-75 text-sm">{subItem.name}</h3>
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        }
                    })
                }
            </div>
        </div>
    )
}

