import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight, FaArrowCircleLeft, FaCircle, FaPlus, FaQuestion, FaQuestionCircle, FaTrash } from 'react-icons/fa'
//components
import { Container } from '../../../components/container/container'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { TableDefault } from '../../../components/table/table.default'
import { Button } from '../../../components/buttons/button.default'
import CurrencyInput from '../../../components/input/inputCurrencyPtBr'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { Quillmodules } from "../../../components/richText/quill.modules";
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { InputCheckBox } from '../../../components/input/input.checkBox'
import { Toogle } from '../../../components/toogle/Toogle'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { getAssociations } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { permittedFiles } from '../../../utils/permittedFiles/permittedFiles'

//utils
import { justNumber } from '../../../utils/validators/justNumber'
import { Footer } from '../../../components/footer/Footer'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../services/api/api'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { verifyFileSize } from '../../../utils/validators/verifyFileSize'
import { resizeImageTest } from '../../../utils/formatImages/resizeImageTest'
import { maxNumber } from '../../../utils/mask/maxNumber'

export function ActivitiesCreate() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, showUniversalModal, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [loadingFile, setLoadingFile] = useState(false)
    const [associationList, setAssociationList] = useState([])
    const [plansEditMode, setPlansEditMode] = useState(false)
    const [showModal, setShowModal] = useState(true)
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    const [showPhoto, setShowPhoto] = useState(<a>Sem dados</a>)

    const [activitiesName, setActivitiesName] = useState('')
    const [isSchool, setIsSchool] = useState(false)
    const [description, setDescription] = useState('')
    const [deltaDescription, setDeltaDescription] = useState({})
    const [termdescription, setTermDescription] = useState('')
    const [deltaTermDescription, setDeltaTermDescription] = useState({})
    const [openForSubscription, setOpenForSubscriptions] = useState(false)
    const [numberOfVacance, setNumberOfVacance] = useState(0)
    const [startDate, setStartDate] = useState('')
    const [finishDate, setFinishDate] = useState('')
    const [associationsEnabled, setAssociationsEnabled] = useState([])
    const [activitiesPhotos, setActivitiesPhotos] = useState([])
    const [activitiesAppendPhotos, setActivitiesAppendPhotos] = useState([])
    const [regulationFile, setRegulationFile] = useState([])
    const [hyperLink, setHyperLink] = useState('')
    const [hyperLinkDescription, setHyperLinkDescription] = useState('')

    //plans config
    const [plansList, setPlansList] = useState([])
    const [planName, setPlanName] = useState('')
    const [typeOfCharge, setTypeOfCharge] = useState('P')
    const [numberOfMonth, setNumberOfMonth] = useState(null)
    const [numberOfInstallments, setNumberOfInstallments] = useState(null)
    const [cancellationFee, setCancellationFee] = useState(null)
    const [valuesPerAssociations, setValuesPerAssociations] = useState([])

    async function getData() {

        setLoading(true)

        const associationData = await getAssociations(userData[0].token)

        if (associationData.error) {
            setLoading(false)
            setShowNotificationModal(associationData.data)
            return
        }

        setAssociationList(associationData.data.filter(association => association.active))
        setValuesPerAssociations(
            associationData.data.filter(association => association.active).map(association => {
                return {
                    enabled: false,
                    associations_name: association.name,
                    associations_id: association.id,
                    value: 0
                }
            })
        )
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    async function handleSubmit() {
        const fields =
            [
                { "name": 'activitiesName', "value": activitiesName, "required": true, "type": 'string' },
                { "name": 'startDate', "value": startDate, "required": true, "type": 'string' },
                { "name": 'finishDate', "value": finishDate, "required": true, "type": 'string' },
            ]

        if (validateFields(fields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        setLoading(true)
        try {

            plansList.map(plan => {
                delete plan.removeFunction
                plan.cancellationFee = plan.cancellationFee === '' ? 0 : plan.cancellationFee
            })

            const newActivitie = await api.post(`/api/v1/activities`, {
                activitiesName,
                isSchool,
                description: deltaDescription,
                openForSubscriptions: openForSubscription,
                numberOfVacancies: numberOfVacance,
                hyperLink: hyperLink?.length > 0 ? hyperLink : undefined,
                hyperLinkDescription: hyperLinkDescription?.length > 0 ? hyperLinkDescription : undefined,
                startDate,
                finishDate,
                termOfUse: deltaTermDescription,
                activitiesPlans: plansList
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            if (activitiesAppendPhotos.length > 0) {

                const formData = new FormData()
                activitiesAppendPhotos.map((photo) => {
                    formData.append('images', photo)
                })
                await api.post(`/api/v1/activitiesPictures/activities_id/${newActivitie.data.data[0].id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                })
            }

            if (regulationFile?.length !== 0) {
                const formData = new FormData()
                formData.append('regulation', regulationFile)
                await api.post(`/api/v1/activities/${newActivitie.data.data[0].id}/regulation`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                })
            }

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            history.back()
            return setShowNotificationModalText("Atividade cadastrada com sucesso!")

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function handleChangeValueText(content, delta, source, editor) {
        setDescription(content)
        setDeltaDescription(editor.getContents());
    }

    function handleChangeValueTextTerm(content, delta, source, editor) {
        setTermDescription(content)
        setDeltaTermDescription(editor.getContents());
    }

    function handleClickInputFile() {
        let inputEl = document.getElementById('pictureAddInput')
        inputEl.click()
    }

    async function handleNewPicture(e) {

        const isValid = verifyFileSize(e.target.files[0])
        if (!isValid) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Arquivo maior do que o permitido (20MB)')
        }
        setShowNotificationModal(false)

        const validFile = permittedFiles(e.target.files[0], 'image')
        if (validFile.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(validFile.response)
        }

        if (activitiesPhotos.length < 5) {

            const formattedImg = await new Promise((resolve, reject) => {
                resizeImageTest(e, (err, resizedFile) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    resolve(resizedFile)
                })
            })

            const photoObj = URL.createObjectURL(formattedImg)
            setActivitiesPhotos([photoObj, ...activitiesPhotos])
            setActivitiesAppendPhotos([formattedImg, ...activitiesAppendPhotos])
        }

    }

    const prevPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = activitiesPhotos[showPhoto - 1]
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto - 1)
    }

    const nextPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = activitiesPhotos[showPhoto + 1]
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto + 1)
    }

    async function showThisPhoto(photo, indexPhoto) {

        setShowPhotoModal(true)

        const modalElement = document.getElementById('showPhotoModal')

        let img = document.createElement('img')
        img.src = photo
        img.id = 'appendedPreviewPhoto'
        modalElement.appendChild(img)
        setShowPhoto(indexPhoto)
        await new Promise(resolve => setTimeout(resolve, 100))
        document.getElementById('photoContainer').style.opacity = '1'
        document.getElementById('photoContainer').style.transform = 'translateY(0px)'

    }

    async function closeThisPhoto() {

        document.getElementById('photoContainer').style.opacity = '0'
        document.getElementById('photoContainer').style.transform = 'translateY(-100px)'
        await new Promise(resolve => setTimeout(resolve, 300))

        setShowPhotoModal(false)
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)
        setShowPhoto('')
    }

    async function removeThisPhoto(photo, indexPhoto) {

        document.getElementById(photo + '-' + indexPhoto).style.transform = 'scale(.5)'
        await new Promise(resolve => setTimeout(resolve, 500))
        setActivitiesPhotos(activitiesPhotos.filter(photoToRemove => photoToRemove !== photo))
        document.getElementById(photo + '-' + indexPhoto).style.transform = 'scale(1)'
    }
    //regulation
    function handleClickRegulationFileInput() {
        let inputEl = document.getElementById('regulationFileInput')
        inputEl.click()
    }

    function handleNewRegulation(e) {

        const validFile = permittedFiles(e.target.files[0], 'pdf')
        if (validFile.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            removeThisRegulation()
            return setShowNotificationModalText(validFile.response)
        }
        setRegulationFile(e.target.files[0])
    }

    async function removeThisRegulation() {
        setLoadingFile(true)
        setRegulationFile([])
        let inputFile = document.getElementById('pictureAddInput')
        inputFile.value = 0
        setLoadingFile(false)
    }

    function addPlan() {
        const fields = [
            { "name": 'planName', "value": planName, "required": true, "type": 'string' },
            { "name": 'numberOfMonth', "value": numberOfMonth, "required": true, "type": 'number' },
            { "name": 'numberOfInstallments', "value": numberOfInstallments, "required": true, "type": 'number' },
            { "name": 'cancellationFee', "value": cancellationFee, "required": true, "type": 'number' }
        ]

        if (validateFields(fields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
        }

        plansList.push({
            planName,
            typeOfCharge: 'P',
            numberOfMonth,
            numberOfInstallments,
            cancellationFee,
            valuesPerAssociations: valuesPerAssociations.map(association => {
                return {
                    associations_id: association.associations_id,
                    value: association.value,
                    enabled: association.enabled
                }
            }),
            removeFunction: (
                <div className='w-full flex items-center justify-center'>
                    <FaTrash onClick={(name) => {
                        setPlansList(plansList.filter(plan => plan.planName == name))
                    }} />
                </div>
            )
        })
        setPlansList(plansList)

        setPlanName('')
        setTypeOfCharge('P')
        setNumberOfMonth(null)
        setNumberOfInstallments(null)
        setCancellationFee(null)
        setValuesPerAssociations(valuesPerAssociations.map(association => {
            return {
                enabled: false,
                associations_name: association.associations_name,
                associations_id: association.associations_id,
                value: null
            }
        }))

        setPlansEditMode(!plansEditMode)
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Nova atividade'} />
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ?
                        <div className='flex flex-col w-full h-96 items-center justify-center'>
                            <DefaultLoader />
                        </div>
                        :
                        <div className='w-full flex flex-col items-start justify-start gap-10'>
                            <div className='w-full flex flex-col items-start justify-start gap-4'>
                                <div className='flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                    <h1 className='text-primaryDefaultLight text-base'>Informações da atividade *</h1>
                                </div>
                                <div className='flex items-center gap-4'>
                                    <LabelInput text={'Título *'}>
                                        <Input id='activitiesName' type={'text'} charLimit={255} value={activitiesName} onChange={(e) => setActivitiesName(e.target.value)}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Escolinha/Infantil *'}>
                                        <Toogle id='isSchool' key='isSchool' status={isSchool} onClick={(e) => setIsSchool(!isSchool)}></Toogle>
                                    </LabelInput>
                                </div>
                                <label className={`flex flex-col relative gap-1 justify-start w-full -mb-2`}>
                                    <a className={`tex-lg sm:text-sm text-inputPlaceholderLight`}>Descrição</a>
                                </label>
                                <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96 mb-12' readOnly={false} theme={"snow"} value={description} onChange={handleChangeValueText} />
                                <div className='mb-8 md:mb-2 xl:hidden'></div>
                                <LabelInput text={'Inscrições abertas ?'}>
                                    <div className='flex flex-row gap-6'>
                                        <div className='flex flex-row gap-4 items-start justify-start'>
                                            <InputCheckBox value={openForSubscription} onClick={() => setOpenForSubscriptions(true)}></InputCheckBox>
                                            <p className='text-sm text-inputPlaceholderLight'>Sim</p>
                                        </div>
                                        <div className='flex flex-row gap-4 items-start justify-start'>
                                            <InputCheckBox value={!openForSubscription} onClick={() => setOpenForSubscriptions(false)}></InputCheckBox>
                                            <p className='text-sm text-inputPlaceholderLight'>Não</p>

                                        </div>
                                    </div>
                                </LabelInput>
                                <LabelInput text={'Número total de vagas:'}>
                                    <Input width={44} value={numberOfVacance} type={'text'} onChange={(e) => setNumberOfVacance(justNumber(e.target.value))} />
                                </LabelInput>
                                <div className='flex flex-col sm:flex-row gap-2 items-start justify-start'>
                                    <LabelInput text={'Data de início da atividade *'}>
                                        <Input id='startDate' width={44} type={'date'} value={startDate} onChange={(e) => setStartDate(e.target.value)}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Data do fim da atividade *'}>
                                        <Input id='finishDate' width={44} type={'date'} value={finishDate} onChange={(e) => setFinishDate(e.target.value)}></Input>
                                    </LabelInput>
                                </div>
                            </div>
                            <div className='w-full flex flex-col items-start justify-start gap-4'>
                                <div className='flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                    <h1 className='text-base text-primaryDefaultLight'>Configurações de planos</h1>
                                </div>
                                {
                                    !plansEditMode ? (
                                        <>
                                            <div className="flex flex-row items-start justify-start w-full">
                                                <Tippy content={<p>Novo plano</p>}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div onClick={() => setPlansEditMode(!plansEditMode)} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                        <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Adicionar</a>
                                                    </div>
                                                </Tippy>
                                            </div>
                                            {/* <Button width={40} shadow={true} onClick={() => setPlansEditMode(!plansEditMode)}>Adicionar Plano</Button> */}
                                            <div className='boxShadow w-full'>
                                                <TableDefault
                                                    title={[
                                                        {
                                                            "name": 'Nome do Plano',
                                                            "original_name": 'planName'
                                                        },
                                                        {
                                                            "name": 'Remover',
                                                            "original_name": "removeFunction"
                                                        },
                                                    ]}
                                                    data={plansList}
                                                    collumns={["planName", "removeFunction"]}
                                                    loading={loading}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <LabelInput text={'Nome do Plano *'}>
                                                <Input id='planName' width={44} type={'text'} value={planName} onChange={(e) => { setPlanName(e.target.value) }}></Input>
                                            </LabelInput>
                                            <div className='flex flex-col sm:flex-row gap-4'>
                                                <Tippy content={<p>Número de duracão do plano</p>}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div>
                                                        <LabelInput text={'Número de meses *'}>
                                                            <Input charLimit={2} id='numberOfMonth' width={44} type={'number'} value={numberOfMonth} onChange={(e) => setNumberOfMonth(e.target.value)}></Input>
                                                        </LabelInput>
                                                    </div>
                                                </Tippy>
                                                <Tippy content={<p>Número de parcelas que o cliente poderá pagar este plano, o calculo será o valor total em até X parcelas</p>}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div>
                                                        <LabelInput text={'Número de parcelas *'}>
                                                            <Input charLimit={2} max={12} id='numberOfInstallments' width={44} type={'number'} value={numberOfInstallments} onChange={(e) => setNumberOfInstallments(maxNumber(12,e.target.value))}></Input>
                                                        </LabelInput>
                                                    </div>
                                                </Tippy>
                                                <LabelInput text={'Multa de cancelamento (%) *'}>
                                                    <Input id='cancellationFee' width={44} type={'number'} value={cancellationFee} onChange={(e) => setCancellationFee(e.target.value)}></Input>
                                                </LabelInput>
                                            </div>
                                            {
                                                valuesPerAssociations.map((association, index) => {
                                                    return (
                                                        <>
                                                            <div className='flex flex-row gap-4 items-center'>
                                                                <div className='flex flex-row gap-2 w-40'>
                                                                    <InputCheckBox value={[true, false].includes(valuesPerAssociations[index].enabled) ? association.enabled : false} onClick={async () => {
                                                                        valuesPerAssociations[index].enabled = [true, false].includes(valuesPerAssociations[index].enabled) ? !valuesPerAssociations[index].enabled : true
                                                                        if (!valuesPerAssociations[index].enabled) {
                                                                            valuesPerAssociations[index].value = 0
                                                                        }
                                                                        setValuesPerAssociations([...valuesPerAssociations])
                                                                    }}></InputCheckBox>
                                                                    <a className='text-sm text-inputPlaceholderLight'>{association.associations_name}</a>
                                                                </div>
                                                                {
                                                                    valuesPerAssociations[index]?.enabled ?
                                                                        <div className='w-40'>
                                                                            <LabelInput>
                                                                                <CurrencyInput
                                                                                    id={`association-${valuesPerAssociations[index]?.associations_id}`}
                                                                                    placeHolder={`R$ ${(valuesPerAssociations[index]?.value ? valuesPerAssociations[index]?.value : '').toString()?.replace('.', ',')}`}
                                                                                    width={40}
                                                                                    onChange={(e) => {
                                                                                        association.value = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                                                        setValuesPerAssociations([...valuesPerAssociations])
                                                                                    }}
                                                                                />
                                                                            </LabelInput>
                                                                        </div>
                                                                        : <LabelInput><Input disabled={true} placeholder={'R$'} width={40} /></LabelInput>
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                            <div className='flex flex-row gap-2 items-start justify-start'>
                                                <Button width={40} shadow={true} approval={false} onClick={() => {
                                                    setPlansEditMode(!plansEditMode)
                                                }}>Voltar</Button>
                                                <Button width={40} shadow={true} approval={true} onClick={() => {
                                                    addPlan()
                                                }}>Adicionar</Button>
                                            </div>
                                        </>
                                    )
                                }

                            </div>
                            <div className='flex flex-col w-full items-start justify-start'>
                                <div className='flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark mb-4'>
                                    <h1 className='text-base text-primaryDefaultLight'>Termos e condição</h1>
                                </div>
                                <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96 mb-12' readOnly={false} theme={"snow"} value={termdescription} onChange={handleChangeValueTextTerm} />
                            </div>
                            <div className='mb-8 xl:hidden'></div>
                            <div className='flex flex-col w-full items-start justify-start gap-4'>
                                <div className='flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                    <h1 className='text-base text-primaryDefaultLight'>Regulamento</h1>
                                </div>
                                <input id='regulationFileInput' type={'file'} accept=".pdf" onChange={(e) => handleNewRegulation(e)} className='hidden'></input>
                                <Button width={64} shadow={true} onClick={() => handleClickRegulationFileInput()}>{`Configurar arquivo (.pdf)`}</Button>
                                {
                                    regulationFile?.name?.length > 0 &&
                                    <div className='flex gap-3 items-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>{regulationFile?.name}</a>
                                        <a className='text-sm text-inputPlaceholderLight underline cursor-pointer hover:brightness-75' onClick={() => removeThisRegulation()}><FaTrash size={20} /></a>
                                    </div>
                                }
                            </div>
                            <div className='flex flex-row items-center gap-2'>
                                <LabelInput text={'Link'}>
                                    <Input type={'text'} onChange={(e) => setHyperLink(e.target.value)} value={hyperLink} />
                                </LabelInput>
                                <Tippy content={'Link para aparecer na ativadade podendo ser um link para o site ou whatsapp'}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='right'
                                    delay={300}>
                                    <div className='mt-6'>
                                        <FaQuestionCircle />
                                    </div>
                                </Tippy>
                            </div>
                            <div className='flex flex-row items-center gap-2'>
                                <LabelInput text={'Descrição do link'}>
                                    <Input type={'text'} onChange={(e) => setHyperLinkDescription(e.target.value)} value={hyperLinkDescription} />
                                </LabelInput>
                                <Tippy content={'Texto para descrever este link'}
                                    arrow={true}
                                    animation='shift-away'
                                    placement='right'
                                    delay={300}>
                                    <div className='mt-6'>
                                        <FaQuestionCircle />
                                    </div>
                                </Tippy>
                            </div>
                            <div id='activitiesCreatePictures' className='w-full flex flex-col items-start justify-start gap-4 user-select-none mb-5'>
                                <div className='flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                    <h1 className='text-primaryDefaultLight text-base'>Galeria de fotos</h1>
                                </div>
                                <div className='flex flex-row gap-2 items-start justify-start'>
                                    <div className={`${showPhotoModal ? 'flex' : 'hidden'} flex-row items-center justify-center w-full h-full fixed top-0 left-0 z-50`}>
                                        <div id='photoContainer' className={`opacity-0 transition-all duration-500 -translate-y-10 flex gap-4 flex-row items-center justify-center w-full h-full`}>
                                            {
                                                showPhoto > 0 ?
                                                    <FaArrowCircleLeft onClick={prevPhoto} className='text-primaryDefaultLight hover:-translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                    :
                                                    <FaArrowCircleLeft className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                            }
                                            <div className='bg-bgPrimaryLight dark:bg-primaryDefaultDark boxShadow p-8 w-full h-full md:w-[700px] md:h-[500px] lg:w-[1000px] lg:h-[600px] flex flex-col items-center justify-start'>
                                                <div className='flex flex-row justify-end w-full'>
                                                    <button className='bg-primaryDefaultLight hover:brightness-125 transition-all duration-200 h-8 w-8 rounded-full flex flex-col items-center justify-center text-titleGrayTextLight' onClick={() => closeThisPhoto()}>X</button>
                                                </div>
                                                <div id='showPhotoModal' className='flex flex-col items-center justify-center overflow-auto w-full h-full'>
                                                </div>
                                            </div>
                                            {
                                                showPhoto < activitiesPhotos.length - 1 ?
                                                    <FaArrowAltCircleRight onClick={nextPhoto} className='text-primaryDefaultLight hover:translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                    :
                                                    <FaArrowAltCircleRight className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                            }
                                        </div>
                                    </div>
                                    <div className={`${showPhotoModal ? 'flex' : 'hidden'}  bg-gray-600 opacity-80 w-full h-full fixed z-40 left-0 top-0`}></div>

                                    <h1 className='text-sm text-inputPlaceholderLight'>Fotos</h1>
                                    <a className='text-inputPlaceholderLight dark:text-titleGrayTextDark text-sm'>{activitiesPhotos.length} de 5</a>
                                    <p className='font-semibold text-base cursor-pointer' onClick={() => handleClickInputFile()}>+</p>
                                </div>
                                {
                                    !loadingFile &&
                                    <input id='pictureAddInput' type='file' accept='.png, .jpeg, .jpg' className='hidden' onChange={(e) => handleNewPicture(e)}></input>
                                }
                                <div className='flex flex-row gap-2 items-center justify-start'>
                                    {
                                        activitiesPhotos.map((photo, indexPhoto) => {
                                            return <div id={photo + '-' + indexPhoto} className='cursor-pointer transition-all duration-500 ease-in-out relative flex flex-row gap-4 items-center justify-center'>
                                                <Tippy content={'Remover foto'}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='right'
                                                    delay={300}>
                                                    <button onClick={() => removeThisPhoto(photo, indexPhoto)} className='text-titleGrayTextLight absolute -left-2 -top-2 w-10 h-10 sm:w-6 sm:h-6 rounded-full bg-gray-400'>x</button>
                                                </Tippy>

                                                <img onClick={() => showThisPhoto(photo, indexPhoto)} className='border border-gray-500 rounded-xl w-20 h-20' src={photo}></img>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button shadow={true} approval={false} onClick={() => history.back()}>Voltar</Button>
                    <Button module={"activities:create"} userData={userData} shadow={true} onClick={() => {
                        handleSubmit()
                    }} >Cadastrar</Button>
                </div>
            </Footer>
        </Container>
    )
}