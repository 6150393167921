export function maskHoursService(hour) {
    try {
        if (hour < 10) {
            return hour?.toString().indexOf('.5') > 0 ? '0'.concat(hour?.toString()).replace('.5', ':30') : '0'.concat(hour?.toString()) + ':00'

        } else {
            return hour?.toString().indexOf('.5') > 0 ? hour?.toString()?.replace('.5', ':30') : hour?.toString() + ':00'
        }
    } catch (error) {
        return hour
    }
}