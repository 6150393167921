import React, { useCallback, useEffect, useRef, useState } from "react";
import { CalendarMain } from "../../../components/calendar/calendar.main";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Input } from "../../../components/input/input";
import { LabelInput } from "../../../components/label/label.input";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Toogle } from "../../../components/toogle/Toogle";
import { Footer } from '../../../components/footer/Footer'
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/buttons/button.default";
import { FaCalendarDay } from "react-icons/fa";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
//utils
import { justNumber } from '../../../utils/validators/justNumber'
import { api } from "../../../services/api/api";
import { useAuth } from "../../../contexts/useAuth";
import { responseError } from "../../../utils/responsesFunctions/error.response";
//context
import { useThemeContext } from "../../../contexts/themeContext";

export function HolidayCalendarCreate() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [description, setDescription] = useState('')
    const [recurrency, setRecurrency] = useState(false)
    const [showCalendar, setShowCalendar] = useState(false)
    const [selectedDay, setSelectedDay] = useState('')
    const [selectedMonth, setSelectedMonth] = useState('')
    const [selectedDate, setSelectedDate] = useState('')

    const calendarRef = useRef()
    const calendaContainerRef = useRef()

    const monthList = [
        { id: 0, month: 1, description: 'Janeiro' },
        { id: 1, month: 2, description: 'Fevereiro' },
        { id: 2, month: 3, description: 'Março' },
        { id: 3, month: 4, description: 'Abril' },
        { id: 4, month: 5, description: 'Maio' },
        { id: 5, month: 6, description: 'Junho' },
        { id: 6, month: 7, description: 'Julho' },
        { id: 7, month: 8, description: 'Agosto' },
        { id: 8, month: 9, description: 'Setembro' },
        { id: 9, month: 10, description: 'Outubro' },
        { id: 10, month: 11, description: 'Novembro' },
        { id: 11, month: 12, description: 'Dezembro' },
    ]

    const handleCloseCalendar = useCallback((e) => {
        if (!calendarRef.current.contains(e.target)) {
            setShowCalendar(false)
        }
        if (e?.key === 'Escape') {
            setShowCalendar(false)
        }
    },[])
    
    useEffect(() => {

        const calendarElement = calendaContainerRef.current
        calendarElement.addEventListener('click', (e) => handleCloseCalendar(e))
        calendarElement.addEventListener('keydown', (e) => handleCloseCalendar(e))

        return () => {
            calendarElement.removeEventListener('keypress', (e) => handleCloseCalendar(e))
            calendarElement.removeEventListener('click', (e) => handleCloseCalendar(e))
        };
    }, [])

    async function handleSubmit() {

        let apiData = {

            description: description,
            day: selectedDay > 0 ? selectedDay : undefined,
            month: selectedMonth.month > 0 ? selectedMonth?.month : undefined,
            holidayDate: selectedDate._isAMomentObject ? selectedDate.format("YYYY-MM-DD") : undefined,
            recurrency: recurrency
        }

        try {
            const holidays = await api.post(`/api/v1/holidays`,
                apiData,
                {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText("Cadastro realizado com sucesso")
            navigate('/holidayCalendar')

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <Container >
            <TitlePage>
                <Title text={'Novo feriado'} />
            </TitlePage>
            <Body hasFooter={true}>
                <div id='holidaysCalendarCreate' ref={calendaContainerRef} className="flex flex-col items-start justify-start flex-wrap w-full gap-4">
                    <LabelInput text={'Descrição *'}>
                        <Input value={description} width={'320px'} type={'text'} charLimit={255} onChange={(e) => setDescription(e.target.value)} />
                    </LabelInput>
                    <a className="text-xt text-inputPlaceholderLight -translate-y-3">{description?.length} de 255</a>
                    <LabelInput text={'Recorrente'}>
                        <Toogle onClick={() => setRecurrency(!recurrency)} status={recurrency ? true : false} />
                    </LabelInput>
                    {
                        recurrency ?
                            <div className="flex flex-row gap-2 items-start justify-start">
                                <LabelInput text={'Mês'}>
                                    <InputAutoComplete data={monthList} selectedLabel={'description'} optionList={['description']} onChange={(e) => setSelectedMonth(e)} />
                                </LabelInput>
                                <LabelInput text={'Dia'}>
                                    <Input value={selectedDay} type={'text'} charLimit={2} onChange={(e) => setSelectedDay(justNumber(e.target.value))}></Input>
                                </LabelInput>
                            </div>
                            :
                            <LabelInput text={'Data'}>
                                <div className="outline-none                                                                               
                                        text-lg sm:text-sm                                      
                                        transition-all duration-200
                                        mt-2 flex flex-row items-start justify-start relative" ref={calendarRef}>
                                    <div onClick={() => setShowCalendar(true)} className="flex flex-col items-center justify-center text-2xl text-primaryDefaultLight h-10 w-12 border border-gray-400 first-letter:dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark">
                                        <FaCalendarDay />
                                    </div>
                                    <input onFocus={(e) => setShowCalendar(true)} value={selectedDate._isAMomentObject ? selectedDate?.format('DD/MM/YYYY') : selectedDate} onClick={() => setShowCalendar(true)} className={`
                                        outline-none
                                        h-10
                                        w-80
                                        pl-2 border border-gray-400 border-opacity-75 
                                        text-lg sm:text-sm                                      
                                        transition-all duration-200
                                        dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark`}>

                                    </input>
                                    <div className="w-56 absolute top-12">
                                        {
                                            showCalendar &&
                                            <CalendarMain onClick={(e) => setSelectedDate(e)} />
                                        }
                                    </div>
                                </div>
                            </LabelInput>
                    }

                </div>
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button shadow={true} approval={false} onClick={() => navigate('/holidayCalendar')}>Voltar</Button>
                    <Button shadow={true} onClick={() => handleSubmit()} >Cadastrar</Button>
                </div>
            </Footer>
        </Container>
    )
}