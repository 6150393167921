import React, { useEffect, useState } from 'react';


const Dropdown = ({width, list, selectedLabel, addItem, onMouseOver, onMouseLeave }) => {

    const [insideList, setInsideList] = useState(list.sort(function (a, b) {
        return +(a[selectedLabel] > b[selectedLabel]) || +(a[selectedLabel] === b[selectedLabel]) - 1;
    }) )

    useEffect(() => {
        setInsideList(list.sort(function (a, b) {
            return +(a[selectedLabel] > b[selectedLabel]) || +(a[selectedLabel] === b[selectedLabel]) - 1;
        }))
    }, [list])

    return (<div id="dropdown" onMouseOver={()=> onMouseOver()} onMouseLeave={()=> onMouseLeave()} className={`dark:shadow-borderShadow absolute shadow top-100 bg-white dark:bg-secondaryDefaultDark z-40 ${width ? `w-${width}` : 'w-96'} lef-0 rounded max-h-select overflow-y-auto`}>
        <div className="flex flex-col w-full overflow-y-auto max-h-40 dark:scrollbar-thin dark:scrollbar-track-zinc-900 dark:scrollbar-thumb-zinc-700">
            {insideList.map((item, key) => {
                return <div key={key}
                    className="cursor-pointer w-full dark:border-secondaryBorderDark border-gray-100 rounded-t border-b hover:bg-primaryDefaultLight"
                    onClick={() => addItem(item)}
                    onTouchStart={()=> addItem(item)}
                    >
                    
                    <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-primaryDefaultLight" >
                        <div className="w-full items-center flex">
                            <div className="mx-2 leading-6 text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                {item[selectedLabel]}
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </div>);

};

export default Dropdown;