import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "../../../components/container/container";
import { Body } from "../../../components/container/Body";


export function ValidUser() {
    
    const navigate = useNavigate()
    const { usersId, userTypeAccess } = useParams()

    useEffect(() => {

        if (Number(userTypeAccess) > 2) {
            return navigate(`/clientsEdit/${usersId}`)
        }
        return navigate(`/usersEdit/${usersId}`)

    }, [])

    return (
        <Container>
            <Body>
                <a>Nenhum usuário encontrado...</a>
            </Body>
        </Container>
    )
}