import { stoneApi } from "./api"

export async function getCardToken({
    card,
    appId
}) {
    try {
        const tokenData = await stoneApi.post(`/tokens?appId=${appId}`, {
            "type": "card",
            "card": {
                "holder_name": card.holder_name,
                "number": card.number,
                "exp_month": card.exp_month,
                "exp_year": card.exp_year,
                "cvv": card.cvv,
                "billing_address": {
                    "line_1": "-",
                    "zip_code": "00000000",
                    "city": "XX",
                    "state": "XX",
                    "country": "BR"                
                }
            }
        })

    return tokenData
} catch (error) {
    console.log(error)
    return error
}
}
