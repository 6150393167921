//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
import { Button } from '../../../components/buttons/button.default'
import { LabelInput } from '../../../components/label/label.input'
import { Input } from '../../../components/input/input'
import { Footer } from '../../../components/footer/Footer'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
//services
import { getCustomers, getPaymentGateways, getPaymentOptionsGateways } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { api } from '../../../services/api/api'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { Toogle } from '../../../components/toogle/Toogle'
import { FaCheck, FaCopy } from 'react-icons/fa'
import { copyToClipboard } from '../../../utils/copyClipboard'

export function PaymentGatewayCreate() {

    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [copied, setCopied] = useState(false)

    const providersData = [{ "name": "Pagar.me", id: 1 }, { "name": "Stone | Pagar.me", id: 1 }]
    const [selectedProvider, setSelectedProvider] = useState([])

    const [description, setDescription] = useState('')
    const [gatewayId, setGatewayId] = useState('')
    const [clientId, setClientId] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [sellerId, setSellerId] = useState('')
    const [clientPublic, setClientPublic] = useState('')
    const [gatewayStatus, setGatewayStatus] = useState(true)
    const [gatewayStatusHistory, setGatewayStatusHistory] = useState(true)
    const [customersData, setCustomersData] = useState('')

    const fields =
        [
            { "name": 'clientId', "value": clientId, "required": true, "type": 'string' },
            { "name": 'clientSecret', "value": clientSecret, "required": true, "type": 'string' },
            { "name": 'sellerId', "value": sellerId, "required": false, "type": 'string' },
            { "name": 'clientPublic', "value": clientPublic, "required": true, "type": 'string' },
        ]

    async function handleSubmit() {

        if (validateFields(fields).length > 0) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            return setLoading(false)
        }

        setLoading(true)
        try {

            await api.post(`/api/v1/paymentGateway`, {
                paymentGatewayOptions_id: providersData[1].id,
                description: description?.length > 0 ? description : undefined,
                client_id: clientId,
                client_secret: clientSecret,
                seller_id: undefined,
                client_public: clientPublic
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            if (gatewayStatus !== gatewayStatusHistory) {

                const updateStatus = await api.put(`/api/v1/paymentGateway/${gatewayId}/status/${gatewayStatus ? 1 : 0}`, {

                }, {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })

            }
            navigate('/paymentGatewayConfig')
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setLoading(false)

        } catch (error) {
            console.log(error.response)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function getData() {
        setLoading(true)
        const data = await getCustomers(userData[0]?.token)
        setCustomersData(data.data)
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Container>
            <TitlePage>
                <div className='w-full justify-start flex flex-row items-center'>
                    <Title text={'Dados do gateway'}></Title>
                </div>
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ?
                        <div className='flex flex-col w-full items-center justify-center h-96'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div className='w-full items-start justify-start flex flex-col gap-4'>
                                <p className='text-sm translate-y-6 mb-4 text-titleBlackTextLight dark:text-titleGrayTextDark'>Informe os dados da sua conta <strong>stone | Pagar.me</strong> abaixo</p>
                                <LabelInput text={'Ativo'}>
                                    <Toogle status={true} onClick={() => setGatewayStatus(!gatewayStatus)} />
                                </LabelInput>
                                <div className='flex flex-col items-start justify-start gap-1'>
                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark underline'>Seus dados para webhook</p>
                                    <div className='flex flex-row gap-1'>
                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'><strong>Usuario:</strong> {userData[0]?.customers_code}</p>
                                        {
                                            copied === 0 ?
                                                <FaCheck className='text-green-500' />
                                                :
                                                <FaCopy className='cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark' onClick={() => {
                                                    copyToClipboard(customersData.customers_code); setCopied(0)
                                                }} />
                                        }
                                    </div>
                                    <div className='flex flex-row gap-1'>
                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'><strong>Senha:</strong> {customersData?.secret_key?.slice(0, 20)?.concat('...')}</p>
                                        {
                                            copied === 1 ?
                                                <FaCheck className='text-green-500' />
                                                :
                                                <FaCopy className='cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark' onClick={() => {
                                                    copyToClipboard(customersData?.secret_key); setCopied(1)
                                                }} />

                                        }
                                    </div>
                                </div>
                            </div>
                            <form className='w-full flex flex-row flex-wrap items-start justify-start gap-4'>
                                {/* <LabelInput text={'Provedor'}>
                                    <InputAutoComplete
                                        disabled={false}
                                        preSelectedValue={selectedProvider.name}
                                        data={paymentGatewayList}
                                        optionList={['name']}
                                        onChange={(e) => handleSelectGateway(e)}
                                        selectedLabel={'name'} />
                                </LabelInput> */}

                                {
                                    selectedProvider.id === 2 ?
                                        <>
                                            <LabelInput text={'Descriçao'}>
                                                <Input id={'description'} charLimit={50} type={'text'} onChange={(e) => setDescription(e.target.value)} value={description}></Input>
                                            </LabelInput>
                                            <LabelInput text={'ID da conta *'}>
                                                <Input id={'clientId'} charLimit={100} type={'text'} onChange={(e) => setClientId(e.target.value)} value={clientId}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Chave secreta *'}>
                                                <Input id={'clientSecret'} charLimit={100} type={'text'} onChange={(e) => setClientSecret(e.target.value)} value={clientSecret}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Chave pública *'}>
                                                <Input id={'clientPublic'} charLimit={100} type={'text'} onChange={(e) => setClientPublic(e.target.value)} value={clientPublic}></Input>
                                            </LabelInput>
                                        </>
                                        :
                                        <>
                                            <LabelInput text={'Descriçao'}>
                                                <Input id={'description'} charLimit={50} type={'text'} onChange={(e) => setDescription(e.target.value)} value={description}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Id da conta *'}>
                                                <Input id={'clientId'} charLimit={100} type={'text'} onChange={(e) => setClientId(e.target.value)} value={clientId}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Chave secreta *'}>
                                                <Input id={'clientSecret'} charLimit={100} type={'text'} onChange={(e) => setClientSecret(e.target.value)} value={clientSecret}></Input>
                                            </LabelInput>
                                            {/* <LabelInput text={'Seller id *'}>
                                                <Input id={'sellerId'} charLimit={100} type={'text'} onChange={(e) => setSellerId(e.target.value)} value={sellerId}></Input>
                                            </LabelInput> */}
                                            <LabelInput text={'Chave pública *'}>
                                                <Input id={'clientPublic'} charLimit={100} type={'text'} onChange={(e) => setClientPublic(e.target.value)} value={clientPublic}></Input>
                                            </LabelInput>
                                        </>
                                }

                            </form>
                        </>
                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button approval={false} shadow={true} onClick={() => navigate('/paymentGatewayConfig')}>Voltar</Button>
                    <Button module={"paymentGateway:create"} userData={userData} shadow={true} onClick={() => handleSubmit()}>Salvar</Button>
                </div>
            </Footer>
        </Container>
    )
}