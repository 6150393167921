import React, { useState, useEffect } from 'react';
//components
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';

function Timer({minutesDisplay,  secondsDisplay, progress}) {

    const [seconds, setSeconds] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [internalProgress, setInternalProgress] = useState(0)
    
    useEffect(()=>{
        setSeconds(secondsDisplay)
        setMinutes(minutesDisplay)
        setInternalProgress(progress)
    }, [secondsDisplay])

    return (
        <Tippy content={'Tempo para concluir a etapa de pagamento, caso o tempo esgote, este horário será liberado para outra pessoa'}
        arrow={true}
        animation='shift-away'
        placement='bottom'
        delay={100}>
        <div className='w-full sm:w-96 flex flex-col items-start justify-between'>
            <div className='w-full flex flex-row gap-1 items-start justify-between'>
                <a className='text-xt sm:text-sm text-titleBlackTextLight'>Tempo para conclusão do pagamento:</a>
                <h1>{minutes}:{seconds}</h1>
            </div>
            <div className='shadow-sm shadow-gray-300 w-full rounded-lg bg-gray-300 h-2'>
                <div style={{ width: `${internalProgress}%` }} className={`bg-emerald-500 transition-all duration-500 rounded-lg h-2 w-full`} ></div>
            </div>
        </div>
            </Tippy>
    );
}

export default Timer;
