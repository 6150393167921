export function generateCsv(data) {
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  
  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(',') + '\n';    
    const rows = data.map(obj => Object.values(obj).join(',')).join('\n');
    return header + rows;
  }