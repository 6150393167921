import React, { useEffect, useState, useRef } from 'react'
import { FaArrowDown, FaArrowUp, FaSearch } from 'react-icons/fa'
import { RiArrowUpDownLine } from 'react-icons/ri'
import { DefaultLoader } from '../loaders/defaultLoader'
import { v4 as uuid } from 'uuid'
import { LabelInput } from '../label/label.input'
import { InputAutoComplete } from '../input/input.autocomplete'
import { Input } from '../input/input'
import { Button } from '../buttons/button.default'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { useThemeContext } from '../../contexts/themeContext'
//components
export function TableDefault({ cursor, title, rowTitle, data, collumns, onClick, filter, pagination, totalPages, filters, loading }) {

    const { screenY } = useThemeContext()
    const [sortBy, setSortBy] = useState('')
    const [sortTo, setSortTo] = useState(true)
    const [tableData, setTableData] = useState(data)
    const [startFilter, setStartFilter] = useState(false)
    const [filteredValue, setFilteredValue] = useState(null)
    const [paginationList, setPaginationList] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [filterObject, setFilterObject] = useState({})
    const [thisLoading, setThisLoading] = useState(false)

    function sortTable(sortBy) {

        let tableToSort = tableData
        let sortedArray = sortTo ? tableToSort.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : -1) : tableToSort.sort((a, b) => (b[sortBy] > a[sortBy]) ? 1 : -1)

        setTableData([...sortedArray])
        filterTable()

    }

    useEffect(() => {
        function countTotalPages() {
            let arrayTotalPages = new Array(Number(totalPages > 0 ? totalPages : 1)).fill(0);
            const newPages = []

            arrayTotalPages.map((page, index) => {
                newPages.push(index + 1)
            })
            setPaginationList(newPages)
        }

        if (pagination) {
            countTotalPages()
        }
    }, [totalPages])

    useEffect(() => {
        setTableData([...data])
        filterTable()

    }, [data])

    function filterTable() {

        let filterHasSpace = filteredValue?.indexOf(' ')
        let filterToSearch = filteredValue
        let filteToSearArray = []

        if (filterHasSpace > - 1) {
            while (filterHasSpace !== -1) {
                filteToSearArray.push(filterToSearch.slice(0, filterHasSpace + 1))
                filterToSearch = filterToSearch.replace(filterToSearch.slice(0, filterHasSpace + 1), '')
                filterHasSpace = filterToSearch?.indexOf(' ')
                if (filterHasSpace === - 1 && filterToSearch?.length > 0) {
                    filteToSearArray.push(filterToSearch)
                }
            }
        } else if (filterHasSpace === - 1) {
            filteToSearArray.push(filterToSearch)
        }
        if (tableData?.length === 0 || filteToSearArray?.length > 0) {

            const filter = tableData?.filter((dataOfTable, index) => {
                let collumNames = Object.keys(dataOfTable)

                const filterWords = filteToSearArray.filter((wordToCheck) => {

                    let wordFinded = false

                    const collumnFilter = collumNames.filter((wordInCollumn) => {
                        let wordToSearch = wordToCheck.toString()?.toLowerCase().replace(' ', '')
                        if (!wordFinded && tableData[index][wordInCollumn]?.toString()?.toLowerCase().indexOf(wordToSearch) > -1) {
                            wordFinded = true
                            return wordInCollumn
                        }
                    })

                    if (collumnFilter.length > 0) {
                        return wordToCheck
                    }
                })
                if (filterWords?.length > 0 && (filterWords?.length === filteToSearArray?.length)) {
                    return dataOfTable
                }
            })
            if (filter?.length > 0) {
                return filter
            } else {
                return tableData
            }
        }
    }

    function alterPage(page) {
        filter({ ...filterObject, page: page })
        setActivePage(page)
    }

    function apiFilter(column, filterCustomized) {    
        setSortTo(sortTo)
        setFilterObject({ filter: { column, table: filterCustomized }, direction: !sortTo, page: activePage })
        return filter({ filter: { column, table: filterCustomized }, direction: !sortTo, page: activePage })
    }

    useEffect(() => {
        if (startFilter) {
            filterTable()
        }
    }, [startFilter])

    useEffect(() => {
        setThisLoading(loading)
    }, [loading])

    return (
        <>
            <div className={`w-full dark:border dark:border-secondaryBorderDark`} style={{ maxHeight: screenY - 600 + 'px' }}>
                <table className='select-none border-collapse table-auto overflow-y-auto w-full'>
                    <thead className='bg-primaryDefaultLight'>
                        <tr className='border-x border-gray-300 dark:border-primaryBorderDark '>
                            <th colSpan={title?.length} className=' text-sm dark:bg-thirdDefaultDark text-white font-extrabold h-8 top-0 sticky bg-primaryDefaultLight z-20'>
                                <div className='flex flex-row gap-2 items-center justify-start pl-6 pt-4 h-full'>
                                    {
                                        !pagination &&
                                        <>
                                            <input onKeyDown={(e) => {
                                                if (e.code === 'Enter') {
                                                    setStartFilter(true)
                                                }
                                            }} type='text' placeholder='Filtrar' onChange={(e) => { if (e.target.value?.length === 0) { setStartFilter(false) }; setFilteredValue(e.target.value) }} className='px-2 py-1 focus:shadow-borderShadow outline-none bg-primaryDefaultLight brightness-90 dark:brightness-75 dark:bg-thirdDefaultDark placeholder:text-white'></input>
                                            <div title='Pesquisar' tabIndex={0} onKeyDown={(e) => { if (filteredValue?.length > 0 && e.code === 'Enter') { setStartFilter(true) } }} className='p-1 rounded-lg border-px border-primaryDefaultLight focus:border focus:border-secondaryDefaultLight outline-none cursor-pointer' onClick={() => { if (filteredValue?.length > 0) { setStartFilter(true) } }}>
                                                <FaSearch />
                                            </div>
                                        </>
                                    }
                                </div>
                            </th>
                        </tr>
                        <tr className='border-x border-gray-300 dark:border-secondaryBorderDark '>
                            {
                                title?.map((titleColumn, indexTh) => {
                                    return <th onClick={(e) => { pagination ? apiFilter(titleColumn.original_name, titleColumn?.table) : sortTable(titleColumn?.original_name); setSortBy(titleColumn?.original_name); setSortTo(!sortTo) }}
                                        key={indexTh+"th"} className={`z-20 bg-primaryDefaultLight text-sm xl:text-xs 2xl:text-sm dark:bg-thirdDefaultDark text-white font-extrabold h-10 ${pagination ? 'top-8' : 'top-11'} sticky`}>
                                        <div className='cursor-pointer flex flex-row gap-2 items-center justify-center'>
                                            {titleColumn?.name}
                                            {
                                                sortBy !== titleColumn?.original_name ?
                                                    <RiArrowUpDownLine size={'20'} />
                                                    :
                                                    sortTo ?
                                                        <FaArrowUp size={'14'} />
                                                        :
                                                        <FaArrowDown size={'14'} />
                                            }
                                        </div>
                                    </th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody className=''>
                        {
                            thisLoading ?
                                <tr>
                                    <td colSpan={title.length}>
                                        <div className='flex flex-col items-center justify-center w-full h-96'>
                                            <DefaultLoader />
                                        </div>
                                    </td>
                                </tr>
                                :
                                tableData?.length === 0 ?
                                    <tr key={'1'} className='hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200'>
                                        <td colSpan={title.length} key={`1-1`} className={`text-sm  ${cursor === 'default' ? 'cursor-default' : 'cursor-pointer'} text-center text-titleBlackTextLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>Sem registros</td>
                                    </tr>
                                    :
                                    startFilter && filterTable()?.length === undefined ?
                                        <tr key={'1'} className='hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200'>
                                            <td colSpan={title.length} key={`1-1`} className={`text-sm ${cursor === 'default' ? 'cursor-default' : 'cursor-pointer'} text-center text-titleBlackTextLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>Sem registros</td>
                                        </tr>
                                        :
                                        startFilter ?
                                            filterTable().map((apiData, index) => {

                                                return <tr key={index+"tr"} className='hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200'>
                                                    {
                                                        collumns?.map((collumn, indexCollum) => {
                                                            return <td key={`${indexCollum}-${index}`} onClick={() => onClick(apiData)} className={`text-sm xl:text-xs 2xl:text-sm ${cursor === 'default' ? 'cursor-default' : 'cursor-pointer'} text-center text-titleBlackTextLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>{apiData[collumn]}</td>

                                                        })
                                                    }
                                                </tr>
                                            })
                                            :
                                            tableData.map((apiData, index) => {
                                                return pagination ?
                                                    <tr key={index+"tr2"} className='even:bg-gray-50 odd:bg-white even:dark:bg-primaryDefaultDark dark:odd:bg-primaryDefaultDark hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200'>
                                                        {
                                                            collumns?.map((collumn, indexCollum) => {
                                                                return <td key={`${indexCollum}-${index}`} onClick={() => onClick(apiData)} className={`border-l text-sm xl:text-xs 2xl:text-sm ${cursor === 'default' ? 'cursor-default' : 'cursor-pointer'} text-center text-titleBlackTextLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>{apiData[collumn]}</td>

                                                            })
                                                        }
                                                    </tr>
                                                    :
                                                    <tr key={index+"tr3"} className='even:bg-gray-50  even:dark:bg-transparent dark:odd:bg-secondaryDeafultDark hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200'>
                                                        {
                                                            collumns?.map((collumn, indexCollum) => {
                                                                return <td key={`${indexCollum}-${index}`} onClick={() => onClick(apiData)} className={`border-l text-sm xl:text-xs 2xl:text-sm truncate ${cursor === 'default' ? 'cursor-default' : 'cursor-pointer'} text-center text-titleBlackTextLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>{apiData[collumn]?.length > 40 ? apiData[collumn].slice(0, 40).concat('...') : apiData[collumn]}</td>

                                                            })
                                                        }
                                                    </tr>
                                            })
                        }
                    </tbody>
                </table>
                {
                    pagination &&

                    <div className='px-2 border border-gray-300 dark:border-primaryBorderDark w-full flex flex-row items-center justify-end h-8 bg-bgSecondaryLight dark:bg-thirdDefaultDark gap-1 bottom-0 sticky'>
                        <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>Pagina {activePage} de {totalPages}</a>
                        <div className='flex flex-row items-center justify-start border border-gray-300 rounded-md dark:border-primaryBorderDark'>
                            {
                                activePage > 1 &&
                                <>
                                    <div className='flex flex-col items-center hover:bg-secondaryDefaultLight justify-start border-l px-1 border-gray-300 dark:border-primaryBorderDark'>
                                        <span className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm cursor-pointer' onClick={() => alterPage(1)}>Primeira</span>
                                    </div>
                                    <div className='flex flex-col items-center hover:bg-secondaryDefaultLight justify-start border-l px-1 border-gray-300 dark:border-primaryBorderDark'>
                                        <span className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm cursor-pointer' onClick={() => alterPage(activePage > 0 ? activePage - 1 : activePage)}>Anterior</span>
                                    </div>
                                </>
                            }
                            {
                                activePage > 3 &&
                                <div className='flex flex-col items-center  hover:bg-secondaryDefaultLight justify-start border-l w-10 border-gray-300 dark:border-primaryBorderDark'>
                                    <span className='text-sm cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark'>...</span>
                                </div>
                            }
                            {
                                paginationList.map((page, index) => {
                                    let showPointers = false
                                    let existsPoints = false
                                    const buttonTrue = <div key={`page-${index}-${uuid()}`} className={`flex flex-col items-center hover:bg-secondaryDefaultLight dark:hover:bg-thirdDefaultDark justify-start border-l w-10 border-gray-300 dark:border-primaryBorderDark ${activePage === page ? 'bg-gray-300 dark:bg-secondaryDefaultDark' : ''}`}>
                                        <span onClick={() => alterPage(page)} className='w-10 text-sm cursor-pointer  flex flex-col items-center justify-center text-titleBlackTextLight dark:text-titleGrayTextDark'>{page}</span>
                                    </div>

                                    const buttonFalse = <div key={"page-button"+uuid()} className={`flex flex-col items-center hover:bg-secondaryDefaultLight dark:hover:bg-thirdDefaultDark justify-start border-l w-10 border-gray-300 dark:border-primaryBorderDark ${activePage === page ? 'bg-gray-300 dark:bg-secondaryDefaultDark' : ''}`}>
                                        <span onClick={() => alterPage(page)} className='w-10 text-sm cursor-pointer  flex flex-col items-center justify-center text-titleBlackTextLight dark:text-titleGrayTextDark'>{page}</span>
                                    </div>

                                    if (index + 1 == page && activePage === page) {
                                        return buttonTrue
                                    } else if (activePage + 3 > index + 1 && activePage - 2 < index + 1) {
                                        return buttonFalse
                                    } else {
                                        if (activePage + 3 < index + 1 || activePage - 2 > index + 1) {
                                            showPointers = true
                                        }
                                        if (existsPoints && showPointers == false) {
                                            return <a>...</a>
                                        }
                                    }
                                })
                            }
                            {
                                totalPages > 1 &&
                                <>
                                    {
                                        totalPages > 6 && activePage < paginationList?.length &&
                                        <div className='flex flex-col items-center  hover:bg-secondaryDefaultLight justify-start border-l w-10 border-gray-300 dark:border-primaryBorderDark'>
                                            <span className='text-sm cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark'>...</span>
                                        </div>
                                    }
                                    {
                                        activePage < paginationList?.length &&
                                        <>
                                            <div className='flex flex-col items-center  hover:bg-secondaryDefaultLight justify-start border-l px-1 border-gray-300 dark:border-primaryBorderDark'>
                                                <span className='text-sm cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark' onClick={() => { alterPage(activePage + 1) }}>Próximo</span>
                                            </div>
                                            <div className='flex flex-col items-center  hover:bg-secondaryDefaultLight justify-start border-l px-1 border-gray-300 dark:border-primaryBorderDark'>
                                                <span className='text-sm cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark' onClick={() => alterPage(totalPages)}>Ultima</span>
                                            </div>
                                        </>

                                    }
                                </>
                            }
                        </div>
                    </div>
                }
            </div >
        </>

    )
}
