import React, { useContext, createContext, useState, useEffect } from 'react'
import { api } from '../services/api/api'

//services
import { getCustomerAlerts, getPaymentGateways, getPaymentMethods, getPaymentPublicGateways, getPermissionGroupEspecific, getUserTerms, isAuth } from '../services/api/callAPIsFunctions/defaultCalls.api'
import moment from 'moment'

const AuthContext = createContext()

export function AuthContextProvider({ children }) {

    const [signed, setSigned] = useState(false)
    const [loadingAuth, setLoadingAuth] = useState(false)
    const [userData, setUserData] = useState('')
    const [customersAlerts, setCustomersAlerts] = useState([])
    const [readCustomersAlerts, setReadCustomersAlerts] = useState(false)
    const [haveTermsOfUse, setHaveTermsOfUse] = useState(false)
    const [dontHaveEmail, setDontHaveEmail] = useState(false)
    const userToken = localStorage.getItem('@auth:token')

    async function getCustomersData() {
        let dotPosition = window.location.hostname.indexOf('.')
        const thisCustomers = window.location.hostname.slice(0, dotPosition)

        try {
            const customersData = await api.get(`api/public/customers/${thisCustomers}`)
            return customersData?.data?.data[0]
        } catch (error) {
            console.log(error)
            console.log(error?.response)
        }
    }

    async function verifytoken(userToken) {

        setLoadingAuth(true)

        try {
            const isAuthUser = await isAuth(userToken)
            if (isAuthUser.statusCode === 200) {
                const apiUserData = []
                apiUserData.push(isAuthUser.data.userData)
                apiUserData[0].customersData = await getCustomersData()

                const paymentGateway = await getPaymentPublicGateways(userToken)
                apiUserData[0].customersData.hasGateway = paymentGateway.data?.data?.length > 0 ? true : false
                apiUserData[0].customersData.paymentGateway = paymentGateway.data?.data?.length > 0 ? paymentGateway.data?.data[0] : []

                apiUserData[0].token = isAuthUser.data.token
                apiUserData[0].customers_id = 1
                localStorage.setItem('typeUser', 1)

                //busca os alertas do cliente caso ele ainda não tenha lido nesta sessão
                const lastLogin = localStorage?.getItem('@lastLogin')
                const lastLoginDate = lastLogin?.length > 0 ? moment(lastLogin) : moment('1900-01-01')
                const numberOfDaysSinceLastLogin = lastLoginDate.diff(moment().format("YYYY-MM-DD"))

                if (numberOfDaysSinceLastLogin < 0 && isAuthUser.data.userData?.typeAccess_id < 4) {
                    const alerts = await getCustomerAlerts(userToken)
                    if (alerts.data.data[0]?.message?.length > 0) {
                        setCustomersAlerts(alerts.data.data)
                    }
                    localStorage.setItem('@lastLogin', moment().format('YYYY-MM-DD'))
                }

                const terms = await getUserTerms(isAuthUser.data.token, apiUserData[0]?.id)
                if (terms.data?.data?.length > 0) {
                    if (apiUserData[0].email?.length === 0 || !apiUserData[0].email) {
                        setDontHaveEmail(true)
                    }
                    setUserData(apiUserData)
                    setSigned(true)
                    setLoadingAuth(false)
                    return setHaveTermsOfUse(true)
                }

                if (apiUserData[0]?.email?.length === 0 || !apiUserData[0].email) {
                    setDontHaveEmail(true)                
                }

                setUserData(apiUserData)
                setSigned(true)
                setLoadingAuth(false)

            }

            if (isAuthUser?.statusCode === 401) {

                localStorage.removeItem('@auth:token')
                setLoadingAuth(false)
                setSigned(false)
                return
            }
        } catch (error) {
            console.log(error)
            alert('Erro no login, contate o administrador')
            localStorage.removeItem('@auth:token')
            setSigned(false)
        }
    }

    useEffect(() => {
        verifytoken(userToken)
    }, [])

    return (
        <AuthContext.Provider value={{
            signed,
            setSigned,
            userData,
            setUserData,
            verifytoken,
            getCustomersData,
            loadingAuth,
            setLoadingAuth,
            customersAlerts,
            setCustomersAlerts,
            readCustomersAlerts,
            setReadCustomersAlerts,
            dontHaveEmail,
            setDontHaveEmail,
            haveTermsOfUse,
            setHaveTermsOfUse
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}