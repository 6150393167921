import React, { useEffect, useState } from 'react'
import { TooltipBars } from '../../components/toolTip/toolTipBars'
import ApexCharts from 'apexcharts';
//contexts
import { useThemeContext } from '../../contexts/themeContext';
import { currencyPtBr } from '../../utils/formatCurrency/currencyTextPTBR';

export function HorizontalBars({ id, data, title, bgGradient, bgGradientFirstColor, bgGradientSecondColor, bgColor, }) {

    const { primaryDefaultLight, secondaryDefaultLight, bgSecondaryLight } = useThemeContext()

    useEffect(() => {
        const ordenedData = data.sort((a, b) => a.value - b.value)
        const dataValue = ordenedData.map(apiData => apiData.value)
        const namesValue = ordenedData.map(apiData => apiData.name)
        const darkModeActive = document.getElementsByClassName('dark')?.length ? true : false
        let dataGraph = dataValue
        var options = {

            series: [{
                data: dataGraph,
                y: 10
            }],
            chart: {
                type: 'bar',
                height: dataGraph?.length < 3 ? 200 : dataGraph?.length < 8 ? dataGraph?.length * 50 : dataGraph?.length * 35
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,

                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: darkModeActive ? 'dark' : 'light',
                    type: "horizontal",
                    shadeIntensity: 0.5,
                    gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100],
                    colorStops: []
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return currencyPtBr(val);
                }
            },
            colors: [primaryDefaultLight],
            xaxis: {

                categories: namesValue,
                position: 'top',
                axisBorder: {
                    show: true
                },

                axisTicks: {
                    show: true
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: primaryDefaultLight,
                            colorTo: bgSecondaryLight,
                            stops: [0, 100],
                            opacityFrom: 0.1,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                },
                labels: {
                    style: {
                        colors: darkModeActive ? 'var(--titleGrayTextDark)' : '', // Cor da fonte (red)
                    },
                },
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: true,
                },
                labels: {
                    show: true,
                    formatter: function (val) {
                        return currencyPtBr(val);
                    }
                },
                labels: {
                    style: {
                        colors: darkModeActive ? 'var(--titleGrayTextDark)' : '', // Cor da fonte (red)
                    },
                },
            },

        };

        var chart = new ApexCharts(document.querySelector(`#horizontal`), options);
        chart.render();

    }, [])

    return (
        <div className='w-full' id='horizontal'>

        </div>
    )
}
