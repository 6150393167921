//hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import { FaEdit } from "react-icons/fa";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { TableDefault } from "../../../components/table/table.default";
import { TableMobile } from "../../../components/table/table.mobile";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import moment from 'moment'
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import 'moment/locale/pt-br'
import { Button } from "../../../components/buttons/button.default";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
//services
import { getHolidays } from "../../../services/api/callAPIsFunctions/defaultCalls.api";
//utils
import { responseError } from "../../../utils/responsesFunctions/error.response";

export function HolidaysCalendar() {

    const [loading, setLoading] = useState(true)
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const navigate = useNavigate()
    const [myHolydaysList, setMyHolidaysList] = useState([])

    function formatDate(year, month, day) {
        let thisDay = day < 10 ? '0' + day?.toString() : day
        let thisYear = moment().year()?.toString()
        let thisMonth = month < 10 ? '0' + month?.toString() : month

        try {
            return moment(`${thisYear}-${thisMonth}-${thisDay}`)?.format('DD/MM/YYYY')
        } catch (error) {
            return `${thisYear}-${thisMonth}-${thisDay}`
        }
    }

    useEffect(() => {

        async function getData() {

            setLoading(true)
            try {

                const holidays = await getHolidays(userData[0]?.token)
                if (holidays.data?.length > 0) {
                    holidays.data.map(holiday => {
                        if (holiday?.recurrency) {
                            holiday.holidayDate = formatDate(holiday.year, holiday.month, holiday.day)
                        }
                        if (holiday.recurrency) {
                            holiday.recurrency = 'Sim'
                        } else {
                            holiday.holidayDate = holiday.holidayDate.slice(0, holiday.holidayDate?.length)
                            holiday.recurrency = 'Não'
                        }
                    })
                    setMyHolidaysList(holidays.data)
                }

                setLoading(false)

            } catch (error) {
                console.log(error)
                if (responseError(error).length > 0) {
                    setLoading(false)
                    animateLoading(true)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    animateLoading(true)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        getData()

    }, [])

    const tableThead = [
        {
            "name": 'Descrição',
            "original_name": 'description'
        },
        {
            "name": 'Data',
            "original_name": 'day'
        },

        {
            "name": 'Recorrente',
            "original_name": "recurrency"
        },
        {
            "name": 'Criado em',
            "original_name": "created_at"
        },
    ]

    function handleEdit(data){

        navigate(`/holidayCalendarEdit/id/${data.id}`)

    }


    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full">
                    <Title text={'Calendário de feriados'} />
                    <Button module={"holidays:create"} userData={userData} shadow={true} onClick={() => navigate('/holidayCalendarCreate')}>Novo</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    loading ?
                        <div className="w-full h-96 flex flex-col items-center justify-center">
                            <DefaultLoader />
                        </div>
                        :
                        <div className="w-full flex flex-col gap-4">
                            <div className='p-4 bg-zinc-50 border border-zinc-100 rounded-lg dark:bg-thirdDefaultDark dark:border-secondaryBorderDark text-base'>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextLight'>
                                    Cadastre os feriados para que sejam observados pelo sistema para considerar nas reservas
                                </p>
                            </div>
                            <div id='holidayCalendarTable' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                <TableDefault data={myHolydaysList}
                                    onClick={(e) => navigate(`/holidayCalendarEdit/id/${e.id}`)}
                                    title={tableThead}
                                    collumns={['description', 'holidayDate', 'recurrency', 'created_at']} />
                            </div>
                            <div id='holidayCalendarTableMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                <TableMobile
                                    onClick={(e) => e.func.execute(e.data)}
                                    title={tableThead}
                                    data={myHolydaysList}
                                    collumns={['description', 'holidayDate', 'recurrency', 'created_at']}
                                    functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                />
                            </div>
                        </div>
                }
            </Body>
        </Container>
    )
}