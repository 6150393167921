import { responseError } from "../../../utils/responsesFunctions/error.response"
import { api } from "../api"
import moment from "moment"
import { maskHours } from '../../../utils/mask/maskHours'
import { currencyPtBr } from "../../../utils/formatCurrency/currencyTextPTBR"

export function formattTimeToShow(apiTime) {
    try {
        let zPos = apiTime?.indexOf('Z')
        let offset = moment().utcOffset()

        let time = apiTime
        let localTime = moment(apiTime).format("L LT")?.replace('00:00', '')
        if (zPos > - 1) {
            time = apiTime.slice(0, zPos)
            time = time.replace('T', ' ')
            localTime = moment.utc(time).utcOffset(offset).format("L LT");
        }

        return localTime
    } catch (error) {
        console.log(error)
        return apiTime
    }
}

//isAuth token
export async function isAuth(tokenKey) {

    try {
        const isAuthData = await api.get('/auth/isAuth', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            statusCode: isAuthData.data.status,
            data: isAuthData.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                statusCode: error.response.data.statusCode,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                statusCode: error.response.data.statusCode,
                data: 'Erro inesperado'
            }
        }
    }

}

//get customers
export async function getCustomers(tokenKey) {

    try {
        const customersData = await api.get(`/api/v1/customers`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: customersData.data.data[0]
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getCustomersAttendance
export async function getCustomersAttendance(tokenKey) {

    try {
        const ccustomersAttendance = await api.get(`/api/v1/customersAttendance`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: ccustomersAttendance.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//typeAccess get
export async function getTypeAccess(tokenKey) {

    try {
        const typeAccessData = await api.get('/api/v1/typeAccess', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: typeAccessData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get users
export async function getUsers(tokenKey) {

    try {
        const userData = await api.get(`/api/v1/users`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        userData.data.data.map((apiData, index) => {
            userData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
            userData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
        })
        let newData = userData.data.data
        return {
            error: false,
            data: newData
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get clients
export async function getUsersFilteredClients(tokenKey, filter, page, direction, active) {

    let params = `/api/v1/users?typeAccess_id=4&direction=${direction ? 'asc' : 'desc'}`

    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params
    params = active ? params.concat(`&active=true`) : params.concat(`&active=false`)

    if (filter?.filterObject?.length > 0) {

        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }
    try {
        const userData = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        userData.data.data.map((apiData, index) => {
            userData.data.data[index].name = userData.data.data[index].firstName + ' ' + userData.data.data[index].lastName
            userData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
            userData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
        })
        let newData = { data: userData.data.data, totalPages: userData.data.totalPages, quantity: userData.data.quantity }
        return {
            error: false,
            data: newData
        }
    } catch (error) {
        console.log(error.response)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get users
export async function getUsersFilteredUsers(tokenKey, filter, page, direction, active) {

    let params = `/api/v1/users?typeAccess_id=2,3&direction=${direction ? 'asc' : 'desc'}`

    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params
    params = active ? params.concat(`&active=true`) : params.concat(`&active=false`)

    if (filter?.filterObject?.length > 0) {
        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }
    try {
        const userData = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        userData.data.data.map((apiData, index) => {
            userData.data.data[index].name = userData.data.data[index].firsName + ' ' + userData.data.data[index].lastName
            userData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
            userData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
        })
        let newData = { data: userData.data.data.filter(user => user.id !== -1), totalPages: userData.data.totalPages, quantity: userData.data.quantity }
        return {
            error: false,
            data: newData
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getAllUsersFiltered
export async function getAllUsersFiltered(tokenKey, filter, page, direction, active, activeAssociation) {

    let params = `/api/v1/users?typeAccess_id=1,2,3,4&direction=${direction ? 'asc' : 'desc'}`

    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params
    params = active ? params.concat(`&active=true`) : params.concat(`&active=false`)
    params = activeAssociation ? params.concat(`&activeAssociation=true`) : params

    if (filter?.filterObject?.length > 0) {

        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }
    try {
        const userData = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        userData.data.data.map((apiData, index) => {
            userData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
            userData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
        })
        let newData = { data: userData.data.data.filter(user => user.id !== -1), totalPages: userData.data.totalPages }
        return {
            error: false,
            data: newData
        }
    } catch (error) {
        console.log(error.response)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//user especific
export async function getUserEspecific(tokenKey, userId) {

    try {
        const userData = await api.get(`/api/v1/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: userData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//delete user especific
export async function deleteUserEspecific(tokenKey, userId) {

    try {
        const userData = await api.delete(`/api/v1/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: userData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//user permissions get
export async function getPermissions(tokenKey) {

    try {
        const permissionsData = await api.get('/api/v1/permissions', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: permissionsData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get associations
export async function getAssociations(tokenKey, status = null) {

    try {
        const associationsData = await api.get(`/api/v1/associations${[1, 0].includes(status) ? `?active=${status}` : ''}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: associationsData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getUserDependentsByUsersId
export async function getUserDependentsByUsersId(tokenKey, usersId) {

    try {
        const data = await api.get(`/api/v1/usersDependents/users_id/${usersId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: data.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get associations by id
export async function getAssociationsById(tokenKey, id) {

    try {
        const associationsData = await api.get(`/api/v1/associations/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: associationsData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//delete associations by id
export async function associationsDelete(tokenKey, id) {

    try {
        const associationsData = await api.delete(`/api/v1/associations/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: associationsData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//permissionGroups get
export async function getPermissionGroups(tokenKey) {

    try {
        const permissionGroupsData = await api.get('/api/v1/permissionGroups', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        permissionGroupsData.data.data.map((apiData) => {
            apiData.created_at = formattTimeToShow(apiData.created_at)
        })
        return {
            error: false,
            data: permissionGroupsData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//permissionGroups especific get
export async function getPermissionGroupEspecific(tokenKey, id) {

    try {
        const permissionGroupData = await api.get(`/api/v1/permissionGroups/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: permissionGroupData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//myProfile
export async function getMyProfile(tokenKey) {

    try {
        const userData = await api.get(`/api/v1/myProfile`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: userData.data.data
        }


    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getServices
export async function getServices(tokenKey, active) {

    let endpoint = active ? `/api/v1/services?active=${active}` : `/api/v1/services`
    try {
        const servicesData = await api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: servicesData.data.data
        }


    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getServicesByCategoriesId
export async function getServicesByCategoriesId(tokenKey, categoriesId) {

    let endpoint = `/api/v1/services/categories_id/${categoriesId}?active=1`
    try {
        const servicesData = await api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: servicesData.data.data
        }


    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getServices
export async function getServicesPagination(tokenKey, filter, page, direction, active) {

    let params = active ? `/api/v1/services?active=${active}&direction=${direction ? 'asc' : 'desc'}` : `/api/v1/services?active=false&direction=${direction ? 'asc' : 'desc'}`

    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params

    if (filter?.filterObject?.length > 0) {
        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }

    try {
        const servicesData = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: servicesData.data
        }


    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getServicesEspecific
export async function getServicesEspecific(tokenKey, id) {

    try {
        const servicesData = await api.get(`/api/v1/services/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: servicesData.data.data
        }


    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getSchedulesBlocked
export async function getSchedulesBlocked(tokenKey, serviceId, filter, page, direction, active) {

    let params = `api/v1/schedulesBlock/${serviceId}?direction=${direction ? 'asc' : 'desc'}`

    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params

    if (filter?.filterObject?.length > 0) {
        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }
    try {
        const scheduleBlocked = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        scheduleBlocked.data.data.data.map((blocked, index) => {
            blocked.created_at = formattTimeToShow(blocked.created_at)
            blocked.startDate = `${formattTimeToShow(blocked.startDate)} - ${maskHours({ hour: blocked.startHour })}`
            blocked.finishDate = `${formattTimeToShow(blocked.finishDate)} - ${maskHours({ hour: blocked.finishHour })} `
        })
        return {
            error: false,
            data: scheduleBlocked.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getSchedulesForAnotherPerson
export async function getSchedulesForAnotherPerson(tokenKey, day, serviceId, userId) {

    try {
        const servicesData = await api.get(`/api/v1/schedules/availableHours/${day}/services_id/${serviceId}/schedule_users_id/${userId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: servicesData.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getFilteredSchedules
export async function getFilteredSchedules(tokenKey, filter, page, direction) {

    let params = `/api/v1/schedules/?direction=${direction ? 'asc' : 'desc'}`

    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = filter?.table ? params.concat(`&orderByTable=${filter.table}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params

    if (filter?.filterObject?.length > 0) {
        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }

    try {
        const schedulesData = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        schedulesData.data.data.map((apiData, index) => {
            schedulesData.data.data[index].original_created_at = apiData.created_at
            schedulesData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
            schedulesData.data.data[index].original_startDate = apiData.startDate
            schedulesData.data.data[index].startDate = formattTimeToShow(apiData.startDate)
            schedulesData.data.data[index].original_finishDate = apiData.finishDate
            schedulesData.data.data[index].finishDate = formattTimeToShow(apiData.finishDate)
            schedulesData.data.data[index].original_startHour = apiData.startHour
            schedulesData.data.data[index].startHour = maskHours({ hour: apiData.startHour })
            schedulesData.data.data[index].original_finishHour = apiData.finishHour
            schedulesData.data.data[index].finishHour = maskHours({ hour: apiData.finishHour })
        })
        let newData = { data: schedulesData.data.data, totalPages: schedulesData.data.totalPages }
        return {
            error: false,
            data: newData
        }
    } catch (error) {
        console.log(error.response)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getSchedulesHours
export async function getSchedulesHours(tokenKey, day, serviceId) {

    try {

        const servicesData = await api.get(`/api/v1/schedules/client/availableHours/${day}/services_id/${serviceId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: servicesData.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getCategories
export async function getCategories(tokenKey, active) {

    let endPoint = active !== undefined ? `/api/v1/categories?active=${active}` : `/api/v1/categories`

    try {
        const categoriesData = await api.get(endPoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: categoriesData.data.data
        }


    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDefaultTableHours
export async function getDefaultTableHours(tokenKey) {

    try {
        const defaultTableDefaultOpenHours = await api.get(`/api/v1/scheduleTableDefaultOpenHours`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: defaultTableDefaultOpenHours.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getServicesValuePerAssociation
export async function getServicesValuePerAssociation(tokenKey, serviceId) {

    try {
        const servicesValuePerAssociation = await api.get(`/api/v1/servicesValuePerAssociations/services_id/${serviceId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: servicesValuePerAssociation.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getServicesHoursEspecific
export async function getServicesHoursEspecific(tokenKey, serviceId) {

    try {
        const getServicesHoursEspecific = await api.get(`/api/v1/servicesHours/${serviceId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: Object.create(getServicesHoursEspecific.data.data)
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getCategoriesEspecific
export async function getCategoriesEspecific(tokenKey, id) {
    try {
        const categoriesData = await api.get(`/api/v1/Categories/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: categoriesData.data.data
        }


    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getAssociationsCategoriesDiscount
export async function getAssociationsCategoriesDiscount(tokenKey, categorieId) {

    try {
        const associationsCategoriesDiscount = await api.get(`/api/v1/associationsCategoriesDiscount/categories_id/${categorieId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: associationsCategoriesDiscount.data.data
        }


    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getCategoriesReservationPerHour
export async function getCategoriesReservationPerHour(tokenKey, categorieId) {

    try {
        const categoriesReservationHour = await api.get(`/api/v1/configCategoriesReservationPerHour/${categorieId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: categoriesReservationHour.data.data
        }


    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}


//get paymentMethods
export async function getPaymentMethods(tokenKey) {

    try {
        const paymentMethodsData = await api.get(`/api/v1/paymentMethods`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: paymentMethodsData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get paymentGatewayOptions
export async function getPaymentOptionsGateways(tokenKey) {

    try {
        const paymentGatewayOptionsData = await api.get(`/api/v1/paymentGatewayOptions`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: paymentGatewayOptionsData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get paymentPublicGateway
export async function getPaymentPublicGateways(tokenKey) {

    let endpoint = `/api/v1/paymentGateway/public`

    try {

        const paymentGatewayData = await api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: paymentGatewayData?.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get paymentGateway
export async function getPaymentGateways(tokenKey, status) {

    let endpoint = `/api/v1/paymentGateway?active=${status}`

    try {
        const paymentGatewayData = await api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        if (paymentGatewayData.data.data?.length > 0) {
            paymentGatewayData.data.data.map(gateway => gateway.created_at = formattTimeToShow(gateway.created_at))
        }
        return {
            error: false,
            data: paymentGatewayData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get paymentById
export async function getPaymentById(tokenKey, paymentId) {

    try {
        const paymentGatewayData = await api.get(`/api/v1/payments/${paymentId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: paymentGatewayData.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get termosOfUse
export async function getTermsOfUse(tokenKey, active, id) {

    let endPoint = id ? `/api/v1/termsOfUse/${id}` : active ? `/api/v1/termsOfUse?active=true` : '/api/v1/termsOfUse'

    try {
        const termsOfUseData = await api.get(endPoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        termsOfUseData.data.data.map((term, index) => {
            termsOfUseData.data.data[index].created_at = formattTimeToShow(termsOfUseData.data.data[index]?.created_at)
        })
        return {
            error: false,
            data: termsOfUseData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get termosOfUseEspecific
export async function getTermsOfUseEspecific(tokenKey, id) {

    try {
        const termsOfUseData = await api.get(`/api/v1/termsOfUse/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        termsOfUseData.data.data.map((term, index) => {
            termsOfUseData.data.data[index].created_at = formattTimeToShow(termsOfUseData.data.data[index]?.created_at)
        })
        return {
            error: false,
            data: termsOfUseData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}
//getTermsOfUseByServicesId
export async function getTermsOfUseByServicesId(tokenKey, id) {

    try {
        const termsOfUseData = await api.get(`/api/v1/termsOfUse?servicesId=${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        termsOfUseData.data.data.map((term, index) => {
            termsOfUseData.data.data[index].created_at = formattTimeToShow(termsOfUseData.data.data[index]?.created_at)
        })
        return {
            error: false,
            data: termsOfUseData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}
//getTimezones
export async function getTimeZones(tokenKey) {

    try {
        const timeZoneData = await api.get(`/api/v1/timezones`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: timeZoneData.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getHolidays
export async function getHolidays(tokenKey) {

    try {
        const holidays = await api.get(`/api/v1/holidays`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        holidays.data.data.map((holiday) => {

            holiday?.holidayDate?.length > 0 ? holiday.holidayDate = formattTimeToShow(holiday.holidayDate) : holiday.holidayDate = holiday.holidayDate
            holiday?.created_at?.length > 0 ? holiday.created_at = formattTimeToShow(holiday.created_at) : holiday.created_at = holiday.created_at
        })

        return {
            error: false,
            data: holidays.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getActivities
export async function getActivities(tokenKey, status = null) {

    try {
        const activities = await api.get(`/api/v1/activities${[1, 0].includes(status) ? `?active=${status}` : ''}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        activities.data.data.map((plan) => {
            plan?.created_at?.length > 0 ? plan.created_at = formattTimeToShow(plan.created_at) : plan.created_at = plan.created_at,
                plan?.typeOfCharge === 'R' ? plan.typeOfCharge = 'Recorrente' : plan.typeOfCharge = 'Parcelado'
        })

        return {
            error: false,
            data: activities.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getActivitiesById
export async function getActivitiesById(tokenKey, id) {

    try {
        const activities = await api.get(`/api/v1/activities/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        activities.data.data.map((plan) => {
            plan?.created_at?.length > 0 ? plan.created_at = formattTimeToShow(plan.created_at) : plan.created_at = plan.created_at,
                plan?.typeOfCharge === 'R' ? plan.typeOfCharge = 'Recorrente' : plan.typeOfCharge = 'Parcelado'
        })
        return {
            error: false,
            data: activities.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getActivitiesPlans
export async function getActivitiesPlans(tokenKey, status) {

    let endpoint = [1, 0].includes(status) ? `/api/v1/activitiesPlans?active=${status}` : `/api/v1/activitiesPlans`
    try {
        const plans = await api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        plans.data.data.map((plan) => {
            plan?.created_at?.length > 0 ? plan.created_at = formattTimeToShow(plan.created_at) : plan.created_at = plan.created_at,
                plan?.typeOfCharge === 'R' ? plan.typeOfCharge = 'Recorrente' : plan.typeOfCharge = 'Parcelado'
        })

        return {
            error: false,
            data: plans.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getActivitiesPlans
export async function getActivitiesPlansById(tokenKey, planId) {

    try {
        const plans = await api.get(`/api/v1/activitiesPlans/${planId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: plans.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getActivitiesPlansPerAssociations
export async function getActivitiesPlansPerAssociations(tokenKey) {

    try {
        const plans = await api.get(`/api/v1/activitiesPlansPerAssociations`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        plans.data.data.map((plan) => {
            plan?.created_at?.length > 0 ? plan.created_at = formattTimeToShow(plan.created_at) : plan.created_at = plan.created_at
        })
        return {
            error: false,
            data: plans.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getActivitiesPlansPerAssociations
export async function getActivitiesPlansPerAssociationsById(tokenKey, activities_id) {

    try {
        const plans = await api.get(`/api/v1/activitiesPlansPerAssociations/${activities_id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        plans.data.data.map((plan) => {
            plan?.created_at?.length > 0 ? plan.created_at = formattTimeToShow(plan.created_at) : plan.created_at = plan.created_at
        })
        return {
            error: false,
            data: plans.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getActivitiesRegulation
export async function getActivitiesRegulation(tokenKey, activitieId) {
    try {
        const plans = await api.get(`/api/v1/activities/${activitieId}/regulation`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            },
            responseType: 'blob'
        })

        return {
            error: false,
            data: plans.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getActivitiesPictures
export async function getActivitiesPictures(tokenKey, activitieId, picture) {
    try {
        const activitiePictures = await api.get(`/api/v1/activitiesPictures/activities_id/${activitieId}/picture/${picture}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            },
            responseType: 'blob',
            responseEncoding: 'base64',
        })

        return {
            error: false,
            data: activitiePictures.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getMyBalance
export async function getMyBalance(tokenKey, usersId) {
    try {
        const balance = await api.get(`/api/v1/balance/`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: balance.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getMyBalanceHistory
export async function getMyBalanceHistory(tokenKey, usersId) {
    try {
        const balance = await api.get(`/api/v1/balance/history/`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        balance.data.data.map(balance => {
            balance.original_created_at = balance.created_at,
                balance.created_at = formattTimeToShow(balance.created_at),
                balance.original_value = balance.value,
                balance.value = currencyPtBr(balance.value)
        })

        return {
            error: false,
            data: balance.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getBalanceHistory
export async function getBalanceHistory(tokenKey, usersId) {
    try {
        const balance = await api.get(`/api/v1/balance/users_id/${usersId}/history`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        balance.data.data.map(balance => {
            balance.original_created_at = balance.created_at,
                balance.created_at = formattTimeToShow(balance.created_at),
                balance.original_value = balance.value,
                balance.value = currencyPtBr(balance.value)
        })

        return {
            error: false,
            data: balance.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getMyBalanceData
export async function getMyBalanceData(tokenKey, usersId) {
    try {
        const balance = await api.get(`/api/v1/balance/users_id/${usersId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: balance.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getGastronomy
export async function getGastronomy(tokenKey) {
    try {
        const gastronomy = await api.get(`/api/v1/gastronomy`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        try {
            gastronomy.data.data.map((apiData, index) => {
                gastronomy.data.data[index].created_at = formattTimeToShow(apiData.created_at)
            })
        } catch (error) {
            console.log(error)
        }
        return {
            error: false,
            data: gastronomy.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getGastronomyByStatus
export async function getGastronomyByStatus(tokenKey, active) {
    try {
        const gastronomy = await api.get(`/api/v1/gastronomy/active/${active}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        try {
            gastronomy.data.data.map((apiData, index) => {
                gastronomy.data.data[index].created_at = formattTimeToShow(apiData.created_at)
            })
        } catch (error) {
            console.log(error)
        }
        return {
            error: false,
            data: gastronomy.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getGastronomyById
export async function getGastronomyById(tokenKey, gastronomyId) {
    try {
        const gastronomy = await api.get(`/api/v1/gastronomy/gastronomy_id/${gastronomyId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: gastronomy.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getCustomersDetails
export async function getCustomersDetails(tokenKey) {
    try {
        const customerDetails = await api.get(`/api/v1/customers/details`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: customerDetails.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getFilteredStructures
export async function getFilteredStructures(tokenKey, filter, page, direction, active) {

    let params = `/api/v1/structures?direction=${direction ? 'asc' : 'desc'}`

    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params
    params = active ? params.concat(`&active=true`) : params.concat(`&active=false`)

    if (filter?.filterObject?.length > 0) {
        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }
    try {
        const structures = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        structures.data.data.map((apiData, index) => {
            structures.data.data[index].created_at = formattTimeToShow(apiData.created_at)
        })
        let newData = { data: structures.data.data, totalPages: structures.data.totalPages, quantity: structures.data.quantity }
        return {
            error: false,
            data: newData
        }
    } catch (error) {
        console.log(error.response)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getStructuresById
export async function getStructuresById(tokenKey, structures_id) {
    try {
        const structures = await api.get(`/api/v1/structures/structures_id/${structures_id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: structures.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getSelectedUserBalaceă
export async function getSelectedUserBalaceă(tokenKey, users_id) {
    try {
        const balance = await api.get(`/api/v1/balance/users_id/${users_id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: balance.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getSelectedUserBalaceă
export async function getStructures(tokenKey, active) {

    let endPoint = active ? `/api/v1/structures?active=true` : `/api/v1/structures`
    try {
        const structures = await api.get(endPoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: structures.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getAgreements
export async function getAgreements(tokenKey, filter, page, direction, categories) {

    let params = `/api/v1/agreements?direction=${direction ? 'asc' : 'desc'}`

    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params
    params = categories ? params.concat(`&categories=${categories}`) : params

    if (filter?.filterObject?.length > 0) {
        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }
    try {
        const agreements = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: agreements.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getAgreementsById
export async function getAgreementsById(tokenKey, id) {

    let params = `/api/v1/agreements/agreements_id/${id}`

    try {
        const agreements = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: agreements.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getAgreementsPicture
export async function getAgreementsPicture(tokenKey, agreementId, picture) {

    let params = `/api/v1/agreementsPictures/agreements_id/${agreementId}/picture/${picture}`

    try {
        const picture = await api.get(params, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: picture.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getAgreementsCategories
export async function getAgreementsCategories(tokenKey, filter, page, direction, active) {

    let params = `/api/v1/agreements/categories`
    try {
        const structures = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: structures.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getEvents
export async function getEvents(tokenKey, filter, page, direction, active) {

    let params = `/api/v1/events?direction=${direction ? 'asc' : 'desc'}`

    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params

    if (filter?.filterObject?.length > 0) {
        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }

    try {
        const events = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: events.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getEventsById
export async function getEventsById(tokenKey, id) {

    let params = `/api/v1/events/events_id/${id}`

    try {
        const structures = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: structures.data.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getEventsPicture
export async function getEventsPicture(tokenKey, agreementId, picture) {

    let params = `/api/v1/eventsPictures/events_id/${agreementId}/picture/${picture}`

    try {
        const picture = await api.get(params, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: picture.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getMySchedules
export async function getMySchedules(tokenKey, filter, page, direction, active) {
    let params = `/api/v1/schedules/client?direction=${direction ? 'asc' : 'desc'}`


    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params
    params = filter?.table ? params.concat(`&orderByTable=${filter.table}`) : params
    // params = active ? params.concat(`&active=true`) : params.concat(`&active=false`)
    if (filter?.filterObject?.length > 0) {

        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }
    try {
        const schedules = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: schedules.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getSchedulesClientsMyPendings
export async function getSchedulesClientsMyPendings(tokenKey, active) {

    let endpoint = `/api/v1/schedules/client/myPendings`
    try {
        const data = await api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getExternalPaymentStatus
export async function getExternalPaymentStatus(tokenKey, paymentId) {

    let endpoint = `/api/v1/payments/external/consultStatus/${paymentId}`

    try {
        const data = await api.patch(endpoint, {}, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: data.data.data
        }



    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getBlockedUsers
export async function getBlockedUsers(tokenKey, filter, page, direction) {

    let params = `/api/v1/blockedUsers?direction=${direction ? 'asc' : 'desc'}`

    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params

    if (filter?.filterObject?.length > 0) {
        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }
    try {
        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getBlockedUsersByBlockedId
export async function getBlockedUsersByBlockedId(tokenKey, blockedId) {
    let endpoint = `/api/v1/blockedUsers/blocked_id/${blockedId}`
    try {
        const data = await api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//geSchedulesDraw
export async function geSchedulesDraw(tokenKey) {
    let endpoint = `/api/v1/schedulesDraw`
    try {
        const data = await api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}


//get customerAlerts
export async function getCustomerAlerts(tokenKey) {

    let endpoint = `/api/v1/customersAlerts`

    try {

        const data = await api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: data?.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getuserTerms
export async function getUserTerms(tokenKey, userId) {

    let endpoint = `/api/v1/userTerms/users_id/${userId}`

    try {

        const data = await api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: data?.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}
//getUserTermsDetails
export async function getUserTermsDetails(tokenKey, terms_id) {

    let endpoint = `/api/v1/userTerms/terms_id/${terms_id}`

    try {

        const data = await api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data?.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get siteBanners
export async function getSiteBanners(tokenKey, id, page) {

    let endpoint = page ? `/api/v1/siteBanners?page=${page}` : id ? `/api/v1/siteBanners/${id}` : `/api/v1/siteBanners/`

    try {

        const data = await api.get(endpoint, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getActivitiesSubscriptions
export async function getActivitiesSubscriptions(tokenKey, filter, page, direction, active) {

    let params = `/api/v1/activitiesSubscriptions?direction=${direction ? 'asc' : 'desc'}`
    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = filter?.table ? params.concat(`&orderByTable=${filter.table}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params
    params = active == 1 ? params.concat(`&active=true`) : params.concat(`&active=false`)
    if (filter?.filterObject?.length > 0) {

        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getActivitiesSubscriptions
export async function getActivitiesSubscriptionsById(tokenKey, id) {

    let params = `/api/v1/activitiesSubscriptions/subscriptions_id/${id}`
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getImportProgress
export async function getImportProgress(tokenKey, id) {

    let params = `/api/v1/users/import/progress`
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getMyActivitiesSubscriptionsById
export async function getMyActivitiesSubscriptionsById(tokenKey, id) {

    let params = `/api/v1/activitiesSubscriptions/client/subscriptions_id/${id}`
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getMyActivitiesSubscriptions
export async function getMyActivitiesSubscriptions(tokenKey, filter, page, direction, active) {

    let params = `/api/v1/activitiesSubscriptions/client?direction=${direction ? 'asc' : 'desc'}`
    params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
    params = filter?.table ? params.concat(`&orderByTable=${filter.table}`) : params
    params = page > 0 ? params.concat(`&page=${page}`) : params
    params = active == 1 ? params.concat(`&active=true`) : params.concat(`&active=false`)
    if (filter?.filterObject?.length > 0) {

        filter.filterObject.map(toFilter => {
            params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
        })
    }
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getActivitiesSubscriptionsPendings
export async function getActivitiesSubscriptionsPendings(tokenKey, id) {

    let params = `/api/v1/activitiesSubscriptions/client/myPendings`
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDashboardGeneralData
export async function getDashboardGeneralData(tokenKey) {

    let params = `/api/v1/dashboards/schedules/general`
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDashboardRevenueOfYear
export async function getDashboardRevenueOfYear(tokenKey, year) {

    let params = `/api/v1/dashboards/schedules/revenueOfYear/${year}`
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDashboardIncomeByCategory
export async function getDashboardIncomeByCategory(tokenKey, year) {

    let params = year ? `/api/v1/dashboards/schedules/incomeByCategory?year=${year}` : `/api/v1/dashboards/schedules/incomeByCategory`

    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDashboardSchedulesPerCategory
export async function getDashboardSchedulesPerCategory(tokenKey, month) {

    let params = month ? `/api/v1/dashboards/schedules/schedulesPerCategory?month=${month}` : `/api/v1/dashboards/schedules/schedulesPerCategory`
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDashboardPaymentMethods
export async function getDashboardPaymentMethods(tokenKey, year) {

    let params = year ? `/api/v1/dashboards/schedules/paymentMethods?year=${year}` : `/api/v1/dashboards/schedules/paymentMethods`
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDashboardPaymentMethodsActivities
export async function getDashboardPaymentMethodsActivities(tokenKey, year) {

    let params = year ? `/api/v1/dashboards/activitiesSubscriptions/paymentMethods?year=${year}` : `/api/v1/dashboards/activitiesSubscriptions/paymentMethods`
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDashboardActivitiesSubscriptionsGeneralData
export async function getDashboardActivitiesSubscriptionsGeneralData(tokenKey) {

    let params = `/api/v1/dashboards/activitiesSubscriptions/general`
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDashboardActivitiesSubscriptionsRevenueOfYear
export async function getDashboardActivitiesSubscriptionsRevenueOfYear(tokenKey, year) {

    let params = `/api/v1/dashboards/activitiesSubscriptions/revenueOfYear/${year}`
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDashboardActivitiesSubscriptionsRevenueOfYear
export async function getDashboardActivitiesSubscriptionsByActivitie(tokenKey, month) {

    let params = month ? `/api/v1/dashboards/activitiesSubscriptions/activitiesSubscriptionsByActivitie?month=${month}` : `/api/v1/dashboards/activitiesSubscriptions/activitiesSubscriptionsByActivitie`
    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDashboardActivitiesSubscriptionsIncomeByActivitie
export async function getDashboardActivitiesSubscriptionsIncomeByActivitie(tokenKey, year) {

    let params = year ? `/api/v1/dashboards/activitiesSubscriptions/incomeByActivitie?year=${year}` : `/api/v1/dashboards/activitiesSubscriptions/incomeByActivitie`

    try {

        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: data.data?.data
        }

    } catch (error) {
        console.log(error)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//reports
//getFilteredSchedulesReports
export async function getFilteredSchedulesReports(tokenKey, filter) {

    let params = `/api/v1/reports/schedules?`
    if (filter.length > 0) {
        filter.map((toFilter, index) => {
            if (typeof (toFilter?.value) !== 'object') {
                params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${toFilter.value}`)
            } else {
                toFilter?.value?.map(thisValue => {
                    params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${thisValue.id}`)

                })
            }
        })
    }
    try {
        const schedulesData = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`,
            },
            responseType: 'blob'
        })
        return {
            error: false,
            data: schedulesData.status === 204 ? schedulesData : schedulesData.data
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getFilteredSchedulesPaymentsReports
export async function getFilteredSchedulesPaymentsReports(tokenKey, filter) {

    let params = `/api/v1/reports/schedulesPayments?`
    if (filter.length > 0) {
        filter.map((toFilter, index) => {
            if (typeof (toFilter?.value) !== 'object') {
                params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${toFilter.value}`)
            } else {
                toFilter?.value?.map(thisValue => {
                    params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${thisValue.id}`)

                })
            }
        })
    }
    try {
        const schedulesData = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`,
            },
            responseType: 'blob'
        })

        return {
            error: false,
            data: schedulesData.status === 204 ? schedulesData : schedulesData.data
        }
    } catch (error) {
        console.log(error.response)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getFilteredSchedulesBlockReports
export async function getFilteredSchedulesBlockReports(tokenKey, filter) {

    let params = `/api/v1/reports/schedulesBlocks?`
    if (filter.length > 0) {
        filter.map((toFilter, index) => {
            if (typeof (toFilter?.value) !== 'object') {
                params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${toFilter.value}`)
            } else {
                toFilter?.value?.map(thisValue => {
                    params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${thisValue.id}`)

                })
            }
        })
    }
    try {
        const schedulesData = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`,
            },
            responseType: 'blob'
        })

        return {
            error: false,
            data: schedulesData.status === 204 ? schedulesData : schedulesData.data
        }
    } catch (error) {
        console.log(error.response)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getFilteredActivitiesSubscriptionsReport
export async function getFilteredActivitiesSubscriptionsReport(tokenKey, filter) {
    let params = `/api/v1/reports/activitiesSubscriptions?`
    if (filter.length > 0) {
        filter.map((toFilter, index) => {
            params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${toFilter.value}`)
        })
    }
    try {
        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`,
            },
            responseType: 'blob'
        })

        return {
            error: false,
            data: data.status === 204 ? data : data.data

        }
    } catch (error) {
        console.log(error.response)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getFilteredPaymentsReport
export async function getFilteredPaymentsReport(tokenKey, filter) {
    let params = `/api/v1/reports/payments?`
    if (filter.length > 0) {
        filter.map((toFilter, index) => {
            if (typeof (toFilter?.value) !== 'object') {
                params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${toFilter.value}`)
            } else {
                toFilter?.value?.map(thisValue => {
                    params = params.concat(`${index > 0 ? '&' : ''}${toFilter.original_name}=${thisValue.id}`)

                })
            }
        })
    }
    try {
        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`,
            },
            responseType: 'blob'
        })

        return {
            error: false,
            data: data.status === 204 ? data : data.data

        }
    } catch (error) {
        console.log(error.response)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getLogs
export async function getLogs(
    tokenKey,
    page,
    table_name,
    table_id,
    requestId,
    users_id,
    created_at,
    startDate,
    finishDate,
    orderBy,
    direction
) {

    let params = `/api/v1/logs?`

    table_name ? params = params.concat(`table_name=${table_name}`) : params
    page ? params = params.concat(`&page=${page}`) : params
    table_id ? params = params.concat(`&table_id=${table_id}`) : params
    requestId ? params = params.concat(`&requestId=${requestId}`) : params
    users_id ? params = params.concat(`&users_id=${users_id}`) : params
    created_at ? params = params.concat(`&created_at=${created_at}`) : params
    startDate ? params = params.concat(`&startDate=${startDate}`) : params
    finishDate ? params = params.concat(`&finishDate=${finishDate}`) : params
    orderBy ? params = params.concat(`&orderBy=${orderBy}`) : params
    direction ? params = params.concat(`&direction=${direction}`) : params

    try {
        const schedulesData = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`,
            }
        })

        const formattedLogs = schedulesData.data.data.map(log => {
            return {
                ...log,
                created_at: formattTimeToShow(log.created_at)
            }
        })

        let formatted = {
            totalPages: schedulesData.data.totalPages,
            data: formattedLogs
        }
        
        return {
            error: false,
            data: formatted
        }
    } catch (error) {
        console.log(error.response)
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

export async function getCompanies(tokenKey) {

    let params = `/api/v1/companies`
    try {
        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        let formattedData = []
        if(data.data.data?.length > 0){
            formattedData = data.data.data.map(companie => { return {...companie, created_at: formattTimeToShow(companie.created_at)}})
        }
        
        return {
            error: false,
            data: formattedData
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

export async function getCompanyById(tokenKey, id) {

    let params = `/api/v1/companies/${id}`
    try {
        const data = await api.get(params, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        let formattedData = []
        if(data.data.data?.length > 0){
            formattedData = data.data.data.map(companie => { return {...companie, created_at: formattTimeToShow(companie.created_at)}})
        }
        
        return {
            error: false,
            data: formattedData
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

