import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useThemeContext } from '../../../contexts/themeContext'

export function ScreenModal({ width, height, funcCloseModal, visible, title, text, children }) {

    const [animateThisModal, setAnimateThisModal] = useState(false)
    const { showUniversalModal, setShowUniversalModal } = useThemeContext()

    useEffect(() => {

        function handleEscape(e) {
            if (e.code === 'Escape') {
                setShowUniversalModal(false)
                if(funcCloseModal) funcCloseModal()
            }
        }
        window.addEventListener('keydown', handleEscape)

        async function animateModal(status) {

            await new Promise(resolve => setTimeout(resolve, 60))
            showUniversalModal ? setAnimateThisModal(true) : setAnimateThisModal(false)
        }

        if (visible) {
            animateModal(true)
        } else {
            animateModal(false)
        }

        return () => window.removeEventListener('keydown', handleEscape)

    }, [showUniversalModal])


    return (
        <div className='flex flex-col'>
            <div className={`${showUniversalModal ? 'flex' : 'hidden'} flex-col items-center justify-center absolute w-full top-0 sm:-translate-x-[30px] h-[100vh] sm:h-full z-[800] `}>
                <div className={`${animateThisModal ? 'translate-y-0' : '-translate-y-96'} transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow`} style={{ width: width + 'px', height: height + 'px' }}>
                    <div className='flex flex-row justify-between items-center p-2'>
                        <h1 className='text-sm text-titleBlackTextLight dark:text-titleGrayTextLight'>{title}</h1>
                        <button
                            onClick={() => {
                                setShowUniversalModal(false) 
                                if(funcCloseModal) funcCloseModal()
                            }} 
                            className='bg-gray-300 w-6 h-6 rounded-full flex justify-center items-center text-sm'
                        ><FaTimes/></button>
                    </div>
                    <div className='flex flex-col items-center justify-center overflow-x-hidden' style={{maxHeight: height - 46 +'px'}}>
                        {children}
                    </div>
                </div>
            </div>
            <div className={`${showUniversalModal ? 'flex' : 'hidden'} bg-gray-500 opacity-75 sm:-translate-x-[60px] absolute top-0 w-full h-[100vh] sm:h-full z-[100]`}></div>
        </div>

    )
}