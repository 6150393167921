import React, { useState, useEffect } from 'react'
import { FaArrowDown } from 'react-icons/fa'
import { useThemeContext } from '../../contexts/themeContext'

export function InputAutoComplete({ data, id, onChange, placeHolder, selectedLabel, preSelectedValue, optionList, width, height, value, inactive, disabled, autoFocus, typeInactive, onBlur }) {

    const { screenX } = useThemeContext()
    const [showThisList, setShowThisList] = useState(null)
    const [showList, setShowList] = useState(false)
    const [selectedValue, setSelectedValue] = useState(value ? value : '')
    const [mouseHover, setMouseHover] = useState(false)
    const [cursor, setCursor] = useState(-1)
    const widthInput = width > 0 ? width : 96
    const heightList = height > 0 && height + 'px'

    async function handleSelectedValue(dataValue, selectedLabel) {

        setMouseHover(true)
        setSelectedValue(dataValue[selectedLabel])
        setShowThisList(null)
        onChange(dataValue)
        await new Promise(resolve => setTimeout(resolve, 300))
        setMouseHover(false)

    }

    function keyBoardNavigation(e) {

        let options = document.getElementById('user-select-options').children
        if (e.key === 'ArrowDown') {

            setCursor(c => (c < options.length - 1 ? c + 1 : c))
            cursor > 0 && Array.from(options)[cursor - 1].scrollIntoView()
        }

        if (e.key === 'ArrowUp') {
            setCursor(c => (c > 0 ? c - 1 : 0))
            cursor > 0 && Array.from(options)[cursor - 1].scrollIntoView()
        }

        if (e.key === 'Enter') {
            setSelectedValue(options[cursor].children[0].getAttribute('value'))
            onChange(JSON.parse(options[cursor].children[0].getAttribute('selectedData')))
            setShowThisList(null)
        }

        if (e.key === 'Escape') {
            setCursor(-1)
            setShowThisList(null)
        }
    }

    function handleSelectValueDefault(value) {

        const valueSelected = data.filter((dataValue) => {
            if (dataValue[selectedLabel] === value) {
                return dataValue
            }
        })
        onChange(valueSelected[0])
    }

    useEffect(() => {

        setSelectedValue(preSelectedValue)


    }, [])

    useEffect(() => {

        const listOptions = document.getElementById('user-select-options')

        if (showThisList && listOptions) {
            listOptions.style.opacity = '1'
        } else if (listOptions) {
            listOptions.style.opacity = '0'
        }

    }, [showThisList])

    const handleBlur = () => {
        setShowThisList(null)
        onBlur()
    }

    return (
        <>
            <div className='hidden sm:flex flex-col scroll-smooths'>
                <input style={{ width: screenX < 640 && '350px' }} disabled={inactive || disabled ? true : false}
                    autoFocus={autoFocus ? true : false}
                    autoComplete="off" type='text'
                    onKeyDown={e => keyBoardNavigation(e)}
                    id={id}
                    value={value ? value : selectedValue}
                    onFocus={() => { !mouseHover && setShowThisList(1); setShowList(true) }}
                    onClick={() => { !mouseHover && setShowThisList(1); setShowList(true) }}
                    onBlur={() =>
                        !mouseHover && handleBlur()}
                    onChange={(e) => { !typeInactive && setSelectedValue(e.target.value); !typeInactive && onChange(e.target.value); !typeInactive && setShowList(false) }}
                    datavalue='text'
                    className={`pl-2 text-lg sm:text-sm border border-titleGrayTextDark
                     focus:bg-titleGrayTextLight dark:text-titleGrayTextDark 
                     dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark h-16 sm:h-8 
                     outline-none transition-all duration-200 focus:shadow-borderShadow w-${widthInput} ${inactive || disabled ? 'bg-gray-300 dark:bg-thirdDefaultDark' : ''}`}>
                </input>
                <div className={`flex flex-row w-full items-center pr-4 absolute z-20 justify-end h-16 sm:h-8  w-${widthInput}`}>
                    <FaArrowDown className={` ${showThisList === 1 ? 'rotate-180 text-primaryDefaultLight' : 'dark:text-titleGrayTextLight rotate-0 text-titleBlackTextLight'}  transition-all duration-300`} size='10'></FaArrowDown>
                </div>
                {
                    showThisList === 1 &&
                    <ul id='user-select-options' style={{ maxHeight: heightList?.length > 0 ? heightList : '200px', overflowX: 'hidden' }} onMouseMove={() => { setMouseHover(true) }} onMouseLeave={() => { setMouseHover(false) }} className={`opacity-0 dark:scrollbar-thin dark:scrollbar-track-zinc-500 dark:scrollbar-thumb-zinc-400  transition-all duration-300 flex z-30 bg-bgPrimaryLight boxShadow flex-col items-start absolute translate-y-20 sm:translate-y-10 w-${widthInput}`}>
                        {
                            data?.length === 0 ?
                                <li className={`p-2 scroll-smooth bg-bgPrimaryLight dark:bg-thirdDefaultDark dark:text-titleGrayTextLight' cursor-not-allowed w-full transition-all duration-300`}>
                                    Sem dados
                                </li>
                                :
                                data.map((dataValue, index) => {
                                    let toSearch = ''
                                    optionList?.length > 0 ? optionList.map((option, index) => {
                                        toSearch = toSearch.concat(dataValue[option]?.toString()).concat(' ')
                                    }) : toSearch = toSearch.concat(dataValue[selectedLabel]?.toString()).concat(' ')

                                    if (toSearch?.toLowerCase()?.indexOf(selectedValue?.toString()?.toLowerCase()) > -1 || showList) {
                                        return <li id={`li-option-${index}`} key={index} className={`p-2 hover:bg-bgSecondaryLight dark:hover:bg-secondaryDefaultDark focus:bg-bgSecondaryLight dark:focus:bg-secondaryDefaultDark scroll-smooth ${cursor === index ? 'bg-bgSecondaryLight dark:bg-secondaryDefaultDark dark:text-titleGrayTextLight ' : 'bg-bgPrimaryLight dark:bg-thirdDefaultDark dark:text-titleGrayTextLight'} cursor-pointer w-full transition-all duration-300`} 
                                        onClick={() => handleSelectedValue(dataValue, selectedLabel)}
                                        onTouchStart={() => handleSelectedValue(dataValue, selectedLabel)}
                                        >
                                            <a className='sm:text-sm text-2xl' selectedData={JSON.stringify(dataValue)} value={
                                                dataValue[selectedLabel]}>{optionList?.length > 0 ? optionList.map((option, index) => {
                                                    if (index < optionList.length - 1) {
                                                        return placeHolder !== undefined ?
                                                            dataValue[option] + placeHolder : dataValue[option] + ' - '
                                                    }
                                                    return dataValue[option]
                                                }) : dataValue[selectedLabel]
                                                }</a>
                                        </li>
                                    }
                                })
                        }
                    </ul>
                }
            </div>
            <div className='flex sm:hidden'>
                <select onChange={(e) => { setSelectedValue(e.target.value); 
                    handleSelectValueDefault(e.target.value); setShowList(false) }} 
                    className="bg-gray-50 border dark:bg-secondaryDefaultDark border-gray-300 dark:border-primaryBorderDark 
                    text-gray-900 dark:text-titleGrayTextDark h-10 text-sm outline-none focus:ring-blue-500 
                    focus:border-primaryDefaultLight block w-full md:w-96 p-2.5">
                    {
                        !selectedValue &&
                        <option selected={true}>-Selecione-</option>
                    }
                    {data.map((dataValue, index) => {
                        return <option selected={!selectedValue ? false : selectedValue === dataValue[selectedLabel] ? selectedValue : false} >{dataValue[selectedLabel]}</option>
                    })}
                </select>
            </div>
        </>
    )
}