// hooks 
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// components 
import { FaEdit, FaTrash } from 'react-icons/fa'
import { Container } from '../../../components/container/container'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { TableDefault } from '../../../components/table/table.default'
import { TableMobile } from '../../../components/table/table.mobile'
import { Button } from '../../../components/buttons/button.default'
//services
import { getPermissionGroups } from '../../../services/api/callAPIsFunctions/defaultCalls.api'

export function UserProfile() {

    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const navegate = useNavigate()
    const { userData } = useAuth()
    const [permissionGroupsList, setPermissionGroupsList] = useState([])
    const [loading, setLoading] = useState(true)
    const [userPermitted, setUserPermiited] = useState(userData[0]?.permissions?.indexOf('permissionGroups:update') > -1 || userData[0]?.typeAccess_id < 3 ? true : false)


    useEffect(() => {

        async function getData() {

            const permissionGroupsData = await getPermissionGroups(userData[0].token)
            if (permissionGroupsData.error) {
                setLoading(false)
                setShowNotificationModalText(permissionGroupsData.data)
                return
            }
            setPermissionGroupsList(permissionGroupsData.data)
            setLoading(false)
        }

        getData()

    }, [])

    const tableThead = [
        {
            "name": 'Grupo',
            "original_name": 'name'
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        },
    ]

    function handleEditGroup(data) {

        navegate(`/usersProfileEdit/${data.id}`)
    }

    return (
        <Container>
            <TitlePage>
                <div className='flex flex-row justify-between w-full'>
                    <Title text={'Grupos de usuários'}></Title>
                    {
                        userData[0]?.permissions?.indexOf('users:create') > -1 || userData[0]?.typeAccess_id < 3 &&
                        <Button
                            shadow={true}
                            onClick={() => navegate('/usersProfileCreate')}
                        >+ Novo grupo</Button>
                    }
                </div>
            </TitlePage>
            <Body>
                {
                    !loading &&
                    <div id='usersProfileTableContent' className='hidden sm:flex flex-col items-center justify-start w-full mt-8 boxShadow overflow-y-auto rounded-lg'>
                        <TableDefault
                            onClick={(e) => userPermitted && handleEditGroup(e)}
                            title={tableThead} data={permissionGroupsList}
                            collumns={["name", 'created_at']} />
                    </div>

                }
                <div id='usersMobileTableContent' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                    <TableMobile
                        onClick={(e) => e.func.execute(e.data)}
                        title={tableThead}
                        data={permissionGroupsList}
                        collumns={["name", 'created_at']}
                        functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEditGroup }]}
                    />
                </div>
            </Body>
        </Container>
    )
}