//hooks
import React, { useEffect, useState } from 'react'
//components
import { Container } from '../../components/container/container'
import {  Button } from '../../components/buttons/button.default'
import { Title } from '../../components/titlePages/title'
import { TitlePage } from '../../components/titlePages/title.page'
import { Body } from '../../components/container/Body'
import { FaArrowCircleLeft, FaCocktail } from 'react-icons/fa'
import ReactQuill from 'react-quill'
import { Quillmodules } from '../../components/richText/quill.modules'
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { getGastronomyById } from '../../services/api/callAPIsFunctions/defaultCalls.api'
import { useAuth } from '../../contexts/useAuth'
import { api } from '../../services/api/api'
import { DefaultLoader } from '../../components/loaders/defaultLoader'
import { ScreenModal } from '../../components/modals/notification/screenModal'
import { useThemeContext } from '../../contexts/themeContext'
//imgs
import cookImg from '../../assets/default_assets/cook.webp'

export function GastronomyShow({ gastronomy, back }) {

    const { userData } = useAuth()
    const { setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [gastronomysAppendPictures, setGastronomysAppendPictures] = useState([])
    const [picturePosition, setPicurePosition] = useState(0)
    const [animate, setAnimate] = useState(false)
    const [screenModalText, setScreenModalText] = useState('')
    let inManualSide = false

    async function getData() {
        setLoading(true)
        const thisGastronomy = await getGastronomyById(userData[0].token, gastronomy.id)
        if (thisGastronomy.data.gastronomyPictures?.length > 0) {

            let photosArray = []

            await Promise.all(thisGastronomy.data.gastronomyPictures.map(async picture => {
                const file = await api.get(`api/v1/gastronomyPictures/gastronomy_id/${picture.gastronomy_id}/picture/${picture.picture}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                const fileObj = URL.createObjectURL(file.data)
                photosArray.push(fileObj)
            }))

            if (photosArray?.length > 0) {
                gastronomy.photosAppendArray = photosArray
            }
        }
        else gastronomy.photosAppendArray = [cookImg]
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    async function slide(side) {
        setAnimate(true)
        await new Promise(resolve => setTimeout(resolve, 500))
        setPicurePosition((prev => (prev === gastronomy.photosAppendArray.length - 1 ? 0 : prev + 1)))
        setAnimate(false)
    }

    useEffect(() => {

        async function animate() {
            await new Promise(resolve => setTimeout(resolve, 5000))
            if (!inManualSide) {
                slide(true)
            }
        }

        animate()

    }, [picturePosition])

    function showThisMenu(data) {
        setScreenModalText({ title: data.title, menu: data.menu })
        setShowUniversalModal(true)
    }

    return (
        <>
            <ScreenModal title={`Cardápio - ${screenModalText?.title}`}>
                <div className='flex flex-col max-w-[1000px] w-auto xl:w-[1000px]'>
                    <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96 mb-12' readOnly={true} theme={"bubble"} value={screenModalText.menu} />
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex flex-row gap-2'>
                        <div className='text-xl animate-[wiggleLeft_1s_ease-in-out]' onClick={() => console.log('ll')}  >
                            <Button onClick={() => back('')} shadow={true}>Voltar</Button>
                        </div>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className='flex flex-col h-96 items-center justify-center'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='flex flex-col w-full lg:w-[1000px] justify-center items-start flex-wrap mb-12 mt-12 pb-6'>
                                <div className='w-full items-start justify-center flex flex-col lg:flex-row gap-12'>
                                    <div className=' border border-primaryDefaultLight rounded-lg w-full lg:w-auto relative'>
                                        <div
                                            style={{
                                                backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), rgba(27, 27, 27, 0.9)), url(${gastronomy.photosAppendArray[picturePosition]})`,
                                                opacity: animate ? '0' : '1'
                                            }}
                                            className='shadow-lg 
                                                            bg-cover
                                                            lg:translate-y-6 lg:translate-x-6
                                                            transition-all duration-500
                                                            bg-no-repeat bg-center
                                                            ease-in-out                                                        
                                                            h-96 bg-bgPrimaryLight dark:bg-secondaryDefaultDark p-2 
                                                            lg:p-8 w-full lg:w-96 border border-zinc-200 dark:border 
                                                            dark:border-primaryBorderDark rounded-lg
                                                            flex flex-col justify-end items-center'>
                                            <a className='animate-[wiggle_1s_ease-in-out] font-light text-white text-2xl -translate-y-8'>{gastronomy.title}</a>
                                            {
                                                gastronomy.hyperLinkDescription?.length > 0 &&
                                                <a className='text-titleBlackTextLight mb-3 sm:mb-0 dark:text-titleGrayTextDark items-center justify-center p-1 rounded-full bg-white border-4 border-white min-w-44 w-44' target="__blank" href={`https://${gastronomy?.hyperLink}`}>
                                                    <div className='flex flex-col items-center justify-center w-full'>
                                                        <p className='text-sm text-primaryDefaultLight dark:text-titleGrayTextDark font-semibold'>{gastronomy.hyperLinkDescription}</p>
                                                    </div>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                    <div className='flex flex-col lg:w-[616px] items-center gap-4'>
                                        <h1 className='text-xl border-b border-b-primaryDefaultLight rounded-lg mb-10'>{gastronomy.title}</h1>
                                        <div className='flex flex-col max-h-96 h-96 overflow-auto'>
                                            <p>{gastronomy.description}</p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    gastronomy?.values?.length > 0 &&
                                    <div className='flex flex-col w-full items-center lg:items-start justify-center'>
                                        <div className='mb-10 w-full flex flex-col items-center lg:items-start justify-center p-2'>
                                            <h1 className='ml-6 text-xl border-b border-primaryDefaultLight rounded-lg'>Valores</h1>
                                        </div>
                                        <div className='flex flex-row gap-4 flex-wrap w-full lg:items-start items-center justify-center lg:justify-start'>
                                            {
                                                gastronomy.values.map((value, index) => {
                                                    return <div id={'gastronomyValue' + index} className='border border-gray-100 relative shadow-md rounded-lg'>
                                                        <div className='flex flex-col w-full bg-primaryDefaultLight px-2 text-sm h-10 items-center justify-center rounded-t-lg'>
                                                            <h1 className='text-white'>{value.title}</h1>
                                                        </div>
                                                        <div className='w-72'>
                                                            <ReactQuill modules={Quillmodules} className='dark:text-titleGrayTextDark w-full min-h-96 mb-12' readOnly={true} theme={"bubble"} value={value.description} />
                                                        </div>
                                                        <div className='w-full h-10 bg-gray-100 absolute bottom-0 flex flex-row text-inputPlaceholderLight items-center justify-center gap-4'>
                                                            {
                                                                value.menu?.ops &&
                                                                <>
                                                                    <FaCocktail />
                                                                    <h1 onClick={() => showThisMenu(value)} className='text-sm underline cursor-pointer'>Ver cardápio</h1>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    gastronomy.gastronomyProducts?.length > 0 &&
                                    <div className='flex flex-col w-full mt-8 items-center lg:items-start justify-center gap-3'>
                                        <div className='mb-10 w-full flex flex-col items-center lg:items-start justify-center p-2'>
                                            <h1 className='ml-6 text-xl border-b border-primaryDefaultLight rounded-lg'>Produtos</h1>
                                        </div>
                                        {
                                            gastronomy.gastronomyProducts.map(product => {
                                                return <div className='shadow-lg bg-gray-50 rounded-lg py-4 px-2 w-full flex flex-row'>
                                                    <div className='w-[50%]'>
                                                        <p>{product.productName}</p>
                                                    </div>
                                                    <div className='w-[50%]'>
                                                        <p>{product.productPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                    }
                </Body>
            </Container>
        </>

    )
}