import ReactQuill, { Quill } from "react-quill"

const FontStyle = Quill.import('attributors/style/font')

// var Size = Quill.import("attributors/style/size");
// Size.whitelist = [
//     "16px",
//     "22px",
//     "28px",
// ];
// Quill.register(Size, true);

export const Quillmodules = {
    toolbar: [

        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        ["clean"], // remove formatting button
        ["link"], // link and image, video
    ]
}