import React, { useEffect, useState } from 'react'
import randomColor from 'randomcolor';
import { useThemeContext } from '../../contexts/themeContext';
import ApexCharts from 'apexcharts'
import style from '../dashboard/styles/dashboard.module.css'
export function SliceGraphMobile({id, data, title }) {

    const { primaryDefaultLight, secondarrDefaultLight } = useThemeContext()
    const [objectColor, setObjectColor] = useState([])

    useEffect(() => {
        if (data) {

            const luminosityFill = document.getElementsByClassName('dark').length > 0 ? 'dark' : 'light'
            const darkModeActive = document.getElementsByClassName('dark')?.length ? true : false

            const dataObject = []
            for (let i = 0; i < data.length; i++) {

                if (i < 200) {
                    dataObject.push(data[i])
                }
            }

            let colors = []

            try {
                colors = randomColor({
                    count: dataObject.length,
                    hue: primaryDefaultLight,
                    luminosity: luminosityFill,
                });
            } catch (error) {

                colors = randomColor({
                    count: dataObject.length,
                    hue: '#FFF',
                    luminosity: luminosityFill,
                });
            }

            colors.map((color, index) => {
                dataObject[index].color = color
            })
            setObjectColor([...dataObject])

            var options = {
                stroke: {
                    show: false,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 0,
                    dashArray: 0,
                },

                colors: colors.map(col => col),


                series: data.map(dataTotal => dataTotal.total),
                labels: data.map(dataLabel => dataLabel.name),

                chart: {
                    dropShadow: {
                        enabled: true,
                        top: 0,
                        left: 0,
                        blur: 2,

                        opacity: 0.3
                    },
                    type: 'donut',
                    width: 430,
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: darkModeActive ? 'dark' : 'light',
                        type: "horizontal",
                        shadeIntensity: 0.5,
                        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        colorStops: []
                    }
                },
                // responsive: [{
                //     breakpoint: 400,
                //     options: {
                //         chart: {
                //             width: 450
                //         },

                //         legend: {
                //             show: true,
                //             position: 'bottom',
                //             horizontalAlign: 'left',
                //             labels: {
                //                 colors: [`'${primaryDefaultLight}'`],
                //                 useSeriesColors: true,
                //             }

                //         }
                //     }
                // }]
            };

            var chart = new ApexCharts(document.querySelector(`#${id}`), options);
            console.log(id)

            chart.render();
        }
    }, [primaryDefaultLight, secondarrDefaultLight])

    return (
        <div id={id} className={`w-full flex items-center justify-center relative ${style.apexcharts} `}>
            <div id={id} className='items-center justify-center flex stroke-transparent w-full'>

            </div>
        </div>
    )
}
