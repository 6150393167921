import { Routes, Route, Link, Navigate } from 'react-router-dom';

export function ProtectedRoute({ module, user, func, isThemeComponent, children }) {
    if (user[0]?.email?.length === 0) {
        return <Navigate to={`/myProfileEdit/:${user[0]?.id}`} replace />; 0
    }

    if (
        //trata rotas com modulos especificos
        (module && user[0]?.customersData?.modules?.map(module => { return module.id }).includes(module) === false)
        ||
        (
            //componente de tema
            (user[0].typeAccess_id !== 2 && user[0].typeAccess_id !== 1 && !isThemeComponent)
            &&
            //filtra rotas pelas permissoes do usuario
            (user[0]?.permissions.filter((userPermission) => {
                return func.includes(userPermission)
            }).length === 0)
        )

    ) {
        return <Navigate to="/" replace />;
    }

    return children;
}
