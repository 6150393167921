import React, { useState, useEffect } from 'react'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'

export function TableMobile({ title, functions, data, collumns, onClick, filter, pagination, totalPages, filters, loading }) {

    const [tableData, setTableData] = useState(data)
    const [startFilter, setStartFilter] = useState(false)
    const [filteredValue, setFilteredValue] = useState(null)
    const [paginationList, setPaginationList] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [filterObject, setFilterObject] = useState({})
    const [thisLoading, setThisLoading] = useState(false)
    const { screenY } = useScreenSizeContext()

    useEffect(() => {

        function countTotalPages() {
            let paginationButtons = []
            let aux = 0

            if (tableData.length % 2 > 0) {
                let thistotalPages = totalPages + 1
                while (aux < thistotalPages) {
                    paginationButtons.push(aux + 1)
                    aux++
                }
            } else {
                let thistotalPages = totalPages
                while (aux < thistotalPages) {
                    paginationButtons.push(aux + 1)
                    aux++
                }
            }
            setPaginationList(paginationButtons)
        }

        if (pagination) {
            countTotalPages()
        }
    }, [totalPages])

    useEffect(() => {
        setTableData([...data])
        filterTable()

    }, [data])

    function filterTable() {

        let filterHasSpace = filteredValue?.indexOf(' ')
        let filterToSearch = filteredValue
        let filteToSearArray = []

        if (filterHasSpace > - 1) {
            while (filterHasSpace !== -1) {
                filteToSearArray.push(filterToSearch.slice(0, filterHasSpace + 1))
                filterToSearch = filterToSearch.replace(filterToSearch.slice(0, filterHasSpace + 1), '')
                filterHasSpace = filterToSearch?.indexOf(' ')
                if (filterHasSpace === - 1 && filterToSearch?.length > 0) {
                    filteToSearArray.push(filterToSearch)
                }
            }
        } else if (filterHasSpace === - 1) {
            filteToSearArray.push(filterToSearch)
        }
        if (tableData?.length === 0 || filteToSearArray?.length > 0) {

            const filter = tableData?.filter((dataOfTable, index) => {
                let collumNames = Object.keys(dataOfTable)

                const filterWords = filteToSearArray.filter((wordToCheck) => {

                    let wordFinded = false

                    const collumnFilter = collumNames.filter((wordInCollumn) => {
                        let wordToSearch = wordToCheck.toString()?.toLowerCase().replace(' ', '')
                        if (!wordFinded && tableData[index][wordInCollumn]?.toString()?.toLowerCase().indexOf(wordToSearch) > -1) {
                            wordFinded = true
                            return wordInCollumn
                        }
                    })

                    if (collumnFilter.length > 0) {
                        return wordToCheck
                    }
                })
                if (filterWords?.length > 0 && (filterWords?.length === filteToSearArray?.length)) {
                    return dataOfTable
                }
            })
            if (filter?.length > 0) {
                return filter
            } else {
                return tableData
            }
        }
    }

    function alterPage(page) {
        setActivePage(page)
        filter({ ...filterObject, page: page })
    }

    useEffect(() => {
        if (startFilter) {
            filterTable()
        }
    }, [startFilter])

    useEffect(() => {
        setThisLoading(loading)
    }, [loading])


    return (
        <div className='flex flex-col w-full gap-8 shadow-lg h-full'>
            {
                data?.length > 0 ?
                    <>
                        {
                            data.map((apiData, index) => {

                                return <div key={index} className='w-full overflow-hidden flex flex-col h-full border border-primaryDefaultLight rounded-lg'>
                                    <div className='flex flex-col w-full rounded-lg'>
                                        <div className='flex flex-col gap-2 w-full'>
                                            {
                                                collumns.map((titleColumn, indexMobile) => {                                             
                                                    return <div key={`${index}-${indexMobile}`} className='bg-zinc-100 flex flex-row w-full'>
                                                        <div className='h-12 flex flex-col w-32 dark:bg-thirdDefaultDark text-bgPrimaryLight font-extrabold justify-center items-start pl-2'>
                                                            <a className='text-sm font-extrabold text-primaryDefaultLight dark:text-titleGrayTextLight'>{title[indexMobile]?.name}</a>
                                                        </div>
                                                        <div className='h-12 w-full flex flex-col justify-center overflow-hidden items-start dark:bg-thirdDefaultDark'>
                                                            <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark pl-2'>{apiData[titleColumn]?.length > 70 ? apiData[titleColumn]?.slice(0,70) + '...' : apiData[titleColumn]}</a>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className='w-full bg-primaryDefaultLight dark:bg-thirdDefaultDark h-12 flex flex-row items-center justify-start pl-2 gap-10 border-t border-primebg-primaryDefaultLight'>
                                            <div>
                                                <a className='text-sm text-bgPrimaryLight font-extrabold'>Opções:</a>
                                            </div>
                                            {
                                                functions?.length > 0 &&
                                                functions.map((func, indexFuncIcon) => {
                                                    return <div key={`${index}-${indexFuncIcon}-`} onClick={() => onClick({ data: apiData, func: func })} className='flex flex-col items-center justify-center cursor-pointer h-full' title={func.title}>
                                                        <div className='border border-zinc-200 rounded-md p-1 shadow-md text-2xl items-center justify-start text-bgPrimaryLight flex gap-1'>{func.icon}<a className='text-sm'>{func?.title}</a></div>
                                                    </div>
                                                })}
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                        {
                            pagination &&
                            <div className='px-2 border border-gray-300 dark:border-primaryBorderDark w-full flex flex-row items-center justify-end h-8 bg-bgSecondaryLight dark:bg-thirdDefaultDark gap-1 bottom-0 sticky'>
                                <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>Pagina {activePage} de {totalPages}</a>
                                <div className='flex flex-row items-center justify-start border border-gray-300 rounded-md dark:border-primaryBorderDark'>
                                    {
                                        activePage > 1 &&
                                        <>
                                            <div className='flex flex-col items-center hover:bg-secondaryDefaultLight justify-start border-l px-1 border-gray-300 dark:border-primaryBorderDark'>
                                                <span className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm cursor-pointer' onClick={() => alterPage(1)}>Primeira</span>
                                            </div>
                                            <div className='flex flex-col items-center hover:bg-secondaryDefaultLight justify-start border-l px-1 border-gray-300 dark:border-primaryBorderDark'>
                                                <span className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm cursor-pointer' onClick={() => alterPage(activePage > 0 ? activePage - 1 : activePage)}>Anterior</span>
                                            </div>
                                        </>
                                    }
                                    {
                                        paginationList.map((page, index) => {
                                            if (index < 6) {
                                                return <div className={`flex flex-col items-center hover:bg-secondaryDefaultLight dark:hover:bg-thirdDefaultDark justify-start border-l w-10 border-gray-300 dark:border-primaryBorderDark ${activePage === page ? 'bg-gray-300 dark:bg-secondaryDefaultDark' : ''}`}>
                                                    <span onClick={() => alterPage(page)} className='w-10 text-sm cursor-pointer  flex flex-col items-center justify-center text-titleBlackTextLight dark:text-titleGrayTextDark'>{page}</span>
                                                </div>
                                            }
                                        })
                                    }
                                    {
                                        totalPages > 1 &&
                                        <>
                                            {
                                                totalPages > 6 && activePage < paginationList?.length &&
                                                <div className='flex flex-col items-center  hover:bg-secondaryDefaultLight justify-start border-l w-10 border-gray-300 dark:border-primaryBorderDark'>
                                                    <span className='text-sm cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark'>...</span>
                                                </div>
                                            }
                                            <div className='flex flex-col items-center  hover:bg-secondaryDefaultLight justify-start border-l px-1 border-gray-300 dark:border-primaryBorderDark'>
                                                <span className='text-sm cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark' onClick={() => { alterPage(activePage + 1) }}>Próximo</span>
                                            </div>
                                            <div className='flex flex-col items-center  hover:bg-secondaryDefaultLight justify-start border-l px-1 border-gray-300 dark:border-primaryBorderDark'>
                                                <span className='text-sm cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark' onClick={() => alterPage(totalPages)}>Ultima</span>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        }
                    </>

                    :
                    <p>Sem dados</p>
            }
        </div>
    )
}

