//hooks
import React, { useState, useEffect } from 'react'
//components
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { LabelInput } from '../../../components/label/label.input'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { FaInfoCircle } from 'react-icons/fa'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { TableDefault } from '../../../components/table/table.default'
//utils
import { hourArr } from '../../../utils/hour/generateHourArr'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { getAssociations, getCategories, getCustomersAttendance, getDefaultTableHours } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { Footer } from '../../../components/footer/Footer'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../services/api/api'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { justNumber } from '../../../utils/validators/justNumber'
import { maskHours } from '../../../utils/mask/maskHours'

export function CustomerAttendance() {

    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const { userData, setUserData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [associationsList, setAssociationsList] = useState([])
    const [categoriesList, setCategoriesList] = useState([])

    const [selectedOpenHour, setselectedOpenHour] = useState(0)
    const [selectedClosedHour, setSelectedClosedHour] = useState(undefined)
    const [selectedIsClosedHoliday, setSelectedIsClosedHoliday] = useState(false)
    const [selectedisClosedWeekend, setSelectedisClosedWeekend] = useState(false)
    const [selectedWorkOnHolidays, setSelectedWorkOnHolidays] = useState(false)
    const [useBalance, setUseBalance] = useState({ id: 0, description: 'Não' })
    const [selectedSchedulesCancelOption, setSelectedSchedulesCancelOption] = useState({ id: 1, description: 'Secretaria e clientes' })
    const [selectedAutomaticSchedulesCancel, setSelectedAutomaticSchedulesCancel] = useState({ id: 0, description: 'Não' })
    const [closedHourBlockWeekend, setClosedHourBlockWeekend] = useState({ id: false, description: 'Não restringe' })
    const schedulesCancelOptions = [
        { id: 0, description: "Somente secretaria" },
        { id: 1, description: "Secretaria e clientes" },
    ]
    const [freeBlockedHoursInHoliday, setFreeBlockedHoursInHoliday] = useState({id: false, description: "Não"});
    const [defaultTableOpenHours, setDefaultTableOpenHours] = useState([])

    const [dailySchedulesPerUser, setDailySchedulesPerUser] = useState(0)
    const [weeklySchedulesPerUser, setWeeklySchedulesPerUser] = useState(0)
    const [monthlySchedulePerUser, setMonthlySchedulePerUser] = useState(0)
    const [associationsParameters, setAssociationsParameters] = useState({})

    const tableThead = [
        {
            "name": 'Dia',
            "original_name": 'dayDescription'
        },
        {
            "name": 'Hora',
            "original_name": 'openHourDescription'
        }
    ]

    useEffect(() => {

        async function getData() {

            setLoading(true)
            try {
                const defaultTableData = await getDefaultTableHours(userData[0].token)
                const getAttendanceDetails = await getCustomersAttendance(userData[0].token)
                const associations = await getAssociations(userData[0].token)
                const categories = await getCategories(userData[0].token)

                let formattedAssociationParameters = {}

                getAttendanceDetails.data[0].associationsParameters.map((association, index) => {
                    formattedAssociationParameters = {
                        ...formattedAssociationParameters,
                        [association.associations_id]: {
                            categorie: {
                                ...formattedAssociationParameters[association.associations_id]?.categorie,
                                [association.categories_id]: {
                                    daysToCancel: association.daysToCancel,
                                    daysToPay: association.daysToPay,
                                    daysToPayAfter: association?.daysToPayAfter,
                                    daysToOpen: association.daysToOpen,
                                    daylySchedules: association.daylySchedules,
                                    weeklySchedules: association?.weeklySchedules,
                                    monthlySchedules: association?.monthlySchedules
                                }
                            }
                        }
                    }
                })
                
                setAssociationsParameters(formattedAssociationParameters)
                setAssociationsList(associations.data)
                setCategoriesList(categories.data)
                setselectedOpenHour(getAttendanceDetails.data[0]?.isOpenBySystemTable ? { id: 0, hour: 0, description: 'Tabela do sistema' } : { hour: getAttendanceDetails.data[0]?.openHour, description: maskHours({ hour: getAttendanceDetails.data[0]?.openHour }) })
                setSelectedClosedHour(getAttendanceDetails.data[0]?.closedHour ? { id: getAttendanceDetails.data[0]?.closedHour, hour: getAttendanceDetails.data[0]?.closedHour, description: maskHours({ hour: getAttendanceDetails.data[0]?.closedHour }) } : { id: 0, hour: undefined, description: 'Não possui' })
                setClosedHourBlockWeekend(getAttendanceDetails.data[0]?.closedHour ? { id: getAttendanceDetails.data[0]?.closedHourBlockWeekend, description: getAttendanceDetails.data[0]?.closedHourBlockWeekend ? 'Restringe' : 'Não restringe' } : { id: false, description: "Não restringe" })
                setSelectedIsClosedHoliday(getAttendanceDetails.data[0]?.allowScheduleOnHolidays ? { id: true, description: 'Sim' } : { id: false, description: 'Não' })
                setSelectedisClosedWeekend(getAttendanceDetails.data[0]?.allowScheduleOnWeekends ? { id: true, description: 'Sim' } : { id: false, description: 'Não' })
                setUseBalance(getAttendanceDetails.data[0]?.useBalance ? { id: true, description: 'Sim' } : { id: false, description: 'Não' })
                setSelectedWorkOnHolidays(getAttendanceDetails.data[0]?.worksOnHolidays ? { id: true, description: 'Sim' } : { id: false, description: 'Não' })
                setDefaultTableOpenHours(defaultTableData?.data)
                setDailySchedulesPerUser(getAttendanceDetails.data[0]?.daylySchedulesPerUser)
                setWeeklySchedulesPerUser(getAttendanceDetails.data[0]?.weeklySchedulesPerUser)
                setMonthlySchedulePerUser(getAttendanceDetails.data[0]?.monthlySchedulesPerUser)
                setSelectedAutomaticSchedulesCancel(getAttendanceDetails?.data[0]?.schedulesAutomaticCancel ? { id: true, description: 'Sim' } : { id: false, description: 'Não' })
                setSelectedSchedulesCancelOption(getAttendanceDetails.data[0]?.allowClientCancel ?
                    { id: 1, description: "Secretaria e clientes" } :
                    { id: 0, description: "Somente secretaria" })
                    setFreeBlockedHoursInHoliday(getAttendanceDetails.data[0]?.freeBlockedHoursInHoliday ? {id: true, description: 'Sim'} : {id: false, description: "Não"}) 
                setLoading(false)
            } catch (error) {
                console.log(error)
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    console.log(error)
                    setLoading(false)
                    setShowNotificationModal(true)
                    setShowNotificationModalSuccess(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }
        getData()
    }, [])


    function validadeCusttomerAttendanceFields() {

        let errors = []

        associationsList.map(association => {

            categoriesList.map(categorie => {

                let daysToCancelElement = document.getElementById(`${association.id}-${categorie.id}-daysToCancel`)
                let daysToOpenElement = document.getElementById(`${association.id}-${categorie.id}-daysToOpen`)
                let daysToPayElement = document.getElementById(`${association.id}-${categorie.id}-daysToPay`)
                let daysToPayAfterElement = document.getElementById(`${association.id}-${categorie.id}-daysToPayAfter`)

                if (!associationsParameters[association.id]?.categorie[categorie.id]?.daysToOpen) {
                    !errors.includes(association.id) && errors.push(association.id)
                    daysToOpenElement.style.border = `1px solid red`
                } else {
                    daysToOpenElement.style.border = `1px solid #94a3b8`
                }

                if (!associationsParameters[association.id]?.categorie[categorie.id]?.daysToCancel) {
                    !errors.includes(association.id) && errors.push(association.id)
                    daysToCancelElement.style.border = `1px solid red`
                } else {
                    daysToCancelElement.style.border = `1px solid #94a3b8`
                }

                // if (!associationsParameters[association.id]?.categorie[categorie.id]?.daysToPay) {
                //     !errors.includes(association.id) && errors.push(association.id)
                //     daysToPayElement.style.border = `1px solid red`
                // } else {
                //     daysToPayElement.style.border = `1px solid #94a3b8`
                // }

                // if (!associationsParameters[association.id]?.categorie[categorie.id]?.daysToPayAfter) {
                //     !errors.includes(association.id) && errors.push(association.id)
                //     daysToPayAfterElement.style.border = `1px solid red`
                // } else {
                //     daysToPayAfterElement.style.border = `1px solid #94a3b8`
                // }
            })
        })
        return errors
    }

    async function handleSubmit() {
        let formattedAssociationParameters = []
        Object.keys(associationsParameters).map(keyAssociation => {
            Object.keys(associationsParameters[keyAssociation].categorie).map(keyCategorie => {
                formattedAssociationParameters.push({
                    associations_id: parseInt(keyAssociation),
                    categories_id: parseInt(keyCategorie),
                    daysToOpen: parseInt(associationsParameters[keyAssociation].categorie[keyCategorie].daysToOpen),
                    daysToCancel: parseInt(associationsParameters[keyAssociation].categorie[keyCategorie].daysToCancel),
                    daysToPay: parseInt(associationsParameters[keyAssociation].categorie[keyCategorie].daysToPay),
                    daysToPayAfter: parseInt(associationsParameters[keyAssociation].categorie[keyCategorie].daysToPayAfter),
                    daylySchedules: parseInt(associationsParameters[keyAssociation].categorie[keyCategorie].daylySchedules),
                    weeklySchedules: parseInt(associationsParameters[keyAssociation].categorie[keyCategorie].weeklySchedules),
                    monthlySchedules: parseInt(associationsParameters[keyAssociation].categorie[keyCategorie].monthlySchedules),
                })
            })
        })
        if (validadeCusttomerAttendanceFields().length > 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(`Verifique a parametrização das associações em vermelho`)
        }
        setShowNotificationModal(false)
        setLoading(true)

        let apiData = {
            openHour: selectedOpenHour?.id === 0 ? undefined : selectedOpenHour.hour,
            isOpenBySystemTable: selectedOpenHour?.id === 0 ? true : false,
            closedHour: selectedClosedHour?.id === 0 ? undefined : selectedClosedHour.hour,
            closedHourBlockWeekend: selectedClosedHour?.id === 0 ? false : closedHourBlockWeekend?.id,
            allowScheduleOnHolidays: selectedIsClosedHoliday?.id,
            allowScheduleOnWeekends: selectedisClosedWeekend?.id,
            allowClientCancel: selectedSchedulesCancelOption?.id === 0 ? false : true,
            schedulesAutomaticCancel: selectedAutomaticSchedulesCancel?.id,
            useBalance: useBalance?.id,
            worksOnHolidays: selectedWorkOnHolidays.id,
            daylySchedulesPerUser: dailySchedulesPerUser === `` ? undefined : dailySchedulesPerUser,
            weeklySchedulesPerUser: weeklySchedulesPerUser === `` ? undefined : weeklySchedulesPerUser,
            monthlySchedulesPerUser: monthlySchedulePerUser === `` ? undefined : monthlySchedulePerUser,
            associationsParameters: formattedAssociationParameters,
            freeBlockedHoursInHoliday: freeBlockedHoursInHoliday.id

        }

        try {
            await api.post(`/api/v1/customersAttendance`, apiData, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
          
            const newUserData = userData
            newUserData[0].customersData.useBalance = useBalance.id
            // console.log(newUserData)
            setUserData([...newUserData])

            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Cadastro realizado com sucesso')
            navigate('/configuration')
        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                animateLoading(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Regras de funcionamento'} />
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ?
                        <div className='flex flex-col items-center justify-center h-96 w-full'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div className='flex flex-col sm:flex-row flex-wrap gap-6 w-full items-start justify-start'>
                                <div className='p-6 bg-zinc-50 dark:bg-thirdDefaultDark border border-zinc-100 dark:border-secondaryBorderDark flex flex-col items-start justify-start w-full'>
                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Parametrize as regras de funcionamento do seu  <strong>estabelecimento</strong> referente ao atendimento ao cliente</p>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <LabelInput text={'Abertura de reserva:'}>
                                        <InputAutoComplete preSelectedValue={selectedOpenHour.description} width={44} data={[{ id: 0, hour: 0, description: 'Tabela do sistema' }, ...hourArr()]} optionList={['description']} selectedLabel={'description'} onChange={(e) => setselectedOpenHour(e)} />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Definir a partir de qual horário as reservas serão liberadas, selecione a tabela padrão do sistema para horários para cada dia</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <LabelInput text={'Horário limite para reservas:'}>
                                        <InputAutoComplete preSelectedValue={selectedClosedHour.description} width={44} data={[{ id: 0, hour: undefined, description: 'Não possui' }, ...hourArr()]} optionList={['description']} selectedLabel={'description'} onChange={(e) => setSelectedClosedHour(e)} />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Definir um horário limite que o usuário poderá efetuar as reservas, este horário só irá bloquear reservas para o dia corrente. Selecione não possui se não precisar limitar</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                {
                                    selectedClosedHour?.hour || selectedClosedHour?.hour === 0 ?
                                    <div className='flex flex-row gap-1'>
                                        <LabelInput text={'Hor. limite vs final de semana:'}>
                                            <InputAutoComplete preSelectedValue={closedHourBlockWeekend?.description} width={44} data={[{ id: true, description: 'Restringe' }, { id: false, description: 'Não restringe' }]} optionList={['description']} selectedLabel={'description'} onChange={(e) => setClosedHourBlockWeekend(e)} />
                                        </LabelInput>
                                        <Tippy
                                            content={<a>Marque "Restringe" para o sistema não permitir reservar para o final de semana após o horário limite, exemplo: Usuário acessou
                                                o sistema sexta-feira após o horário limite, ele não poderá reservar após este horario no dia que está logado, e caso seja sexta-feira, não poderá reservar para sábado e domingo, somente para segunda-feira em diante.
                                            </a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                <FaInfoCircle />
                                            </div>
                                        </Tippy>
                                    </div>
                                    :
                                    <></>
                                }
                                <div className='flex flex-row gap-1'>
                                    <LabelInput text={'Permite reservar em feriados:'}>
                                        <InputAutoComplete preSelectedValue={selectedIsClosedHoliday.description} width={44} data={[{ id: true, description: 'Sim' }, { id: false, description: 'Não' }]} optionList={['description']} selectedLabel={'description'} onChange={(e) => setSelectedIsClosedHoliday(e)} />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Ao marcar esta opção como "Não", o sistema não permitirá a ação de realizar agendamentos durante o feriado para o mesmo dia, apenas para o próximo dia útil, ou um dia antes do feriado antes do horário limite para reservas, exemplo: Usuário acessou o sistema no feriado, o mesmo não irá conseguir realizar nova reserva até o próximo dia útil {selectedOpenHour?.description?.length > 0 && selectedOpenHour?.id === 0 ? 'no horário da tabela do sistema' : selectedOpenHour?.description?.length > 0 ? selectedOpenHour?.description : 'na hora definida no campo abertura de reserva'}</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <LabelInput text={'feriados vs Bloqueios'}>
                                        <InputAutoComplete preSelectedValue={freeBlockedHoursInHoliday.description} width={44} data={[{ id: false, description: 'Não' }, {id: true, description: "Sim"}]} optionList={['description']} selectedLabel={'description'} onChange={(e) => setFreeBlockedHoursInHoliday(e)} />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Este parâmetro define se o sistema deve liberar os horarios bloqueados em dia de feriado. Exemplo: O Espaço X tem um bloqueio no horário das 08h as 09h todas as segundas-feiras, caso marque sim aqui, se a data escolhida pelo cliente for feriado, o sistema não irá bloquear o horário, marque não para manter bloqueado</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <LabelInput text={'Permite em final de semana:'}>
                                        <InputAutoComplete preSelectedValue={selectedisClosedWeekend.description} width={44} data={[{ id: true, description: 'Sim' }, { id: false, description: 'Não' }]} optionList={['description']} selectedLabel={'description'} onChange={(e) => setSelectedisClosedWeekend(e)} />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Ao marcar esta opção como "Não", o sistema não permitirá a ação de realizar agendamentos durante o final de semana, apenas para o próximo dia útil, ou um dia antes do final de semana antes do horário limite para reservas, exemplo: Usuário acessou o sistema no final de semana, o mesmo não irá conseguir realizar nova reserva até o próximo dia útil {selectedOpenHour?.description?.length > 0 && selectedOpenHour?.id === 0 ? 'no horário da tabela do sistema' : selectedOpenHour?.description?.length > 0 ? selectedOpenHour?.description : 'na hora definida no campo abertura de reserva'}</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <LabelInput text={'Abre em Feriados:'}>
                                        <InputAutoComplete preSelectedValue={selectedWorkOnHolidays.description} width={44} data={[{ id: true, description: 'Sim' }, { id: false, description: 'Não' }]} optionList={['description']} selectedLabel={'description'} onChange={(e) => setSelectedWorkOnHolidays(e)} />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Marque "Não" para que o sistema bloqueie automaticamente reservas em dias de feriado, e sim para que o sistema permita a reserva</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <LabelInput text={'Agendamentos no dia:'}>
                                        <Input charLimit={3} type={'text'} id='dailySchedulesPerUser' value={dailySchedulesPerUser} width={44} onChange={(e) => setDailySchedulesPerUser(e.target.value === `` ? e.target.value : justNumber(e.target.value))} selectedLabel={'description'} optionList={['description']} />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Número de reservas que o mesmo usuário poderá fazer no mesmo dia</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <LabelInput text={'Agendamentos na semana:'}>
                                        <Input charLimit={3} id='weeklySchedulesPerUser' value={weeklySchedulesPerUser} width={44} onChange={(e) => setWeeklySchedulesPerUser(e.target.value === `` ? e.target.value : justNumber(e.target.value))} selectedLabel={'description'} optionList={['description']} />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Número de reservas que o mesmo usuário poderá fazer na mesma semana</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <LabelInput text={'Agendamentos no mês:'}>
                                        <Input charLimit={3} id='monthlySchedulePerUser' value={monthlySchedulePerUser} width={44} onChange={(e) => setMonthlySchedulePerUser(e.target.valeu === `` ? e.target.value : justNumber(e.target.value))} selectedLabel={'description'} optionList={['description']} />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Número de reservas que o mesmo usuário poderá fazer no mesmo mês</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <LabelInput text={'Cancelamento das reservas:'}>
                                        <InputAutoComplete
                                            preSelectedValue={selectedSchedulesCancelOption?.description}
                                            width={44} data={schedulesCancelOptions}
                                            optionList={['description']}
                                            selectedLabel={'description'}
                                            onChange={(e) => setSelectedSchedulesCancelOption(e)} />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Definir se o cliente terá automomia para cancelar as próprias reservas, ou se somente a secretaria/administração poderá efetuar os cancelamentos</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <LabelInput text={'Cancelamento automatico:'}>
                                        <InputAutoComplete
                                            preSelectedValue={selectedAutomaticSchedulesCancel?.description}
                                            width={44}
                                            data={[{ id: true, description: 'Sim' }, { id: false, description: 'Não' }]}
                                            optionList={['description']}
                                            selectedLabel={'description'}
                                            onChange={(e) => setSelectedAutomaticSchedulesCancel(e)} />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Definir se o sistema deve cancelar automaticamente as reservas que não forem pagas até a data limite de pagamento com base no campo "Prazo para pagamento da reserva de XX até X dias depois de reservar"</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <LabelInput text={'Utiliza Saldo:'}>
                                        <InputAutoComplete
                                            preSelectedValue={useBalance?.description}
                                            width={44}
                                            data={[{ id: true, description: 'Sim' }, { id: false, description: 'Não' }]}
                                            optionList={['description']}
                                            selectedLabel={'description'}
                                            onChange={(e) => setUseBalance(e)} />
                                    </LabelInput>
                                    <Tippy
                                        content={<a>Definir se o sistema deve trabalhar com saldos, ao ativar este parâmetro, o sistema voltará saldo em reservas ja pagas, e poderá ser utilizado o saldo para reservas</a>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                            <FaInfoCircle />
                                        </div>
                                    </Tippy>
                                </div>
                            </div>

                            {
                                selectedOpenHour?.id === 0 &&

                                <div className='flex flex-col w-full items-start justify-start gap-2 mt-6'>
                                    <h2 className='text-base text-primaryDefaultLight'>Tabela de horários do sistema</h2>
                                    <div id='defaultTableHours' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                        <TableDefault data={defaultTableOpenHours} title={tableThead} collumns={['dayDescription', 'openHourDescription']} />
                                    </div>
                                </div>

                            }
                            {
                                categoriesList.length > 0 &&
                                associationsList.map(association => {
                                    return <div id={`${association.id}-association`} className='py-4 border-b border-zinc-200 dark:border-secondaryBorderDark flex flex-col w-full items-start justify-start mt-6 gap-2'>
                                        <h1 className='text-base font-bold mb-2 text-titleBlackTextLight dark:text-titleGrayTextDark'>{association?.name}</h1>
                                        {
                                            categoriesList.map(categorie => {
                                                return <div className='sm:ml-4 flex flex-col w-full items-start justify-center gap-6 sm:gap-2'>
                                                    <div className='flex flex-col sm:flex-row gap-1 text-sm text-titleBlackTextLight dark:to-titleGrayTextLight items-start sm:items-center justify-center'>
                                                        <div className='sm:w-[500px]'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>É permitido cancelamento de reservas de <strong>{categorie.name}</strong> até:</p>
                                                        </div>
                                                        <Input
                                                            id={`${association.id}-${categorie.id}-daysToCancel`}
                                                            onChange={(e) => setAssociationsParameters((prev) => (
                                                                {
                                                                    ...prev, [association.id]: {
                                                                        categorie: { ...associationsParameters[association.id]?.categorie, [categorie.id]: { ...associationsParameters[association.id]?.categorie[categorie.id], daysToCancel: justNumber(e?.target?.value) } }
                                                                    }
                                                                }
                                                            ))}
                                                            charLimit={3}
                                                            value={associationsParameters[association.id]?.categorie[categorie.id]?.daysToCancel} width={16}></Input>
                                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>dia(s) antes do início da reserva.</p>
                                                    </div>
                                                    <div className='flex flex-col sm:flex-row gap-1 text-sm text-titleBlackTextLight dark:to-titleGrayTextLight items-start sm:items-center justify-center'>
                                                        <div className='sm:w-[500px]'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Período aberto para reservas de <strong>{categorie.name}</strong> com antecedência de:</p>
                                                        </div>
                                                        <Input
                                                            id={`${association.id}-${categorie.id}-daysToOpen`}
                                                            onChange={(e) => setAssociationsParameters((prev) => (
                                                                {
                                                                    ...prev, [association.id]: {
                                                                        categorie: { ...associationsParameters[association.id]?.categorie, [categorie.id]: { ...associationsParameters[association.id]?.categorie[categorie.id], daysToOpen: justNumber(e?.target?.value) } }
                                                                    }
                                                                }
                                                            ))}
                                                            charLimit={3}
                                                            value={associationsParameters[association.id]?.categorie[categorie.id]?.daysToOpen} width={16}></Input>
                                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>dia(s) antes do início da reserva.</p>
                                                    </div>
                                                    <div className='flex flex-col sm:flex-row gap-1 text-sm text-titleBlackTextLight dark:to-titleGrayTextLight items-start sm:items-center justify-center'>
                                                        <div className='sm:w-[500px]'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Prazo para pagamento de reservas de: <strong>{categorie.name}</strong> até:</p>
                                                        </div>
                                                        <Input
                                                            id={`${association.id}-${categorie.id}-daysToPay`}
                                                            onChange={(e) => setAssociationsParameters((prev) => (
                                                                {
                                                                    ...prev, [association.id]: {
                                                                        categorie: { ...associationsParameters[association.id]?.categorie, [categorie.id]: { ...associationsParameters[association.id]?.categorie[categorie.id], daysToPay: justNumber(e.target.value) } }
                                                                    }
                                                                }
                                                            ))}
                                                            charLimit={3}
                                                            value={associationsParameters[association.id]?.categorie[categorie.id]?.daysToPay} width={16}></Input>
                                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>dia(s) antes do início da reserva.</p>
                                                    </div>
                                                    <div className='flex flex-col sm:flex-row gap-1 text-sm text-titleBlackTextLight dark:to-titleGrayTextLight items-start sm:items-center justify-center'>
                                                        <div className='sm:w-[500px]'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Prazo para pagamento da reserva <strong>{categorie.name}</strong> até:</p>
                                                        </div>
                                                        <Input
                                                            id={`${association.id}-${categorie.id}-daysToPayAfter`}
                                                            onChange={(e) => setAssociationsParameters((prev) => (
                                                                {
                                                                    ...prev, [association.id]: {
                                                                        categorie: { ...associationsParameters[association.id]?.categorie, [categorie.id]: { ...associationsParameters[association.id]?.categorie[categorie.id], daysToPayAfter: justNumber(e.target.value) } }
                                                                    }
                                                                }
                                                            ))}
                                                            charLimit={3}
                                                            value={associationsParameters[association.id]?.categorie[categorie.id]?.daysToPayAfter} width={16}></Input>
                                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>dia(s) depois de reservar.</p>
                                                        {/* teste */}
                                                    </div>
                                                    <div className='flex flex-col sm:flex-row gap-1 text-sm text-titleBlackTextLight dark:to-titleGrayTextLight items-start sm:items-center justify-center'>
                                                        <div className='sm:w-[500px]'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Quantidade de horários que pode selecionar no mesmo dia para <strong>{categorie.name}</strong></p>
                                                        </div>
                                                        <Input
                                                            id={`${association.id}-${categorie.id}-daylySchedules`}
                                                            onChange={(e) => setAssociationsParameters((prev) => (
                                                                {
                                                                    ...prev, [association.id]: {
                                                                        categorie: { ...associationsParameters[association.id]?.categorie, [categorie.id]: { ...associationsParameters[association.id]?.categorie[categorie.id], daylySchedules: justNumber(e.target.value) } }
                                                                    }
                                                                }
                                                            ))}
                                                            charLimit={3}
                                                            value={associationsParameters[association.id]?.categorie[categorie.id]?.daylySchedules} width={16}></Input>
                                                    </div>
                                                    <div className='flex flex-col sm:flex-row gap-1 text-sm text-titleBlackTextLight dark:to-titleGrayTextLight items-start sm:items-center justify-center'>
                                                        <div className='sm:w-[500px]'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Quantidade de horários que pode selecionar na mesma semana para <strong>{categorie.name}</strong></p>
                                                        </div>
                                                        <Input
                                                            id={`${association.id}-${categorie.id}-daylySchedules`}
                                                            onChange={(e) => setAssociationsParameters((prev) => (
                                                                {
                                                                    ...prev, [association.id]: {
                                                                        categorie: { ...associationsParameters[association.id]?.categorie, [categorie.id]: { ...associationsParameters[association.id]?.categorie[categorie.id], weeklySchedules: justNumber(e.target.value) } }
                                                                    }
                                                                }
                                                            ))}
                                                            charLimit={3}
                                                            value={associationsParameters[association.id]?.categorie[categorie.id]?.weeklySchedules} width={16}></Input>
                                                    </div>
                                                    <div className='flex flex-col sm:flex-row gap-1 text-sm text-titleBlackTextLight dark:to-titleGrayTextLight items-start sm:items-center justify-center'>
                                                        <div className='sm:w-[500px]'>
                                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Quantidade de horários que pode selecionar no mesmo mes <strong>{categorie.name}</strong></p>
                                                        </div>
                                                        <Input
                                                            id={`${association.id}-${categorie.id}-daylySchedules`}
                                                            onChange={(e) => setAssociationsParameters((prev) => (
                                                                {
                                                                    ...prev, [association.id]: {
                                                                        categorie: { ...associationsParameters[association.id]?.categorie, [categorie.id]: { ...associationsParameters[association.id]?.categorie[categorie.id], monthlySchedules: justNumber(e.target.value) } }
                                                                    }
                                                                }
                                                            ))}
                                                            charLimit={3}
                                                            value={associationsParameters[association.id]?.categorie[categorie.id]?.monthlySchedules} width={16}></Input>
                                                    </div>

                                                </div>
                                            })
                                        }
                                    </div>
                                })
                            }
                        </>
                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button shadow={true} approval={false} onClick={() => navigate('/configuration')}>Voltar</Button>
                    <Button shadow={true} onClick={() => {
                        handleSubmit()
                    }} >Confirmar</Button>
                </div>
            </Footer>
        </Container>
    )
}