//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
import { Toogle } from '../../../components/toogle/Toogle'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { api } from '../../../services/api/api'
import { validateFields } from '../../../utils/form.validator'
import { getServices } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { ScreenModal } from '../../../components/modals/notification/screenModal'

export function AssociatiosCreate() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [servicesList, setServicesList] = useState([])

    //selected variables
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [agreementsAccess, setAgreementsAccess] = useState(true)
    const [requireRegistrationCode, setRequireRegistrationCode] = useState(false)
    const requiredFields = [
        { "name": 'name', "value": name, "required": true, "type": 'string' },
        { "name": 'description', "value": description, "required": true, "type": 'string' },
    ]

    useEffect(() => {

        setShowUniversalModal(false)
        async function getData() {

            const servicesData = await getServices(userData[0].token)
            if (servicesData.error) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(servicesData.data)
            }
            setServicesList(servicesData.data)
        }

        getData()

    }, [])

    async function handleSubmit() {

        //chamada API
        setLoading(true)
        try {

            if (validateFields(requiredFields).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            const createAssociation = await api.post(`/api/v1/associations`, {
                name,
                description: description ? description : undefined,
                agreementsAccess,
                requireRegistrationCode
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Associação criada com sucesso!")
            navigate('/associations')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal>
                <div className='p-4 flex flex-col items-center justify-center gap-2'>
                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>
                        Já existem serviços cadastrados no sistema, lembre-se adicionar valores para esta nova associação após a criação
                    </p>
                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>
                        Deseja continuar?
                    </p>
                    <div className='flex flex-row w-full gap-2 items-center justify-center'>
                        <Button onClick={() => handleSubmit()} shadow={true}>Cadastrar</Button>
                        <Button approval={false} onClick={() => setShowUniversalModal(false)} shadow={true}>Cancelar</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Cadastro de associação'}></Title>
                </TitlePage>
                <Body hasFooter={true}>

                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <form className='flex flex-col gap-2 items-start justify-start sm:justify-center w-full'>
                                    <LabelInput text={'Nome: *'}>
                                        <Input id='name' type={'text'} value={name} onChange={(e) => setName(e.target.value)}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Descrição: *'}>
                                        <Input id='description' type={'text'} value={description} onChange={(e) => setDescription(e.target.value)}></Input>
                                    </LabelInput>
                                    <Tippy content={'Mantenha ativado caso esta associação possa ver os convênios disponíveis no sistema'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div>
                                            <LabelInput text={'Acesso a convênios'}>
                                                <Toogle value={agreementsAccess} status={agreementsAccess} onClick={() => setAgreementsAccess(!agreementsAccess)} />
                                            </LabelInput>
                                        </div>
                                    </Tippy>
                                    <Tippy content={'Ative caso esta associação exija que no cadastro do usuário haja matrícula'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div>
                                            <LabelInput text={'Exige matrícula'}>
                                                <Toogle value={requireRegistrationCode} status={requireRegistrationCode} onClick={() => setRequireRegistrationCode(!requireRegistrationCode)} />
                                            </LabelInput>
                                        </div>
                                    </Tippy>
                                </form>
                            </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate('/associations')}>Cancelar</Button>
                        <Button shadow={true} approval={true} onClick={() => {
                            if (servicesList?.length > 0) {
                                setShowUniversalModal(true)
                            } else {
                                handleSubmit()
                            }
                        }}>Cadastrar</Button>
                    </div>
                </Footer>
            </Container>
        </>

    )
}