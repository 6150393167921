import React, { useEffect, useState } from 'react'
//components
import { Container } from '../../components/container/container'
import { Title } from '../../components/titlePages/title'
import { TitlePage } from '../../components/titlePages/title.page'
import { Body } from '../../components/container/Body'
import { DefaultLoader } from '../../components/loaders/defaultLoader'
import { TableDefault } from '../../components/table/table.default'
//contexts
import { useAuth } from '../../contexts/useAuth'
import { useThemeContext } from '../../contexts/themeContext'
import { formattTimeToShow, getMyBalance, getMyBalanceData, getMyBalanceHistory } from '../../services/api/callAPIsFunctions/defaultCalls.api'
import { TableMobile } from '../../components/table/table.mobile'
import { Button } from '../../components/buttons/button.default'
import { FaEye } from 'react-icons/fa'

export function MyBalance() {

    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalText, setShowNotificationModalSuccess } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [myBalanceList, setMyBalanceList] = useState([])
    const [myBalance, setMyBalance] = useState(0)
    const [showHistory, setShowHistory] = useState({ status: 0, data: [] })

    const tableThead = [
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        },
        {
            "name": 'Valor',
            "original_name": 'value'
        },
        {
            "name": 'Descrição',
            "original_name": "description"
        }
    ]

    async function getData() {

        setLoading(true)
        const balanceData = await getMyBalance(userData[0].token)
        const balanceHistory = await getMyBalanceHistory(userData[0].token)

        setMyBalanceList(balanceHistory.data)

        if (balanceData.error) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(balanceData.data)
        }
        console.log(balanceData)
        if (balanceData.data?.length > 0) {

            setMyBalance(balanceData.data[0]?.balance)
        } else {
            setMyBalance(0)
        }
        setLoading(false)
    }

    useEffect(() => {

        getData()

    }, [])


    function handleHistory(e){

        setShowHistory({status: true, data: e})
    }

    function showHistoryContainer() {

        return <Container>
            <TitlePage>
                <Title text={'Histórico'} />
            </TitlePage>
            <Body>
                <div className='overflow-hidden flex flex-col items-center justify-center gap-4 w-full'>
                    <div className='animate-[wiggleLeft_.8s_ease-in-out] flex flex-col w-full gap-4 items-center justify-center'>
                        <div className='flex flex-col w-full items-start justify-start gap-4'>
                            <Button onClick={() => setShowHistory({ status: 0, data: [] })} shadow={true}>Voltar</Button>
                            <a className='text-base text-primaryDefaultLight font-semibold'>Detalhamento</a>
                        </div>
                        <div className='w-full border gap-2 border-gray-200 rounded-lg bg-gray-100 flex flex-col p-2 items-start justify-start text-sm'>
                            <a className=''><strong>Referente a: </strong>{showHistory?.data?.description}</a>
                            <a><strong>Valor: </strong><a className='text-primaryDefaultLight font-semibold'>{showHistory?.data?.value}</a></a>
                            <a><strong>Criado em: </strong><a className='text-primaryDefaultLight font-semibold'>{formattTimeToShow(showHistory?.data?.original_created_at)}</a></a>

                        </div>
                        <div className='flex flex-col w-full items-start justify-start'>
                            <a className='text-sm text-inputPlaceholderLight'>Problemas com saldo? Contate a secretaria</a>
                        </div>
                    </div>
                </div>
            </Body>
        </Container>
    }

    return showHistory?.status ?
        showHistoryContainer()
        :
        <Container>
            <TitlePage>
                <Title text='Meu saldo'></Title>
            </TitlePage>
            <Body>
                {
                    loading ?
                        <div className='flex flex-col h-96 items-center justify-center'>
                            <DefaultLoader />
                        </div>
                        :
                        <div className='flex flex-col items-start justify-start gap-4 w-full'>
                            <h1 className='text-primaryDefaultLight font-bold'>Saldo atual:</h1>
                            <div className='p-4 rounded-lg bg-primaryDefaultLight w-40 xl:w-72 2xl:w-96 flex flex-col items-center justify-center'>
                                <p className='text-white'>{myBalance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                            {
                                userData[0]?.customersData?.phone > 0 &&
                                <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm'>{`Problemas com saldo? Contate a administração: ${userData[0]?.customersData.dddPhone} - ${userData[0]?.customersData.phone}`}</p>
                            }
                            <h1 className='text-primaryDefaultLight font-bold'>Histórico</h1>

                            {
                                !loading &&
                                <>
                                    <div id='myBalanceTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                        <TableDefault
                                            // pagination={true}
                                            // totalPages={totalPages}
                                            onClick={(e) => setShowHistory({ status: true, data: e })}
                                            title={tableThead}
                                            data={myBalanceList}
                                            collumns={["created_at", "value", 'description']}
                                        // loading={loading}
                                        // filter={(e) => orderTableData(e)}
                                        />
                                    </div>
                                    <div id='myBalanceTableContentMobile' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                                        <TableMobile
                                            onClick={(e) => e.func.execute(e.data)}
                                            title={tableThead}
                                            // loading={loading}
                                            // pagination={true}
                                            // totalPages={totalPages}
                                            // filter={(e) => orderTableData(e)}
                                            functions={[{ "title": "Ver", "icon": <FaEye />, "execute": handleHistory }]}
                                            data={myBalanceList}
                                            collumns={["created_at", "value", 'description']}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                }
            </Body>
        </Container>

}