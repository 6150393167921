import React, { lazy, useEffect, useState } from "react";

import styles from './styles/logonLoader.module.css'

export function LogonLoader() {

    const textList = ['Carregando...', 'Só mais um pouco...', 'Preparando quadras...', 'Quasé lá...']
    const [indexText, setIndexText] = useState(0)

    async function animateTextLogin() {
        await new Promise(resolve => setTimeout(resolve, 3000))
        if (indexText < 3) {
            setIndexText((prev) => (prev + 1))
        } else {
            setIndexText(0)
        }
    }

    useEffect(() => {
        animateTextLogin()
    }, [indexText])

    return (
        <div className="flex flex-col items-center justify-center animate-wiggle">

            <div className={styles.loading}>
                <div className={styles.dot}></div>
                <div className={styles.dot}></div>
                <div className={styles.dot}></div>
                <div className={styles.dot}></div>
                <div className={styles.dot}></div>
            </div>
            <a className="animate-bounce mt-28 text-base text-white">{textList[indexText]}</a>
        </div>
    )
}