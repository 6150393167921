import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
//components
import { Body } from '../../components/container/Body'
import { Toogle } from '../../components/toogle/Toogle'
import { Button } from '../../components/buttons/button.default'
import { Container } from '../../components/container/container'
import { Title } from '../../components/titlePages/title'
import { TitlePage } from '../../components/titlePages/title.page'
import moment from 'moment'
import { Footer } from '../../components/footer/Footer'
import { ScreenModal } from '../../components/modals/notification/screenModal'
import CurrencyInput from '../../components/input/inputCurrencyPtBr'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { TableDefault } from '../../components/table/table.default'
//contexts
import { useThemeContext } from '../../contexts/themeContext'
import { useAuth } from '../../contexts/useAuth'
import { LabelInput } from '../../components/label/label.input'
import { InputAutoComplete } from '../../components/input/input.autocomplete'
//utils
import { paymentMethods } from '../../utils/payment/paymentMethods'
import { api } from '../../services/api/api'
import { responseError } from '../../utils/responsesFunctions/error.response'
import { DefaultLoader } from '../../components/loaders/defaultLoader'
import { formatPaymentMethods } from '../../utils/formatObjects/formatPaymentMethods'
import { formattTimeToShow, getLogs, getPaymentsById, getSelectedUserBalaceă } from '../../services/api/callAPIsFunctions/defaultCalls.api'
import { InputCheckBox } from '../../components/input/input.checkBox'
import { FaInfoCircle, FaList, FaRegMoneyBillAlt, FaRegNewspaper, FaRegWindowClose, FaPencilAlt } from 'react-icons/fa'
import { FiX, FiCheck } from 'react-icons/fi'
import { currencyPtBr } from '../../utils/formatCurrency/currencyTextPTBR'
import { LoginLoader } from '../../components/loaders/loginLoader'

export function ReservationEdit({ setInEdit, data }) {

    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const [loadingLog, setLoadingLog] = useState(false)
    const [reservationData, setReservationData] = useState(data)
    const { setShowUniversalModal, setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
    const [cancelSchedule, setCancelSchedule] = useState(false)
    const [selectedReturnBalance, setSelectedReturnBalance] = useState(true)
    const [observationUpdate, setObservationUpdate] = useState(true)
    const [observation, setObservation] = useState(data?.observation)
    console.log(data)

    //informar pagamento
    const [totalValue, setTotalValue] = useState(0)
    const [userBalance, setUserBalance] = useState(0)
    const [useBalance, setUseBalance] = useState(false)
    const [usedBalance, setUsedBalance] = useState(0)
    const [discount, setDiscount] = useState(0)

    //valores multa e reembolso
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [showChangeValuesModal, setShowChangeValuesModal] = useState(false)
    const [fee, setfee] = useState(0)
    const [chargeBack, setChargeBack] = useState(0)

    const [showReceiptNumber, setShowReceiptNumber] = useState(false)
    const [receiptNumber, setReceiptNumber] = useState('')

    //logs
    const [filteredLogs, setFilteredLogs] = useState([])
    const tableThead = [
        {
            "name": 'Data realizada',
            "original_name": 'created_at'
        },
        {
            "name": 'Executado por',
            "original_name": 'users_fullname'
        },
        {
            "name": 'Ação',
            "original_name": 'action'
        }
    ]

    //filters search
    const [totalPages, setTotalPages] = useState(1)

    async function handleCancelSchedule() {

        setLoading(true)
        //api para cancelar o pagamento
        try {
            let apiData = userData[0]?.customersData?.useBalance ?
                {
                    returnBalance: (reservationData.payments_status === 0 || reservationData.payments_status === 3 || !userData[0].customersData?.useBalance) ? false : selectedReturnBalance,
                    fee

                }
                :
                {
                    returnBalance: false,
                    fee,
                    chargeBack
                }

            const cancellationData = await api.put(`/api/v1/schedules/cancel/schedules_id/${reservationData.id}`,
                apiData,
                {
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
            setLoading(false)
            reservationData.payments_totalValue = cancellationData.data.data.payment[0]?.totalValue,
                reservationData.payments_chargeBack = cancellationData.data.data.payment[0]?.chargeBack,
                reservationData.payments_fee = cancellationData.data.data.payment[0]?.fee,
                reservationData.payments_balance = cancellationData.data.data.payment[0]?.balance,
                reservationData.status = cancellationData.data.data.schedules[0]?.status,
                reservationData.original_status = cancellationData.data.data.payment[0]?.status,
                setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText(`Reserva cancelada`)
            setShowUniversalModal(false)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function logs(e) {
        setLoadingLog(true)
        const logData = await getLogs(
            userData[0]?.token,
            e?.page ? e.page : '',
            'schedules',
            reservationData.id
        )
        setTotalPages(logData.data.totalPages)
        setFilteredLogs(logData.data.data)
        setLoadingLog(false)
    }

    useEffect(() => {
        logs()
    }, [])

    async function updatePaymentStatus(status) {

        if (!selectedPaymentMethod?.value) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            document.getElementById('paymentMethod').style.border = "1px solid red"
            return setShowNotificationModalText('Forma de pagamento é obrigatoria')
        }
        //Api atualizacao de pagamentos
        setLoading(true)
        try {
            const updatePayment = await api.put(`/api/v1/schedules/confirm/schedules_id/${reservationData?.id}`, {
                discount: discount > 0 ? discount : undefined,
                useBalance,
                paymentMethod: selectedPaymentMethod.value,
                sendEmail: true
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setReservationData({
                ...reservationData,
                status: updatePayment.data?.data.schedules[0]?.status,
                original_status: updatePayment.data?.data.schedules[0]?.status,
                payments_status: updatePayment.data?.data.payment[0]?.status,
                originalPaymentStatus: updatePayment.data?.data.payment[0]?.status,
                paymentStatus: <div className='p-2 '><a className='bg-green-700 rounded-lg p-1 text-white'>Pago</a></div>,
                payments_discount: updatePayment.data.data?.payment[0].discount,
                payments_balance: updatePayment.data.data?.payment[0].balance,
                payments_fee: updatePayment.data.data?.payment[0].fee,
                payments_chargeBack: updatePayment.data.data?.payment[0].chargeBack,
                paymentStatus: <div className='p-2 '><a className='bg-green-700 rounded-lg p-1 text-white'>Pago</a></div>
            })

            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Pagamento informado com sucesso!')
            reservationData.paymentMethod = selectedPaymentMethod?.value
            setShowUniversalModal(false)
            setLoading(false)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                animateLoading(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
        setLoading(false)
        setCancelSchedule(false)

    }

    async function handleSubmitNewPayment() {

        setCancelSchedule(false)
        setTotalValue(reservationData.serviceValue)
        setShowUniversalModal(true)
        setLoading(true)
        const balance = await getSelectedUserBalaceă(userData[0].token, reservationData.schedule_users_id)
        setUserBalance(balance?.data[0]?.balance)

        setLoading(false)
    }

    async function handleChangeSchedulesValues() {

        try {
            setLoading(true)
            const updateScheduleValues = await api.put(`/api/v1/payments/${reservationData?.payments_id}`, {
                discount,
                fee,
                chargeBack
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            reservationData.payments_discount = updateScheduleValues.data.data[0]?.discount,
                reservationData.payments_totalValue = updateScheduleValues.data.data[0]?.totalValue,
                reservationData.payments_chargeBack = updateScheduleValues.data.data[0]?.chargeBack,
                reservationData.payments_fee = updateScheduleValues.data.data[0]?.fee,
                reservationData.payments_balance = updateScheduleValues.data.data[0]?.balance
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Valores atualizados')
            setShowUniversalModal(false)
            setShowChangeValuesModal(false)
            setLoading(false)

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowUniversalModal(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowUniversalModal(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function cancelAndPayDate() {
        //data do agendamento convertida para momentjs
        let scheduleDate = moment(reservationData.original_startDate)
        let scheduleDatePay = moment(reservationData.original_startDate)

        let daysToPay = reservationData.daysToPay
        let daysToCancel = reservationData.daysToCancel

        //Formata a data de cancelamento caso náo encontre parametrizacao ficará vazia
        if (daysToCancel > 0) {
            setReservationData((prev) => ({ ...prev, cancelDate: scheduleDate.subtract(daysToCancel, 'days').format("DD/MM/YYYY").concat(" 23:59") }))
        } else {
            setReservationData((prev) => ({ ...prev, cancelDate: '-' }))
        }
        //Formata a data de pagamento caso náo encontre parametrizacao ficará vazia
        if (daysToPay > 0) {
            setReservationData((prev) => ({ ...prev, payDate: scheduleDatePay.subtract(daysToPay, 'days').format("DD/MM/YYYY").concat(" 23:59") }))
        } else {
            setReservationData((prev) => ({ ...prev, payDate: '-' }))
        }
    }

    function handleDiscount(discountValue) {
        if (discountValue > 0) {
            setDiscount(discountValue)
            setUseBalance(false)
            setTotalValue(reservationData?.serviceValue - discountValue)
            setUsedBalance(0)
        }
    }

    function handleUseBalance() {

        setUseBalance(!useBalance)

        if (userBalance > 0 && !useBalance) {

            let thisTotalValue = 0
            let hasDiscount = discount > 0 ? true : false

            if (discount >= reservationData.serviceValue) {
                setUseBalance(false)
                setUsedBalance(0)
                return
            }

            //desconto menor do que a reserva e desconto + saldo menor do que a reserva
            if ((discount < reservationData.serviceValue) && ((userBalance + discount) <= reservationData.serviceValue)) {
                thisTotalValue = reservationData.serviceValue - (userBalance + discount)
                setUsedBalance(userBalance)
                setTotalValue(thisTotalValue)
            }

            //desconto menor do que a reserva e desconto + saldo maior do que a reserva
            if ((discount < reservationData.serviceValue) && ((userBalance + discount) > reservationData.serviceValue)) {
                let rest = reservationData.serviceValue - discount
                let diference = rest - userBalance
                setUsedBalance(userBalance + diference)
                setTotalValue(0)
            }
        } else {
            handleDiscount(discount)
            setUsedBalance(0)
            // setTotalValue(reservationData?.serviceValue)
        }
    }

    useEffect(() => {
        setReceiptNumber(reservationData?.payments_manualReceipt)
        if (reservationData.associateSchedules?.length > 0) {
            let value = 0

            reservationData.associateSchedules.map(schedule => {
                value += schedule.serviceValue
            })

            value += reservationData.serviceValue
            reservationData.serviceValue = value
        }
        cancelAndPayDate()

    }, [])

    async function handleNewReceipt() {

        if (!receiptNumber) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(`Campo recibo está vazio, verifique!`)
        }
        try {
            const receipt = await api.put(`/api/v1/payments/${reservationData.payments_id}/manualReceipt`, {
                manualReceipt: receiptNumber
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setShowReceiptNumber(false)
        } catch (error) {

            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(responseError(error))

        }
    }

    function handleShowCancelModal() {
        userData[0]?.customersData?.useBalance ?
            handleCancelSchedule()
            :
            setShowCancelModal(true)
    }

    async function handleUpdateContract(contractStatus) {
        setLoading(true)
        try {
            const changeContractStatus = await api.put(`/api/v1/schedules/contract/schedules_id/${reservationData.id}/contractSigned/${contractStatus}`, {}, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })
            setReservationData({ ...reservationData, contractSigned: contractStatus })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(responseError(error))
        }
    }

    async function handleUpdateObservation(){
        setLoading(true)
        try {
            await api.put(`/api/v1/schedules/${reservationData.id}/observation`, {
                observation
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            setReservationData({ ...reservationData, observation })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(responseError(error))
        }
    }

    return (
        <>
            <ScreenModal title={'Informar pagamento'}>
                {
                    loading ?
                        <div className='h-96 w-[600px] flex flex-col items-center justify-center'>
                            <DefaultLoader />
                        </div>
                        :
                        !cancelSchedule && !showCancelModal && !showChangeValuesModal ? //modal para informar pagamento
                            <div className='flex flex-col w-full sm:w-[600px] items-center justify-start gap-6 px-2 pb-8 sm:pb-8'>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark mt-10'>Informe a forma de pagamento</p>
                                <LabelInput text={'Forma de pagamento'}>
                                    <InputAutoComplete id="paymentMethod" data={paymentMethods} selectedLabel={'description'} optionList={['id', 'description']} onChange={(e) => setSelectedPaymentMethod(e)} />
                                </LabelInput>
                                <div className='flex flex-row gap-4 items-start justify-start w-96'>
                                    <LabelInput text={'Desconto'}>
                                        <CurrencyInput placeHolder={`R$`} id='discountForReservation' onChange={(e) => {
                                            if (e.target.value?.length > 0 && parseFloat(e.target.value?.replace('R$', '')?.replace('.', '')?.replace(',', '.')) <= reservationData.serviceValue) {
                                                handleDiscount(parseFloat(e.target.value?.replace('R$', '')?.replace('.', '')?.replace(',', '.')))
                                            } else {
                                                let resetDiscount = discount
                                                resetDiscount = resetDiscount?.length > 0 ? parseFloat(discount?.replace('R$', '')?.replace('.', '')?.replace(',', '.')).toString() : ''
                                                document.getElementById('discountForReservation').value = resetDiscount
                                                setDiscount(resetDiscount)
                                                setTotalValue(reservationData.serviceValue)
                                            }
                                        }}></CurrencyInput>
                                    </LabelInput>
                                    {
                                        userData[0]?.customersData?.useBalance &&
                                        <LabelInput text={`Usar saldo`}>
                                            <InputCheckBox value={useBalance} onClick={() => handleUseBalance()} />
                                        </LabelInput>
                                    }
                                </div>
                                <div className='flex flex-col justify-start items-start w-96 gap-2 border border-primaryDefaultLight rounded-md p-2'>
                                    <div className='flex flex-row flex-wrap gap-4 justify-between border-b border-primaryDefaultLight w-full'>
                                        <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Saldo do cliente:</a>
                                        <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{userBalance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                                    </div>
                                    <div className='flex flex-row flex-wrap gap-4 justify-between border-b border-primaryDefaultLight w-full'>
                                        <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Saldo do utilizado:</a>
                                        <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{usedBalance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                                    </div>
                                    <div className='flex flex-row flex-wrap gap-4 justify-between border-b border-primaryDefaultLight w-full'>
                                        <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Desconto:</a>
                                        <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{discount?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                                    </div>
                                    <div className='flex flex-row flex-wrap gap-4 justify-between border-b border-primaryDefaultLight w-full'>
                                        <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Valor da reserva:</a>
                                        <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.serviceValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                                    </div>
                                    <div className='flex flex-row justify-between w-full flex-wrap gap-4'>
                                        <a className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Total a pagar:</a>
                                        <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark font-bold'>{totalValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</a>
                                    </div>
                                </div>
                                <Button onClick={() => updatePaymentStatus(reservationData.original_status)} shadow={true}>Salvar</Button>

                            </div>
                            :
                            showCancelModal ? //modal para informar multa/reembolso
                                <div className="gap-4 flex flex-col w-full p-4 items-start justify-center">
                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Dados para confirmação de cancelamento da reserva</p>
                                    <div className='flex items-start justify-start'>
                                        <LabelInput text={'Multa'}>
                                            <CurrencyInput
                                                id={`fee`}
                                                placeHolder={`R$ ${(fee ? fee : '0').toString()?.replace('.', ',')}`}
                                                width={40}
                                                onChange={(e) => {
                                                    setfee(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                                }}
                                            />
                                        </LabelInput>
                                        <Tippy
                                            content={<a>Informe se foi cobrado algum valor de multa para o cliente efetuar o cancelamento, o valor da multa será somado ao valor da reserva</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                <FaInfoCircle />
                                            </div>
                                        </Tippy>
                                    </div>
                                    {
                                        reservationData?.originalPaymentStatus == 1 &&
                                        <div className='flex items-start justify-start'>
                                            <LabelInput text={'Reembolso'}>
                                                <CurrencyInput
                                                    id={`chargeBack`}
                                                    placeHolder={`R$ ${(chargeBack ? chargeBack : '0').toString()?.replace('.', ',')}`}
                                                    width={40}
                                                    onChange={(e) => {
                                                        setChargeBack(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                                    }}
                                                />
                                            </LabelInput>
                                            <Tippy
                                                content={<a>Informe se foi reembolsado algum valor ao cliente</a>}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}>
                                                <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                    <FaInfoCircle />
                                                </div>
                                            </Tippy>
                                        </div>
                                    }
                                    <div className='flex gap-4 items-center justify-center'>
                                        <Button onClick={() => handleCancelSchedule()} shadow={true}>Confirmar</Button>
                                        <Button approval={false} onClick={() => {
                                            setShowCancelModal(false)
                                            setShowUniversalModal(false)
                                        }} shadow={true}>Canclear</Button>
                                    </div>
                                </div>
                                :
                                showChangeValuesModal ? //modal para alteraçao de valores
                                    <div className="gap-4 flex flex-col w-full p-4 items-start justify-center">
                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Dados para alteraçao de valores da reserva.</p>
                                        <div className='flex items-start justify-start'>
                                            <LabelInput text={'Desconto'}>
                                                <CurrencyInput
                                                    id={`discount`}
                                                    placeHolder={`R$ ${(discount ? discount : '').toString()?.replace('.', ',')}`}
                                                    width={40}
                                                    onChange={(e) => {
                                                        setDiscount(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                                    }}
                                                />
                                            </LabelInput>
                                            <Tippy
                                                content={<a>Informe se dado algum desconto ao cliente. Caso não alterar será mantido o valor atual</a>}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}>
                                                <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                    <FaInfoCircle />
                                                </div>
                                            </Tippy>
                                        </div>
                                        <div className='flex items-start justify-start'>
                                            <LabelInput text={'Multa'}>
                                                <CurrencyInput
                                                    id={`fee`}
                                                    placeHolder={`R$ ${(fee ? fee : '').toString()?.replace('.', ',')}`}
                                                    width={40}
                                                    onChange={(e) => {
                                                        setfee(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                                    }}
                                                />
                                            </LabelInput>
                                            <Tippy
                                                content={<a>Informe se foi cobrado algum valor de multa para o cliente efetuar o cancelamento, o valor da multa será somado ao valor da reserva. Caso não alterar será mantido o valor atual</a>}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}>
                                                <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                    <FaInfoCircle />
                                                </div>
                                            </Tippy>
                                        </div>
                                        {
                                            reservationData?.originalPaymentStatus == 1 &&
                                            <div className='flex items-start justify-start'>
                                                <LabelInput text={'Reembolso'}>
                                                    <CurrencyInput
                                                        id={`chargeBack`}
                                                        placeHolder={`R$ ${(chargeBack ? chargeBack : '').toString()?.replace('.', ',')}`}
                                                        width={40}
                                                        onChange={(e) => {
                                                            setChargeBack(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                                        }}
                                                    />
                                                </LabelInput>
                                                <Tippy
                                                    content={<a>Informe se foi reembolsado algum valor ao cliente. Caso não alterar será mantido o valor atual</a>}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                        <FaInfoCircle />
                                                    </div>
                                                </Tippy>
                                            </div>
                                        }
                                        <div className='flex gap-4 items-center justify-center'>
                                            <Button onClick={() => handleChangeSchedulesValues()} shadow={true}>Confirmar</Button>
                                            <Button approval={false} onClick={() => {
                                                setShowCancelModal(false)
                                                setShowUniversalModal(false)
                                            }} shadow={true}>Canclear</Button>
                                        </div>
                                    </div>
                                    : //modal para cancelamento clientes que utilizam saldo
                                    <div className='flex flex-col p-4 items-center justify-start gap-6'>
                                        {
                                            reservationData.associateSchedules?.length > 0 ?
                                                <div className='flex flex-col gap-2 items-center'>
                                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark '>{`Nesta reserva existem ${reservationData.associateSchedules?.length + 1} horários`}</p>
                                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark '>{`ao efetuar o cancelamento, todos os horários serão afetados`}</p>
                                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja cancelar a reserva?</p>
                                                    <p className='text-xs text-red-400 dark:text-red-700'>* Caso o método de pagamento escolhido for desconto em folha, o sistema irá cancelar a reserva e zerar o valor pago</p>
                                                </div>

                                                :
                                                <div className='flex flex-col gap-2 items-center'>
                                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja cancelar a reserva?</p>
                                                    <p className='text-xs text-red-400 dark:text-red-700'>* Caso o método de pagamento escolhido for desconto em folha, o sistema irá cancelar a reserva e zerar o valor pago</p>
                                                </div>
                                        }
                                        {
                                            reservationData.payments_status === 1 && userData[0].customersData?.useBalance &&
                                            <div className='flex flex-col sm:flex-row-reverse gap-2 items-center'>
                                                <div className='flex items-start justify-start gap-1'>
                                                    <LabelInput text={'Voltar Saldo'}>
                                                        <Toogle status={selectedReturnBalance} onClick={() => setSelectedReturnBalance(!selectedReturnBalance)}></Toogle>
                                                    </LabelInput>
                                                    <Tippy
                                                        content={<a>Informe se o cancelamento deverá gerar saldo para o cliente, o saldo será o mesmo valor pago na reserva</a>}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='top'
                                                        delay={100}>
                                                        <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                            <FaInfoCircle />
                                                        </div>
                                                    </Tippy>
                                                </div>

                                                {
                                                    userData[0]?.customersData?.useBalance &&
                                                    <div className='flex items-start justify-start'>
                                                        <LabelInput text={'Multa'}>
                                                            <CurrencyInput
                                                                id={`fee`}
                                                                placeHolder={`R$ ${(fee ? fee : '0').toString()?.replace('.', ',')}`}
                                                                width={40}
                                                                onChange={(e) => {
                                                                    setfee(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                                                }}
                                                            />
                                                        </LabelInput>
                                                        <Tippy
                                                            content={<a>Informe se foi cobrado algum valor de multa para o cliente efetuar o cancelamento, o valor da multa será somado ao valor da reserva</a>}
                                                            arrow={true}
                                                            animation='shift-away'
                                                            placement='top'
                                                            delay={100}>
                                                            <div className='text-titleBlackTextLight dark:text-titleGrayTextDark'>
                                                                <FaInfoCircle />
                                                            </div>
                                                        </Tippy>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <div className='flex flex-row justify-center gap-4'>
                                            <Button onClick={() => handleShowCancelModal()} shadow={true}>Sim</Button>
                                            <Button onClick={() => setShowUniversalModal(false)} approval={false} shadow={true}>Não</Button>
                                        </div>
                                    </div>
                }
            </ScreenModal>
            <Container>
                {
                    loading &&
                    <div className='absolute z-50 flex flex-col items-center justify-center w-full h-full bg-zinc-700 opacity-70'>
                        <LoginLoader />
                    </div>
                }
                <TitlePage>
                    <Title text={`Detalhes da reserva`} />
                    <div className='flex gap-1 items-center justify-center'>
                        {reservationData.status !== 2 && <a className='text-sm'>{reservationData?.paymentStatus}</a>}
                        {
                            reservationData.categories_contractRequired && !reservationData.contractSigned &&
                            <a className='text-xs p-1 rounded-lg text-white bg-red-500'>Pendente assinar contrato</a>
                        }
                    </div>
                </TitlePage>
                <Body hasFooter={true}>
                    <div className='flex flex-col w-full gap-10'>
                        <div className='flex flex-col items-start justify-start gap-4 sm:gap-2'>
                            <div className='mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                <h1 className='text-primaryDefaultLight text-base'>Informações da reserva</h1>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>ID da reserva:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.id}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>ID do pagamento:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{[1, 2].includes(reservationData?.status) && reservationData.payments_status == 1 ? reservationData.payments_id : '-'}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>ID do gateway de pagamento:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.externalPayment_id ? reservationData?.externalPayment_id : '-'}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Responsável:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.schedule_users_firstname}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Criado por:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{`${reservationData.users_created_firstname} ${reservationData.users_created_lastname}`}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Categoria:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.categories_name}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Espaço:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.services_name}</p>
                            </div>
                            <div className='flex flex-row flex-wrap items-start justify-start w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Data:</p>
                                </div>
                                {
                                    reservationData.associateSchedules?.length > 0 &&
                                    reservationData.associateSchedules.map(schedule => {
                                        return <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{schedule.startDate} | </p>
                                    })
                                }
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.startDate}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Associação:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.associations_name}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Observação:</p>
                                </div>
                                <textarea 
                                    wrap='hard' 
                                    disabled={observationUpdate}
                                    className='border border-gray-100 bg-gray-50 dark:bg-thirdDefaultDark max-h-96 overflow-auto text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'
                                    onChange={(e) => {
                                        setObservation(e.target.value)
                                    }}
                                >
                                    {observation}
                                </textarea>
                                <div className='flex items-center justify-center gap-2'>
                                    {
                                        !observationUpdate ? <>
                                            <Button type={'button'} height={8} approval={true} onClick={() => {
                                                handleUpdateObservation()
                                                setObservationUpdate(!observationUpdate)
                                            }}><FiCheck size={18}/></Button> 
                                            <Button type={'button'} height={8} approval={false} onClick={() => {
                                                setObservation(reservationData.observation)
                                                setObservationUpdate(!observationUpdate)
                                            }}><FiX size={18}/></Button>
                                        </> : <Button type='button' height={8} onClick={() => setObservationUpdate(!observationUpdate)}><FaPencilAlt/></Button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col items-start justify-start gap-4 sm:gap-2'>
                            <div className='mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                <h1 className='text-primaryDefaultLight text-base'>Dados de pagamento</h1>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Data da criação:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.created_at}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Prazo para cancelamento:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.dateToCancel?.length > 0 ? moment(reservationData?.dateToCancel).utcOffset(0).format('DD/MM/YYYY HH:mm') : '-'}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Prazo para pagamento:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_dueDate}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Pagamento:</p>
                                </div>
                                <p className='text-xs'>{reservationData?.status === 2 && reservationData?.payments_status === 2 ? '-' : reservationData.paymentStatus}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Valor da reserva:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                            {
                                userData[0]?.customersData?.useBalance &&
                                <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                    <div className='w-56'>
                                        <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Saldo utilizado:</p>
                                    </div>
                                    <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_balance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                </div>
                            }
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Desconto concedido:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_discount?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Valor multa:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_fee ? reservationData?.payments_fee.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : currencyPtBr(0)}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Valor reembolsado:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_chargeBack ? reservationData?.payments_chargeBack.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : currencyPtBr(0)}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Valor recebido:</p>
                                </div>
                                {
                                    [0, 3, 2].includes(reservationData?.originalPaymentStatus) ?
                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{currencyPtBr(0)}</p>
                                        :
                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData?.payments_totalValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>

                                }
                                {
                                    !userData[0]?.customersData?.useBalance && reservationData?.originalPaymentStatus === 1 &&
                                    <Tippy content={'Clique se for necessário ajustar valores como multa, desconto ou valor reembolsado'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <Button shadow={true} module={'payments:update'} userData={userData} onClick={() => { setShowChangeValuesModal(true); setShowUniversalModal(true); setDiscount(undefined), setfee(undefined), setChargeBack(undefined) }} >
                                            <p className='text-xs'>Alterar valores</p>
                                        </Button>
                                    </Tippy>
                                }
                            </div>
                        </div>
                        <div className='flex flex-col items-start justify-start gap-2'>
                            <div className='mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                <h1 className='text-primaryDefaultLight text-base'>Dados do pedido</h1>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Forma de pagamento:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{formatPaymentMethods(reservationData?.payments_paymentMethod)}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Origem do pagamento:</p>
                                </div>
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{reservationData.payments_origin === 0 ? 'Secretária / Administracão' : "Online"}</p>
                            </div>
                            <div className='flex flex-col sm:flex-row w-full sm:gap-2'>
                                <div className='w-56'>
                                    <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Recibo número:</p>
                                </div>
                                {
                                    showReceiptNumber ?
                                        <div className='flex flex-row gap-2 '>
                                            <input className='w-40 max-w-40 text-sm p-2 h-10 border border-zinc-100' type='text' onChange={(e) => setReceiptNumber(e.target.value)} value={receiptNumber}></input>
                                            <div onClick={() => handleNewReceipt()} className='w-16 flex flex-col items-center justify-center shadow-lg rounded-lg cursor-pointer p-2 bg-primaryDefaultLight text-sm text-white'>
                                                <p>Salvar</p>
                                            </div>
                                            <div onClick={() => setShowReceiptNumber(false)} className='w-16 flex flex-col items-center justify-center shadow-lg rounded-lg cursor-pointer p-2 bg-primaryDefaultLight text-sm text-white'>
                                                <p>Cancelar</p>
                                            </div>
                                        </div>
                                        :
                                        receiptNumber?.length > 0 ?
                                            <div className='flex flex-row gap-2'>
                                                <div className='p-2 bg-gray-200 border max-w-40 w-40 h-10 overflow-hidden border-gray-300 text-sm text-titleBlackTextLight dark:bg-thirdDefaultDark dark:text-titleGrayTextDark'>
                                                    <p>{receiptNumber}</p>
                                                </div>
                                                <Tippy content={'Editar número do recibo'}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div onClick={() => setShowReceiptNumber(true)} className='w-16 flex flex-col items-center justify-center cursor-pointer shadow-lg rounded-lg p-2 bg-primaryDefaultLight text-sm text-white'>
                                                        <p>Editar</p>
                                                    </div>
                                                </Tippy>
                                            </div>
                                            :
                                            (userData[0]?.permissions.includes('schedules:update') || userData[0]?.typeAccess_id < 3) &&
                                            <Tippy content={'Informar um número de recibo manual, este número será impresso no recibo do sistema'}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}>
                                                <div onClick={() => setShowReceiptNumber(true)} className='w-16 flex flex-col items-center justify-center shadow-lg rounded-lg cursor-pointer p-2 bg-primaryDefaultLight text-sm text-white'>
                                                    <p className='text-xs'>Informar</p>
                                                </div>
                                            </Tippy>
                                }
                            </div>
                            {
                                reservationData?.categories_contractRequired &&
                                <div className='relative flex flex-col sm:flex-row w-full sm:gap-2'>
                                    <div className='w-56'>
                                        <p className='text-sm font-bold text-titleBlackTextLight dark:text-titleGrayTextDark'>Contrato assinado:</p>
                                    </div>
                                    <LabelInput>
                                        <Toogle status={reservationData.contractSigned} onClick={(e) => handleUpdateContract(!e)} />
                                    </LabelInput>

                                </div>
                            }
                        </div>
                        <div className='flex flex-row gap-4 flex-wrap'>
                            {

                                <div className="mt-6 flex flex-col sm:flex-row flex-wrap items-start justify-start w-full">
                                    {
                                        reservationData.status !== 2 && (userData[0]?.permissions.includes('schedules:update') || userData[0]?.typeAccess_id < 3) &&
                                        <Tippy content={<p>Cancelar esta reserva</p>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div onClick={() => { setShowUniversalModal(true); setCancelSchedule(true) }} className="p-2 hover:bg-gray-100 hover:dark:bg-zinc-600 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                <FaRegWindowClose className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Cancelar</a>
                                            </div>
                                        </Tippy>
                                    }
                                    {
                                        reservationData.status === 1 &&
                                        <Tippy content={<p>Impressão do recibo</p>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div onClick={() => {
                                                localStorage.setItem('receipt', JSON.stringify({
                                                    id: reservationData?.id,
                                                    textComplement: 'da reserva',
                                                    manualReceiptNumber: receiptNumber,
                                                    local: reservationData.services_name,
                                                    paymentStatus: reservationData.original_status,
                                                    startDate: reservationData.startDate,
                                                    userId: reservationData.schedule_users_id,
                                                    userName: reservationData.schedule_users_firstname,
                                                    original_user_firstName: reservationData.original_user_firstName,
                                                    original_user_lastName: reservationData.schedule_users_lastname,
                                                    ...reservationData,
                                                    associateSchedules: reservationData.associateSchedules.map(anotherHour => anotherHour.startDate),
                                                    associateSchedulesValue: reservationData.associateSchedules.map(anotherHour => anotherHour.serviceValue),
                                                }));
                                            }}
                                                className="p-2 hover:bg-gray-100 hover:dark:bg-zinc-600 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                <FaList className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                <Link className='flex flex-col items-center justify-center' to="/receipt" target="_blank">
                                                    <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Imprimir recibo</a>
                                                </Link>
                                            </div>
                                        </Tippy>
                                    }
                                    {
                                        (reservationData.payments_status === 0 || reservationData.payments_status === 3) && reservationData.status !== 2 &&
                                        <Tippy content={<p>Informar pagamento da reserva</p>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div onClick={() => handleSubmitNewPayment()} className="p-2 hover:bg-gray-100 hover:dark:bg-zinc-600 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                <FaRegMoneyBillAlt className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Informar pagamento</a>
                                            </div>
                                        </Tippy>
                                    }

                                    {
                                        !loading && reservationData.status !== 2 &&
                                        <Tippy content={<p>Imprimir ficha de reserva</p>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={100}>
                                            <div
                                                onClick={() => localStorage.setItem('receipt', JSON.stringify({
                                                    id: reservationData?.id,
                                                    textComplement: 'da reserva',
                                                    local: reservationData.services_name,
                                                    paymentStatus: reservationData.originalPaymentStatus,
                                                    startDate: reservationData.startDate,
                                                    userId: reservationData.schedule_users_id,
                                                    userName: reservationData.schedule_users_firstname,
                                                    original_user_firstName: reservationData.original_user_firstName,
                                                    ...reservationData,
                                                    associateSchedules: reservationData.associateSchedules.map(anotherHour => anotherHour.startDate),
                                                    associateSchedulesValue: reservationData.associateSchedules.map(anotherHour => anotherHour.serviceValue),
                                                }))}
                                                className="p-2 hover:bg-gray-100 hover:dark:bg-zinc-600 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                <FaRegNewspaper className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                <Link className='flex flex-col items-center justify-center' to="/receiptConfirmation" target="_blank">
                                                    <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Ficha de confirmação</a>
                                                </Link>
                                            </div>
                                        </Tippy>
                                    }
                                </div>
                            }
                            {
                                reservationData.status === 2 &&
                                <div className='flex flex-col p-2 bg-gray-500'>
                                    <a className='text-white text-sm'>Cancelado</a>
                                </div>
                            }
                        </div>
                        <div className='flex flex-col w-full'>
                            <div className='mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                <h1 className='text-primaryDefaultLight text-base'>Logs</h1>
                            </div>
                            <div className='hidden sm:flex flex-col items-center justify-center w-full boxShadow overflow-y-auto rounded-lg'>
                                <TableDefault
                                    cursor={false}
                                    pagination={true}
                                    totalPages={totalPages}
                                    onClick={(e) => console.log(e)}
                                    title={tableThead}
                                    data={filteredLogs}
                                    collumns={["created_at", "users_fullname", "action",]}
                                    loading={loadingLog}
                                    filter={(e) => logs(e)}
                                />
                            </div>
                        </div>
                    </div>
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button
                            id='cancelUserCreate'
                            approval={false}
                            shadow={true}
                            onClick={() => setInEdit({ edit: false, data: reservationData })}
                        >Voltar
                        </Button>
                    </div>
                </Footer>
            </Container>
        </>

    )
}