import React, { useState } from 'react'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { FaArrowCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa'
import { Button } from '../../../components/buttons/button.default'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
//UTILS
import { permittedFiles } from '../../../utils/permittedFiles/permittedFiles'
import { resizePhoto } from '../../../utils/formatImages/resizeImage'
import { Footer } from '../../../components/footer/Footer'
import { validateFields } from '../../../utils/form.validator'
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { api } from '../../../services/api/api'
import { useNavigate } from 'react-router-dom'
import { verifyFileSize } from '../../../utils/validators/verifyFileSize'
import { resizeImageCanvas } from '../../../utils/formatImages/resizeImageCanvas'
import { UrlToImage } from '../../../utils/formatImages/urlToImgFile'
import { resizeImageTest } from '../../../utils/formatImages/resizeImageTest'

export function EventsCreate() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()

    const [loading, setLoading] = useState(false)
    const [loadingFile, setLoadingFile] = useState(false)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [starts_in, setStarts_in] = useState('')

    //photos
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    const [showPhoto, setShowPhoto] = useState(<a>Sem dados</a>)
    const [agreementAppendPhotos, setAgreementAppendPhotos] = useState([])

    //pictures
    function handleClickInputFile() {
        let inputEl = document.getElementById('pictureAddInput')
        inputEl.click()
    }

    async function handleNewPicture(e) {

        const isValid = verifyFileSize(e.target.files[0])
        if (!isValid) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Arquivo maior do que o permitido (20MB)')
        }
        setShowNotificationModal(false)

        const validFile = permittedFiles(e.target.files[0], 'image')
        if (validFile.error) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText(validFile.response)
        }

        if (agreementAppendPhotos.length < 1) {

            const formattedImg = await new Promise((resolve, reject) => {
                resizeImageTest(e, (err, resizedFile) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    resolve(resizedFile)
                })
            })
            const photoObj = URL.createObjectURL(e.target.files[0])
            setAgreementAppendPhotos([{ photoObj, file: formattedImg }])           
        }
    }

    const prevPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = agreementAppendPhotos[showPhoto - 1].photoObj
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto - 1)
    }

    const nextPhoto = () => {
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)

        let nextImg = document.createElement('img')
        nextImg.src = agreementAppendPhotos[showPhoto + 1].photoObj
        nextImg.id = 'appendedPreviewPhoto'
        modalElement.appendChild(nextImg)

        setShowPhoto(showPhoto + 1)
    }

    async function showThisPhoto(photo, indexPhoto) {

        setShowPhotoModal(true)

        const modalElement = document.getElementById('showPhotoModal')

        let img = document.createElement('img')
        img.src = photo
        img.id = 'appendedPreviewPhoto'
        modalElement.appendChild(img)
        setShowPhoto(indexPhoto)
        await new Promise(resolve => setTimeout(resolve, 100))
        document.getElementById('photoContainer').style.opacity = '1'
        document.getElementById('photoContainer').style.transform = 'translateY(0px)'

    }

    async function closeThisPhoto() {

        document.getElementById('photoContainer').style.opacity = '0'
        document.getElementById('photoContainer').style.transform = 'translateY(-100px)'
        await new Promise(resolve => setTimeout(resolve, 300))

        setShowPhotoModal(false)
        const modalElement = document.getElementById('showPhotoModal')
        const img = document.getElementById('appendedPreviewPhoto')
        modalElement.removeChild(img)
        setShowPhoto('')
    }

    async function removeThisPhoto(photo, indexPhoto) {

        document.getElementById(photo.photoObj + '-' + indexPhoto).style.transform = 'scale(.5)'
        await new Promise(resolve => setTimeout(resolve, 500))

        if (photo.isMaster) {
            let photos = []
            agreementAppendPhotos.filter(photoToRemove => photoToRemove.file.name !== photo.file.name).map((newPhotos, index) => {
                if (index === 0) {
                    photos.push({
                        ...newPhotos,
                        isMaster: true,
                        isMasterName: newPhotos.file.name
                    })
                } else {
                    photos.push({
                        ...newPhotos,
                        isMaster: false
                    })
                }
            })
            setAgreementAppendPhotos(photos)
        } else {
            setAgreementAppendPhotos(agreementAppendPhotos.filter(photoToRemove => photoToRemove.file.name !== photo.file.name))
        }

        document.getElementById(photo.photoObj + '-' + indexPhoto).style.transform = 'scale(1)'
    }

    async function handleSubmit() {

        const fields = [
            { "name": 'title', "value": title, "required": true, "type": 'string' },
            { "name": 'description', "value": description, "required": true, "type": 'string' },
            { "name": 'starts_in', "value": starts_in, "required": true, "type": 'string' },
        ]

        if (validateFields(fields)?.length > 0) {
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(false)
            setLoading(false)
            return setShowNotificationModalText('Campos Obrigatórios não preenchidos, verifique os campos em vermelho')
        }
        setLoading(true)
        try {

            const event = await api.post(`/api/v1/events`, {
                "title": title,
                "description": description,
                "starts_in": starts_in
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            })

            let eventId = event.data.data[0]?.id

            if (agreementAppendPhotos.length > 0) {
                const formData = new FormData();
                agreementAppendPhotos.map((photo) => {
                    formData.append('images', photo.file)
                })
                const sendPhoto = await api.post(`/api/v1/eventsPictures/events_id/${eventId}`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                })

            }
            setLoading(false)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            navigate('/events')
        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                animateLoading(true)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <Container>
            <TitlePage>
                <Title text={'Novo evento'} />
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ?
                        <div className='flex flex-col h-96 w-full items-center justify-center'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div className='w-full items-start justify-start flex flex-col gap-4'>
                                <div className='w-full flex flex-col gap-2 items-start justify-start'>
                                    <LabelInput text={'Titulo'}>
                                        <Input charLimit={100} id='title' value={title} onChange={(e) => setTitle(e.target.value)} />
                                    </LabelInput>
                                    <a className='text-xt text-inputPlaceholderLight'>{title?.length} de 100</a>
                                    <LabelInput text={'Data de início'}>
                                        <Input charLimit={100} id='starts_in' type={'date'} value={starts_in} onChange={(e) => setStarts_in(e.target.value)} />
                                    </LabelInput>
                                    <a className='text-inputPlaceholderLight text-sm'>Descrição</a>
                                    <textarea maxLength={1000} id='description' value={description} onChange={(e) => setDescription(e.target.value)} wrap="hard" className=" p-2 border border-gray-400 border-opacity-75 
                            h-64
                            w-full
                            text-sm
                            outline-none transition-all duration-200 focus:shadow-borderShadow
                            dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark" />
                                    <a className='text-xt text-inputPlaceholderLight'>{description?.length} de 1000</a>

                                </div>
                            </div>
                            <div id='eventCreatePictures' className='w-full flex flex-col  items-start justify-start gap-4 user-select-none'>
                                <div className="flex flex-col gap-4">
                                    <div className='flex flex-col '>
                                        <h1 className='text-primaryDefaultLight text-base'>Foto</h1>
                                    </div>
                                    <div className='flex flex-row gap-2 items-start justify-start'>
                                        <div className={`${showPhotoModal ? 'flex' : 'hidden'} flex-row items-center justify-center w-full h-full fixed top-0 left-0 z-50`}>
                                            <div id='photoContainer' className={`opacity-0 transition-all duration-500 -translate-y-10 flex gap-4 flex-row items-center justify-center w-full h-full`}>
                                                {
                                                    showPhoto > 0 ?
                                                        <FaArrowCircleLeft onClick={prevPhoto} className='text-primaryDefaultLight hover:-translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                        :
                                                        <FaArrowCircleLeft className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowCircleLeft>
                                                }
                                                <div className='bg-bgPrimaryLight dark:bg-primaryDefaultDark boxShadow p-8 w-full h-full md:w-[700px] md:h-[500px] lg:w-[1000px] lg:h-[600px] flex flex-col items-center justify-start'>
                                                    <div className='flex flex-row justify-end w-full'>
                                                        <button className='bg-primaryDefaultLight hover:brightness-125 transition-all duration-200 h-8 w-8 rounded-full flex flex-col items-center justify-center text-titleGrayTextLight' onClick={() => closeThisPhoto()}>X</button>
                                                    </div>
                                                    <div id='showPhotoModal' className='flex flex-col items-center justify-center overflow-auto w-full h-full'>
                                                    </div>
                                                </div>
                                                {
                                                    showPhoto < agreementAppendPhotos.length - 1 ?
                                                        <FaArrowAltCircleRight onClick={nextPhoto} className='text-primaryDefaultLight hover:translate-x-2 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                        :
                                                        <FaArrowAltCircleRight className='text-transparent opacity-0 hover:brightness-150 transition-all duration-300 text-4xl cursor-pointer'></FaArrowAltCircleRight>
                                                }
                                            </div>
                                        </div>
                                        <div className={`${showPhotoModal ? 'flex' : 'hidden'}  bg-gray-600 opacity-80 w-full h-full fixed z-40 left-0 top-0`}></div>

                                        <h1 className='text-sm text-inputPlaceholderLight'>Foto</h1>
                                        <p className='font-semibold text-base cursor-pointer' onClick={() => handleClickInputFile()}>+</p>
                                    </div>

                                    {
                                        !loadingFile &&
                                        <input id='pictureAddInput' type='file' accept='.png, .jpeg, .jpg' className='hidden' onChange={(e) => handleNewPicture(e)}></input>
                                    }
                                    <div className='flex flex-row gap-2 items-center justify-start'>
                                        {
                                            agreementAppendPhotos.map((photo, indexPhoto) => {
                                                return <div id={photo.photoObj + '-' + indexPhoto} className='cursor-pointer transition-all duration-500 ease-in-out relative flex flex-row gap-4 items-center justify-center'>
                                                    <Tippy content={'Remover foto'}
                                                        arrow={true}
                                                        animation='shift-away'
                                                        placement='right'
                                                        delay={300}>
                                                        <button onClick={() => removeThisPhoto(photo, indexPhoto)} className='text-titleGrayTextLight absolute -left-2 -top-2 w-10 h-10 sm:w-6 sm:h-6 rounded-full bg-gray-400'>x</button>
                                                    </Tippy>

                                                    <img onClick={() => showThisPhoto(photo.photoObj, indexPhoto)} className='border border-gray-500 rounded-xl w-20 h-20' src={photo.photoObj}></img>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </Body>
            <Footer>
                {
                    !loading &&
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button
                            id='cancelAgreementCreate'
                            approval={false}
                            shadow={true}
                            onClick={() => { history.back(); }}
                        >Voltar
                        </Button>

                        <Button
                            module={"events:create"}
                            userData={userData}
                            id='approvalEventsCreate'
                            approval={true}
                            shadow={true}
                            onClick={() => handleSubmit()}
                        >Cadastrar
                        </Button>

                    </div>
                }
            </Footer>
        </Container>
    )
}