import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
//components
import { Container } from "../../../components/container/container"
import { TitlePage } from "../../../components/titlePages/title.page"
import { Title } from "../../../components/titlePages/title"
import { Body } from "../../../components/container/Body"
import { useAuth } from "../../../contexts/useAuth"
import { useThemeContext } from "../../../contexts/themeContext"
import { DefaultLoader } from "../../../components/loaders/defaultLoader"
import { formattTimeToShow, geSchedulesDraw } from "../../../services/api/callAPIsFunctions/defaultCalls.api"
import { FaSearch, FaPlay, FaTimes, FaRegCalendarCheck, FaTrash, FaCheck, FaTrashAlt } from "react-icons/fa"
import Tippy from "@tippyjs/react"
import "tippy.js/themes/light.css"
import "tippy.js/animations/perspective.css"
import { ScreenModal } from "../../../components/modals/notification/screenModal"
import { Button } from "../../../components/buttons/button.default"
import { api } from "../../../services/api/api"
import { responseError } from "../../../utils/responsesFunctions/error.response"
import { maskHoursService } from "../../../utils/mask/maskHoursService"
import { TableDefault } from "../../../components/table/table.default"

export function SchedulesDraw() {

    const { userData } = useAuth()
    const {
        setShowUniversalModal,
        setShowNotificationModal,
        setShowNotificationModalText,
        setShowNotificationModalSuccess,
    } = useThemeContext()

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [loadingModal, setLoadingModal] = useState(false)
    const [loadingDraw, setLoadingDraw] = useState(false)
    const [showSubscribers, setShowSubscribers] = useState(false)
    const [showCancelOptions, setShowCancelOptions] = useState(false)
    const [drawList, setDrawList] = useState([])
    const [drawUserList, setDrawUserList] = useState([])
    const [selectedDraw, setSelectedDraw] = useState("")
    const [typeModal, setTypeModal] = useState("")
    const [winnerData, setWinnerData] = useState("")
    const [showCanCompeteUserQuestion, setShowCanCompeteUserQuestion] = useState(false)
    const [userToCanCompete, setUserToCanCompete] = useState('')
    const [selectedDrawToRemove, setSelectedDrawToRemove] = useState('')
    const [showRemoveDraw, setShowRemoveDraw] = useState(false)

    useEffect(() => {
        setShowNotificationModal(false)
        setShowUniversalModal(false)
        getData()
    }, [])

    async function getData(load) {
        load === "false" ? "" : setLoading(true)
        const drawData = await geSchedulesDraw(userData[0]?.token)
        if (drawData?.error) {
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(drawData?.data)
        }

        setDrawList(drawData.data)
        setLoading(false)
    }

    async function handleSchedule(data) {
        setLoading(true)
        try {
            await api.post(`/api/v1/schedules`, {
                schedule_users_id: data.users_id,
                scheduleDate: data.scheduleDate,
                servicesHours: [data.servicesHours_id],
                services_id: data.services_id,
                discount: undefined,
                useBalance: false,
                status: 0,
                paymentMethod: "D",
                observation: "Sorteio",
                schedulesDraw_id: data?.id,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`,
                },
            }
            )

            setLoading(false)
            setShowNotificationModalText("Reserva realizada com sucesso!")
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowUniversalModal(false)
            setTypeModal("")
            getData()
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setLoadingDraw(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText(responseError(error))
                return { error: true }
            } else {
                setLoading(false)
                setLoadingDraw(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText("Erro inesperado")
                return { error: true }
            }
        }
    }

    async function handleNewDraw(draw) {
        setLoadingDraw(true)
        try {
            const data = await api.post("/api/v1/schedulesDraw/drawWinner", {
                servicesHours_id: draw?.servicesHours_id,
                scheduleDate: draw?.scheduleDate,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`,
                }
            }
            )

            setWinnerData(data.data.data[0])
            getData("false")
            setShowNotificationModalText("Sorteio realizado com sucesso!")
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            await handleSchedule(data.data.data[0])
            setLoadingDraw(false)

        } catch (error) {

            if (responseError(error).length > 0) {
                // setLoadingDraw(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText(responseError(error))
                setShowUniversalModal(false)
                return { error: true }
            } else {
                // setLoadingDraw(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText("Erro inesperado")
                setShowUniversalModal(false)
                return { error: true }
            }
        }
    }

    function handleCanCompeteUser(user) {
        setShowCanCompeteUserQuestion(true)
        setUserToCanCompete(user)
    }

    async function handleCanCompeteUserOfDraw() {

        setLoading(true)
        try {
            const responseCanCompete = await api.patch(`/api/v1/schedulesDraw/${userToCanCompete?.id}/canCompete`, {
                compete: !userToCanCompete.compete
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText('Sucesso')
            setShowCanCompeteUserQuestion(false)
            setShowUniversalModal(false)
            setLoading(false)
        } catch (error) {
            if (responseError(error).length > 0) {
                // setLoadingDraw(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText(responseError(error))
                setShowCanCompeteUserQuestion(false)
                return { error: true }
            } else {
                // setLoadingDraw(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText("Erro inesperado")
                setShowCanCompeteUserQuestion(false)
                return { error: true }
            }
        }
    }

    async function handleShowSubscribers(draw, toEdit) {
        cleanStates('setShowSubscribers')
        setShowUniversalModal(true)
        setShowSubscribers(true)
        setLoadingModal(true)

        const getSubscribers = await api.get(
            `api/v1/schedulesDraw/scheduleDate/${draw?.scheduleDate}/servicesHours_id/${draw?.servicesHours_id}`,
            {
                headers: {
                    authorization: `Bearer ${userData[0]?.token}`
                }
            }
        )

        setDrawUserList(getSubscribers.data.data.map(sub => {
            return {
                ...sub,
                created_at: formattTimeToShow(sub?.created_at),
                compete: sub.compete ? 'Sim' : 'Não',
                remove: toEdit && sub.compete ?
                    <div onClick={() => handleCanCompeteUser(sub)} className="flex w-full items-center justify-center">
                        <FaTrash className="text-lg text-red-700 cursor-pointer" />
                    </div> : <div onClick={() => handleCanCompeteUser(sub)} className="flex w-full items-center justify-center">
                        <FaCheck className="text-lg text-green-700 cursor-pointer" />
                    </div>
            }
        }))

        setLoadingModal(false)
    }

    async function cancelScheduleAndDraw(draw) {
        setLoading(true)
        try {

            await api.put(
                `/api/v1/schedules/cancel/schedules_id/${draw?.schedules_id}`, {
                returnBalance: false
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0].token}`
                }
            }
            )

            setLoading(false)
            setShowNotificationModalText("Sucesso ao cancelar reserva e sorteio!")
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowUniversalModal(false)
            setShowCancelOptions(false)
            setTypeModal("")
            return getData()
        } catch (error) {
            if (responseError(error).length > 0) {
                setShowCancelOptions(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText(responseError(error))
                return { error: true }
            } else {
                setShowCancelOptions(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText("Erro inesperado")
                return { error: true }
            }
        }

    }

    async function handleDeleteDraw() {

        setLoading(true)
        try {
            await api.put(`/api/v1/schedulesDraw/cancelDraw/scheduleDate/${selectedDrawToRemove.scheduleDate}/servicesHours_id/${selectedDrawToRemove.servicesHours_id}`, {}, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Sorteio removido com sucesso!')
            setShowUniversalModal(false)
            setLoading(false)
            getData()
        } catch (error) {
            if (responseError(error).length > 0) {
                setShowCancelOptions(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText(responseError(error))
                return { error: true }
            } else {
                setShowCancelOptions(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowNotificationModalText("Erro inesperado")
                return { error: true }
            }
        }
    }

    function cleanStates(state) {
        if (state !== 'setShowCanCompeteUserQuestion') {
            setShowCanCompeteUserQuestion(false)
        }
        if (state !== 'setShowCancelOptions') {
            setShowCancelOptions(false)
        }
        if (state !== 'setShowRemoveDraw') {
            setShowRemoveDraw(false)
        }
        if (state !== 'setShowSubscribers') {
            setShowSubscribers(false)
        }
    }

    return (
        <>
            <ScreenModal funcCloseModal={() => { setShowSubscribers(false); setShowCancelOptions(false); setShowCanCompeteUserQuestion(false), setShowRemoveDraw(false) }} >
                {loadingModal ? (
                    <div className="flex flex-col w-60 h-48 items-center justify-center">
                        <DefaultLoader />
                    </div>
                ) :
                    showRemoveDraw ?
                        (
                            <div className="p-4 flex flex-col gap-5 items-center justify-center">
                                <a>Alerta!</a>
                                <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                    Deseja realmente excluir este sorteio?
                                </p>
                                <div className="flex mt-2 flex-row gap-4 w-full items-center justify-center">
                                    <Button shadow={true} onClick={() => handleDeleteDraw()}>Sim</Button>
                                    <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                                </div>
                            </div>
                        ) :
                        showCanCompeteUserQuestion ? (
                            <div className="p-4 flex flex-col gap-5 items-center justify-center">
                                <a>Alerta!</a>
                                <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                    Deseja realmente {userToCanCompete.compete ? 'tirar permissão de concorrer' : 'permitir que concorra novamente'}?
                                </p>
                                <div className="flex mt-2 flex-row gap-4 w-full items-center justify-center">
                                    <Button shadow={true} onClick={() => handleCanCompeteUserOfDraw()}>Sim</Button>
                                    <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                                </div>
                            </div>
                        ) : showCancelOptions ? (
                            <div className="p-4 flex flex-col gap-5 items-center justify-center">
                                <a>Alerta!</a>
                                <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                    Deseja realmente cancelar reserva?
                                </p>
                                <div className="flex mt-2 flex-row gap-4 w-full items-center justify-center">
                                    <Button shadow={true} onClick={() => cancelScheduleAndDraw(selectedDraw)}>Sim</Button>
                                    <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                                </div>
                            </div>
                        ) : showSubscribers ? (
                            <div className="p-5 w-[50rem]">
                                <div id='gastronomyTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow  overflow-y-auto rounded-lg'>
                                    <TableDefault
                                        onClick={(e) => e.func.execute(e.data)}
                                        title={[
                                            { name: 'ID da inscrição', original_name: 'id' },
                                            { name: 'Nome do usuário', original_name: 'users_fullname' },
                                            { name: 'Criado em', original_name: 'created_at' },
                                            { name: 'Compete', original_name: 'compete' },
                                            { name: 'Remover', original_name: 'remove' },
                                        ]}
                                        data={drawUserList}
                                        collumns={["id", "users_fullname", "created_at", "compete", "remove"]}
                                    />
                                </div>
                            </div>
                        ) : typeModal === "newSchedule" ? (
                            <div className="p-4 flex flex-col gap-4 items-center justify-center">
                                <a>Ganhador: {winnerData?.userName}</a>
                                <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                    Deseja realizar a reserva para o ganhador?
                                </p>
                                <div className="flex flex-row gap-4 w-full items-center justify-center">
                                    <Button shadow={true} onClick={() => handleSchedule(selectedDraw)}>Sim</Button>
                                    <Button shadow={true} approval={false} onClick={() => setShowUniversalModal(false)}>Não</Button>
                                </div>
                            </div>
                        ) : (
                            <div className="p-4 flex flex-col gap-4 items-center justify-center">
                                {loadingDraw ? (
                                    <div className="w-96 h-48 flex flex-col items-center justify-center">
                                        <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark animate-bounce">
                                            sorteando...
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                            Deseja realizar o sorteio deste dia/horário?
                                        </p>
                                        <div className="flex flex-row gap-4 w-full items-center justify-center">
                                            <Button
                                                shadow={true}
                                                onClick={() =>
                                                    handleNewDraw(selectedDraw)
                                                }
                                            >
                                                Sim
                                            </Button>
                                            <Button
                                                shadow={true}
                                                approval={false}
                                                onClick={() =>
                                                    setShowUniversalModal(false)
                                                }
                                            >
                                                Não
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={"Sorteios"} />
                </TitlePage>
                <Body>
                    {loading ? (
                        <div className="flex flex-col w-full h-96 items-center justify-center">
                            <DefaultLoader />
                        </div>
                    ) : (
                        <div className="flex flex-col w-full items-center gap-6">
                            {drawList?.length === 0 ?
                                <p className="text-titleBlackTextLight dark:text-titleGrayTextDark">
                                    Nenhum Sorteio disponível
                                </p>
                                :
                                <p className="text-titleBlackTextLight dark:text-titleGrayTextDark">
                                    Sorteios disponíveis
                                </p>
                            }
                            <div className="flex flex-row flex-wrap gap-4 items-center justify-center">
                                {drawList?.map((draw) => {
                                    return draw?.winner ? (
                                        <div
                                            className={` dark:border-zinc-700 flex flex-col items-start justify-start gap-2 w-[26rem] h-[18rem] rounded-lg p-2 dark:bg-zinc-700 bg-zinc-200 shadow-lg`}
                                        >
                                            <div className="flex flex-row gap-2 items-start justify-between w-full">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    Status:{" "}
                                                </strong>
                                                <a className={`text-sm ${draw?.schedules_id ? 'text-green-600' : 'text-blue-600'} font-bold`}>
                                                    {draw?.schedules_id ? 'Sorteio encerrado - Reserva realizada' : 'Sorteio encerrado - Pendente realizar reserva'}
                                                </a>
                                            </div>
                                            <div className="flex flex-row gap-2 items-start justify-between w-full ">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    Serviço/Espaço:
                                                </strong>
                                                <a onClick={() => navigate(`/servicesEdit/${draw?.services_id}`)}
                                                    className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark cursor-pointer underline hover:text-primaryDefaultLight"
                                                >
                                                    {draw?.services_name}
                                                </a>
                                            </div>
                                            <div className="flex flex-row gap-2 items-start justify-between w-full">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    Data:
                                                </strong>
                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    {formattTimeToShow(draw?.scheduleDate)}
                                                </a>
                                            </div>
                                            <div className="flex flex-row gap-2 items-start justify-between w-full">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    Horário:
                                                </strong>
                                                <div className="flex flex-row items-center justify-center gap-1">
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                        {maskHoursService(draw?.startHour)}
                                                    </a>{" "}
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">as</a>{" "}
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                        {maskHoursService(draw?.finishHour)}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="flex flex-row gap-2 items-start justify-between w-full">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    Ganhador:{" "}
                                                </strong>
                                                <a
                                                    onClick={() => navigate(`/clientsEdit/${draw?.users_id}`)}
                                                    className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark cursor-pointer underline hover:text-primaryDefaultLight"
                                                >
                                                    {draw?.userName}
                                                </a>
                                            </div>
                                            <div className="flex flex-row gap-2 items-start justify-between w-full">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    ID sorteio:{" "}
                                                </strong>
                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    {draw?.id}
                                                </a>
                                            </div>
                                            <div className="flex flex-row gap-2 items-start justify-between w-full">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    ID da reserva:
                                                </strong>
                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    {
                                                        draw?.schedules_id
                                                            ? <a
                                                                onClick={() => navigate('/reservation')}
                                                                className='text-titleBlackTextLight dark:text-titleGrayTextDark cursor-pointer underline hover:text-primaryDefaultLight'
                                                            >
                                                                {draw.schedules_id}
                                                            </a>
                                                            : "Reserva ainda não realizada para o ganhador"
                                                    }
                                                </a>
                                            </div>
                                            <div className="h-full w-full flex items-center justify-center gap-4">
                                                {
                                                    userData[0]?.permissions.includes('schedulesDraw:delete') || userData[0]?.typeAccess_id < 3 &&
                                                    <Tippy
                                                        content={draw?.schedules_id ? "Cancelar sorteio e reserva" : "Reservar para ganhador"}
                                                        arrow={true}
                                                        animation="shift-away"
                                                        placement="top"
                                                        zIndex={"100"}
                                                        delay={100}>
                                                        <div
                                                            onClick={() => {
                                                                if (draw?.schedules_id) {
                                                                    cleanStates('setShowCancelOptions')
                                                                    setSelectedDraw(draw)
                                                                    setShowCancelOptions(true)
                                                                    setShowUniversalModal(true)
                                                                } else {
                                                                    setWinnerData(draw)
                                                                    setSelectedDraw(draw)
                                                                    setTypeModal("newSchedule")
                                                                    setShowUniversalModal(true)
                                                                }
                                                            }}
                                                            className="cursor-pointer hover:scale-110 hover:bg-zinc-400 hover:text-zinc-500 text-zinc-400 p-4 rounded-full border border-zinc-400"
                                                        >
                                                            {draw?.schedules_id ? <FaTimes className="text-xl text-zinc-500" /> : <FaRegCalendarCheck className="text-xl text-zinc-500" />}
                                                        </div>
                                                    </Tippy>
                                                }
                                                <Tippy
                                                    content={"Visualizar inscritos"}
                                                    arrow={true}
                                                    animation="shift-away"
                                                    placement="top"
                                                    zIndex={"100"}
                                                    delay={100}
                                                >
                                                    <div
                                                        onClick={() => handleShowSubscribers(draw)}
                                                        className="cursor-pointer hover:scale-110 hover:bg-zinc-400 hover:text-zinc-500 text-zinc-400 p-4 rounded-full border border-zinc-400"
                                                    >
                                                        <FaSearch className="text-xl text-zinc-500"></FaSearch>
                                                    </div>
                                                </Tippy>
                                                {
                                                    !draw?.schedules_id && userData[0]?.permissions.includes('schedulesDraw:delete') || userData[0]?.typeAccess_id < 3 &&
                                                    <Tippy
                                                        content={"Excluir o sorteio"}
                                                        arrow={true}
                                                        animation="shift-away"
                                                        placement="top"
                                                        zIndex={"100"}
                                                        delay={100}
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                cleanStates('setShowRemoveDraw')
                                                                setShowRemoveDraw(true)
                                                                setSelectedDrawToRemove(draw)
                                                                setShowUniversalModal(true)
                                                            }}
                                                            className="cursor-pointer hover:scale-110 hover:bg-zinc-400 
                                                hover:text-zinc-500 text-zinc-400 p-4 rounded-full border border-zinc-400">
                                                            <FaTrashAlt className="text-xl text-zinc-500"></FaTrashAlt>
                                                        </div>
                                                    </Tippy>
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={`flex flex-col gap-2 items-start justify-start w-[26rem] h-[18rem] rounded-lg ${!draw?.winner
                                                ? "dark:bg-zinc-700 bg-zinc-100 border hover:bg-zinc-200"
                                                : " bg-bgSecondaryLight "
                                                } shadow-lg p-2`}
                                        >
                                            <div className="flex flex-row gap-2 items-start justify-between w-full">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    Status:{" "}
                                                </strong>
                                                <a className="text-sm text-red-700 font-bold">
                                                    Sorteio pendente
                                                </a>
                                            </div>
                                            <div className="flex flex-row gap-2 items-start justify-between w-full ">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    Serviço/Espaço:
                                                </strong>
                                                <a
                                                    onClick={() => navigate(`/servicesEdit/${draw?.services_id}`)}
                                                    className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark cursor-pointer underline hover:text-primaryDefaultLight"
                                                >
                                                    {draw?.services_name}
                                                </a>
                                            </div>
                                            <div className="flex flex-row gap-2 items-start justify-between w-full">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    Data:
                                                </strong>
                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    {formattTimeToShow(
                                                        draw?.scheduleDate
                                                    )}
                                                </a>
                                            </div>
                                            <div className="flex flex-row gap-2 items-start justify-between w-full">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    Horário:
                                                </strong>
                                                <div className="flex flex-row items-center justify-center gap-1">
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                        {maskHoursService(
                                                            draw?.startHour
                                                        )}
                                                    </a>{" "}
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">as</a>{" "}
                                                    <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                        {maskHoursService(
                                                            draw?.finishHour
                                                        )}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="flex flex-row gap-2 items-start justify-between w-full">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    Ganhador:{" "}
                                                </strong>
                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark cursor-pointer">-</a>
                                            </div>
                                            <div className="flex flex-row gap-2 items-start justify-between w-full">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    ID sorteio:{" "}
                                                </strong>
                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">-</a>
                                            </div>
                                            <div className="flex flex-row gap-2 items-start justify-between w-full">
                                                <strong className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">
                                                    ID da reserva:
                                                </strong>
                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">-</a>
                                            </div>
                                            <div className="w-full flex flex-row items-center justify-center h-full gap-4">
                                                {
                                                    userData[0]?.permissions.includes('schedulesDraw:create') || userData[0]?.typeAccess_id < 3 &&
                                                    <Tippy
                                                        content={"Realizar sorteio"}
                                                        arrow={true}
                                                        animation="shift-away"
                                                        placement="top"
                                                        zIndex={"100"}
                                                        delay={100}
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                setSelectedDraw(draw)
                                                                cleanStates()
                                                                setShowUniversalModal(true)
                                                            }}
                                                            className="cursor-pointer hover:scale-110 hover:bg-zinc-400 hover:text-zinc-500 text-zinc-400 p-4 rounded-full border border-zinc-400"
                                                        >
                                                            <FaPlay />
                                                        </div>
                                                    </Tippy>
                                                }

                                                <Tippy
                                                    content={"Verificar inscritos"}
                                                    arrow={true}
                                                    animation="shift-away"
                                                    placement="top"
                                                    zIndex={"100"}
                                                    delay={100}
                                                >
                                                    <div
                                                        onClick={() => handleShowSubscribers(draw, true)}
                                                        className="cursor-pointer hover:scale-110 hover:bg-zinc-400 
                                                hover:text-zinc-500 text-zinc-400 p-4 rounded-full border border-zinc-400">
                                                        <FaSearch className="text-xl text-zinc-500"></FaSearch>
                                                    </div>
                                                </Tippy>
                                                {
                                                    userData[0]?.permissions.includes('schedulesDraw:delete') || userData[0]?.typeAccess_id < 3 &&
                                                    <Tippy
                                                        content={"Excluir o sorteio"}
                                                        arrow={true}
                                                        animation="shift-away"
                                                        placement="top"
                                                        zIndex={"100"}
                                                        delay={100}
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                cleanStates('setShowRemoveDraw')
                                                                setShowRemoveDraw(true)
                                                                setSelectedDrawToRemove(draw)
                                                                setShowUniversalModal(true)
                                                            }}
                                                            className="cursor-pointer hover:scale-110 hover:bg-zinc-400 
                                                hover:text-zinc-500 text-zinc-400 p-4 rounded-full border border-zinc-400">
                                                            <FaTrashAlt className="text-xl text-zinc-500"></FaTrashAlt>
                                                        </div>
                                                    </Tippy>
                                                }

                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </Body>
            </Container>
        </>
    )

}
