import React from 'react'

export function Footer({ children, setDisplayNotification }) {

    return (

        <div className='shadow-sm bg-bgPrimaryLight border-t border-zinc-200 dark:bg-primaryDefaultDark dark:border-b dark:border-primaryBorderDark flex flex-col justify-center items-center w-full px-1 sm:pl-8 sm:pr-8 h-14 2xl:h-20 '>
            <div className='flex flex-col w-full max-w-[1950px] h-full items-center justify-between sm:px-6'>
                {children}
            </div>
        </div>
    )
}